
//table columns for lab test 
export const columns: TableColumn[] = [
    {
        Header: 'operatorManagement.tableColumns.slNo',
        accessor: 'slno',
      },
      {
        Header: 'tableCommon.batch_code',
        accessor: 'batch_number',
      },
      {
        Header: 'tableCommon.seriel_number',
        accessor: 'serial_number',
      },
      {
        Header: 'operatorManagement.tableColumns.labTestDoneOn',
        accessor: 'labTestDoneOn',
      },
      {
        Header: 'operatorManagement.tableColumns.colonyCount',
        accessor: 'colonyCount',
      },
      {
        Header: 'operatorManagement.tableColumns.testRemark',
        accessor: 'testRemarks',
      },
  ];
  

  export const PendingColumns: TableColumn[] = [
    {
        Header: 'operatorManagement.tableColumns.slNo',
        accessor: 'sl_no',
      },
      {
        Header: 'operatorManagement.tableColumns.serialNo',
        accessor: 'serial_number',
      },
      {
        Header: 'tableCommon.batch_code',
        accessor: 'batch_number',
      },
      {
        Header: 'operatorManagement.tableColumns.plateType',
        accessor: 'activity_type',
      },
      {
        Header: 'operatorManagement.tableColumns.labStatus',
        accessor: 'lab_status',
      },
  ];


  interface TableColumn {
    Header: string;
    accessor: string;
  }
  