import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import HeaderSection from '../../../common/headerSection'
import PageHeader from '../../../common/pageHeader'
import CustomTable from '../../../../widgets/table'
import Pagination from '../../../../widgets/pagination'
import { TfiClose } from 'react-icons/tfi'
import { Modal } from 'react-bootstrap'
import { dashboardServices } from '../../../../service/dashboard'
import { userService } from '../../../../service/plateServices'
import { useTranslation } from 'react-i18next';
import { setLoading } from '../../../../store/store'
import { useDispatch } from 'react-redux'
import DownloadPdf from '../../../../widgets/downloadPdf'
import { labtestColumns as columns } from './partials/columns'
import { DEFAULT_PAGE_SIZE } from '../../../../constants/constants'

const moment = require('moment');


type LabtestStatType = {
  pending_plates_for_lab_test: number;
  plates_for_1st_lab_test: number;
  plates_for_2nd_lab_test: number;
  reviewed_plates: number;
  colony_count_within_the_range: number;
  colony_count_outside_the_range: number;
  deviations: number;
};

type PlateViewType = {
  incubation_point_name: string;
  incubation_start_date: string;
  end_date_time : string;
  serial_number: string;
  exceptionStatus: string;
  exception_added : string;
  started_by : string;
  ended_by : string;
  status : string;
  escallation : string;
};

const LabtestTable = () => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showModal , setShowModal] = useState(false);
  const [showButtonWithCloseIcon, setShowButtonWithCloseIcon] = useState(false);
  const [closeIconText, setCloseIconText] = useState("");
  const [labtestStat, setLabTestStat] = useState<LabtestStatType | null>(null);
  const [plateDetails, setPlateDetails] = useState<Array<any>>([]);
  const [plateView, setPlateView] = useState<PlateViewType | null>(null);
  const [page, setpage] = useState(1)
  const [pageSize, setpagesize] = useState(DEFAULT_PAGE_SIZE)
  const [totalPages, settotalPages] = useState(0)
  const [totalElements, setTotalElements] = useState(0)
  const [useEffectRequired, setUseEffectRequired] = useState(false)


  //handle table pagination 
const handleSchedulePageChange = (newPage : any, pageSize?: number) => {  
    setpage(newPage);
    if(pageSize) {
      setpagesize(pageSize)
    }   
    setUseEffectRequired(true)
};

//handle view click list each plate details 
const handleViewClick = (id: string, viewValue: boolean) => {
  const updatedData = plateDetails?.filter((row) => row.slno === id);
  setShowModal(viewValue);
  getPlateView(updatedData[0]?.id);
};

//handle close button 
const handleCloseBtnClick = () => {
  setShowButtonWithCloseIcon(false)
}

//api call to get the labtest stat details 
const getLabtestStatuDetails = async () => {
  try {
    const response = await dashboardServices.getLabtestStatuDetails(); 
      if(response.status && response.statusCode === 200){
        setLabTestStat({
          pending_plates_for_lab_test : response.data.pending_plates_for_lab_test,
          plates_for_1st_lab_test: response.data.lab_inprogress_plates,
          plates_for_2nd_lab_test : response.data.second_lab_plates_count,
          reviewed_plates : response.data.reviewed_plates_count,
          colony_count_within_the_range : response.data.count_within_range_plates,
          colony_count_outside_the_range : response.data.count_outside_range_plates,
          deviations : response.data.escalations,
          });
          dispatch(setLoading(false));
    }
  } catch (error) {
    console.error('getLabtestStatuDetails', error);
  }
};

//api call to list plate details under the labtest area 
const ListLabtestPlates = async () => {
    try {
      const response = await dashboardServices.ListLabtestPlates(page, pageSize);
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              settotalPages(response?.totalPages)
              setTotalElements(response?.totalElements)
             

              let rowData: any = response.data.map((element: any, index: number) => {

              
              const plateType = parseInt(element.plate_type, 10); 
              if (plateType == 1){
                var plate_type = "Q A Plate"
              }else if (plateType == 2){
                var plate_type = "Exposure Plate"
              }else if (plateType == 3){
                var plate_type = "Personnel Monitoring Plate"
              } else{
                var plate_type = "-"
              }

              const statusNumber = parseInt(element.plate_status, 10); 
              const plateStatus = userService.getPlateStatus(statusNumber);

              const createdFirstName = element.lab_details[0]?.collected_by_user_firstname || '-';
              const createdLastName = element.lab_details[0]?.collected_by_user_lastname || '-';
              const fullName = createdFirstName + ' ' + createdLastName;

              const updatedFirstName = element.lab_details[0]?.completed_by_user_firstname || '-';
              const updatedLastName = element.lab_details[0]?.completed_by_user_lastname || '-';
              let updatedFullName = '';
              if (updatedFirstName !== null) {
                updatedFullName = updatedFirstName;
              if (updatedLastName !== null) {
              updatedFullName += ' ' + updatedLastName;
              }
              } else {
              updatedFullName = '-';
              }

              const endDateTime = element.lab_details[0]?.completed_date_time;
              const formattedEndDate = endDateTime
              ? moment(endDateTime, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY - HH:mm:ss')
              : '-';
              // const status = endDateTime ? 'Completed' : 'Pending';


                return { 
                  slno: index + 1, 
                  id: element.id,
                  plate_serial_number : element.serial_number,
                  plate_status : plateStatus,
                  plate_type : plate_type,
                  lab_start_time : moment(element.lab_details[0]?.start_date_time, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY - HH:mm:ss'),
                  lab_start_by : fullName,
                  lab_end_time : formattedEndDate,
                  lab_end_by : updatedFullName,
                  colony_count : element.lab_details[0]?.colony_count
              };
            });
            setPlateDetails(rowData);
          }
        } catch (error) {
          console.log(error); 
        }
    } else {
      setPlateDetails([])
      console.log("no data")
    }
  } catch (error) {
    console.error('Plate response', error);
  }
};

//api call to load the plate details while clicking the view button 
const getPlateView = async (id: any) => {
  const dataToSend = {
    "id" : id
  }
  try {
    const response = await dashboardServices.scanPlateById(dataToSend);
    if (response.status && response.statusCode === 200) {

      const endDateTime = response.data.lab_test_details[0]?.completed_date_time;
      const formattedEndDate = endDateTime
      ? moment(endDateTime, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY - HH:mm:ss')
      : '-';
      const status = endDateTime ? 'Completed' : 'Pending';

    const createdFirstName = response.data.lab_test_details[0]?.collected_by_user_firstname;
    const createdLastName = response.data.lab_test_details[0]?.collected_by_user_lastname;
    const fullName = createdFirstName + ' ' + createdLastName;

    const updatedFirstName = response.data.lab_test_details[0]?.completed_by_user_firstname;
    const updatedLastName = response.data.lab_test_details[0]?.completed_by_user_lastname;
    let updatedFullName = '';
    if (updatedFirstName !== null) {
      updatedFullName = updatedFirstName;
    if (updatedLastName !== null) {
    updatedFullName += ' ' + updatedLastName;
    }
    } else {
    updatedFullName = '-';
    }
      setPlateView({
        incubation_point_name: response.data.lab_test_details[0]?.location_name,
        serial_number : response.data.serial_number,
        incubation_start_date : moment(response.data.lab_test_details[0]?.start_date_time, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY - HH:mm:ss'),
        end_date_time :  formattedEndDate,
        exceptionStatus : response.data.lab_test_details[0]?.exception_status || "-",
        exception_added : response.data.lab_test_details[0]?.exception_added || "-",
        escallation : response.data.lab_test_details[0]?.escallation || "-",
        started_by : fullName,
        ended_by : updatedFullName,
        status  : status
      });
    }
  } catch (error) {
    console.error('view response', error);
  }
};




useEffect(() => {
  dispatch(setLoading(true));
  getLabtestStatuDetails();
  ListLabtestPlates();
  setUseEffectRequired(false)
}, [useEffectRequired])

  return (
   <div>
    <HeaderSection />
    <PageHeader pageHeader="Dashboard" />
    <div id='dashWrap'>
    <nav aria-label="breadcrumb" className="breadcrumb-container row">
      <div className='col-10 d-flex'>
        <ol className="breadcrumb">
          <li className="breadcrumb-item dashboard-hover"><Link to="/*">{t('dashboard.title')}</Link></li>
          <li className="breadcrumb-item active" aria-current="page">{t('dashboard.lab_test_area')}</li>
        </ol>
      </div>
      <div className='col-2 d-flex'>
        <DownloadPdf divId="dashWrap" reportHead="LabTest Report" />
      </div>
    </nav>

    {/*Map the lab test area stat values */}
    <div className="key-values-container">
      {labtestStat && Object.entries(labtestStat).map(([key, value]) => (
          <div  key={key} className="key-value">
            <div className="key">
              {key.replace(/_/g, ' ')}
            </div>
            <div className='value'>{value}</div>
          </div>
        ))}
      </div>


    <div className='row'>
          <div className='col-md-12 table-footer mx-3 pe-5' style={{"height":"45vh"}}>
                <CustomTable
                    tableHead='Lab Test Area Details' 
                    data={plateDetails} 
                    columns={columns} 
                    isEditable={false} 
                    isActionVisible={false} 
                    isViewVisible={true} 
                    totalElements={totalElements}
                    isSeachable={false}
                    isToggleStatus={false}      
                    onViewClick={handleViewClick}
                    onCloseBtnClick={() => handleCloseBtnClick()}
                />
        </div>
        <div>
              <Pagination
                page={page}
                totalPages={totalPages}
                handlePageChange={handleSchedulePageChange}
              />
        </div>
    </div>
    </div>


    <Modal
              className="top-right-modal"
              centered={false}
              show={showModal}
          >
            <div>
                <div className="modalHead">
                <p className="modalHeadText ms-3 pt-3 p-2">{plateView?.incubation_point_name}</p>
                <div onClick={() => setShowModal(false)} className="p-2 mx-3 my-2 ">
                  <TfiClose fill="#FFFFFF" />
                </div>
              </div>
              <div>

              <div className="selectWrap view-exposure-head">
                  <div className="form-group my-1">
                    <label htmlFor="">{t('dashboard.plate_serial_id')}</label>
                    <br />
                    <input 
                       id="plate_serial_id"
                       value={plateView?.serial_number}
                       name="plate_serial_id"
                       type='text'
                       placeholder='Plate Serial Id'
                       className='view-exposure-head'
                       disabled
                    />
                  </div>

                  <div className="form-group my-1">
                    <label>{t('dashboard.status')}</label>
                    <br />
                    <input 
                       id="status"
                       value={plateView?.status}
                       name="status"
                       type='text'
                       placeholder='Status'
                       className='view-exposure-head'
                       disabled 
                    />  
                  </div>
                </div>
                  <div className="selectWrap mt-4">
                  <div className="form-group my-1">
                    <label htmlFor="">{t('dashboard.labtest_start')}</label>
                    <br />
                    <input 
                       id="userId"
                       value={plateView?.incubation_start_date}
                       name="userId"
                       type='text'
                       placeholder='user ID'
                       className='view-exposure-details'
                       disabled
                    />
                  </div>

                  <div className="form-group my-1">
                    <label>{t('dashboard.labtest_by')}</label>
                    <br />
                    <input 
                       id="labtest_by"
                       value={plateView?.started_by}
                       name="labtest_by"
                       type='text'
                       placeholder='Labtest By'
                       className='view-exposure-details'
                       disabled
                    />  
                  </div>
                </div>

                <div className="selectWrap mt-4">
                  <div className="form-group my-1">
                    <label htmlFor="">{t('dashboard.labtest_end')}</label>
                    <br />
                    <input 
                       id="userId"
                       value={plateView?.end_date_time}
                       name="userId"
                       type='text'
                       placeholder='user ID'
                       className='view-exposure-details'
                       disabled
                    />
                  </div>

                  <div className="form-group my-1">
                    <label>{t('dashboard.labtest_ended_by')}</label>
                    <br />
                    <input 
                       id="firstName"
                       value={plateView?.ended_by}
                       name="firstName"
                       type='text'
                       placeholder='First Name'
                       className='view-exposure-details'
                       disabled
                    />  
                  </div>
                </div>

                <div className="selectWrap mt-4">
                  <div className="form-group my-1">
                    <label htmlFor="">{t('dashboard.Exception_added')}</label>
                    <br />
                    <input 
                       id="Exception_added"
                       value={plateView?.exception_added}
                       name="Exception_added"
                       type='text'
                       placeholder='Exception added'
                       className='view-exposure-details'
                       disabled
                    />
                  </div>

                  <div className="form-group my-1">
                    <label>{t('dashboard.exception_status')}</label>
                    <br />
                    <input 
                       id="exception_status"
                       value={plateView?.exceptionStatus}
                       name="exception_status"
                       type='text'
                       placeholder='Exception Status'
                       className='view-exposure-details'
                       disabled 
                    />  
                  </div>
                </div>

                <div className="selectWrap-email mt-4">
                  <div className="form-group my-1">
                    <label htmlFor="">{t('dashboard.escalations')}</label>
                    <br />
                    <input 
                       id="escalations"
                       value={plateView?.escallation}
                       type='text'
                       placeholder='escalations'
                       className='view-exposure-details'
                       disabled
                    />
                  </div>
                </div>
               
              </div>
              <div className="bottomArea">
            <button onClick={() => setShowModal(false)} className="modalCancelBtn">
            {t('buttons.cancel')}
            </button>        
          </div>
            </div>
    </Modal>

 </div>

  )
}

export default LabtestTable;
