import React, { useEffect, useRef, useState } from "react";
import HeaderSection from "../../common/headerSection";
import PageHeader from "../../common/pageHeader";
import { useTranslation } from "react-i18next";
import Pagination from "../../../widgets/pagination";
import CustomTable from "../../../widgets/table";
import { HistoryColumns, columns } from "./columns";
import { userService } from "../../../service/plateServices";
import { BsQrCodeScan } from "react-icons/bs";
import { areaService } from "../../../service/areaServices";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import AddException from "../../common/addException";
import { SerielNumberScanner } from "../common/serielNumberScanner";
import baseUrl from "../../../service";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { floraServices } from "../../../service/fileServices/environmentFlora";
import CustomToast from "../../../widgets/toaster";
import AlertModal, { EsignModal } from "../../../widgets/alertModal";
import PlateDetailsViewModel from "../../lab_operator/labReport/models/plateDetailsModel";
import { DEFAULT_PAGE_SIZE } from "../../../constants/constants";

const EnvironmentalFlora = () => {
  const { t } = useTranslation();
  const scanAreaRef = useRef<HTMLInputElement>(null);

  const [tab, setTab] = useState("1");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [useEffectRequired, setUseEffectRequired] = useState(false);
  const [pendingDataTable, setPendingDataTable] = useState<Array<any>>([]);
  const [scanCode, setScanCode] = useState("");
  const [plateDetails, setPlateDetails] = useState<Array<any>>([]);
  const [userId, setUserId] = useState<number>(0);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const [serielScanShow, setSerielScanShow] = useState(false);
  const [show, setShow] = useState(false);
  const [labAreaName, setLabAreaName] = useState("");
  const areaId = useSelector((state: RootState) => state.area.areaId) || "";

  const [showView, setShowView] = useState(false);
  const [singlePlateDetails, setSinglePlateDetails] = useState<any>({});
  const [plateId, setPlateId] = useState("");
  const [capturedImageId, setCapturedImageId] = useState<any | null>("");
  const [zoomLevel, setZoomLevel] = React.useState(1);
  const [imageIds, setImageIds] = useState([]);
  const [colonyCount, setColonyCount] = useState<number>(0);
  const [areaAlert, setAreaAlert] = useState(false);
  const [historyPlateId, setHistoryPlateId] = useState("");

  const [shape, setShape] = useState<Array<any>>([]);
  const [type, setType] = useState<Array<any>>([]);
  const [color, setColor] = useState<Array<any>>([]);
  const [genus, setGenus] = useState<Array<any>>([]);
  const [species, setSpecies] = useState<Array<any>>([]);
  const [strain, setStrain] = useState<Array<any>>([]);

  const [pageHistory, setPageHistory] = useState(1);
  const [pageSizeHistory, setPageSizeHistory] = useState(DEFAULT_PAGE_SIZE);
  const [totalPagesHistory, setTotalPagesHistory] = useState(0);
  const [totalElementsHistory, setTotalElementsHistory] = useState(0);
  const [historyDataTable, setHistoryDataTable] = useState<Array<any>>([]);

  const [selectedOrganismTypes, setSelectedOrganismTypes] = useState<
    Array<any>
  >([]);
  const [selectedGenus, setSelectedGenus] = useState<Array<any>>([]);
  const [selectedSpecies, setSelectedSpecies] = useState<Array<any>>([]);
  const [savedLabId, setSavedLabId] = useState("");
  const [plateBatches, setPlateBatches] = useState([]);
  const [plateSerielNum, setPlateSerielNums] = useState([]);
  const [keyWord, setkeyWord] = useState("");
  const [activityType, setActivityType] = useState("");
  const [keyWordHistory, setkeyWordHistrory] = useState("");
  const [showHistoryView, setShowHistoryView] = useState(false);
  const [showEsignAlert, setShowEsignAlert] = useState(false);
  const [students, setStudents] = useState<Array<any>>([]);

  //handle pagination change
  const handlePageChange = (newPage: any, pageSize?: number) => {
    setPage(newPage);
    if (pageSize) {
      setPageSize(pageSize);
    }
    setUseEffectRequired(true);
  };

  //handle history pagination change
  const handleHistoryPageChange = (newPage: any, pageSize?: number) => {
    setPageHistory(newPage);
    if (pageSize) {
      setPageSizeHistory(pageSize);
    }
    setUseEffectRequired(true);
  };

  useEffect(() => {
    var storedData = localStorage.getItem("userDetails");

    if (storedData) {
      var storedObject = JSON.parse(storedData);
      setUserId(storedObject[0].id);
    }
    getPendingPlates();
    HistoryFloraPlates();
    getAreaByScan(areaId, "");
    listPlateBatch();
    listSerialNumber();
    setUseEffectRequired(false);
  }, [useEffectRequired]);

  //changes based on change in colony count
  useEffect(() => {
    setSelectedOrganismTypes(Array.from({ length: colonyCount }, () => ""));
    setSelectedGenus(Array.from({ length: colonyCount }, () => ""));
    setSelectedSpecies(Array.from({ length: colonyCount }, () => ""));
    setStudents(
      Array.from({ length: colonyCount }, (_: any, index: number) => ({
        colony_order: (index + 1).toString(),
        description: "",
        organism_type_id: "",
        genus_id: "",
        species_id: "",
        strain_id: "",
      }))
    );
  }, [colonyCount]);

  //api call to get pending plate list
  const getPendingPlates = async () => {
    try {
      const response = await floraServices.PendingFloraPlates(
        page,
        pageSize,
        areaId,
        activityType,
        keyWord
      );
      if (response?.status && response?.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            setTotalPages(response?.totalPages);
            setTotalElements(response?.totalElements);
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                const plateType = parseInt(element.plate_type, 10);
                if (plateType == 1) {
                  var plate_type = "Q A Plate";
                } else if (plateType == 2) {
                  var plate_type = "Exposure Plate";
                } else if (plateType == 3) {
                  var plate_type = "Personnel Monitoring Plate";
                } else if (plateType == 4) {
                  var plate_type = "Sampling Plate";
                } else if (plateType == 5) {
                  var plate_type = "Material Test Plate";
                } else if (plateType == 6) {
                  var plate_type = "Surface Monitoring Plate";
                } else if (plateType == 7) {
                  var plate_type = "Surface Monitoring - SWAB Collection";
                } else {
                  var plate_type = "-";
                }

                return {
                  sl_no: index + 1,
                  id: element.id,
                  serial_number: element.serial_number,
                  batch_number: element.batch_number,
                  activity_type: plate_type,
                };
              }
            );
            setPendingDataTable(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        setPendingDataTable([]);
        setTotalPages(0);
        setTotalElements(0);
      }
    } catch (error) {
      console.error("user response", error);
    }
  };

  //api call to get shapes
  const listShapesOptions = async () => {
    try {
      const response = await floraServices.listShapesOptions();
      if (response?.status && response?.statusCode === 200) {
        const rowData = response.data.map((element: any, index: any) => ({
          sl_no: index + 1,
          id: element.id,
          shape_name: element.shape_name,
        }));
        setShape(rowData);
      } else {
        setShape([]);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //api call to get color
  const listColorOptions = async () => {
    try {
      const response = await floraServices.listColorOptions();
      if (response?.status && response?.statusCode === 200) {
        const rowData = response.data.map((element: any, index: any) => ({
          sl_no: index + 1,
          id: element.id,
          colony_colour: element.colony_colour,
        }));
        setColor(rowData);
      } else {
        setColor([]);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //api call to get type
  const listTypeOptions = async () => {
    try {
      const response = await floraServices.listTypeOptions();
      if (response?.status && response?.statusCode === 200) {
        const rowData = response.data.map((element: any, index: any) => ({
          sl_no: index + 1,
          id: element.id,
          organism_type: element.organism_type,
        }));
        setType(rowData);
      } else {
        setType([]);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //handle table filters
  const handleFilter = (key: string, value: string) => {
    if (key === "search") {
      setkeyWord(value);
    } else if (key === "Plate Type") {
      setActivityType(value);
    }
    setUseEffectRequired(true);
  };

  //handle history filter
  const handleFilterHistory = (key: string, value: string) => {
    if (key === "search") {
      setkeyWordHistrory(value);
    }
    setUseEffectRequired(true);
  };

  //batch list for filter dropdown
  const listPlateBatch = async () => {
    try {
      const response = await userService.listPlateBatch();
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                return {
                  value: element.batch_number,
                  label: element.batch_number,
                };
              }
            );
            setPlateBatches(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.error("Plate response", error);
    }
  };

  //serial number listing for table drop down
  const listSerialNumber = async () => {
    try {
      const response = await userService.listSerialNumber();
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                return {
                  value: element.serial_number,
                  label: element.serial_number,
                };
              }
            );
            setPlateSerielNums(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.error("Plate response", error);
    }
  };

  //api call to get genus
  const handleOrganismTypeChange = async (
    selectedTypeId: string,
    i: number,
    id: string
  ) => {
    try {
      const response = await floraServices.listGenusOptions(selectedTypeId);
      if (response?.status && response?.statusCode === 200) {
        const rowData = response.data.map((element: any, index: any) => ({
          sl_no: index + 1,
          id: element.id,
          genus_name: element.genus_name,
          organism_type_id: i + 1,
        }));

        // Group data by organism_type_id
        const groupedData: { [key: number]: any[] } = {};
        rowData.forEach((row: any) => {
          const { organism_type_id } = row;
          if (!groupedData[organism_type_id]) {
            groupedData[organism_type_id] = [];
          }
          groupedData[organism_type_id].push(row);
        });

        // Iterate through grouped data and remove duplicates
        Object.keys(groupedData).forEach((key: string) => {
          const typeId = parseInt(key);
          const group = groupedData[typeId];
          if (group.length > 0) {
            // Remove duplicates
            const uniqueRows = group.reduce((acc: any[], current: any) => {
              if (!acc.find((item) => item.id === current.id)) {
                acc.push(current);
              }
              return acc;
            }, []);
            // Update genus state
            setGenus((prevGenus) => [
              ...prevGenus.filter(
                (genusItem) => genusItem.organism_type_id !== typeId
              ),
              ...uniqueRows,
            ]);
          }
        });

        // setGenus(rowData);
        // setGenus(prevGenus => [...prevGenus, ...rowData]);
      } else {
        let organismTypeId = i + 1;
        setGenus((prevGenus) => [
          ...prevGenus.filter(
            (genusItem) => genusItem.organism_type_id !== organismTypeId
          ),
        ]);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //handle genus api
  const handleGenusTypeChange = async (
    selectedTypeId: string,
    i: number,
    id: string
  ) => {
    try {
      const response = await floraServices.listSpeciesOptions(selectedTypeId);
      if (response?.status && response?.statusCode === 200) {
        const rowData = response.data.map((element: any, index: any) => ({
          sl_no: index + 1,
          id: element.id,
          name: element.name,
          genus_id: i + 1,
        }));

        // Group data by genus_id
        const groupedData: { [key: number]: any[] } = {};
        rowData.forEach((row: any) => {
          const { genus_id } = row;
          if (!groupedData[genus_id]) {
            groupedData[genus_id] = [];
          }
          groupedData[genus_id].push(row);
        });

        // Iterate through grouped data and remove duplicates
        Object.keys(groupedData).forEach((key: string) => {
          const typeId = parseInt(key);
          const group = groupedData[typeId];
          if (group.length > 0) {
            // Remove duplicates
            const uniqueRows = group.reduce((acc: any[], current: any) => {
              if (!acc.find((item) => item.id === current.id)) {
                acc.push(current);
              }
              return acc;
            }, []);
            // Update species state
            setSpecies((prevSpecies) => [
              ...prevSpecies.filter(
                (speciesItem) => speciesItem.genus_id !== typeId
              ),
              ...uniqueRows,
            ]);
          }
        });

        // setSpecies(rowData);
        // setSpecies(prevSpecies => [...prevSpecies, ...rowData]);
      } else {
        let genusId = i + 1;
        setSpecies((prevSpecies) => [
          ...prevSpecies.filter(
            (speciesItem) => speciesItem.genus_id !== genusId
          ),
        ]);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //handle species type api
  const handleSpeciesTypeChange = async (
    selectedTypeId: string,
    i: number,
    id: string
  ) => {
    try {
      const response = await floraServices.listStrainOptions(selectedTypeId);
      if (response?.status && response?.statusCode === 200) {
        const rowData = response.data.map((element: any, index: any) => ({
          sl_no: index + 1,
          id: element.id,
          name: element.name,
          species_id: i + 1,
        }));

        // Group data by species_id
        const groupedData: { [key: number]: any[] } = {};
        rowData.forEach((row: any) => {
          const { species_id } = row;
          if (!groupedData[species_id]) {
            groupedData[species_id] = [];
          }
          groupedData[species_id].push(row);
        });

        // Iterate through grouped data and remove duplicates
        Object.keys(groupedData).forEach((key: string) => {
          const typeId = parseInt(key);
          const group = groupedData[typeId];
          if (group.length > 0) {
            // Remove duplicates
            const uniqueRows = group.reduce((acc: any[], current: any) => {
              if (!acc.find((item) => item.id === current.id)) {
                acc.push(current);
              }
              return acc;
            }, []);
            // Update strain state
            setStrain((prevStrain) => [
              ...prevStrain.filter(
                (strainItem) => strainItem.species_id !== typeId
              ),
              ...uniqueRows,
            ]);
          }
        });

        // setStrain(rowData);
        // setStrain(prevStrain => [...prevStrain, ...rowData]);
      } else {
        let speciesId = i + 1;
        setStrain((prevStrain) => [
          ...prevStrain.filter(
            (strainItem) => strainItem.species_id !== speciesId
          ),
        ]);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //onclick on navigate icon from pending tab
  const onNavigateFloraClick = (data: any) => {
    setTab("2");
    handleScanCodeChange(data);
  };

  //pass the serial number if it is entered in text area
  const handleScanCodeInput = (result: any) => {
    try {
      const parsedResult = JSON.parse(result);
      if (typeof parsedResult === "object") {
        if (parsedResult.hasOwnProperty("serial_number")) {
          handleScanCodeChange(parsedResult.serial_number);
        }
      }
    } catch (error) {
      handleScanCodeChange(result);
      console.error("Error parsing JSON:", error);
    }
  };

  //fetch plate details by serial number in scan plate area
  const handleScanCodeChange = async (e: any) => {
    setScanCode(e);
    if (e.trim() === "") {
      setPlateDetails([]);
    } else {
      try {
        const response = await userService.listAutoClavePlateBySerielNo(
          e,
          "environmentalFlora"
        );
        if (response?.status && response?.statusCode === 200) {
          try {
            let rowData: any;
            if (response.data) {
              const element = response.data;
              interface Exposure {
                id: string;
                start_date_time: string;
                end_date_time: string;
                updated_by_first_name: string;
                updated_by_last_name: string;
                exposure_area_name: string;
              }
              // Calculate total exposed time
              const exposureDetails: Exposure[] =
                element.exposure_details || [];
              let totalExposedTime = 0;

              exposureDetails.forEach((exposure) => {
                const startDate = new Date(exposure.start_date_time);
                const endDate = new Date(exposure.end_date_time);
                const timeDifferenceMs =
                  endDate.getTime() - startDate.getTime();
                totalExposedTime += timeDifferenceMs;
              });
              //caluculate the total incubated time
              interface Incubation {
                id: string;
                start_date_time: string;
                end_date_time: string;
                created_by_first_name: string;
                updated_by_first_name: string;
                updated_by_last_name: string;
              }
              const incubationDetails: Incubation[] =
                element.incubation_details || [];
              let totalIncubatedTime = 0;
              incubationDetails?.forEach((incubation) => {
                const startDate = new Date(incubation.start_date_time);
                const endDate = incubation.end_date_time
                  ? new Date(incubation.end_date_time)
                  : null;
                if (endDate) {
                  const timeDifferenceMs =
                    endDate.getTime() - startDate.getTime();
                  totalIncubatedTime += timeDifferenceMs;
                }
              });
              interface Labtest {
                colony_count: string;
                plate_image_id: string;
                id: string;
                final_test: string;
              }
              // Calculate total exposed time
              const labDetails: Labtest[] = element.lab_test_details || [];
              let highestColonyCount: number = 0;
              let correspondingPlateImageIds: any = [];
              labDetails.forEach((labTest, index) => {
                const finalTest = labTest.final_test;
                const colonyCount = parseInt(labTest.colony_count);
                const labSaveId = labTest.id;
                if (
                  (colonyCount > highestColonyCount ||
                    (colonyCount === highestColonyCount && index === 0)) &&
                  finalTest === "true"
                ) {
                  highestColonyCount = colonyCount;
                  correspondingPlateImageIds = labTest.plate_image_id
                    .split(",")
                    .map((id) => parseInt(id.trim()));
                  setImageIds(correspondingPlateImageIds);
                  setCapturedImageId(correspondingPlateImageIds?.[0]);
                  setColonyCount(highestColonyCount);
                  setSavedLabId(labTest.id);
                }
              });
              rowData = {
                "Plate Serial Num": element.serial_number,
                "Batch ID": element.batch_number,
                "Plate Experiy Date": element.expiry_date,
              };
              setPlateId(element.id);
              scanPlateById(element.id)
            }
            setPlateDetails(rowData);
            listShapesOptions();
            listColorOptions();
            listTypeOptions();
          } catch (error) {
            console.log(error);
          }
        } else {
          setPlateDetails([]);
          setToastMessage(response.message);
          setToastType("error");
          setShowToast(true);
        }
      } catch (error) {
        console.error("Plate response", error);
      }
    }
  };

  //on click cancel button in labtest
  const handleClearBtn = () => {
    handleScanCodeChange("");
    setSinglePlateDetails({});
    setPlateId("");
    setImageIds([]);
    setCapturedImageId("");
    setColonyCount(0);
  };

  //top bar area name listing
  const data: Record<string, string | number> = {
    [t("EnvironmentalFlora.areaName")]: labAreaName,
  };

  //get area details from scan qr
  const getAreaByScan = async (id: any, location_type: any) => {
    try {
      const response = await areaService.getAreaByScan(id, location_type);
      if (response?.status && response?.statusCode === 200) {
        try {
          setLabAreaName(response.data.lab_area_name);
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.error("user response", error);
    }
  };

  //handle password confirmation function
  const handleESignConfirm = async (remarks: string) => {
    setShowEsignAlert(false);
    const dataToSend = {
      user_id: userId,
      plate_id: plateId,
      lab_id: savedLabId,
      colony_details: students,
      area_master_id: areaId,
      remarks: remarks,
      interface_name: "Add Environmental Flora",
    };

    try {
      const response = await floraServices.saveEnvironmentFlora(dataToSend);
      if (response?.status && response?.statusCode === 200) {
        setToastType("success");
        setToastMessage(response.message);
        setTab("1");
        getPendingPlates();
        setScanCode("");
      } else {
        setToastType("error");
        setToastMessage(response.message);
      }
      setShowToast(true);
      handleClearBtn();
    } catch (error) {
      console.error(error);
    }
  };

  //handle password authentication
  const handleConfirmSubmit = async () => {
    setShowEsignAlert(true);
    setAreaAlert(false);
  };

  //toaster message after scaning plate
  const handleScanCode = (serielNumber: any) => {
    if (serielNumber) {
      handleScanCodeChange(serielNumber);
      setToastMessage(t("EnvironmentalFlora.plateScanSuccess"));
      setToastType("success");
    } else {
      setToastMessage(t("EnvironmentalFlora.plateScanFailed"));
      setToastType("error");
    }
    setShowToast(true);
  };

  //handle view click
  const handleViewBtnClick = () => {
    setShowView(true);
  };

  //handle zoom out
  const handleZoomIn = () => {
    setZoomLevel(zoomLevel + 0.1);
  };

  //handle zoom out
  const handleZoomOut = () => {
    setZoomLevel(zoomLevel - 0.1);
  };

  //generate fields
  const generateFields = (
    shape: string[],
    color: string[],
    type: string[],
    genus: string[],
    species: string[],
    strain: string[]
  ) => {
    const fields = [];
    const labels = ["Remarks", "Organism Type", "Genus", "Species", "Strain"];
    const ids = [
      "description",
      "organism_type_id",
      "genus_id",
      "species_id",
      "strain_id",
    ];

    for (let i = 1; i <= colonyCount; i++) {
      fields.push(
        <>
          <p>{t('EnvironmentalFlora.colonyCount')}: {i}</p>
          <div key={i} className="d-flex mt-3 ">
            {labels.map((label, index) => (
              <div key={index} className="w-25 mx-3">
                <label htmlFor={`${ids[index]}_${i}`}>{label}:</label>
                {label === "Remarks" || label === "Description" ? (
                  <textarea
                    id={`${ids[index]}_${i}`}
                    className="colonyRemarkTextArea"
                    style={{ height: "32px" }}
                    placeholder={label}
                    onChange={(event) => {
                      handleStudentInfoChange(
                        i - 1,
                        ids[index],
                        event.target.value
                      );
                    }}
                  ></textarea>
                ) : (
                  <select
                    id={`${ids[index]}_${i}`}
                    className="form-select"
                    onChange={(event) => {
                      handleStudentInfoChange(
                        i - 1,
                        ids[index],
                        event.target.value
                      );
                    }}
                  >
                    <option value="">{t('common.select')}</option>
                    {label === "Shape" &&
                      shape.map((option: any) => (
                        <option key={option.id} value={option.id}>
                          {option.shape_name}
                        </option>
                      ))}
                    {label === "Colour" &&
                      color.map((option: any) => (
                        <option key={option.id} value={option.id}>
                          {option.colony_colour}
                        </option>
                      ))}
                    {label === "Organism Type" &&
                      type.map((option: any) => (
                        <option key={option.id} value={option.id}>
                          {option.organism_type}
                        </option>
                      ))}
                    {label === "Genus" &&
                      genus
                        .filter((option: any) => option.organism_type_id == i)
                        .map((option: any) => (
                          <option key={option.id} value={option.id}>
                            {option.genus_name}
                          </option>
                        ))}
                    {label === "Species" &&
                      species
                        .filter((option: any) => option.genus_id == i)
                        .map((option: any) => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                    {label === "Strain" &&
                      strain
                        .filter((option: any) => option.species_id == i)
                        .map((option: any) => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                  </select>
                )}
              </div>
            ))}
          </div>
        </>
      );
      fields.push(<hr key={`hr_${i}`} className="mt-4" />);
    }
    return fields;
  };

  //generate input fields based on data
  const handleStudentInfoChange = (index: number, key: any, value: any) => {
    const updatedStudents = [...students];
    updatedStudents[index][key] = value;
    setStudents(updatedStudents);

    if (key === "organism_type_id") {
      handleOrganismTypeChange(value, index, key);
      const updatedOrganismTypes = [...selectedOrganismTypes];
      updatedOrganismTypes[index] = value;
      setSelectedOrganismTypes(updatedOrganismTypes);
    } else if (key === "genus_id") {
      handleGenusTypeChange(value, index, key);
      const updatedGenusTypes = [...selectedGenus];
      updatedGenusTypes[index] = value;
      setSelectedGenus(updatedGenusTypes);
    } else if (key === "species_id") {
      handleSpeciesTypeChange(value, index, key);
      const updatedSpeciesTypes = [...selectedSpecies];
      updatedSpeciesTypes[index] = value;
      setSelectedSpecies(updatedSpeciesTypes);
    }
  };

  const fields = generateFields(shape, color, type, genus, species, strain);

  //api call to get pending plate list
  const HistoryFloraPlates = async () => {
    try {
      const response = await floraServices.HistoryFloraPlates(
        pageHistory,
        pageSizeHistory,
        areaId,
        keyWordHistory
      );
      if (response?.status && response?.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            setTotalPagesHistory(response?.totalPages);
            setTotalElementsHistory(response?.totalElements);
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                return {
                  slno: index + 1,
                  id: element.id,
                  serial_number: element.serial_number,
                  batch_number: element.batch_number,
                  // location_name:element.location_name,
                  updated_at: element.updated_at,
                  plate_id: element.plate_id,
                };
              }
            );
            setHistoryDataTable(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        setHistoryDataTable([]);
      }
    } catch (error) {
      console.error("user response", error);
    }
  };

  //click on view button in history
  const handleHistoryViewClick = (id: string, viewValue: boolean) => {
    const updatedData = historyDataTable?.filter((row: any) => row.slno === id);
    scanPlateById(updatedData[0].id, "history")
    setHistoryPlateId(updatedData[0].plate_id)
    setShowHistoryView(true);
  }

  useEffect(() => {
    if (tab === "2" && scanAreaRef.current) {
      scanAreaRef.current.focus();
    }
  }, [tab]);

  //api call to set the basic detail in flora 
  const scanPlateById = async (id:any, key?:string) => {
    const dataToSend = {
      "id" : id,
      "lab_area_id":areaId
    }
    let response
    if(key === "history") {
      response = await userService.scanPlateById({"id": id});
    } else {
      response = await floraServices.scanEnvironmentFloraById(dataToSend);
    }

    if(response?.status && response?.statusCode === 200){
     
      const plateType = parseInt(response?.data.plate_type, 10); 
                    if (plateType == 1){
                      var plate_type = "Q A Plate"
                    }else if (plateType == 2){
                      var plate_type = "Exposure Plate"
                    }else if (plateType == 3){
                      var plate_type = "Personnel Monitoring Plate"
                    }else if (plateType == 4){
                      var plate_type = "Sampling Plate"
                    }else if (plateType == 5){
                      var plate_type = "Material Test Plate"
                    }else if (plateType == 6){
                      var plate_type = "Surface Monitoring Plate"
                    }else if (plateType == 7){
                      var plate_type = "Surface Monitoring - SWAB Collection"
                    } else{
                      var plate_type = "-"
                    }

      const statusNumber = parseInt(response?.data?.status, 10); 
                   if (statusNumber == 21){
                    var status = "pending"
                   }else{
                    var status = "unknown"
                   }

      const basicDetails = {
        "id": response.data.id,
        "serial_number": response.data.serial_number,
        "batch_number": response.data.batch_number,
        "plate_status": response.data.plate_status,
        "status": status,
        "expiry_date": response.data.expiry_date,
        "manufacturing_date": response.data.manufacturing_date,
        "current_location_id": response.data.current_location_id,
        "plate_type": plate_type,
        "accepted_colony_count" : `${response.data.min_count} - ${response.data.max_count}`
      }
      setSinglePlateDetails(basicDetails)
    }else{
      setToastMessage(response?.message);
      setToastType("error");
      setShowToast(true)
    }
  }


  return (
    <div>
      <HeaderSection />
      <PageHeader pageHeader={t("EnvironmentalFlora.flora")} />
      <CustomToast
        show={showToast}
        onClose={() => setShowToast(false)}
        delay={5000}
        message={toastMessage}
        toastType={toastType}
      />
      <div className="exceptionHandlingTab">
        <button
          onClick={() => {
            setTab("1");
            handleClearBtn();
          }}
          className={tab === "1" ? "colTabBtnSel" : "colTabBtn"}
        >
          {t("EnvironmentalFlora.pending")}
        </button>
        <button
          onClick={() => setTab("2")}
          className={tab === "2" ? "colTabBtnSel" : "colTabBtn"}
        >
          {t("EnvironmentalFlora.flora")}
        </button>
        <button
          onClick={() => setTab("3")}
          className={tab === "3" ? "colTabBtnSel" : "colTabBtn"}
        >
          {t("EnvironmentalFlora.history")}
        </button>
      </div>

      {tab === "1" && (
        <div className="me-3" style={{ marginTop: "10px" }}>
          <div className=" mx-3 pe-2" style={{ height: "45vh" }}>
            <CustomTable
              tableHead={t("EnvironmentalFlora.pending")}
              data={pendingDataTable}
              columns={columns}
              totalElements={totalElements}
              isActionVisible={false}
              isViewVisible={false}
              isSeachable={true}
              showBatchFilter={true}
              showSerielNoFilter={true}
              onUseEffectRequired={() => setUseEffectRequired(true)}
              isNavigateFlora={true}
              onNavigateFloraClick={onNavigateFloraClick}
              plateBatch={plateBatches}
              plateSerialNumber={plateSerielNum}
              onFilterClick={handleFilter}
              showPlateTypeFilter={true}
            />
          </div>
          <div>
            <Pagination
              page={page}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
            />
          </div>
        </div>
      )}

      {tab === "3" && (
        <div className="me-3" style={{ marginTop: "10px" }}>
          <div className=" mx-3 pe-2" style={{ height: "45vh" }}>
            <CustomTable
              tableHead={t("EnvironmentalFlora.history")}
              data={historyDataTable}
              columns={HistoryColumns}
              totalElements={totalElementsHistory}
              isActionVisible={false}
              isViewVisible={true}
              isSeachable={true}
              showBatchFilter={false}
              onUseEffectRequired={() => setUseEffectRequired(true)}
              onViewClick={handleHistoryViewClick}
              onFilterClick={handleFilterHistory}
            />
          </div>
          <div>
            <Pagination
              page={pageHistory}
              totalPages={totalPagesHistory}
              handlePageChange={handleHistoryPageChange}
            />
          </div>
        </div>
      )}

      {tab === "2" && (
        <div>
          <div className="scanWrapLg">
            <div className="scanInputWrapLg p-1">
              <input
                type="text"
                ref={scanAreaRef}
                value={scanCode}
                onChange={(e) => handleScanCodeInput(e.target.value)}
                placeholder={t("EnvironmentalFlora.scanCode")}
              />
              {scanCode ? (
                <p
                  onClick={() => handleClearBtn()}
                  className="scanAddText py-2 me-2 my-0 "
                >
                  {t("buttons.cancel")}
                </p>
              ) : (
                <div
                  onClick={() => setSerielScanShow(true)}
                  className="scanBg "
                >
                  <BsQrCodeScan />
                </div>
              )}
            </div>
            <div className="pt-3 mx-2">
              <p onClick={() => setShow(true)} className="exceptionBtn ms-3">
                {t("Exception.exception")}
              </p>
            </div>
            <div
              className="scannedDetailsWrap ps-3"
              style={{ marginLeft: "15%" }}
            >
              <table className="">
                <tbody>
                  {Object.keys(data).map((key) => (
                    <tr key={key}>
                      <td className="keyText">{key}</td>
                      <td className="px-3">:</td>
                      <td className="valueText">{data[key]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {singlePlateDetails?.serial_number && scanCode && (
            <div className="scanWrapLg-flora mt-3 ">
              <div className="view-body-content">
                <p className="plateDetailHead">{t("EnvironmentalFlora.plateDetails")}</p>
                <div className="view-body-details">
                  <div className="row">
                    <div className="col-md-2">
                      <p className="plate-detail-flora">{t("EnvironmentalFlora.plateSlNo")}</p>
                      <p className="plate-detail-flora-value">
                        {singlePlateDetails?.serial_number}
                      </p>
                    </div>
                    <div className="col-md-2">
                      <p className="plate-detail-flora">{t("EnvironmentalFlora.batchId")}</p>
                      <p className="plate-detail-flora-value">
                        {singlePlateDetails?.batch_number}
                      </p>
                    </div>
                    <div className="col-md-2">
                      <p className="plate-detail-flora">{t("EnvironmentalFlora.expiryDate")}</p>
                      <p className="plate-detail-flora-value">
                        {singlePlateDetails?.expiry_date}
                      </p>
                    </div>
                    <div className="col-md-2">
                      <p className="plate-detail-flora">{t("EnvironmentalFlora.activityType")}</p>
                      <p className="plate-detail-flora-value">
                        {singlePlateDetails?.plate_type}
                      </p>
                    </div>
                    <div className="col-md-2">
                      <p className="plate-detail-flora">
                        {t("EnvironmentalFlora.acceptedColonyCount")}
                      </p>
                      <p className="plate-detail-flora-value">
                        {singlePlateDetails?.accepted_colony_count}
                      </p>
                    </div>
                    {plateId && (
                      <div className="col-md-2">
                        <p
                          onClick={() => handleViewBtnClick()}
                          className="plate-detail-flora-modal"
                        >
                          {t("EnvironmentalFlora.viewPlateDetails")}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* image section */}

          {singlePlateDetails?.serial_number && capturedImageId === "" && (
            <div className="d-flex my-2 justify-content-center">
              <div>
                <TransformWrapper>
                  {({ zoomIn, zoomOut }) => (
                    <>
                      <TransformComponent>
                        <div
                          className="capturedPlateBg my-2"
                          style={{ transform: `scale(${zoomLevel})` }}
                        >
                          <img
                            src={`${baseUrl}/files/download?file_id=${capturedImageId}`}
                          />
                        </div>
                      </TransformComponent>
                      <div className=" d-flex justify-content-end">
                        <button
                          className="zoominBtn m-1"
                          onClick={() => zoomIn()}
                        >
                          {t("buttons.zoomIn")}
                        </button>
                        <button
                          className="zoominBtn m-1 px-2"
                          onClick={handleZoomIn}
                        >
                          +
                        </button>
                        <button
                          className="zoomoutBtn m-1 px-2"
                          onClick={handleZoomOut}
                        >
                          -
                        </button>
                        <button
                          className="zoomoutBtn m-1"
                          onClick={() => zoomOut()}
                        >
                          {t("buttons.zoomOut")}
                        </button>
                      </div>
                    </>
                  )}
                </TransformWrapper>
              </div>

              <div>
                {imageIds.map((obj: any) => (
                  <div
                    className="m-1 p-1"
                    onClick={() => setCapturedImageId(obj)}
                  >
                    {/* <img src={obj} width={100} height={60} alt="Captured" /> */}
                    <img
                      src={`${baseUrl}/files/download?file_id=${obj || ""}`}
                      alt={t("EnvironmentalFlora.plateImg")}
                      width={100}
                      height={50}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* plate details and submit section */}
          {singlePlateDetails?.serial_number && scanCode && (
            <div className="row">
              <div className="labtestRightWrap col-12 my-1 ms-2">
                <p className="plateDetailHead ms-2">
                  {t("EnvironmentalFlora.floraIdentification")}
                </p>
                <div className="my-2"></div>
                <div className="labtestInputWrap p-2">
                  {fields}
                  <div className="d-flex mt-3 "></div>
                  <div className="" style={{ height: "7vh" }}>
                  </div>
                  <div className="colonyCountBottomSec pe-3 py-1">
                    <button
                      onClick={() => handleClearBtn()}
                      className="modalCancelBtn"
                    >
                      {t("buttons.cancel")}
                    </button>
                    <button
                      onClick={() => setAreaAlert(true)}
                      className="squareOrangeBtn3"
                    >
                      {t("buttons.submit")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      <AddException showModel={show} setShowModel={() => setShow(false)} />

      <SerielNumberScanner
        show={serielScanShow}
        setShow={setSerielScanShow}
        handleScanCode={handleScanCode}
      />

      {/* labreport view */}
      <PlateDetailsViewModel
        showView={showView}
        setShowView={(value: boolean) => setShowView(value)}
        apiType="floraById"
        plateId={plateId}
      />

      {/* labreport view for history */}
      <PlateDetailsViewModel
        showView={showHistoryView}
        setShowView={(value: boolean) => setShowHistoryView(value)}
        apiType="plateById"
        plateId={historyPlateId}
      />

      {/*alert for submit flora */}
      <AlertModal
        show={areaAlert}
        onHide={() => setAreaAlert(false)}
        title={t("common.alert")}
        message={t("EnvironmentalFlora.floraSubmitAlert")}
        onConfirm={handleConfirmSubmit}
      />

      {/*alert for password authentication*/}
      <EsignModal
        show={showEsignAlert}
        onHide={() => setShowEsignAlert(false)}
        onConfirm={handleESignConfirm}
        needRemraks={false}
      />
    </div>
  );
};

export default EnvironmentalFlora;
