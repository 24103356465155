import React, { useEffect, useState } from 'react'
import Pagination from '../../../../widgets/pagination';
import CustomTable from '../../../../widgets/table';
import { reportServices } from '../../../../service/reportServices';
import Select, { ActionMeta, SingleValue } from 'react-select';
import { useTranslation } from 'react-i18next';
import { scheduleService } from '../../../../service/scheduleService';
import { userService } from '../../../../service/plateServices';
import { batchSummaryReport } from './partials/columns';
import baseUrl from '../../../../service';
import { DEFAULT_PAGE_SIZE } from '../../../../constants/constants';
import AlertModal from '../../../../widgets/alertModal';




interface BatchSummaryReportProps {
    ishide?: boolean;
  }

  type Filters = {
    batchCode: string;
    activityType: string;
    area: string;
    fromDate: string;
    toDate: string;
  };
  type OptionType = {
    value: string;
    label: string;
  };

const BatchSummaryReport: React.FC<BatchSummaryReportProps> = ({ishide}) => {

  const { t } = useTranslation()

  const [filters, setFilters] = useState({
    batchCode: '',
    activityType: '',
    area: '',
    fromDate: '',
    toDate: '',
  });

  const [plateDetails, setPlateDetails] = useState<Array<any>>([]);
  // pagination
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);

  const [plateBatches, setPlateBatches] = useState([]);
  const [activityType, setActivityType] = useState<Array<any>>([]);
  const [areaList, setAreaList] = useState<Array<any>>([]);
  const [useEffectRequired, setUseEffectRequired] = useState(false);
  const [showDownloadCSV, setShowDownloadCSV] = useState(true);
  const [isApplyClicked, setIsApplyClicked] = useState(true);
  const [authUserID, setAuthUserID] = useState("");
  const [pdfDownload, setpdfDownload] = useState(false);

  //handle error section
  interface Errors {
    batchCode?: string;
  }
  const [errors, setErrors] = useState<Errors>({});




  const handleApplyClick =() =>{
    const errors: Errors = {};
    if (!filters.batchCode) {
      errors.batchCode = "Please Select Batch Code";
    }
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    setErrors({});
    setShowDownloadCSV(false)
    setIsApplyClicked(true)
    getLabApprovedPlates();
  }

  const getLabApprovedPlates = async () => {
    try {
      const response = await reportServices.batchSummaryReport(page, pageSize, filters.batchCode, filters.activityType, filters.area);
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              setTotalPages(response?.totalPages)
              setTotalElements(response?.totalElements)
              let rowData: any = [];
              let emptySpace = " "

              response.data.map((element: any, indexMain: number) => {

                //  const statusNumber = parseInt(element.status, 10); 
                //  if (statusNumber == 21){
                //   var status = "pending"
                //  }else{
                //   var status = "unknown"
                //  }
                
                // return { 
                //     slno: index + 1, 
                //     batch_id: element.batch_number,
                //     plate_serial_number: element.serial_number,
                //     colony_count: element.colony_count,
                //     plate_type : element.activity_type,
                //     status : status,
                //     id : element.id,
                //     plate_id: element.plate_id,
                //     lab_approved_date: element.approved_date
                // };

                let monitoredUser = ""

                if(element.fingerdabbing_user_name === "") {
                  monitoredUser = element.fingerdabbing_guest_name
                } else {
                  monitoredUser = element.fingerdabbing_user_name
                }

                if (element.colony_data.length > 0) {
                  element.colony_data.forEach((detail: any, index: number) => {
                    rowData.push({
                      slno: index === 0 ? indexMain + 1 : emptySpace,
                      id : index === 0 ? element.id : emptySpace,
                      plate_id: index === 0 ? element.plate_id : emptySpace,
                      area_name: index === 0 ? element.assigned_area_name : emptySpace,
                      location: index === 0 ? element.assigned_location_name : emptySpace,
                      activity: index === 0 ? element.assigned_activity_name : emptySpace,
                      test_tube_serial_no: index === 0 ? element.swab_test_tube_serial_number : emptySpace,
                      serial_number: index === 0 ? element.plate_serial_number : emptySpace,
                      batch_code: index === 0 ? element.plate_batch_number : emptySpace,
                      lot_code: index === 0 ? element.lot_code : emptySpace,
                      equipment_code: index === 0 ? element.exposure_equipment_area_code : emptySpace,
                      sampling_started_by: index === 0 ? element.activity_started_by : emptySpace,
                      sampling_started_date: index === 0 ? element.activity_started_date : emptySpace,
                      sampling_ended_by: index === 0 ? element.activity_ended_by : emptySpace,
                      sampling_ended_date: index === 0 ? element.activity_ended_date : emptySpace,
                      sampling_duration: index === 0 ? element.activity_duration : emptySpace,
                      monitored_user: index === 0 ? monitoredUser : emptySpace,
                      monitored_location: index === 0 ? element.plate_finger_dab_option_name : emptySpace,
                      first_incubation_start_by: index === 0 ? element.incubation_started_by : emptySpace,
                      first_incubation_start_date: index === 0 ? element.incubation_start_date : emptySpace,
                      first_incubation_end_by: index === 0 ? element.incubation_end_by : emptySpace,
                      first_incubation_end_date: index === 0 ? element.incubation_end_date : emptySpace,
                      first_incubation_duration: index === 0 ? element.incubation_duration : emptySpace,
                      second_incubation_start_by: index === 0 ? element.incubation2_started_by : emptySpace,
                      second_incubation_start_date: index === 0 ? element.incubation2_start_date : emptySpace,
                      second_incubation_end_by: index === 0 ? element.incubation2_end_by : emptySpace,
                      second_incubation_end_date: index === 0 ? element.incubation2_end_date : emptySpace,
                      second_incubation_duration: index === 0 ? element.incubation2_duration : emptySpace,
                      observer1_name: index === 0 ? element.lab_1_observer_name : emptySpace,
                      count1: index === 0 ? element.lab_1_colony_count : emptySpace,
                      observer2_name: index === 0 ? element.lab_2_observer_name : emptySpace,
                      count2: index === 0 ? element.lab_2_colony_count : emptySpace,
                      observer3_name: index === 0 ? element.lab_3_observer_name : emptySpace,
                      count3: index === 0 ? element.lab_3_colony_count : emptySpace,
                      colony_type: detail.colony_type_name,
                      organism_type: detail.organism_type,
                      genus: detail.genus_name,
                      species: detail.species_name,
                      strain: detail.strain_name,
                      plate_status: index === 0 ? element.lab_status : emptySpace,
                      approve_id: index === 0 ? element.approved_lab_test_id : emptySpace,
                      expiry_date: index === 0 ? element.expiry_date : emptySpace,
                      exposure_equipment_name: index === 0 ? element.exposure_equipment_name : emptySpace,
                      first_incubatior_name: index === 0 ? element.incubation_name : emptySpace,
                      second_incubatior_name: index === 0 ? element.incubation_name2 : emptySpace,
                      lab_1_area_name: index === 0 ? element.lab_1_area_name : emptySpace,
                      approved_user_name: index === 0 ? element.approved_user_name : emptySpace,
                      approved_date: index === 0 ? element.approved_date : emptySpace,
                      approver_remarks: index === 0 ? element.approver_remarks : emptySpace,
                      flora_conducted_by: index === 0 ? element.flora_conducted_by : emptySpace,
                      flora_conducted_date: index === 0 ? element.flora_conducted_date : emptySpace,
                      autoclave_name: index === 0 ? element.autoclave_name : emptySpace,
                      autoclave_start_date: index === 0 ? element.autoclave_start_time : emptySpace,
                      autoclave_start_by: index === 0 ? element.autoclave_started_by : emptySpace,
                      autoclave_end_date: index === 0 ? element.autoclave_end_time : emptySpace,
                      autoclave_end_by: index === 0 ? element.autoclave_ended_by : emptySpace,
                      autoclave_duration: index === 0 ? element.autoclave_duration : emptySpace,

                      qr_generated_datetime: index === 0 ? element.qr_generated_datetime : emptySpace,
                      plate_size_type_name: index === 0 ? element.plate_size_type_name : emptySpace,
                      media_type_name: index === 0 ? element.media_type_name : emptySpace,
                    });
                  });
                } else {
                  rowData.push({
                    slno: indexMain + 1, 
                    id : element.id,
                    plate_id: element.plate_id,
                    area_name: element.assigned_area_name,
                    location: element.assigned_location_name,
                    activity: element.assigned_activity_name,
                    test_tube_serial_no: element.swab_test_tube_serial_number,
                    serial_number: element.plate_serial_number,
                    batch_code: element.plate_batch_number,
                    lot_code: element.lot_code,
                    equipment_code: element.exposure_equipment_area_code,
                    sampling_started_by: element.activity_started_by,
                    sampling_started_date: element.activity_started_date,
                    sampling_ended_by: element.activity_ended_by,
                    sampling_ended_date: element.activity_ended_date,
                    sampling_duration: element.activity_duration,
                    monitored_user: monitoredUser,
                    monitored_location: element.plate_finger_dab_option_name,
                    first_incubation_start_by: element.incubation_started_by,
                    first_incubation_start_date: element.incubation_start_date,
                    first_incubation_end_by: element.incubation_end_by,
                    first_incubation_end_date: element.incubation_end_date,
                    first_incubation_duration: element.incubation_duration,
                    second_incubation_start_by: element.incubation2_started_by,
                    second_incubation_start_date: element.incubation2_start_date,
                    second_incubation_end_by: element.incubation2_end_by,
                    second_incubation_end_date: element.incubation2_end_date,
                    second_incubation_duration: element.incubation2_duration,
                    observer1_name: element.lab_1_observer_name,
                    count1: element.lab_1_colony_count,
                    observer2_name: element.lab_2_observer_name,
                    count2: element.lab_2_colony_count,
                    observer3_name: element.lab_3_observer_name,
                    count3: element.lab_3_colony_count,
                    colony_type: element.colony_type_name,
                    organism_type: element.organism_type,
                    genus: element.genus_name,
                    species: element.species_name,
                    strain: element.strain_name,
                    plate_status: element.lab_status,
                    approve_id: element.approved_lab_test_id,
                    expiry_date: element.expiry_date,
                    exposure_equipment_name: element.exposure_equipment_name,
                    first_incubatior_name: element.incubation_name,
                    second_incubatior_name: element.incubation_name2,
                    lab_1_area_name: element.lab_1_area_name,
                    approved_user_name: element.approved_user_name,
                    approved_date: element.approved_date,
                    approver_remarks: element.approver_remarks,
                    flora_conducted_by: element.flora_conducted_by,
                    flora_conducted_date: element.flora_conducted_date,
                    autoclave_name: element.autoclave_name,
                    autoclave_start_date: element.autoclave_start_time,
                    autoclave_start_by: element.autoclave_started_by,
                    autoclave_end_date: element.autoclave_end_time,
                    autoclave_end_by: element.autoclave_ended_by,
                    autoclave_duration: element.autoclave_duration,

                    qr_generated_datetime: element.qr_generated_datetime,
                    plate_size_type_name: element.plate_size_type_name,
                    media_type_name: element.media_type_name,
                  });
                }


              });
              setPlateDetails(rowData);
            }
          } catch (error) {
            console.log(error); 
          }
      } else {
        setPlateDetails([])
        setTotalPages(0)
        setTotalElements(0)
      }
    } catch (error) {
      console.error('Plate response', error);
    }
  };

  const handlePageChange = (newPage : any, pageSize?: number) => {
    setPage(newPage);
    if(pageSize) {
      setPageSize(pageSize)
    }
    setUseEffectRequired(true)
  };

  const listPlateBatch = async () => {
    try {
      const response = await userService.listPlateBatch();
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              let rowData: any = response.data.map((element: any, index: number) => {
  
                return { 
                    value: element.batch_number,
                    label: element.batch_number ,
                };
              });
              setPlateBatches(rowData);
            }
          } catch (error) {
            console.log(error); 
          }
      }
    } catch (error) {
      console.error('Plate response', error);
    }
  }

  // get Activity Type List
  const getActivityTypeList = async () => {
    try {
    const response = await scheduleService.getActivityTypeList();
    if (response.status && response.statusCode === 200) {
        try {
        if (Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map(
            (element: any, index: number) => {
                return {
                slno: index + 1,
                id: element.id,
                name: element.name,
                };
            }
            );
            setActivityType(rowData);
        }
        } catch (error) {
        console.log(error);
        }
    } else {
        setActivityType([]);
    }
    } catch (error) {
    console.error("user response", error);
    }
  };
  
  //api call to get area list
  const getAreaList = async () => {
    try {
      const response = await scheduleService.getAreaList("id", "desc", "6");
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                return {
                  id: element.id,
                  name: element.name,
                  grade_name: element.grade_name,
                };
              }
            );
            setAreaList(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        setAreaList([]);
      }
    } catch (error) {
      console.error("user response", error);
    }
  };

  const handleChange = (
    eventOrValue: React.ChangeEvent<HTMLSelectElement | HTMLInputElement> | SingleValue<OptionType>,
    actionOrUndefined?: ActionMeta<OptionType>
  ) => {
    let newFilters: Filters;
  
    if (actionOrUndefined) {
      // For react-select
      const { name } = actionOrUndefined as { name: string };
      // if (name === "batchCode") {
      //   lotCode((eventOrValue as SingleValue<OptionType>)?.value || '');
      // }

      newFilters = { ...filters, [name]: (eventOrValue as SingleValue<OptionType>)?.value || '' };
    } else {
      // For native select and input elements
      const { name, value } = (eventOrValue as React.ChangeEvent<HTMLSelectElement | HTMLInputElement>).target;
      newFilters = { ...filters, [name]: value };
    }
  
    setFilters(newFilters);
    console.log(newFilters, "newFilters")
  
    // Enable button if any filter is changed
    const anyFilterChanged = Object.values(newFilters).some(val => val !== '');
    // setIsButtonDisabled(!anyFilterChanged);
    setIsApplyClicked(false)
    setShowDownloadCSV(true)
  };

  const handleCancel = () => {
    const initialFormData = {
      batchCode: '',
      activityType: '',
      area: '',
      fromDate: '',
      toDate: '',
    };
    setFilters(initialFormData);
    setShowDownloadCSV(true)
    setUseEffectRequired(true)
    // listAuditTrialReport();
  }

  const downloadAuditCSV = async () => {
    setpdfDownload(false)
    const link = `${baseUrl}/summary/export_batch_summary_data?batch_number=${filters.batchCode}&master_activities_id=${filters.activityType}&area_id=${filters.area}&auth_id=${authUserID}`
    window.location.href = link;
  };

  useEffect (() => {

    var storedData = localStorage.getItem("userDetails");

    if (storedData) {
      var storedObject = JSON.parse(storedData);
      setAuthUserID(storedObject[0].x_userid);
    }

    getLabApprovedPlates();
    listPlateBatch();
    getActivityTypeList();
    getAreaList();


    setUseEffectRequired(false)
  },[useEffectRequired])


  return (
    <div>

      <div className="filter-component">

        <div className='filter-row'>
            <div>
                   <label htmlFor="areaTypeSelect" className=''>Batch Code <span style={{ color: "red" }}>*</span></label> 
               </div>
               <div>
              <div style={{"zIndex":"10"}}>
                    <Select
                      options={plateBatches}
                      onChange={handleChange}
                      name='batchCode'
                      placeholder="Select"
                      value={plateBatches.find((option: any) => option.value === filters.batchCode) || null}
                      isSearchable={true} 
                      isClearable
                      className='areaTypeSelect2'
                    />
              </div>
              {errors.batchCode && <p className="errorText">{errors.batchCode}</p>}
              </div>
        </div>

        <div className="filter-row">
               <div>
                   <label htmlFor="areaTypeSelect" className='filter-label'>Activity Type</label> 
               </div>
               <div>
              <select className='areaTypeSelect mx-1 py-2 mb-2' name='activityType' value={filters.activityType} onChange={handleChange} >
                  <option value="">Select</option>
                  {activityType?.map((obj, index) => (
                        <option key={index} value={obj?.id}>
                            {obj?.name}
                        </option>
                  ))}
              </select>
              </div>
        </div>

        <div className="filter-row">
            <div>
                <label htmlFor="areaTypeSelect" className='filter-label'>Area</label> 
            </div>
            <div>
          <select className='areaTypeSelect mx-1 py-2 mb-2' name='area' value={filters.area} onChange={handleChange} >
              <option value="">Select</option>
              {areaList?.map((areaList: any) => (
                <option key={areaList.id} value={areaList.id}>
                  {areaList.name}
                </option>
              ))}
          </select>
          </div>
        </div>

        {/* <div className="filter-row">
          <label className="filter-label">
            From Date
            <input 
              type="datetime-local" 
              name="fromDate" 
              value={filters.fromDate} 
              onChange={handleChange} 
              className="filter-input"
              onKeyDown={(e) => {
                const allowedKeys = [
                  "Tab",
                  "Enter",
                  "Escape",
                ];
                if (!allowedKeys.includes(e.key)) {
                  e.preventDefault();
                }
              }}
              onPaste={(e) => e.preventDefault()} 
              />
          </label>
        </div> */}

        {/* <div className="filter-row">
          <label className="filter-label">
            To Date
            <input 
                type="datetime-local" 
                name="toDate" 
                value={filters.toDate} 
                onChange={handleChange} 
                className="filter-input" 
                onKeyDown={(e) => {
                  const allowedKeys = [
                    "Tab",
                    "Enter",
                    "Escape",
                  ];
                  if (!allowedKeys.includes(e.key)) {
                    e.preventDefault();
                  }
                }}
                onPaste={(e) => e.preventDefault()}
              />
          </label>
        </div> */}

        {/* <div className="filter-row">
          <button className="filter-button" disabled={isButtonDisabled} onClick={downloadAuditCSV}>Download Passive Air Sampling Report</button>
        </div> */}
      </div>

      <div className="mx-2 d-flex justify-content-end" >
            <button className="analyticalFilterClose" title="Click here to Reset Filter"  onClick={handleCancel}>{t("buttons.cancel")}</button>
            <button 
            className={isApplyClicked ? "analyticalFilterApplyDisabledDownload" : "analyticalFilterApply"}
            disabled={isApplyClicked} 
            onClick={handleApplyClick} 
            title="Click here to Apply Filter" >
                {t("buttons.apply")}
            </button>
            <button 
            className={showDownloadCSV ? "analyticalFilterDisabledDownload" : "analyticalFilterDownload"}
            disabled={showDownloadCSV} 
            onClick={()=>setpdfDownload(true)}
            title="Click here to Download CSV" >
            {t("buttons.download_pdf")}
            </button>

        </div>

       <div className=" mx-3 pe-2" style={{"height":"45vh"}}>
          <CustomTable
            data={plateDetails}
            columns={batchSummaryReport}
            isEditable={false}
            totalElements={totalElements}
            isActionVisible={false}
            isViewVisible={false}
            isSeachable={false}
            tableHead={'Batch Summary Report'}
            showBatchFilter={false}
            showLotCodeFilter={false}
            showSerielNoFilter={false}
          />
        </div>
        <div>
          <Pagination
            page={page}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
          />
        </div>

        <AlertModal
        show={pdfDownload}
        onHide={() => setpdfDownload(false)}
        title="Alert"
        message={t('downloadPdf.download')}
        onConfirm={downloadAuditCSV}
      />

    </div>
  )
}

export default BatchSummaryReport