//table columns for pending table section 
export const columns: TableColumn[] = [
    {
      Header: 'EnvironmentalFlora.tableColumns.slNo',
      accessor: 'sl_no',
    },
    {
      Header: 'EnvironmentalFlora.tableColumns.serialNo',
      accessor: 'serial_number',
    },
    {
      Header: 'tableCommon.batch_code',
      accessor: 'batch_number',
    },
    {
      Header: 'EnvironmentalFlora.tableColumns.activityType',
      accessor: 'activity_type',
    },
  ];

  //table columns for history table section 
  export const HistoryColumns: TableColumn[] = [
    {
      Header: 'EnvironmentalFlora.tableColumns.slNo',
      accessor: 'slno',
    },
    {
      Header: 'EnvironmentalFlora.tableColumns.serialNo',
      accessor: 'serial_number',
    },
    {
      Header: 'tableCommon.batch_code',
      accessor: 'batch_number',
    },
    {
       Header: 'EnvironmentalFlora.tableColumns.updatedAt',
       accessor: 'updated_at',
    },
  ];
  
  interface TableColumn {
    Header: string;
    accessor: string;
  }
  