import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { TfiClose } from "react-icons/tfi";
import { CiCircleRemove } from "react-icons/ci";
import CustomToast from "../../../../widgets/toaster";
import AlertModal, { EsignModal } from "../../../../widgets/alertModal";
import { useTranslation } from "react-i18next";
import { BatchService } from "../../../../service/plateServices/discard";

interface TemporaryManagementProps {
  show: boolean;
  plateBySerielNo: any[]; // Define a proper type if available
  setPlateBySerielNo: (e: any) => void;
  setShow: (e: any) => void;
  setuseEffectRequired: (e:any) => void;
  setActionClearAll : (e: any) => void;
}

interface FormData {
  reason_id: string;
  remarks: string;
}

interface FormErrors {
  reason_id: string;
  remarks: string;
  batch_id: string
}

const TemporaryManagementModal: React.FC<TemporaryManagementProps> = ({
  show,
  plateBySerielNo,
  setPlateBySerielNo,
  setShow,
  setuseEffectRequired,
  setActionClearAll,
}) => {
  const { t } = useTranslation();
  const [alertShow , setAlertShow] = useState(false)
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const [formData, setFormData] = useState<FormData>({
    reason_id: "",
    remarks: "",
  });
  const [errors, setErrors] = useState<FormErrors>({
    reason_id: "",
    remarks: "",
    batch_id: ""
  });
   const [showEsignAlert, setShowEsignAlert] = useState(false)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    // Clear the error as the user types
    if (value.trim()) {
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    let hasError = false;
    const newErrors: FormErrors = {
      reason_id: "",
      remarks: "",
      batch_id: ""
    };

    const specialCharRegex = /[^a-zA-Z0-9\s-]/;

    if (!formData.reason_id.trim()) {
      hasError = true;
      newErrors.reason_id = "Reference / Deviation Id is required.";
    } else if (specialCharRegex.test(formData.reason_id)) {
      hasError = true;
      newErrors.reason_id =
        "Reference/Deviation ID is required and must be alphanumeric.";
    }
  
    if (!formData.remarks.trim()) {
      hasError = true;
      newErrors.remarks = "Remarks are required.";
    } else if (specialCharRegex.test(formData.remarks)) {
      hasError = true;
      newErrors.remarks = "Remarks must not contain special characters.";
    }

    const plateIds = plateBySerielNo.map((plate) => plate.id).join(",");
    if(!plateIds){
      hasError = true;
      newErrors.batch_id = "Batch Id is required.";
    }
  
    setErrors(newErrors);
    if(!hasError) {
      setAlertShow(true)
    }
  };

  const handleRemovePlate = (id: string) => {
    setPlateBySerielNo((prevState: { id: string }[]) =>
      prevState.filter((plate) => plate.id !== id)
    );
  };

  const handleModalClose = () => {
    setShow(false);
    setPlateBySerielNo([])
    setFormData({
    reason_id: "",
    remarks: "",
    })
    setErrors({
    reason_id: "",
    remarks: "",
    batch_id: ""
    })
    setActionClearAll(true)
  }

  const handleSubmitTcm = () => {
    setAlertShow(false)
    setShowEsignAlert(true)
  }

  const handleESignConfirm = async (remarks: string) => {
    setShowEsignAlert(false)
    const plateIds = plateBySerielNo.map((plate) => plate.id).join(",");
    const dataToSend = {  
      batch_ids: plateIds,
      tcm_status: "enabled",
      tcm_remarks: formData.remarks,
      tcm_ref : formData.reason_id,
      interface_name : "Temporary Change Management"
    }  
  try {
    const response = await BatchService.saveTemporaryManagement(dataToSend);
    if (response?.status && response?.statusCode === 200) {
      setToastType("success")
      setToastMessage(response.message)
      handleModalClose()
      setuseEffectRequired(true)
      setActionClearAll(true)
    } else {
      setToastType("error")
      setToastMessage(response.message)
    } 
    setShowToast(true);
  } catch (error) {
    console.error(error);
  }  
  };

  return (
    <Modal
      className="top-right-modal"
      centered={false}
      show={show}
      size="lg"
    >
      <div>
        <CustomToast
          show={showToast}
          onClose={() => setShowToast(false)}
          delay={5000}
          message={toastMessage}
          toastType={toastType}
        />
        <div className="modalHead">
          <p className="modalHeadText ms-3 pt-3 p-2">
            {"Temporary Change Management"}
          </p>
          <div onClick={handleModalClose} className="p-2 mx-3 my-2">
            <TfiClose fill="#FFFFFF" />
          </div>
        </div>

        <div className="d-flex justify-content-end my-4" style={{"visibility":"hidden"}}></div>
        <div className="px-3 mx-2" style={{ overflow: "scroll", height: "180px" }}>
          <table className="custom-table">
            <thead>
              <tr>
                <th scope="col" className="tableHeadData2">Sl.No ({plateBySerielNo.length})</th>
                <th scope="col" className="tableHeadData2">Batch Code</th>
                <th scope="col" className="tableHeadData2">Batch GRN</th>
                <th scope="col" className="tableHeadData2">Manufacture Details</th>
                <th scope="col" className="tableHeadData2">Created Date</th>
                <th scope="col" className="tableHeadData2">Quantity</th>
                <th scope="col" className="tableHeadData2">Plate Type</th>
                <th scope="col" className="tableHeadData2">Media Type</th>
                <th scope="col" className="tableHeadData2">Remove</th>
              </tr>
            </thead>
            <tbody>
              {plateBySerielNo.map((obj, index) => (
                <tr>
                  <td className="tableHeadData">{index + 1}</td>
                  <td className="tableHeadData">{obj.batch_number}</td>
                  <td className="tableHeadData">{obj.batch_grn}</td>
                  <td className="tableHeadData">{obj.manufacturer_details}</td>
                  <td className="tableHeadData">{obj.manufacturing_date}</td>
                  <td className="tableHeadData">{obj.quantity}</td>
                  <td className="tableHeadData">{obj.plate_type}</td>
                  <td className="tableHeadData">{obj.media_type_name}</td>
                  <td className="tableHeadData">
                    <CiCircleRemove
                      onClick={() => handleRemovePlate(obj.id)}
                      fontSize={25}
                      color="red"
                      style={{ cursor: "pointer" }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {errors.batch_id && <p className="errorText m-2">{errors.batch_id}</p>}

        <div className="selectWrap-new">
        <div
            style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            }}
        >
            <div
            style={{
                flex: 1,
                marginRight: "30px",
            }}
            >
            <label htmlFor="reason_id">
                Reference / Deviation Id <span style={{ color: "red" }}>*</span>
            </label>
            <br />
            <input
                id="reason_id"
                name="reason_id"
                className="placeholder-style"
                style={{
                width: "100%",
                }}
                value={formData.reason_id}
                onChange={handleChange}
                maxLength={20}
            />
             {errors.reason_id && <p className="errorText">{errors.reason_id}</p>}
            </div>
            <div
            style={{
                flex: 1,
                marginRight: "10px",
            }}
            >
            <label htmlFor="remarks">
                Remarks <span style={{ color: "red" }}>*</span>
            </label>
            <br />
            <input
                id="remarks"
                name="remarks"
                className="placeholder-style"
                style={{
                width: "100%",
                boxSizing: "border-box",
                }}
                value={formData.remarks}
                onChange={handleChange}
                maxLength={50}
            />
           {errors.remarks && <p className="errorText">{errors.remarks}</p>}
            </div>
        </div>
        </div>



        <div className="bottomArea">
          <button onClick={handleModalClose} className="modalCancelBtn">
            Cancel
          </button>
          <button onClick={(e)=>handleSubmit(e)} className="squareOrangeBtn3">
            Submit
          </button>
        </div>
      </div>

       <AlertModal
          show={alertShow}
          onHide={() => setAlertShow(false)}
          title='Alert'
          message={t('temporaryManagement.submit')}
          onConfirm={handleSubmitTcm}
        /> 

        <EsignModal 
          show={showEsignAlert}
          onHide={() => setShowEsignAlert(false)}
          onConfirm={handleESignConfirm}
          needRemraks={false}
        />

    </Modal>
  );
};

export default TemporaryManagementModal;
