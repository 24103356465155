export const HolidayNamecolumns: TableColumn[] = [
    {
        Header: "tableCommon.sl_no",
        accessor: "slno",
      },
      {
        Header: "shiftManagement.tableColumns.shiftName",
        accessor: "shift_name",
      },
      {
        Header: "shiftManagement.tableColumns.startTime",
        accessor: "start_time",
      },
      {
        Header: "shiftManagement.tableColumns.endTime",
        accessor: "end_time",
      },
      {
        Header: "shiftManagement.tableColumns.duration",
        accessor: "duration",
      },

]

export const columns: TableColumn[] = [
  {
      Header: "tableCommon.sl_no",
      accessor: "slno",
    },
    {
        Header: "shiftManagement.tableColumns.detailName",
        accessor: "remarks",
    },
    {
      Header: "shiftManagement.tableColumns.shiftName",
      accessor: "shift_name",
    },
    {
        Header: "shiftManagement.tableColumns.startTime",
        accessor: "start_time",
    },
    {
        Header: "shiftManagement.tableColumns.endTime",
        accessor: "end_time",
    },
    {
      Header: "shiftManagement.tableColumns.startDate",
      accessor: "start_date",
    },
    {
        Header: "shiftManagement.tableColumns.endDate",
        accessor: "end_date",
    },
    {
        Header: "shiftManagement.tableColumns.assignedOperator",
        accessor: "assigned_operator",
    },
]


interface TableColumn {
    Header: string;
    accessor: string;
  }