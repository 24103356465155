export const columns: TableColumn[] = [
    {
        Header: 'tableCommon.sl_no',
        accessor: 'slno',
      },
      {
        Header: 'applicationConfiguration.tableColumns.gradeType',
        accessor: 'grade',
      },
      {
        Header: 'scheduleFrequency.tableColumns.activity_type_name',
        accessor: 'activity_type',
      },
      {
        Header: 'applicationConfiguration.tableColumns.minCFUCount',
        accessor: 'min_count',
      },
      {
        Header: 'applicationConfiguration.tableColumns.maxCFUCount',    
        accessor: 'max_count',
      },
]

interface TableColumn {
    Header: string;
    accessor: string;
  }