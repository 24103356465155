import React, { useEffect, useState } from 'react'
import PageHeader from '../../common/pageHeader'
import HeaderSection from '../../common/headerSection'
import { Modal} from 'react-bootstrap'
import CustomTable from '../../../widgets/table'
import { columns} from './partials/column'
import { TfiClose } from 'react-icons/tfi'
import { useTranslation } from 'react-i18next';
import AlertModal from '../../../widgets/alertModal'
import CustomToast from '../../../widgets/toaster'
import Pagination from '../../../widgets/pagination';
import { userService } from '../../../service/userService'
import { deviceRegistration } from '../../../service/deviceRegistration'
// import NotificationConfigModal from './partials/modal'
import { DEFAULT_PAGE_SIZE } from '../../../constants/constants'
import baseUrl from '../../../service'

interface DeviceRegisterItem {
  slno: string; 
  downloadPath: string;
  document_name?: string; 
  id?: string;
  status?: string;
  file_name?: string;
  generated_date_time?: string;
}

const DownloadReports = () => {
  const { t } = useTranslation();
  const [deviceRegister, setDeviceRegister] = useState<DeviceRegisterItem[]>([]);
  const [useEffectRequired, setuseEffectRequired] = useState(false)
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
   // table pagination for sample type
   const [page, setPage] = useState(1);
   const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
   const [totalPages, setTotalPages] = useState(0);
   const [totalElements, setTotalElements] = useState(0);
   // table pagination for sample Sub type
  const [keyWord, setkeyWord] = useState("")
  const [permission, setPermission] = useState<any>([]);
  const [reportName , setReportName] = useState([]);
  const [sort, setSort] = useState("id");
  const [order, setOrder] = useState("desc");

  useEffect(() => {

    var storedData = localStorage.getItem('userDetails');

    if(storedData) {
      var storedObject = JSON.parse(storedData);
      getPermissionByRole(storedObject[0].role_master_id);
    }
    listReportType();
    listDeviceList();
    setuseEffectRequired(false)
  }, [useEffectRequired])
  

  //get permission by role 
  const getPermissionByRole = async (role : number) => {
    try {
      const response = await userService.getPermissionByRole(role);
      if(response.status && response.statusCode === 200){
        setPermission(response.data.permissions)
      }
    } catch (error) {
      console.error(error);
    }
  }

  //get all_raw_material_type list from api  
  const listDeviceList = async () => {
    try {
      const response = await deviceRegistration.listdownloadReport(page, pageSize,sort, order, keyWord);
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              setTotalPages(response?.totalPages)
              setTotalElements(response?.totalElements)
              let rowData: any = response.data.map((element: any, index: number) => {

                return { 
                    slno: index + 1, 
                    document_name: element.document_name,
                    id : element.id,
                    status : element.download_status,
                    file_name: element.file_name,
                    generated_date_time: element.generated_date_time,
                    downloadPath:element.downloadPath,
                    is_executed: element.is_executed
                };
              });
              setDeviceRegister(rowData);
            }
          } catch (error) {
            console.log(error);
          }
      }else {
        setDeviceRegister([]);
      }
    } catch (error) {
      console.error('user response', error);
    }
  }


  //handle table pagination 
  const handlePageChange = (newPage : any, pageSize?: number) => {
    setPage(newPage)
    if(pageSize) {
      setPageSize(pageSize)
    }
    setuseEffectRequired(true)
  };

  //handle filter section in sample type
  const handleFilter = (key: string, value: any) => {
    if(key === "Role") {
      // setRole(value)
    } else if (key === "ReportType") {
      setkeyWord(value)
    }
    setuseEffectRequired(true)
  }

  const listReportType = async () => {
    try {
      const response = await deviceRegistration.listReportType();
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              let rowData: any = response.data.map((element: any, index: number) => {

                return { 
                    id: element.id,
                    label: element.type_name,
                };
              });
              setReportName(rowData);
            }
          } catch (error) {
            console.log(error); 
          }
      }
    } catch (error) {
      console.error('Plate response', error);
    }
}


const handleDownload = (slno: string) => {
  const matchedItem = deviceRegister.find((item) => item.slno === slno); // Find an individual item
  if (matchedItem) {
    const link = baseUrl + matchedItem.downloadPath; // Access downloadPath on the matched item
    window.open(link, '_blank');
  }
};

const handleSort = (key: string, value: boolean) => {
  setSort(key);
  if (value) {
  setOrder("asc");
  } else {
  setOrder("desc");
  }
  setuseEffectRequired(true);
};
  return (
    <div>
      <HeaderSection />
      <PageHeader pageHeader={t('downloadPdfScreen.download')} />
      <CustomToast
            show={showToast}
            onClose={() => setShowToast(false)}
            delay={5000}
            message={toastMessage}
            toastType={toastType}
        /> 
   
        <div className='me-3' style={{marginTop : "20px"}}>
            <div className=' mx-3 pe-2' style={{"height":"45vh"}}>
                <CustomTable 
                    tableHead={t('downloadPdfScreen.download')}
                    data={deviceRegister} 
                    columns={columns} 
                    isEditable={false} 
                    isTempDeletable={false}
                    totalElements={totalElements} 
                    isActionVisible={false} 
                    isViewVisible={false} 
                    isSeachable={false}
                    isToggleStatus={false}
                    isResetPassword={false}
                    addButton={false}
                    onUseEffectRequired={() => setuseEffectRequired(true)}
                    onFilterClick={handleFilter}
                    changeRoleButton={false}
                    showTableBtn={true}
                    reportName={reportName}
                    tableBtnText="Download Report"
                    onEditClick={handleDownload}
                    onSortClick={handleSort}
                />
            </div>
            <div>
              <Pagination
                page={page}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
              />
            </div>
        </div>

    </div>

  )
}

export default DownloadReports;
