import React, { useState , useEffect} from 'react'
import { Link } from 'react-router-dom'
import HeaderSection from '../../../common/headerSection'
import PageHeader from '../../../common/pageHeader'
import CustomTable from '../../../../widgets/table'
import Pagination from '../../../../widgets/pagination'
import { TfiClose } from 'react-icons/tfi'
import { Modal } from 'react-bootstrap'
import { dashboardServices } from '../../../../service/dashboard'
import { useTranslation } from 'react-i18next';
import { setLoading } from '../../../../store/store'
import { useDispatch } from 'react-redux'
import DownloadPdf from '../../../../widgets/downloadPdf'
import { ExposureTableColumns as columns } from './partials/columns'
import { DEFAULT_PAGE_SIZE } from '../../../../constants/constants'
const moment = require('moment');


type ExposureStatType = {
 
  exposure_area: number;
  plates_exposed: number;
  plates_in_process: number;
  plates_ready_for_collection: number;
  overdue_plates_for_collection: number;
  missed_schedules: number;
  upcoming_schedules: number;
  deviations: number;
  // approved_exception: number;
};

type PlateViewType = {
  exposure_point_name: string;
  exposure_start_date: string;
  end_date_time : string;
  serial_number: string;
  exception_status: string;
  exception_added : string;
  started_by : string;
  ended_by : string;
  status : string;
  escalations : string;
};


const ExposureTable = () => {

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [showModal , setShowModal] = useState(false);
  const [exposureStat, setExposureStat] = useState<ExposureStatType | null>(null);
  const [exposureAreaNames, setExposureAreaNames] = useState<Array<any>>([]);
  const [exposurePlateDetails, setExposurePlateDetails] = useState<Array<any>>([]);
  const [plateView, setPlateView] = useState<PlateViewType | null>(null);
  const [page, setpage] = useState(1)
  const [pagesize, setpagesize] = useState(DEFAULT_PAGE_SIZE)
  const [totalPages, settotalPages] = useState(0)
  const [totalElements, setTotalElements] = useState(0)
  const [selExposureAreaName, setSelExposureAreaName] = useState("Exposure Details");
  const [selExposureAreaId, setSelExposureAreaId] = useState(1);
  const [useEffectRequired, setUseEffectRequired] = useState(false)


//handle pagination
const handleSchedulePageChange = (newPage :any, newPageSize?: number) => {
  setpage(newPage);
  if (newPageSize) {
    setpagesize(newPageSize);  
    // getExposurePlateDetails(selExposureAreaId,newPage,newPageSize)
  }
  setUseEffectRequired(true)
  // getExposurePlateDetails(selExposureAreaId,newPage,pagesize)
};

//api call to get the exposure stat listing
const getExposureStatus = async () => {
  try {
    const response = await dashboardServices.getExposureStatuDetails(); 
      if(response.status && response.statusCode === 200){
        setExposureStat({
           exposure_area: response.data.total_exposure_areas,
           plates_exposed : response.data.exposed_plate_count,
           plates_in_process : response.data.plate_in_exposure,
           plates_ready_for_collection : response.data.ready_for_collection,
           overdue_plates_for_collection : response.data.over_due_plates,          
            upcoming_schedules : response.data.upcoming_schedules,
            missed_schedules : response.data.missed_schedules,
            // exception_added : response.data.exception_added,
            deviations : response.data.escalations
          });
          dispatch(setLoading(false));

    }
  } catch (error) {
    console.error('user response', error);
  }
};

//api call to get the area name listing 
const getExposureAreaNames = async () => {
  try {
    const response = await dashboardServices.getExposureAreaNames();
    if(response.status && response.statusCode === 200){
      try {
          if ( Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map((element: any, index: number) => {
              return { 
                  slno: index + 1, 
                  id: element.id,
                  name: element.name,  
              };
            });
            setExposurePlateDetails([])
            const firstId = rowData.length > 0 ? rowData[0].id : null;
            getExposurePlateDetails(firstId,page,pagesize)
            setSelExposureAreaId(firstId)
            setExposureAreaNames(rowData);
          }
        } catch (error) {
          console.log(error);
        }
    }
  } catch (error) {
    console.error('exposure response', error);
  }
};

//api call for listing plate details in table 
const getExposurePlateDetails = async (id : number , newPage :any, newPageSize?: any) => {
  try {
    const response = await dashboardServices.getExposurePlateDetails(id, newPage, newPageSize);
    if(response.status && response.statusCode === 200){
      try {
          if ( Array.isArray(response.data) && response.data?.length > 0) {
            settotalPages(response?.totalPages)
            setTotalElements(response?.totalElements)
            let rowData: any = response.data.map((element: any, index: number) => {
              return { 
                  slno: index + 1, 
                  id: element.id,
                  name: element.name,  
                  plate_in_exposure : element.stat.plate_in_exposure,
                  ready_for_collection : element.stat.ready_for_collection,
                  over_due_plates : element.stat.over_due_plates,
                  upcoming_schedules : element.stat.upcoming_schedules,
                  missed_schedules : element.stat.missed_schedules,
                  exception_added : element.stat.exception_added,
                  approved_exceptions : element.stat.approved_exceptions,
                  escalations : element.stat.escalations,
                  parent_area: element.parent_area,
              };
            });
            setExposurePlateDetails(rowData);
          }
        } catch (error) {
         
          console.log(error);
        }
    }else{
      setExposurePlateDetails([])
    }
  } catch (error) {
    console.error('exposure response', error);
  }
};


//api call for plate view modal 
const getPlateView = async (id: any) => {
  try {
    const response = await dashboardServices.getPlateView(id);
    if (response.status && response.statusCode === 200) {

      const endDateTime = response.data.exposure_plate_details[0]?.end_date_time;
      const formattedEndDate = endDateTime
      ? moment(endDateTime, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY - HH:mm:ss')
      : '-';
      const status = endDateTime ? 'Completed' : 'Pending';

    const createdFirstName = response.data.exposure_plate_details[0]?.created_by_first_name;
    const createdLastName = response.data.exposure_plate_details[0]?.created_by_last_name;
    const fullName = createdFirstName + ' ' + createdLastName;

    const updatedFirstName = response.data.exposure_plate_details[0]?.updated_by_first_name;
    const updatedLastName = response.data.exposure_plate_details[0]?.updated_by_last_name;
    let updatedFullName = '';
    if (updatedFirstName !== null) {
      updatedFullName = updatedFirstName;
    if (updatedLastName !== null) {
    updatedFullName += ' ' + updatedLastName;
    }
    } else {
    updatedFullName = '-';
    }
      setPlateView({
        exposure_point_name: response.data.exposure_point_name,
        serial_number : response.data.exposure_plate_details[0]?.serial_number,
        exposure_start_date : moment(response.data.exposure_plate_details[0]?.start_date_time, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY - HH:mm:ss'),
        end_date_time :  formattedEndDate,
        exception_status : response.data.exposure_plate_details[0]?.exception_status,
        exception_added : response.data.exposure_plate_details[0]?.exception_added,
        escalations : response.data.exposure_plate_details[0]?.escalation,
        started_by : fullName,
        ended_by : updatedFullName,
        status  : status
      });
    }
  } catch (error) {
    console.error('view response', error);
  }
};

//on handle area name click loads the plate details under the area
const onExpAreaNameClick = (id : number, name: string) => {
  getExposurePlateDetails(id, page,pagesize)
  setSelExposureAreaId(id)
  setSelExposureAreaName(name)
}

//handle row selected on view click 
const handleViewClick = (id: string, viewValue: boolean) => {
  const updatedData = exposurePlateDetails.filter((row) => row.slno === id);
  getPlateView(updatedData[0]?.id)
  if(updatedData[0]?.plate_in_exposure == 1 || updatedData[0]?.ready_for_collection == 1 || updatedData[0]?.over_due_plates){
    setShowModal(viewValue)
  }
};


useEffect(() => {
  dispatch(setLoading(true));
  getExposureStatus();
  getExposureAreaNames();
}, [])

useEffect(() => {
  getExposurePlateDetails(selExposureAreaId, page,pagesize)
  setUseEffectRequired(false)
}, [useEffectRequired, selExposureAreaId])
  return (
   <div>
    <HeaderSection />
    <PageHeader pageHeader="Dashboard" />
    <div id='dashWrap'>
    <nav aria-label="breadcrumb" className="breadcrumb-container row">
      <div className='col-10 d-flex'>
        <ol className="breadcrumb">
          <li className="breadcrumb-item dashboard-hover"><Link to="/*">{t('dashboard.title')}</Link></li>
          <li className="breadcrumb-item active" aria-current="page">{t('dashboard.exposure')}</li>
        </ol>
      </div>
      <div className='col-2 d-flex'>
        <DownloadPdf divId="dashWrap" reportHead="Exposure Report" />
      </div>
    </nav>

    {/*list the exposure stat values */}
      <div className="key-values-container">
      {exposureStat && Object.entries(exposureStat).map(([key, value]) => (
          <div  key={key} className="key-value">
            <div className="key">
              {key.replace(/_/g, ' ')}
            </div>
            <div className='value'>{value}</div>
          </div>
        ))}
      </div>

    {/*list the exposure Area values */}
    <div className='row'>
        <div className='col-md-2 area-names ml-5'>
             <h3 className='filter-head mb-3'>{t('dashboard.exp_areas')}</h3> 
             <div className='outer-area' style={{overflowY: "scroll", height: "60vh"}}>
             {exposureAreaNames && exposureAreaNames.map((exp_names) => (
              <div 
                className={exposurePlateDetails[0]?.parent_area === exp_names.id ?'selAreaDetails': 'area-detail'}
                key={exp_names.id} 
                onClick={() => onExpAreaNameClick(exp_names.id, exp_names.name)}>
                  {exp_names.name}
              </div>       
             ))}        
             </div>        
        </div>

        <div className='col-md-9 table-footer'>
                <CustomTable
                    tableHead={selExposureAreaName}
                    data={exposurePlateDetails} 
                    columns={columns} 
                    isEditable={false} 
                    isActionVisible={false} 
                    isViewVisible={true} 
                    totalElements={totalElements}
                    isSeachable={false}
                    isToggleStatus={false}      
                    onViewClick={handleViewClick}
                    onUseEffectRequired={() => setUseEffectRequired(true)} 
                />
        </div>
        <div>
              <Pagination
                page={page}
                totalPages={totalPages}
                handlePageChange={handleSchedulePageChange}
              />
        </div>
    </div>
    </div>

    {/*Modal for exposure view click */}
    <Modal
              className="top-right-modal"
              centered={false}
              show={showModal}
          >
            <div>
                <div className="modalHead">
                <p className="modalHeadText ms-3 pt-3 p-2">{plateView?.exposure_point_name}</p>
                <div onClick={() => setShowModal(false)} className="p-2 mx-3 my-2 ">
                  <TfiClose fill="#FFFFFF" />
                </div>
              </div>
              <div>

              <div className="selectWrap view-exposure-head">
                  <div className="form-group my-1">
                    <label htmlFor="">{t('dashboard.plate_serial_id')}</label>
                    <br />
                    <input 
                       id="plate_serial_id"
                       value={plateView?.serial_number}
                       name="plate_serial_id"
                       type='text'
                       placeholder='Plate Serial ID'
                       className='view-exposure-head'
                      disabled
                    />
                  </div>

                  <div className="form-group my-1">
                    <label>{t('dashboard.status')}</label>
                    <br />
                    <input 
                       id="status"
                       value={plateView?.status}
                       name="status"
                       type='text'
                       placeholder='Status'
                       className='view-exposure-head'
                       disabled
                    />  
                  </div>
                </div>
                  <div className="selectWrap mt-4">
                  <div className="form-group my-1">
                    <label htmlFor="">{t('dashboard.exposure_start')}</label>
                    <br />
                    <input 
                       id="exposure_start"
                       value={plateView?.exposure_start_date}
                       name="exposure_start"
                       type='text'
                       placeholder='Exposure Start Date'
                       className='view-exposure-details'
                       disabled
                    />
                  </div>

                  <div className="form-group my-1">
                    <label>{t('dashboard.exposure_by')}</label>
                    <br />
                    <input 
                       id="exposure_by"
                       value={plateView?.started_by}
                       name="exposure_by"
                       type='text'
                       placeholder='Started By'
                       className='view-exposure-details'
                       disabled 
                    />  
                  </div>
                </div>

                <div className="selectWrap mt-4">
                  <div className="form-group my-1">
                    <label htmlFor="">{t('dashboard.exposure_end')}</label>
                    <br />
                    <input 
                       id="exposure_end"
                       value={plateView?.end_date_time}
                       name="exposure_end"
                       type='text'
                       placeholder='Exposure End Date'
                       className='view-exposure-details'
                       disabled
                    />
                  </div>

                  <div className="form-group my-1">
                    <label>{t('dashboard.exposure_ended_by')}</label>
                    <br />
                    <input 
                       id="exposure_ended_by"
                       value={plateView?.ended_by}
                       name="exposure_ended_by"
                       type='text'
                       placeholder='Ended By'
                       className='view-exposure-details'
                       disabled 
                    />  
                  </div>
                </div>

                <div className="selectWrap mt-4">
                  <div className="form-group my-1">
                    <label htmlFor="">{t('dashboard.Exception_added')}</label>
                    <br />
                    <input 
                       id="exception_added"
                       value={plateView?.exception_added}
                       name="exception_added"
                       type='text'
                       placeholder='Exception Added'
                       className='view-exposure-details'
                       disabled
                    />
                  </div>

                  <div className="form-group my-1">
                    <label>{t('dashboard.exception_status')}</label>
                    <br />
                    <input 
                       id="exception_status"
                       value={plateView?.exception_status}
                       name="exception_status"
                       type='text'
                       placeholder='Exception Status'
                       className='view-exposure-details'
                       disabled 
                    />  
                  </div>
                </div>

                <div className="selectWrap-email mt-4">
                  <div className="form-group my-1">
                    <label htmlFor="">{t('dashboard.escalations')}</label>
                    <br />
                    <input 
                       id="escalations"
                       value={plateView?.escalations}
                       name="escalations"
                       type='text'
                       placeholder='escalations'
                       className='view-exposure-details'
                       disabled
                    />
                  </div>
                </div>
               
              </div>
              <div className="bottomArea">
            <button onClick={() => setShowModal(false)} className="modalCancelBtn">
            {t('buttons.cancel')}
            </button>        
          </div>
            </div>
    </Modal>

 </div>

  )
}

export default ExposureTable;
