
//table columns for area management table section 
export const columns: TableColumn[] = [
    {
        Header: "operatorManagement.tableColumns.slNo",
        accessor: "slno",
      },
      {
        Header: "tableCommon.batch_code",
        accessor: "batch_id",
      },
      {
        Header: "tableCommon.seriel_number",
        accessor: "serial_number",
      },
      {
        Header: "operatorManagement.tableColumns.activityType",
        accessor: "plate_type",
      },
      {
        Header: "operatorManagement.tableColumns.duration",
        accessor: "incubation_duration",
      },
      {
        Header: "operatorManagement.tableColumns.plateStatus",
        accessor: "status",
      },
  ];
  
  interface TableColumn {
    Header: string;
    accessor: string;
  }
  