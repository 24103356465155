//columns for main table 
export const columns: TableColumn[] = [
        {
          Header: "operatorManagement.tableColumns.slNo",
          accessor: "slno",
        },
        {
          Header: "tableCommon.batch_code",
          accessor: "batch_number",
        },
        {
          Header: "tableCommon.seriel_number",
          accessor: "serial_number",
        },
        {
          Header: "operatorManagement.tableColumns.autoClaveEndsIn",
          accessor: 'autoclaveEndTime',
        },
        {
          Header: "operatorManagement.tableColumns.activityType",
          accessor: "plate_type",
        },
        {
          Header: "operatorManagement.tableColumns.plateStatus",
          accessor: "status",
        },
        {
          Header: 'operatorManagement.tableColumns.autoClavedBy',
          accessor: 'autoclave_user',
        },
  ];

//columns for history table 
export const HistoryColumns: TableColumn[] = [
  {
    Header: "operatorManagement.tableColumns.slNo",
    accessor: "slno",
  },
  {
    Header: "tableCommon.batch_code",
    accessor: "batch_number",
  },
  {
    Header: "tableCommon.seriel_number",
    accessor: "serial_number",
  },
  {
    Header: 'operatorManagement.tableColumns.autoClaveDuration',
    accessor: 'autoclave_end_time',
  },
  {
    Header: "operatorManagement.tableColumns.activityType",
    accessor: "plate_type",
  },
  {
    Header: 'operatorManagement.tableColumns.autoClavedBy',
    accessor: 'autoclave_user',
  },

]

  
interface TableColumn {
    Header: string;
    accessor: string;
}