export const columns: TableColumn[] = [
    {
        Header: "tableCommon.sl_no",
        accessor: "slno",
    },
    {
        Header: "downloadPdfScreen.reportName",
        accessor: "document_name",
    },
    {
        Header: "downloadPdfScreen.fileName",
        accessor: "file_name",
    },
    {
        Header: "downloadPdfScreen.generated_datetime",
        accessor: "generated_date_time",
    },
    {
        Header: "downloadPdfScreen.status",
        accessor: "status",
    },
  ];


    
  interface TableColumn {
    Header: string;
    accessor: string;
  }