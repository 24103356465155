export const columns: TableColumn[] = [
    {
        Header: "tableCommon.sl_no",
        accessor: "slno",
    },
    {
        Header: "tableCommon.batch_code",
        accessor: "batch_number",
    },
     {
        Header: "plateInventory.tableColumns.manufacture_details",
        accessor: "manufacturer_details",
    },
    {
        Header: "plateInventory.tableColumns.quantity",
        accessor: "quantity",
    },
    // {
    //     Header: "Test Tube Type",
    //     accessor: "plate_type",
    // },
    // {
    //     Header: "plateInventory.tableColumns.status",
    //     accessor: "status",
    // },
  ];

  export const columnsByBatch: TableColumn[] = [
    {
        Header: "tableCommon.sl_no",
        accessor: "slno",
    },
    // {
    //     Header: "Batch Code",
    //     accessor: "batch_number",
    // },
    {
        Header: "tableCommon.seriel_number",
        accessor: "serial_number",
    },
    {
        Header: "plateInventory.tableColumns.manufacture_date",
        accessor: "manufacturing_date",
    },
    {
        Header: "plateInventory.tableColumns.expiry_date",
        accessor: "expiry_date",
    },
    {
        Header: "plateInventory.tableColumns.status",
        accessor: "status",
    },
  ];

  export const BatchWiseReconcilationColums: TableColumn[] = [
    {
      Header: 'tableCommon.sl_no',
      accessor: 'slno',
    },
    {
      Header: 'tableCommon.batch_code',
      accessor: 'batch_code',
    },
    // {
    //   Header: 'Lot Count',
    //   accessor: 'lot_count',
    // },
    {
      Header: 'Total Swab Stick Count',
      accessor: 'plates_available',
    },
    {
      Header: 'Swab Stick Used Count',    
      accessor: 'plates_used_',
    },
    {
      Header: 'Remaining Swab Stick Count',    
      accessor: 'remaining_plates_reconciliation_',
    },
  ]

  interface TableColumn {
    Header: string;
    accessor: string;
  }
