import React, { useEffect, useState } from 'react'
import CustomToast from '../../../../../widgets/toaster'
import { useTranslation } from 'react-i18next'
import CustomTable from '../../../../../widgets/table'
import { deviceRegistration } from '../../../../../service/deviceRegistration'
import Pagination from '../../../../../widgets/pagination'
import { weakDayColumns } from './columns'
import { userService } from '../../../../../service/userService'
import DateRangeModal from './weekOffModal'
import moment from 'moment'
import AlertModal from '../../../../../widgets/alertModal'
import { DEFAULT_PAGE_SIZE } from '../../../../../constants/constants'

const WeekHoliday = () => {

  const { t } = useTranslation();
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const [useEffectRequired, setuseEffectRequired] = useState(false)
  const [permission, setPermission] = useState<any>([]);

  const [deviceRegister, setDeviceRegister] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const currentYear = new Date().getFullYear();
  const minDate = `${currentYear}-01-01`;
  const [keyWord, setkeyWord] = useState("")

  const [modalName, setModalName] = useState(t('applicationConfiguration.addWeekHoliday'));
  const [showModal, setShowModal] = useState(false);
  // const [selectedDays, setSelectedDays] = useState<{ value: string; label: string }[]>([]);
  const [selectedDays, setSelectedDays] = useState<any>([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [statusId, setStatusId] = useState("");
  const [statusAlert, setStatusAlert] = useState(false);
  const [updatedStatus, setUpdatedStatus] = useState("");
  const [errors, setErrors] = useState<Errors>({});
  const [reason, setReason] = useState("");


  //handle error section
  interface Errors {
    reason?: string;
    selectedDays?: string;
    fromDate?: string;
    toDate?: string;
  }




  // Render initial api calls 
  useEffect(() => {

    var storedData = localStorage.getItem('userDetails');

    if (storedData) {
      var storedObject = JSON.parse(storedData);
      getPermissionByRole(storedObject[0].role_master_id);
    }

    listWeekends();
    setuseEffectRequired(false)
  }, [useEffectRequired])


  //list Weekends api  
  const listWeekends = async () => {
    try {
      const response = await deviceRegistration.listWeekends(page, pageSize, keyWord);
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            setTotalPages(response?.totalPages)
            setTotalElements(response?.totalElements)
            let rowData: any = response.data.map((element: any, index: number) => {

              return {
                slno: index + 1,
                id: element.id,
                fromDate: element.from_date,
                toDate: element.to_date,
                weekDays: element.week_day,
                weekDaysName: element.week_day_names,
                status: element.status
              };
            });
            setDeviceRegister(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        setDeviceRegister([]);
      }
    } catch (error) {
      console.error('user response', error);
    }
  }

  // handle pagination change
  const handlePageChange = (newPage: any, pageSize?: number) => {
    setPage(newPage)
    if (pageSize) {
      setPageSize(pageSize)
    }
    setuseEffectRequired(true)
  };

  // handle status change
  const handleStatusChange = (policy_id: any, status: string) => {
    setStatusAlert(true)
    setStatusId(policy_id)
    if (status === "active") {
      setUpdatedStatus("inactive")
    } else {
      setUpdatedStatus("active")
    }
  }

  //handle WeekHoliday status change 
  const handleStatusChangeSubmit = async () => {
    try {
      const response = await deviceRegistration.changeWeekHolidayStatus(statusId, updatedStatus, "Change Weekend Status");

      if (response.status && response.statusCode === 200) {
        setToastType("success")
        setToastMessage(response.message)
      }
      else {
        setToastType("error")
        setToastMessage(response.message)
      }
      setShowToast(true)
      listWeekends();
      setUpdatedStatus("")
      setStatusId("")
      setStatusAlert(false)
    } catch (error) {
      console.error(error);
    }
  }

  //week holiday edit function 
  const handleEditClick = (id: string) => {
    setModalName(t('applicationConfiguration.editWeekHoliday'))
    setShowModal(true)
    const filteredUserDetails: any = deviceRegister.filter((item: any) => item.slno === id);
    console.log(filteredUserDetails, "dataToSend")
    const formattedFromDate = moment(filteredUserDetails[0]?.fromDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
    const formattedToDate = moment(filteredUserDetails[0]?.toDate, 'DD-MM-YYYY').format('YYYY-MM-DD');

    setFromDate(formattedFromDate)
    setToDate(formattedToDate)
    const weekDaysString = filteredUserDetails[0]?.weekDays; // e.g., "1,5"
    const weekDaysArray = weekDaysString ? weekDaysString.split(',') : []; // ["1", "5"]
    setSelectedDays(weekDaysArray);
    setSelectedId(filteredUserDetails[0]?.id)

    // setHolidayRegister({
    //   dropDown: filteredUserDetails[0]?.holiday_master_id,
    //   date: formattedDate,
    //   id: filteredUserDetails[0]?.id,
    //   reason: filteredUserDetails[0]?.reason
    // })
    // setShowAddModal(true)
  }

  // handle listing filter
  const handleFilter = (key: string, value: any) => {
    if (key === "Role") {
      // setRole(value)
    } else if (key === "search") {
      setkeyWord(value)
    }
    setuseEffectRequired(true)
  }

  //get permission by role 
  const getPermissionByRole = async (role: number) => {
    try {
      const response = await userService.getPermissionByRole(role);
      if (response.status && response.statusCode === 200) {
        setPermission(response.data.permissions)
      }
    } catch (error) {
      console.error(error);
    }
  }

  // add week day holiday
  const addHolidayClick = () => {
    setModalName(t('applicationConfiguration.addWeekHoliday'))
    setShowModal(true)
  }

  // handle modal close
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedId("");
    setErrors({})
    listWeekends();
    setReason("")
  };

  return (
    <div>
      <CustomToast
        show={showToast}
        onClose={() => setShowToast(false)}
        delay={5000}
        message={toastMessage}
        toastType={toastType}
      />

      <div className='me-3' style={{ marginTop: "20px" }}>
        <div className=' mx-3 pe-2' style={{ "height": "45vh" }}>
          <CustomTable
            tableHead={t('applicationConfiguration.weekHoliday')}
            data={deviceRegister}
            columns={weakDayColumns}
            isEditable={permission?.includes("edit_holiday")}
            isTempDeletable={false}
            totalElements={totalElements}
            isActionVisible={false}
            isViewVisible={false}
            isSeachable={true}
            isToggleStatus={permission?.includes("edit_holiday")}
            isResetPassword={false}
            addButton={permission?.includes("add_holiday")}
            addButtonText={t('applicationConfiguration.addWeekHoliday')}
            onUseEffectRequired={() => setuseEffectRequired(true)}
            onFilterClick={handleFilter}
            onAddClick={addHolidayClick}
            changeRoleButton={false}
            onEditClick={handleEditClick}
            onStatusChange={handleStatusChange}
          />
        </div>
        <div>
          <Pagination
            page={page}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
          />
        </div>
      </div>

      <DateRangeModal
        show={showModal}
        handleClose={handleCloseModal}
        selectedDays={selectedDays}
        setSelectedDays={setSelectedDays}
        fromDate={fromDate}
        setFromDate={setFromDate}
        toDate={toDate}
        setToDate={setToDate}
        selectedId={selectedId}
        setErrors={setErrors}
        errors={errors}
        modalName={modalName}
        reason={reason}
        setReason={setReason}
      />

      <AlertModal
        show={statusAlert}
        onHide={() => setStatusAlert(false)}
        title={t('common.alert')}
        message={t('applicationConfiguration.weekholidayStatusChangeAlert')}
        onConfirm={handleStatusChangeSubmit}
      />

    </div>
  )
}

export default WeekHoliday
