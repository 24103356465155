import React, { useEffect, useState } from "react";
import HeaderSection from "../../../common/headerSection";
import PageHeader from "../../../common/pageHeader";
import { useTranslation } from "react-i18next";
import CustomTable from "../../../../widgets/table";
import Pagination from "../../../../widgets/pagination";
import { PlateReconcilationApi } from "../../../../service/plateReconcilation";
import {
  userService as plateRelatedService,
  userService,
} from "../../../../service/plateServices";
import {
  ActivityWiseReconcilationColums,
  BatchWiseReconcilationColums,
  columns,
  Lotcolumns,
  LotWiseReconcilationColums,
  PlateWiseReconcilationColums,
} from "../partials/columns";
import { FaCompress, FaExpand } from "react-icons/fa";
import { visualInspection } from "../../../../service/visualinspection";
import CustomLoader from "../../../../widgets/loader/loader";
import { DEFAULT_PAGE_SIZE } from "../../../../constants/constants";
import baseUrl from "../../../../service";
import CustomToast from "../../../../widgets/toaster";



// var batchWiseReTotal: any = []
// var platesUsedTotal: any = []
// var remainingPlatesTotal: any = []


const PlateReconcilationSummary = () => {
  const { t } = useTranslation();

  const currentDate = new Date().toISOString().split('T')[0]; // Get current date in YYYY-MM-DD format
  const [batchlist, setBatchList] = useState<Array<any>>([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [plateBatches, setPlateBatches] = useState([]);
  const [useEffectRequired, setUseEffectRequired] = useState(false);
  const [selectedBatch, setSelectedBatch] = useState("");
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [showDownloadCSV, setShowDownloadCSV] = useState(true);
  const [isApplyClicked, setIsApplyClicked] = useState(true);
  const [addFilterData, setAddFilterData] = useState({
      event: "",
      interface : "",
      role : "",
      employee:"",
      fromDate: "",
      toDate: "",
    });
  const [plateDetails, setPlateDetails] = useState<Array<any>>([]);
  const [dropDownlotList , setDropDownlotList] = useState<Array<any>>([]); 
  const [lotList , setLotList] = useState<Array<any>>([]); 
  const [visibleTable, setVisibleTable] = useState('1');
  const [plateSerielNum, setPlateSerielNums] = useState([]);
  const [activityWiseList , setActivityWiseList] = useState<Array<any>>([]); 
  const [activityConciliationType, setActivityConciliationType] = useState("");
  const [batchWiseReTotal, setBatchWiseReTotal] = useState<string[]>([]);
  const [platesUsedTotal, setPlatesUsedTotal] = useState<string[]>([]);
  const [remainingPlatesTotal, setRemainingPlatesTotal] = useState<string[]>([]);






  // pagination

  const [lotWisePage, setLotWisePage] = useState(1);
  const [lotWisePageSize, setLotWisePageSize] = useState(DEFAULT_PAGE_SIZE);
  const [lotWiseTotalPages, setLotWiseTotalPages] = useState(0);
  const [lotWiseTotalElements, setLotWiseTotalElements] = useState(0);

  const [plateWisePage, setPlateWisePage] = useState(1);
  const [PlateWisePageSize, setPlateWisePageSize] = useState(DEFAULT_PAGE_SIZE);
  const [PlateWiseTotalPages, setPlateWiseTotalPages] = useState(0);
  const [PlateWiseTotalElements, setPlateWiseTotalElements] = useState(0);

  const [activityWisePage, setActivityWisePage] = useState(1);
  const [activityWisePageSize, setActivityWisePageSize] = useState(DEFAULT_PAGE_SIZE);
  const [activityWiseTotalPages, setActivityWiseTotalPages] = useState(0);
  const [activityWiseTotalElements, setActivityWiseTotalElements] = useState(0);

  const [lotCallFlag, setLotCallFlag] = useState(false);
  const [lotid, setLotId] = useState(false);
  const [batchid, setBatchId] = useState("");
  const [lotNum, setLotNum] = useState([]);
  const [summaryStats, setSummaryStats] = useState<any>({});
  const [batchStats, setBatchStats] = useState<any>({});
  const [subHead, setSubHead] = useState(t("reconciliationSummary.reconciliationSummary"));
  const [fromdate, setFromDate] = useState(
      new Date(new Date().setDate(new Date().getDate() - 7))
        .toISOString()
        .split("T")[0]
    );
  const [toDate, setToDate] = useState(currentDate)
  const [showDownloadPdfBtn, setShowDownloadPdfBtn] = useState(true);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");


  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  const getBatchList = async () => {
    try {
      const response = await PlateReconcilationApi.getBatchList(
        page,
        pageSize,
        selectedBatch,
        fromdate,
        toDate
      );
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            setTotalPages(response?.totalPages);
            setTotalElements(response?.totalElements);
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                const StatusConstants = {
                  QUARANTINE: "0",
                  QA_APPROVED: "1",
                  READY_TO_USE: "2",
                  ACTIVITY_INPROGRESS: "3",
                  QA_REJECTED: "4",
                  UNDER_TEST: "5",
                  BATCH_DISCARD: "6",
                };

                let status;

                // Mapping numeric status to string representation
                switch (element.batch_status) {
                  case StatusConstants.UNDER_TEST:
                    status = "Waiting for QA Approval";
                    break;
                  case StatusConstants.QA_APPROVED:
                    status = "QA APPROVED";
                    break;
                  case StatusConstants.READY_TO_USE:
                    status = "READY TO USE";
                    break;
                  case StatusConstants.ACTIVITY_INPROGRESS:
                    status = "ACTIVITY INPROGRESS";
                    break;
                  case StatusConstants.QA_REJECTED:
                    status = "QA REJECTED";
                    break;
                  case StatusConstants.QUARANTINE:
                    status = "QUARANTINE";
                    break;
                  case StatusConstants.BATCH_DISCARD:
                    status = "DISCARD";
                    break;
                  default:
                    status = "UNKNOWN STATUS";
                }

                return {
                  id: element.id,
                  slno: index + 1,
                  batch_code: element.batch_no,
                  lot_count: element.lot_count,
                  plates_available: element.plate_count,
                  plates_used: element.used_plates_count,
                  remaining_plates_reconciliation: element.remaining_plates_count,
                };
              }
            );
            setBatchList(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        setBatchList([]);
        setTotalPages(0);
        setTotalElements(0);
      }
    } catch (error) {
      console.error("Plate response", error);
    }
  };

  const listPlateBatch = async () => {
    try {
      const response = await plateRelatedService.listPlateBatch();
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                return {
                  value: element.batch_number,
                  label: element.batch_number,
                };
              }
            );
            setPlateBatches(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.error("Plate response", error);
    }
  };

  const getActivityConcilation = async (page: number, pageSize: number, id: any, type: string) => {
    setActivityConciliationType(type)
    try {
      let response
      if (type === "batchId") {
        response = await PlateReconcilationApi.getActivityConcilation(page, pageSize, id)
      } else if (type === "lotId") {
        response = await PlateReconcilationApi.getActivityConcilationByLotId(page, pageSize, id)
      }

      if(response.status && response.statusCode === 200){
        try {
          if (response.data && response.data && response.data.length > 0) {
              setActivityWiseTotalPages(response?.totalPages)
              setActivityWiseTotalElements(response?.totalElements)
              let rowData: any = response.data.map((element: any, index: number) => {
  
                return { 
                    id : element.id,
                    slno: index + 1, 
                    lot_code: element.lot_code,
                    plate_sampling: element.activity_wise_reconciliation.sampling_plate_count,
                    air_sampling: element.activity_wise_reconciliation.active_air_sampling_plate_count,
                    passive_sampling: element.activity_wise_reconciliation.passive_air_sampling_plate_count,

                    sample_type_test: element.activity_wise_reconciliation.raw_material_plate_count,

                    surface_monitoring: element.activity_wise_reconciliation.sf_monitoring_plate_count,
                    personnel_monitoring: element.activity_wise_reconciliation.personal_monitoring_plate_count,
                    swab_collection: element.activity_wise_reconciliation.sf_monitoring_swab_plate_count,
                    visual_inspection_failed: element.activity_wise_reconciliation.visual_inspection_failed_plate_count,
                    plate_rejected: element.activity_wise_reconciliation.rejected_plate_count,
                    plate_discarded: element.activity_wise_reconciliation.discarded_plate_count,
                    exception_added: element.activity_wise_reconciliation.exception_added_plate_count,
                    
                };
              });
              setActivityWiseList(rowData);
              setIsLoading(false)
            }
          } catch (error) {
            console.log(error); 
          }
      } else {
        setActivityWiseList([])
        setIsLoading(false)
      }
    } catch (error) {
      console.error('Plate response', error);
    }
  
}

  // const getPlateList = async (page :number, size :number, batchId: any) => {
  //     try {
  //       const response = await PlateReconcilationApi.getPlateListByBatchId(page, size, batchId);
  //       if(response.status && response.statusCode === 200){
  //         try {
  //             if ( Array.isArray(response.data) && response.data?.length > 0) {
  //               setPlateWiseTotalPages(response?.totalPages)
  //               setPlateWiseTotalElements(response?.totalElements)
  //               let rowData: any = response.data.map((element: any, index: number) => {

  //                 const statusNumber = parseInt(element.plate_status, 10); 
  //                 const plateStatus = userService.getPlateStatus(statusNumber);

  //                 return { 
  //                     id : element.id,
  //                     slno: index + 1, 
  //                     serial_number: element.serial_number,
  //                     plateStatus: plateStatus                        
  //                 };
  //               });
  //               setPlateDetails(rowData);
  //               setIsLoading(false)
  //             }
  //           } catch (error) {
  //             console.log(error); 
  //           }
  //       } else {
  //         setPlateDetails([])
  //         setIsLoading(false)
  //       }
  //     } catch (error) {
  //       console.error('Plate response', error);
  //     }
  //   };

  //     //on cell click 
  const handleCellClick = (rowData: any) => {
      const activeColumnIds = ["lot_count", "plates_used", "remaining_plates_reconciliation"]
      if(activeColumnIds.includes(rowData.column.id)){
        setIsLoading(true)
      }
      getBatcheStat(rowData?.row?.original?.batch_code);
      if(rowData.column.id === "lot_count"){
          getLotList(1, DEFAULT_PAGE_SIZE, rowData?.row?.original?.id)
          setLotWisePage(1)
          setLotWisePageSize(DEFAULT_PAGE_SIZE)
          setBatchId(rowData?.row?.original?.id)
          setVisibleTable("2")
          setSubHead(t("reconciliationSummary.lotwiseReconciliation"))
      }
      if(rowData.column.id === "plates_used"){
          setVisibleTable("3")
          getActivityConcilation(1, DEFAULT_PAGE_SIZE, rowData?.row?.original?.id, "batchId")
          setActivityWisePage(1)
          setActivityWisePageSize(DEFAULT_PAGE_SIZE)
          setBatchId(rowData?.row?.original?.id)
          getActivityWiseReconciliationStat(rowData?.row?.original?.id, "")
          setSubHead(t("reconciliationSummary.activitywiseReconciliation"))
      }
      if(rowData.column.id === "remaining_plates_reconciliation"){
          setVisibleTable("4")
          getRemainingLotList(1, DEFAULT_PAGE_SIZE, rowData?.row?.original?.id)
          setPlateWisePage(1)
          setPlateWisePageSize(DEFAULT_PAGE_SIZE)
          setBatchId(rowData?.row?.original?.id)
          getRemainingReconciliationStat(rowData?.row?.original?.batch_code)
          setSubHead(t("reconciliationSummary.platewiseSummary"))
      }
      // setShowButtonWithCloseIcon(true)
      // setCloseIconText(rowData?.row?.original?.batch_number)
      // listBatchPlateDetails(BatchByPage, BatchByPageSize,sort,order, 0, rowData?.row?.original?.batch_number, keyWord)
  }

  //lot wise listing
  const getLotList = async (page: number, pageSize: number, batchId: any) => {
      try {
        const response = await PlateReconcilationApi.ListLots(page, pageSize, batchId, "", "failed,passed,pending,Not required")
        if(response.status && response.statusCode === 200){
          try {
              if ( Array.isArray(response.data) && response.data?.length > 0) {
                setLotWiseTotalPages(response?.totalPages)
                setLotWiseTotalElements(response?.totalElements)
                let rowData: any = response.data.map((element: any, index: number) => {
    
                  return { 
                      id : element.id,
                      slno: index + 1, 
                      lot_code: element.lot_code,
                      plates_availables: element.plate_count,
                      plates_used: element.used_plates_count,
                      remaining_plates: element.remaining_plates_count,
                  };
                });
                setLotList(rowData);
                setIsLoading(false)
              }
            } catch (error) {
              console.log(error); 
            }
        } else {
          setLotList([])
          setIsLoading(false)
        }
      } catch (error) {
        console.error('Plate response', error);
      }
    
  }

  //remaining plate listing
  const getRemainingLotList = async (page: number, pageSize: number, batchId: any, lotId?: any) => {
    try {
      const response = await PlateReconcilationApi.ListLots(page, pageSize, batchId, lotId || "", "failed,passed,pending,Not required")
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              setPlateWiseTotalPages(response?.totalPages)
              setPlateWiseTotalElements(response?.totalElements)
              // setLotWiseTotalPages(response?.totalPages)
              // setLotWiseTotalElements(response?.totalElements)
              let rowData: any = response.data.map((element: any, index: number) => {
  
                return { 
                    id : element.id,
                    slno: index + 1, 
                    lot_code: element.lot_code,
                    plate_qr_generated: element.qr_generated_new_plates,
                    pending_for_qr_generated: element.remaining_plates_for_qr,
                };
              });
              setPlateDetails(rowData);
              setIsLoading(false)
            }
          } catch (error) {
            console.log(error); 
          }
      } else {
        setPlateDetails([])
        setIsLoading(false)
      }
    } catch (error) {
      console.error('Plate response', error);
    }
  
  }

  const handlebatchWiseReconciliation = (key: string, value: any) => {
    if (key === "batch_code") {
      setSelectedBatch(value)
      if (value) {
        setShowDownloadPdfBtn(false)
      } else {
        setShowDownloadPdfBtn(true)
      }
    }else if (key === "FromDate"){
      setFromDate(value)
      setToDate("")
    }else if (key === "ToDate"){
      setToDate(value)
    }
    setUseEffectRequired(true)
  };

  //handle lot wise table pagination 
  const handleLotWisePageChangeLot = (newPage : any, pageSize?: number) => {
    setLotWisePage(newPage);
    if(pageSize) {
      setLotWisePageSize(pageSize)
      getLotList(newPage, pageSize, batchid)
    } else {
      getLotList(newPage, lotWisePageSize, batchid)
    }
  };

  //handle activity wise table pagination 
  const handleActivityWisePageChange = (newPage : any, pageSize?: number) => {
    setActivityWisePage(newPage);
    if(pageSize) {
      setActivityWisePageSize(pageSize)
      getActivityConcilation(newPage, pageSize, batchid, "batchId")
    } else {
      getActivityConcilation(newPage, activityWisePageSize, batchid, "batchId")
    }
  };

  //handle platewise table pagination 
  const handlePlateWisePageChange = (newPage : any, pageSize?: number) => {
    setPlateWisePage(newPage);
    if(pageSize) {
      setPlateWisePageSize(pageSize)
      getRemainingLotList(newPage, pageSize, batchid)
    } else {
      getRemainingLotList(newPage, PlateWisePageSize, batchid)
    }
  };

  const handlePageChange = (newPage: any, pageSize?: number) => {
    setPage(newPage);
    if (pageSize) {
      setPageSize(pageSize);
    }
    setUseEffectRequired(true);
  };

  const getReconciliationBatcheStat = async (batchNo: string) => {
    try {
      const response = await PlateReconcilationApi.getReconciliationBatcheStat(batchNo);
      if(response.status && response.statusCode === 200){
          let rowData = {
            totalBatches : response.data.total_batches,
            totalPlates: response.data.total_plates, 
            totalPlatesUsed: response.data.used_plates,
            totalPlatesRemaining: response.data.remaining_plates  
          }
          setSummaryStats(rowData)
        } else {
        setSummaryStats({})
      }
    } catch (error) {
      console.error('Plate response', error);
    }
  };

  const getBatcheStat = async (batchNo: string) => {
    try {
      const response = await PlateReconcilationApi.getReconciliationBatcheStat(batchNo);
      if(response.status && response.statusCode === 200){
          let element = response.data.batch_details
          let batchData = {
            batchName : element.batch_no,
            batchGrn : element.batch_grn,
            totalPlates: response.data.total_plates, 
            totalPlatesUsed: response.data.used_plates,
            totalPlatesRemaining: response.data.remaining_plates  
          }

          setBatchWiseReTotal([
            "Total",
            "-",
            response.data.total_plates.toString(),
            response.data.used_plates.toString(),
            response.data.remaining_plates.toString()
          ]);
          setBatchStats(batchData)
        } else {
        setBatchStats({})
      }
    } catch (error) {
      console.error('Plate response', error);
    }
  };

  const getActivityWiseReconciliationStat = async (batchId: string, lotId: string) => {
    try {
      const response = await PlateReconcilationApi.getActivityWiseReconciliationStat(batchId, lotId);
      if(response.status && response.statusCode === 200){
          
        setPlatesUsedTotal([
            "Total",
            "-",
            response.data.sampling_plate_count.toString(),  
            response.data.active_air_sampling_plate_count.toString(), 
            response.data.passive_air_sampling_plate_count.toString(), 
            response.data.raw_material_plate_count.toString(), 
            response.data.sf_monitoring_plate_count.toString(), 
            response.data.personal_monitoring_plate_count.toString(),  
            response.data.sf_monitoring_swab_plate_count.toString(), 
            response.data.visual_inspection_failed_plate_count.toString(), 
            response.data.rejected_plate_count.toString(), 
            response.data.discarded_plate_count.toString(), 
            response.data.exception_added_plate_count.toString(),   
          ]);
        }
    } catch (error) {
      console.error('Plate response', error);
    }
  };

  const handleLotWiseCellClick = (col: any, rowData: any) => {
    if(col.accessor === "plates_used"){
      setVisibleTable("3")
      getActivityConcilation(1, DEFAULT_PAGE_SIZE, rowData?.id, "lotId")
      setActivityWisePage(1)
      setActivityWisePageSize(DEFAULT_PAGE_SIZE)
      setBatchId(rowData?.id)
      getActivityWiseReconciliationStat("", rowData?.id)
      setSubHead(t("reconciliationSummary.activitywiseReconciliation"))
    }
    if(col.accessor === "remaining_plates"){
      setVisibleTable("4")
      getRemainingLotList(1, DEFAULT_PAGE_SIZE, "" ,rowData?.id)
      setPlateWisePage(1)
      setPlateWisePageSize(DEFAULT_PAGE_SIZE)
      setBatchId(rowData?.id)
      getRemainingReconciliationStat(rowData?.id, "lotWise")
      setSubHead(t("reconciliationSummary.platewiseSummary"))
    }
  }

  const getRemainingReconciliationStat = async (batchId: string, type?:string) => {
    try {
      let response
      if(type === "lotWise") {
         response = await PlateReconcilationApi.getRemainingReconciliationStat("", batchId);
      } else {
         response = await PlateReconcilationApi.getRemainingReconciliationStat(batchId, "");
      }
      if(response.status && response.statusCode === 200){
          
        setRemainingPlatesTotal([
            "Total",
            "-",
            response.data.total_qr_generated_new_plates.toString(),  
            response.data.total_remaining_plates_for_qr.toString(),
          ]);
        }
    } catch (error) {
      console.error('Plate response', error);
    }
  };

  //download reconceliation report
  const downloadAuditCSV = async () => {
    try {
      const response = await PlateReconcilationApi.downloadReconceliationReport(selectedBatch);
      if (response.status && response.statusCode === 200) {
        setToastMessage(response.message)
        setToastType("success")
      } else {
        setToastMessage(response.message)
        setToastType("error")
      }
      setShowToast(true)
    } catch (error) {
      console.error("Plate response", error);
    }
  };

  useEffect(() => {
    listPlateBatch();
    getReconciliationBatcheStat("");
  }, []);

  useEffect(() => {
    getBatchList();
    setUseEffectRequired(false);
  }, [useEffectRequired]);

  return (
    <div>
      <HeaderSection />
      <PageHeader
        pageHeader={t("reconciliationSummary.reconciliationSummary")}
      />
      <CustomToast
        show={showToast}
        onClose={() => setShowToast(false)}
        delay={5000}
        message={toastMessage}
        toastType={toastType}
      />

      <div className="row" style={{"minHeight" : "80vh"}}>
        {!isFullScreen && (
          <div className="col-7">
            {/* Content for the first column */}
            <div className="row key-value-pair-container">
              <div className="col-3 key-value-item">
                <span className="key">{t("reconciliationSummary.totalBatches")}</span>
                <span className={`total-batches-value ${summaryStats.totalBatches ? "value" : "spinner-border align-self-center"}`}>{summaryStats.totalBatches}</span>
              </div>
              <div className="col-3 key-value-item">
                <span className="key">{t("reconciliationSummary.totalPlates")}</span>
                <span className={`total-plates-value ${summaryStats.totalPlates ? "value" : "spinner-border align-self-center"}`}>{summaryStats.totalPlates}</span>
              </div>
              <div className="col-3 key-value-item">
                <span className="key">{t("reconciliationSummary.platesUsed")}</span>
                <span className={`plates-used-value ${summaryStats.totalPlatesUsed ? "value" : "spinner-border align-self-center"}`}>{summaryStats.totalPlatesUsed}</span>
              </div>
              <div className="col-3 key-value-item">
                <span className="key">{t("reconciliationSummary.remainingPlates")}</span>
                <span className={`remaining-plates-value ${summaryStats.totalPlatesRemaining ? "value" : "spinner-border align-self-center"}`}>{summaryStats.totalPlatesRemaining}</span>
              </div>
            </div>
            <>
              <div className=" m-3 pe-2" style={{ height: "45vh" }}>
                <CustomTable
                  tableHead={t("reconciliationSummary.batchWiseReconciliation")}
                  data={batchlist}
                  columns={BatchWiseReconcilationColums}
                  isEditable={false}
                  totalElements={totalElements}
                  isActionVisible={false}
                  isViewVisible={false}
                  addPlateUploadText={t("plateInventory.bulk")}
                  showBlueBtn={true}
                  onBlueBtnClick={downloadAuditCSV}
                  disableBlueBtn={showDownloadPdfBtn}
                  blueBtnText={t("reconciliationSummary.downloadPDF")}
                  blueBtnTextTitle={t("reconciliationSummary.PDFBtnTitle")}
                  plateBatch={plateBatches}
                  isSeachable={false}
                  showPlateStatus={false}
                  onCellClick={handleCellClick}
                  showBatchFilter={true}
                  onFilterClick={handlebatchWiseReconciliation}
                  showFromDateFilter={true}
                  showToDateFilter={true}
                  showFullFilters={true}
                />
              </div>
              <div>
                <Pagination
                  page={page}
                  totalPages={totalPages}
                  handlePageChange={handlePageChange}
                />
              </div>
            </>
          </div>
        )}
          {isFullScreen && (        
          <nav aria-label="breadcrumb" className="breadcrumb-container row">
          <div className="col-10 d-flex">
            <ol className="breadcrumb">
              <li className="breadcrumb-item dashboard-hover" onClick={toggleFullScreen}>{t("reconciliationSummary.reconciliationSummary")}</li>
              <li className="breadcrumb-item active" aria-current="page">
              {subHead}
              </li>
            </ol>
          </div>
          <div className="col-2 d-flex"></div>
          </nav>
          )}
        
        <div
          className={`row ${
            isFullScreen ? "col-12" : "col-5"
          }`}
        >
          {/* Content inside the box */}
          <div className="subTableHead">
            <div className="d-flex justify-content-between mt-1">
              <p style={{ color: "black", marginLeft: "5px", fontSize: "16px"}}>{subHead}</p>
              <div className="content" onClick={toggleFullScreen}>
                {isFullScreen ? <FaCompress size={20} color="orange"/> : <FaExpand size={20} color="orange" />}
              </div>
            </div>
            <div className="row">
              <div className="col d-flex">
                <span style={{ color: "black", marginLeft: "5px" }}>{t("reconciliationSummary.batchCode")}:</span>
                <span style={{ color: "red", marginLeft: "5px" }}>{batchStats.batchName}</span>
              </div>
              <div className="col d-flex">
                <span style={{ color: "black", marginLeft: "5px" }}>{t("reconciliationSummary.batchGrn")}:</span>
                <span style={{ color: "red", marginLeft: "5px" }}>{batchStats.batchGrn}</span>
              </div>
              <div className="col d-flex"></div>
            </div>
            <div className="row my-3">
              <div className="col d-flex">
                <span style={{ color: "black", marginLeft: "5px" }}>{t("reconciliationSummary.totalPlates")}:</span>
                <span style={{ color: "red", marginLeft: "5px" }}>{batchStats.totalPlates}</span>
              </div>
              <div className="col d-flex">
                <span style={{ color: "black", marginLeft: "5px" }}>{t("reconciliationSummary.platesUsed")}:</span>
                <span style={{ color: "red", marginLeft: "5px" }}>{batchStats.totalPlatesUsed}</span>
              </div>
              <div className="col d-flex">
                <span style={{ color: "black", marginLeft: "5px" }}>{t("reconciliationSummary.remainingPlates")}:</span>
                <span style={{ color: "red", marginLeft: "5px" }}>{batchStats.totalPlatesRemaining}</span>
              </div>
            </div>
          </div>

          {isLoading ? (
               <div style={{"height":"65vh"}}>  <CustomLoader /> </div> ) : (<>

          {visibleTable === "1" && (
          <div style={{"minHeight": "55vh", "display":"flex","justifyContent": "center", "alignItems":"center"}}>
            <p className="">
              {t("common.emptyTableText")}
            </p>
          </div>
          )}
        {visibleTable === "2" && (
          <>
          <div style={{"minHeight": "50vh","maxHeight":"40vh", "overflowY": "auto", "fontSize":"14px", "overflowX": "auto",}}>
          <table className="table" >
            <thead>
              <tr>
                {/* Create headers from Lotcolumns */}
                {LotWiseReconcilationColums.map((column, index) => (
                  <th key={index}>{column.Header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {/* Map through each row */}
              {lotList.map((row: any, rowIndex) => (
                <tr key={rowIndex}>
                  {/* Render cells based on Lotcolumns' accessors */}
                  {LotWiseReconcilationColums.map((column, colIndex) => (
                    <td 
                      className={column.accessor === "plates_used" || column.accessor === "remaining_plates" ? "quantity" : ""}
                      onClick={() => handleLotWiseCellClick(column, row)} key={colIndex}>{row[column.accessor]}
                    </td>
                  ))}
                </tr>
              ))}
              <tr>
                {/* Create headers from Lotcolumns */}
                {batchWiseReTotal.map((column: any, index:any) => (
                  <th style={{"background": "#FF7344", "color": "white","borderLeft" : "1px solid white"}} key={index}>{column}</th>
                ))}
              </tr>
            </tbody>
          </table>
          </div>

          <div>
                <Pagination
                    page={lotWisePage}
                   totalPages={lotWiseTotalPages}
                   handlePageChange={handleLotWisePageChangeLot}
                />
          </div>
          </>
        )}

       {visibleTable === "4" && (
        <>
          <div style={{"minHeight": "50vh","maxHeight":"40vh", "overflowY": "auto", "fontSize":"14px", "overflowX": "auto",}}>
          <table className="table">
          <thead>
            <tr>
              {/* Create headers from Lotcolumns */}
              {PlateWiseReconcilationColums.map((column, index) => (
                <th key={index}>{column.Header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {/* Map through each row */}
            {plateDetails.map((row: any, rowIndex) => (
              <tr key={rowIndex}>
                {/* Render cells based on Lotcolumns' accessors */}
                {PlateWiseReconcilationColums.map((column, colIndex) => (
                  <td key={colIndex}>{row[column.accessor]}</td>
                ))}
              </tr>
            ))}
            <tr>
                {remainingPlatesTotal.map((column: any, index: any) => (
                  <th style={{"background": "#FF7344", "color": "white", "borderLeft" : "1px solid white"}} key={index}>{column}</th>
                ))}
              </tr>
          </tbody>
        </table>
        </div>

        <div>
                 <Pagination
                     page={plateWisePage}
                     totalPages={PlateWiseTotalPages}
                     handlePageChange={handlePlateWisePageChange}
                 />
             </div>

        </>
        )}

      {visibleTable === "3" && (
        <>
          <div style={{"minHeight": "50vh","maxHeight":"40vh", "overflowY": "auto", "fontSize":"14px", "overflowX": "auto",}}>
          <table className="table">
          <thead>
            <tr>
              {/* Create headers from Lotcolumns */}
              {ActivityWiseReconcilationColums.map((column, index) => (
                <th key={index}>{column.Header}</th>
              ))}
            </tr>
          </thead>
          <tbody >
            {/* Map through each row */}
            {activityWiseList.map((row: any, rowIndex) => (
              <tr key={rowIndex}>
                {/* Render cells based on Lotcolumns' accessors */}
                {ActivityWiseReconcilationColums.map((column, colIndex) => (
                  <td key={colIndex}>{row[column.accessor]}</td>
                ))}
              </tr>
            ))}
            <tr>
                {platesUsedTotal.map((column: any, index: any) => (
                  <th style={{"background": "#FF7344", "color": "white", "borderLeft" : "1px solid white"}} key={index}>{column}</th>
                ))}
              </tr>
          </tbody>
        </table>
        </div>

        <div>
                <Pagination
                    page={activityWisePage}
                    totalPages={activityWiseTotalPages}
                    handlePageChange={handleActivityWisePageChange}
              />
            </div>
        </>
        )}




        </>)}

        </div>
      </div>
    </div>
  );
};

export default PlateReconcilationSummary;
