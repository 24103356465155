import React, { useEffect, useState } from 'react'
import HeaderSection from '../../common/headerSection'
import PageHeader from '../../common/pageHeader'
import CustomTable from '../../../widgets/table'
import Pagination from '../../../widgets/pagination'
import { PlateReconcilationApi } from '../../../service/plateReconcilation'
import {
    userService as plateRelatedService,
    userService,
  } from "../../../service/plateServices";
  import { BatchWiseReconcilationColums } from './partials/columns';
import { BatchService } from '../../../service/plateServices/discard'
import baseUrl from '../../../service'
import { DEFAULT_PAGE_SIZE } from '../../../constants/constants'
import AlertModal from '../../../widgets/alertModal'
import { useTranslation } from 'react-i18next'

const TestTubeReconciliation = () => {

    const currentDate = new Date().toISOString().split('T')[0]; 
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const [totalPages, setTotalPages] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [summaryStats, setSummaryStats] = useState<any>({});
    const [useEffectRequired, setUseEffectRequired] = useState(false);
    const [fromdate, setFromDate] = useState(
        new Date(new Date().setDate(new Date().getDate() - 7))
          .toISOString()
          .split("T")[0]
      );
    const [toDate, setToDate] = useState(currentDate)
    const [selectedBatch, setSelectedBatch] = useState("");
    const [plateBatches, setPlateBatches] = useState([]);
    const [batchlist, setBatchList] = useState<Array<any>>([]);
    const [authUserID, setAuthUserID] = useState("");
    const [sort, setSort] = useState("id");
    const [order, setOrder] = useState("desc");
    const [pdfDownload, setpdfDownload] = useState(false);
    const { t } = useTranslation()

    useEffect(() => {

      var storedData = localStorage.getItem("userDetails");

      if (storedData) {
        var storedObject = JSON.parse(storedData);
        setAuthUserID(storedObject[0].x_userid);
      }

        getReconciliationBatcheStat();
        setUseEffectRequired(false);
        getBatchList()
        listPlateBatch()
      }, [useEffectRequired]);


    const getReconciliationBatcheStat = async () => {
        try {
          const response = await PlateReconcilationApi.getTestTubeStat();
          if(response.status && response.statusCode === 200){
              let rowData = {
                totalBatches : response.data.total_batches,
                totalPlates: response.data.total_swabs, 
                totalPlatesUsed: response.data.total_swabs_used,
                totalPlatesRemaining: response.data.total_swabs_remaining  
              }
              setSummaryStats(rowData)
            } else {
            setSummaryStats({})
          }
        } catch (error) {
          console.error('Plate response', error);
        }
      };

      const handlePageChange = (newPage: any, pageSize?: number) => {
        setPage(newPage);
        if (pageSize) {
          setPageSize(pageSize);
        }
        setUseEffectRequired(true);
      };

      const handlebatchWiseReconciliation = (key: string, value: any) => {
        if (key === "batch_code") {
          setSelectedBatch(value)
        }else if (key === "FromDate"){
          setFromDate(value)
          setToDate("")
        }else if (key === "ToDate"){
          setToDate(value)
        }
        setUseEffectRequired(true)
      };

      const listPlateBatch = async () => {
      try {
        const response = await BatchService.getTestTubeBatchFilter();
        if(response.status && response.statusCode === 200){
          try {
              if ( Array.isArray(response.data) && response.data?.length > 0) {
                let rowData: any = response.data.map((element: any, index: number) => {

                  return { 
                      value: element.id,
                      label: element.batch_no ,
                  };
                });
                setPlateBatches(rowData);
              }
            } catch (error) {
              console.log(error); 
            }
        }
      } catch (error) {
        console.error('Plate response', error);
      }
    }
    
      const getBatchList = async () => {
        try {
          // const response = await PlateReconcilationApi.getBatchList(
          //   page,
          //   pageSize,
          //   selectedBatch,
          //   fromdate,
          //   toDate
          // );
          const response = await BatchService.getTestTubeBatchDetails(
            page,
            pageSize,
            sort,
            order,
            selectedBatch,
            fromdate,
            toDate
          )
          if (response.status && response.statusCode === 200) {
            try {
              if (Array.isArray(response.data) && response.data?.length > 0) {
                setTotalPages(response?.totalPages);
                setTotalElements(response?.totalElements);
                let rowData: any = response.data.map(
                  (element: any, index: number) => {
                    const StatusConstants = {
                      QUARANTINE: "0",
                      QA_APPROVED: "1",
                      READY_TO_USE: "2",
                      ACTIVITY_INPROGRESS: "3",
                      QA_REJECTED: "4",
                      UNDER_TEST: "5",
                      BATCH_DISCARD: "6",
                    };
    
                    let status;
    
                    // Mapping numeric status to string representation
                    switch (element.batch_status) {
                      case StatusConstants.UNDER_TEST:
                        status = "Waiting for QA Approval";
                        break;
                      case StatusConstants.QA_APPROVED:
                        status = "QA APPROVED";
                        break;
                      case StatusConstants.READY_TO_USE:
                        status = "READY TO USE";
                        break;
                      case StatusConstants.ACTIVITY_INPROGRESS:
                        status = "ACTIVITY INPROGRESS";
                        break;
                      case StatusConstants.QA_REJECTED:
                        status = "QA REJECTED";
                        break;
                      case StatusConstants.QUARANTINE:
                        status = "QUARANTINE";
                        break;
                      case StatusConstants.BATCH_DISCARD:
                        status = "DISCARD";
                        break;
                      default:
                        status = "UNKNOWN STATUS";
                    }
    
                    return {
                      id: element.id,
                      slno: index + 1,
                      batch_code: element.batch_no,
                      plates_available: element.total_test_tube_count,
                      plates_used_: element.used_test_tube_count,
                      remaining_plates_reconciliation_: element.remaining_test_tube_count,
                    };
                  }
                );
                setBatchList(rowData);
              }
            } catch (error) {
              console.log(error);
            }
          } else {
            setBatchList([]);
            setTotalPages(0);
            setTotalElements(0);
          }
        } catch (error) {
          console.error("Plate response", error);
        }
      };

      const handleDownloadPDF = async () => {
        setpdfDownload(false)
        const link = `${baseUrl}/generate/test_tube_reconcillation?&batch_id=${selectedBatch}&from_date=${fromdate || ""}&to_date=${toDate || ""}&auth_id=${authUserID}`
        window.location.href = link;
      }

      const handleSort = (key: string, value: boolean) => {
        setSort(key);
        if (value) {
          setOrder("asc");
        } else {
          setOrder("desc");
        }
        setUseEffectRequired(true);
      };

  return (
    <div>
       <HeaderSection />
      <PageHeader pageHeader={"Test Tube Reconciliation"}/>


      <div className="row" style={{"minHeight" : "80vh"}}>
          <div className="col-12">
            {/* Content for the first column */}
            <div className="row key-value-pair-container">
              <div className="col-3 key-value-item">
                <span className="key">Total Batches</span>
                <span className={`total-batches-value ${summaryStats.totalBatches ? "value" : "spinner-border align-self-center"}`}>{summaryStats.totalBatches}</span>
              </div>
              <div className="col-3 key-value-item">
                <span className="key">Total Test Tube</span>
                <span className={`total-plates-value ${summaryStats.totalPlates ? "value" : "spinner-border align-self-center"}`}>{summaryStats.totalPlates}</span>
              </div>
              <div className="col-3 key-value-item">
                <span className="key">Test Tube Used</span>
                <span className={`plates-used-value ${summaryStats.totalPlatesUsed ? "value" : "spinner-border align-self-center"}`}>{summaryStats.totalPlatesUsed}</span>
              </div>
              <div className="col-3 key-value-item">
                <span className="key">Remaining Test Tube</span>
                <span className={`remaining-plates-value ${summaryStats.totalPlatesRemaining ? "value" : "spinner-border align-self-center"}`}>{summaryStats.totalPlatesRemaining}</span>
              </div>
            </div>
            <>
              <div className=" mx-3 pe-2" style={{ height: "45vh" }}>
                <CustomTable
                  tableHead={"Test Tube Reconciliation"}
                  data={batchlist}
                  columns={BatchWiseReconcilationColums}
                  isEditable={false}
                  totalElements={totalElements}
                  isActionVisible={false}
                  isViewVisible={false}
                //   addPlateUploadText={t("plateInventory.bulk")}
                  blueBtnText="Plate Sampling"
                  plateBatch={plateBatches}
                  isSeachable={false}
                  showPlateStatus={false}
                  showBatchFilter={true}
                  onFilterClick={handlebatchWiseReconciliation}
                  showFromDateFilter={true}
                  showToDateFilter={true}
                  addButton={true}
                  addButtonText='Download PDF'
                  onAddClick={()=>setpdfDownload(true)}
                  onSortClick={handleSort}
                />
              </div>
              <div>
                <Pagination
                  page={page}
                  totalPages={totalPages}
                  handlePageChange={handlePageChange}
                />
              </div>
            </>
          </div>
        </div>

        <AlertModal
        show={pdfDownload}
        onHide={() => setpdfDownload(false)}
        title="Alert"
        message={t('downloadPdf.download')}
        onConfirm={handleDownloadPDF}
      />

        </div>
  )
}

export default TestTubeReconciliation