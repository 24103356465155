import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, Navigate } from "react-router-dom";
import { authenticationService } from "../service/authentication";
import { useTranslation } from "react-i18next";
import CustomToast from "../widgets/toaster";
import ResetPassword from "../widgets/resetPasswordModal";
import { IoIosEyeOff } from "react-icons/io";
import { IoIosEye } from "react-icons/io";


const Signin = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentHostAndPort = window.location.host;
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [loginSuccessMessage, setLoginSuccessMessage] = useState("");
  const [daysRemaining, setDaysRemaining] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [isLoginAgain, setIsLoginAgain] = useState(false);

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const canLogin = username !== "" && password !== "";

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      if (event.target.id === "username") {
        // Move focus to the password input field
        const passwordInput = document.getElementById("password");
        if (passwordInput) {
          passwordInput.focus();
        }
      } else if (event.target.id === "password") {
        // Trigger login button click if not already disabled
        if (!isButtonDisabled) {
          handleLoginBtn();
        }
      }
    }
  };

  const handleAlertShown = () => {
    setShowAlert(false);
    localStorage.removeItem("userDetails");
    window.location.replace(`/updatePassword?username=${username}`);
  };

  const handleAlertClose = () => {
    let loginDetails = localStorage.getItem("loginDetails");
    if (loginDetails !== null) {
      // Set the value under the new key
      localStorage.setItem("userDetails", loginDetails);
      localStorage.removeItem("loginDetails");
      console.log("Key renamed successfully.");
    }
    if (!isLoginAgain) {
      setToastType("success");
      setToastMessage(loginSuccessMessage);
      setShowToast(true);
    } 
    setShowAlert(false);
    window.location.reload();
  };

  const handleLoginBtn = async (transferSession: boolean = false) => {
    // setIsButtonDisabled(true);
    try {

      const licenseExpiryDate = new Date("2025-01-31"); // Set the license expiration date
      const currentDateLogin = new Date();

      if (currentDateLogin > licenseExpiryDate) {
        // Show license expired toaster
        setToastType("error");
        setToastMessage("License expired. Please contact Administrator.");
        setShowToast(true);
        return; 
      }


      const response = await authenticationService.loginRequest(
        username,
        password,
        transferSession
      );
      const userDetails = [];
      if (response.status && response.statusCode === 200) {
        setIsButtonDisabled(true);
        userDetails.push({
          email_id: response.user_details.email_id,
          first_name: response.user_details.first_name,
          id: response.user_details.id,
          last_name: response.user_details.last_name,
          pin: response.user_details.pin,
          role_master_id: response.user_details.role_master_id,
          user_id: response.user_details.user_id,
          x_auth_token: response["x-auth-token"],
          x_userid: response["x-userid"],
          password_expiry_date: response.password_expiry_date,
          password_expiry_warning_flag: response.password_expiry_warning_flag,
        });

        if (response.is_default_password === "0") {
          localStorage.setItem("loginDetails", JSON.stringify(userDetails));
          navigate(`/updatePassword?username=${response.user_details.user_id}`);
          // window.location.replace("/updatePassword")
        } else {
          if (response.password_expiry_warning_flag === 1) {
            localStorage.setItem("loginDetails", JSON.stringify(userDetails));
            setShowAlert(true);
            setLoginSuccessMessage(response.message);
          } else {
            localStorage.setItem("userDetails", JSON.stringify(userDetails));
            window.location.reload();
            setToastType("success");
            setToastMessage(response.message);
            setShowToast(true);
          }
        }
      } else if (!response.status && response.statusCode === 419) {
        setShowAlert(true);
        setIsLoginAgain(true);
      } else {
        userDetails.push({
          x_auth_token: response["x-auth-token"],
          x_userid: response["x-userid"],
          reset_flg: response.reset_flg,
        });
        localStorage.setItem("loginDetails", JSON.stringify(userDetails));

        setToastType("error");
        setToastMessage(response.message);
        setShowToast(true);
        if (response.reset_flg === 1) {
          setToastType("error");
          setToastMessage(response.message);
          setTimeout(() => {
            navigate(`/updatePassword?username=${username}`);
          }, 4000);
        }
      }

      const passwordExpiryDate = response.password_expiry_date;
      const expiryDate = new Date(passwordExpiryDate);
      const currentDate = new Date();
      const timeDifference = expiryDate.getTime() - currentDate.getTime();
      const daysRemaining = Math.ceil(timeDifference / (1000 * 3600 * 24));
      setDaysRemaining(daysRemaining);
    } catch (error) {
      // Handle errors here
      console.error("Login failed:", error);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const isLoginProceed = () => {
    handleLoginBtn(true);
    setShowAlert(false);
    setIsLoginAgain(false);
  };

  return (
    <div className="signinBg">
      <CustomToast
        show={showToast}
        onClose={() => setShowToast(false)}
        delay={5000}
        message={toastMessage}
        toastType={toastType}
      />
      <div className="loginhexagon" style={{ float: "left" }}>
        <>
          <div className="main-wrapper2">
            <div className="badge environmental">
              <div className="ribbon">Environment</div>
            </div>

            <div className="badge components">
              <div className="ribbon">components</div>
            </div>
          </div>

          <div className="main-wrapper">
            <div className="badge clean_utilities">
              <div className="ribbon">Clean Utilities</div>
            </div>

            <div className="badge heading">
              <div className="login-text">
                Monitoring & <br /> Management
                <br />
                through
                <br /> MPATS&reg;
                <br />
              </div>
            </div>

            <div className="badge containerClosure">
              <div className="ribbon">container / Closure</div>
            </div>
          </div>

          <div className="main-wrapper2">
            <div className="badge personnel">
              <div className="ribbon">Personnel</div>
            </div>

            <div className="badge surfaces">
              <div className="ribbon">Surfaces</div>
            </div>
          </div>
        </>
      </div>

      <div className="loginInputArea" style={{ float: "right" }}>
        <div className="loginToText">
          <p>Login to</p>
        </div>
        <div className="signinBrandArea">
          <p className="brandHead">MPATS</p>
          <p className="brandDisc">
            Microbial Media Plates Tracking & Management System
          </p>
        </div>
        <div className="formGroup input-login">
          <label htmlFor="username" className="login-label">
            User ID
          </label><span style={{ color: 'yellow' }}>*</span>
          <input
            type="text"
            placeholder="User ID"
            id="username"
            className="form-control custom-input"
            value={username}
            onChange={handleUsernameChange}
            onKeyPress={handleKeyPress}
            style={{ width: "20rem", height: "3rem" }}
          />
        </div>
        <div className="formGroup input-password">
          <label htmlFor="password" className="login-label">
            Password
          </label><span style={{ color: 'yellow' }}>*</span>
          {/* <div className="d-flex form-control custom-input" style={{ width: "20rem", paddingLeft: "8px"}}> */}
          <input
            type='password'
            placeholder="Password"
            id="password"
            className="form-control custom-input"
            value={password}
            onChange={handlePasswordChange}
            onKeyPress={handleKeyPress}
            style={{ width: "20rem", height: "3rem"}}
          />
          {/* {showPassword ? (
            <IoIosEyeOff onClick={togglePasswordVisibility} style={{"fontSize":"25px", "marginTop":"6px", "marginLeft":"4px"}} />
          ) : (
            <IoIosEye onClick={togglePasswordVisibility} style={{"fontSize":"25px", "marginTop":"6px", "marginLeft":"4px"}} />
          )} */}
          {/* </div> */}
        </div>
        {canLogin ? (
          <button
            onClick={() => handleLoginBtn()}
            type="button"
            className="signinBtn2"
            disabled={isButtonDisabled}
          >
            Login
          </button>
        ) : (
          <button type="button" className="signinBtn1">
            Login
          </button>
        )}
      </div>

      <ResetPassword
        show={showAlert}
        onHide={() => handleAlertClose()}
        title="Alert"
        message={
          isLoginAgain
            ? "User is already logged in from another device. Do you want to continue ?"
            : `Dear User, Your MPATS password is set to expire in ${daysRemaining} days. To update your password please click on "Reset"`
        }
        onConfirm={isLoginAgain ? isLoginProceed : handleAlertShown}
        buttonName={isLoginAgain ? "Proceed" : "Reset"}
      />
    </div>
  );
};

export default Signin;
