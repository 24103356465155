import moment from 'moment';
import React, { useState, useEffect } from 'react';

interface CountdownTimerProps {
  endTime: string;
  status?: string;
}

const AutoclaveTimer: React.FC<CountdownTimerProps> = (props: CountdownTimerProps) => {
  const { endTime, status } = props;
  const [timeLeft, setTimeLeft] = useState<moment.Duration>(moment.duration(0));

  useEffect(() => {
    const updateTimer = () => {
      const now = moment(); // Current time
      const end = moment(endTime, 'YYYY-MM-DD HH:mm:ss'); // Parse end time
      const diff = moment.duration(end.diff(now)); // Calculate duration difference

      if (diff.asSeconds() > 0) {
        setTimeLeft(diff); // Timer is still running
      } else {
        setTimeLeft(moment.duration(0)); // Timer has expired
      }
    };

    updateTimer(); // Initial update
    const interval = setInterval(updateTimer, 1000); // Update every second

    return () => clearInterval(interval); // Cleanup on component unmount
  }, [endTime]);

  const formatTime = (duration: moment.Duration) => {
    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.minutes());
    const seconds = Math.floor(duration.seconds());

    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  return (
    <div>
      {timeLeft.asSeconds() === 0 ? (
        <>
          {status === 'In Progress' && <div className="overdue">Over Due</div>}
          {status === 'Pending' && <div className="overdue">Not Started</div>}
          {status === 'Completed' && <div className="overdue">00:00:00</div>}
        </>
      ) : (
        <div className="counter-timer">{formatTime(timeLeft)}</div>
      )}
    </div>
  );
};

export default AutoclaveTimer;
