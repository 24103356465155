import React, { useEffect, useState } from "react";
import CustomTable from "../../../../widgets/table";
import { floraServices } from "../../../../service/fileServices/environmentFlora";
import { PendingColumns } from "../partials/columns";
import { useTranslation } from "react-i18next";
import Pagination from "../../../../widgets/pagination";
import { userService } from "../../../../service/plateServices";
import { DEFAULT_PAGE_SIZE } from "../../../../constants/constants";

interface PendingTableLabtestProps {
  setDebouncedScanCode: (id: string) => void;
  setTab?: string;
}
const PendingTableLabtest: React.FC<PendingTableLabtestProps> = ({
  setDebouncedScanCode,
  setTab,
}) => {
  const { t } = useTranslation();
  const [pendingDataTable, setPendingDataTable] = useState<Array<any>>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [batchCode, setBatchCode] = useState("");
  const [keyWord, setkeyWord] = useState("");
  const [useEffectRequired, setUseEffectRequired] = useState(false);
  const [plateBatches, setPlateBatches] = useState([]);
  const [plateSerielNum, setPlateSerielNums] = useState([]);
  const [labStatus, setLabStatus] = useState("");
  useEffect(() => {
    var storedData = localStorage.getItem("userDetails");

    if (storedData) {
      var storedObject = JSON.parse(storedData);
    }
    getPendingPlates();
    listPlateBatch();
    listSerialNumber();
    setUseEffectRequired(false);
  }, [useEffectRequired]);

  //get pending plate list for labtest
  const getPendingPlates = async () => {
    try {
      const response = await floraServices.PendingLabtestPlates(
        page,
        pageSize,
        batchCode,
        keyWord,
        labStatus
      );
      if (response?.status && response?.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            setTotalPages(response?.totalPages);
            setTotalElements(response?.totalElements);
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                const plateType = parseInt(element.plate_type, 10);
                if (plateType == 1) {
                  var plate_type = "Q A Plate";
                } else if (plateType == 2) {
                  var plate_type = "Exposure Plate";
                } else if (plateType == 3) {
                  var plate_type = "Personnel Monitoring Plate";
                } else if (plateType == 4) {
                  var plate_type = "Sampling Plate";
                } else if (plateType == 5) {
                  var plate_type = "Material Test Plate";
                } else if (plateType == 6) {
                  var plate_type = "Surface Monitoring Plate";
                } else if (plateType == 7) {
                  var plate_type = "Surface Monitoring - SWAB Collection";
                } else {
                  var plate_type = "-";
                }

                return {
                  sl_no: index + 1,
                  id: element.id,
                  serial_number: element.serial_number,
                  batch_number: element.batch_number,
                  activity_type: plate_type,
                  lab_status: element.lab_status,
                };
              }
            );
            setPendingDataTable(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        setPendingDataTable([]);
      }
    } catch (error) {
      console.error("user response", error);
    }
  };

  //batch list for filter dropdown
  const listPlateBatch = async () => {
    try {
      const response = await userService.listPlateBatch();
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                return {
                  value: element.batch_number,
                  label: element.batch_number,
                };
              }
            );
            setPlateBatches(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.error("Plate response", error);
    }
  };

  //handle pagination icon click
  const onNavigateFloraClick = (data: any) => {
    setDebouncedScanCode(data);
  };

  //serial number listing for table drop down
  const listSerialNumber = async () => {
    try {
      const response = await userService.listSerialNumber();
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                return {
                  value: element.serial_number,
                  label: element.serial_number,
                };
              }
            );
            setPlateSerielNums(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.error("Plate response", error);
    }
  };

  //handle table filters
  const handleFilter = (key: string, value: string) => {
    console.log(key, value, "values--");
    if (key === "search") {
      setkeyWord(value);
    } else if (key === "batch_code") {
      setBatchCode(value);
    } else if (key === "lab_status") {
      setLabStatus(value);
    }
    setUseEffectRequired(true);
  };

  //handle pagination filter
  const handlePageChange = (newPage: any, pageSize?: number) => {
    setPage(newPage);
    if (pageSize) {
      setPageSize(pageSize);
    }
    setUseEffectRequired(true);
  };

  return (
    <div>
      <div className="me-3" style={{ marginTop: "10px" }}>
        <div className=" mx-3 pe-2" style={{ height: "45vh" }}>
          <CustomTable
            tableHead={t("EnvironmentalFlora.pending")}
            data={pendingDataTable}
            columns={PendingColumns}
            totalElements={totalElements}
            isActionVisible={false}
            isViewVisible={false}
            isSeachable={true}
            showBatchFilter={true}
            showSerielNoFilter={false}
            onUseEffectRequired={() => setUseEffectRequired(true)}
            isNavigateFlora={true}
            onNavigateFloraClick={onNavigateFloraClick}
            plateBatch={plateBatches}
            plateSerialNumber={plateSerielNum}
            onFilterClick={handleFilter}
            showPlateTypeFilter={false}
          />
        </div>
        <div>
          <Pagination
            page={page}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default PendingTableLabtest;
