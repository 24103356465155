import React, { useState, useEffect } from "react";
import CustomTable from "../../../widgets/table/index";
import { BiSearch } from "react-icons/bi";
import HeaderSection from "../../common/headerSection";
import PageHeader from "../../common/pageHeader";
import { userService } from "../../../service/userService";
import { tab } from "@testing-library/user-event/dist/tab";
import Pagination from "../../../widgets/pagination";
import { columns } from "./partials/columns";
import { Modal } from "react-bootstrap";
import { TfiClose } from "react-icons/tfi";
import AlertModal from "../../../widgets/alertModal";
import CustomToast from "../../../widgets/toaster";
import { authenticationService } from "../../../service/authentication";
import { useTranslation } from "react-i18next";
import { helperFunctions as _h } from "../../../utils/helper";
import { DEFAULT_PAGE_SIZE } from "../../../constants/constants";

const UserManagement = () => {
  const { t } = useTranslation();
  const [userData, setUerData] = useState<Array<any>>([]);
  const [useEffectRequired, setUseEffectRequired] = useState(false);
  // pagination
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);

  const [roleId, setRole] = useState(0);
  const [departmentId, setDepartment] = useState(0);
  const [keyWord, setkeyWord] = useState("");
  const [showAddModal, setShowAddModal] = useState(false);
  const [addUserData, setAddUserData] = useState({
    userId: "",
    firstName: "",
    lastName: "",
    role: "",
    department: "",
    contact: "",
    email: "",
    id: "",
    qualification: "",
    reason: "",
    parent_id: "",
  });
  const [departmentList, setDepartmentList] = useState<Array<any>>([]);
  const [userRoleList, setUserRoleList] = useState<Array<any>>([]);
  const [userSupervisors, setUserSupervisors] = useState<Array<any>>([]);
  interface Errors {
    userId?: string;
    firstName?: string;
    lastName?: string;
    role?: string;
    department?: string;
    contact?: string;
    qualification?: string;
    reason?: string;
    roleReason?: string;
    email?: string;
    parent_id?: string;
  }
  const [errors, setErrors] = useState<Errors>({});
  const [showAddUserAlert, setShowAddUserAlert] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const [modalName, setModalName] = useState("");
  const [resetPassId, setResetPassId] = useState("");
  const [showResetPassAlert, setShowResetPassAlert] = useState(false);
  const [showUserStatusChangeAlert, setShowUserStatusChangeAlert] =
    useState(false);
  const [showRoleModal, setShowRoleModal] = useState(false);
  const [changeRoleData, setChangeRoleData] = useState({
    role: "",
    department: "",
    reason: "",
  });
  const [showChangeUserRole, setshowChangeUserRole] = useState(false);
  const [userId, setUserId] = useState("");
  const [idAndStatus, setIdAndStatus] = useState({
    id: "",
    status: "",
  });
  const [tempDeletId, setTempDeleteId] = useState("");
  const [showTempDeleteAreaAlert, setShowTempDeleteAreaAlert] = useState(false);
  const [sort, setSort] = useState("id");
  const [order, setOrder] = useState("desc");
  const [permission, setPermission] = useState<any>([]);
  const [isUserIdDisabled, setIsUserIdDisabled] = useState(false);
  const [localStoredId, setLocalStoredId] = useState("");

  useEffect(() => {
    var storedData = localStorage.getItem("userDetails");

    if (storedData) {
      var storedObject = JSON.parse(storedData);
      // setUserRole(storedObject[0].role_master_id)
      getPermissionByRole(storedObject[0].role_master_id);
      setLocalStoredId(storedObject[0].id);
    }

    getUserList();
    setUseEffectRequired(false);
    getDepartmentList();
    getUserRoleList();
    getSupervisorUsersList();
  }, [useEffectRequired]);

  useEffect(() => {
    if (_h.isNotEmpty(addUserData?.id)) {
      getSupervisorUsersList();
    }
  }, [addUserData]);

  //get users list for table listing
  const getUserList = async () => {
    try {
      const response = await userService.getUserList(
        page,
        pageSize,
        sort,
        order,
        keyWord,
        roleId,
        departmentId
      );
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            setTotalPages(response?.totalPages);
            setTotalElements(response?.totalElements);
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                return {
                  slno: index + 1,
                  id: element.id,
                  first_name: element.first_name,
                  last_name: element.last_name,
                  user_id: element.user_id,
                  department_name: element.department_name,
                  role: element.role_master_name,
                  roleId: element.role_master_id,
                  status: element.status,
                  is_deleted: element.is_deleted,
                  storage_id: localStoredId,
                };
              }
            );
            setUerData(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        setUerData([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //get department list from api
  const getDepartmentList = async () => {
    try {
      const response = await userService.getUserDepartmentList();
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                return {
                  slno: index + 1,
                  department_name: element.department_name,
                  id: element.id,
                  status: element.status,
                };
              }
            );
            setDepartmentList(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.error("user response", error);
    }
  };

  //get user role dropdown from api
  const getUserRoleList = async () => {
    try {
      const response = await userService.getUserRoleList();
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                return {
                  slno: index + 1,
                  title: element.title,
                  id: element.id,
                  status: element.status,
                };
              }
            );
            setUserRoleList(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  //get user supervisor dropdown from api
  const getSupervisorUsersList = async () => {
    try {
      const response = await userService.getSupervisorUsersList(addUserData);
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                return {
                  slno: index + 1,
                  title: element.first_name + " " + element.last_name,
                  id: element.id,
                };
              }
            );
            setUserSupervisors(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  //handle pagination changes
  const handlePageChange = (newPage: any, pageSize?: number) => {
    setPage(newPage);
    if (pageSize) {
      setPageSize(pageSize);
    }
    setUseEffectRequired(true);
  };

  //handle filter section in user management table
  const handleFilter = (key: string, value: any) => {
    if (key === "Role") {
      setRole(value);
    } else if (key === "Department") {
      setDepartment(value);
    } else if (key === "search") {
      setkeyWord(value);
    }
    setUseEffectRequired(true);
  };

  //api call to make user active or inactive
  const handleStatusChange = async () => {
    let updatedStatus = "";
    if (idAndStatus?.status === "active") {
      updatedStatus = "inactive";
    } else {
      updatedStatus = "active";
    }

    try {
      const response = await userService.changeUserStatus(
        idAndStatus.id,
        updatedStatus,
        "User Management"
      );

      if (response.status && response.statusCode === 200) {
        setUseEffectRequired(true);
        setShowUserStatusChangeAlert(false);
        updatedStatus = "";
        setToastMessage(response.message);
        setToastType("success");
      } else {
        setShowUserStatusChangeAlert(false);
        updatedStatus = "";
        setToastMessage(response.message);
        setToastType("error");
      }
      setShowToast(true);
    } catch (error) {
      console.error(error);
    }
  };

  //show status alert modal for status change
  const handleStatusAlert = async (id: any, status: string) => {
    setIdAndStatus({ id, status });
    setShowUserStatusChangeAlert(true);
  };

  //shows modal when add user button clicks
  const handleAddClick = () => {
    setShowAddModal(true);
    setModalName("Add User");
    setIsUserIdDisabled(false);
  };

  //onchange in add user
  const handleAddUserChange = (e: any) => {
    const { name, value } = e.target;

    let regex;
    if (name === "userId") {
      regex = /^[a-zA-Z][a-zA-Z0-9]*$/;
    } else if (name === "firstName" || name === "lastName") {
      regex = /^[a-zA-Z ]*$/;
    } else if (name === "contact") {
      regex = /^[0-9]{0,10}$/;
    }

    if (value === "") {
      // console.log("haa122")
    } else if (regex && !regex.test(value)) {
      return;
    }

    setAddUserData((prevFormData: any) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  //handle errors for add user modal
  const handleUserAddAlert = () => {
    const errors: Errors = {};
    let emailRegex = /^\S+@\S+\.\S+$/;
    if (!addUserData.userId) {
      errors.userId = "Please enter User ID";
    }
    if (!addUserData.firstName) {
      errors.firstName = "Please enter First Name";
    }
    if (!addUserData.lastName) {
      errors.lastName = "Please enter Last Name";
    }
    if (!addUserData.role) {
      errors.role = "Please select Role";
    }
    if (!addUserData.department) {
      errors.department = "Please select Department";
    }
    if (!addUserData.qualification) {
      errors.qualification = "Please enter Qualification";
    }
    if (addUserData.id && !addUserData.reason) {
      errors.reason = "Please enter Reason";
    }
    if (!addUserData.email) {
      errors.email = "Please enter Email";
    }
    if (addUserData.email && !emailRegex.test(addUserData.email)) {
      errors.email = "Invalid Email";
    }
    // if (addUserData.contact.length !== 10) {
    //   errors.contact = "Number should be 10 digits";
    // }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    setShowAddUserAlert(true);
    setErrors({});
  };

  //api call for handle user add submit
  const handleAddUserSubmit = async () => {
    const dataToSend = {
      user_id: addUserData.userId,
      first_name: addUserData.firstName,
      last_name: addUserData.lastName,
      role_master_id: addUserData.role,
      department_id: addUserData.department,
      mobile_number: addUserData.contact,
      email_id: addUserData.email,
      qlf_required_flag: addUserData.qualification,
      pin: "",
      id: addUserData.id,
      interface_name: "User Management",
      reason: addUserData.reason,
      parent_id: addUserData.parent_id,
    };
    try {
      const response = await userService.registerUser(dataToSend);
      if (response.status && response.statusCode === 200) {
        setToastType("success");
        setToastMessage(response.message);
        getUserList();
        setShowAddModal(false);
        addUserClose();
      } else {
        setToastType("error");
        setToastMessage(response.message);
      }
      setShowAddUserAlert(false);   
      setShowToast(true);  
    } catch (error) {
      console.error(error);
    }
  };

  //on add user submit clears the modal
  const addUserClose = () => {
    setShowAddModal(false);
    setErrors({});
    setAddUserData({
      userId: "",
      firstName: "",
      lastName: "",
      role: "",
      department: "",
      contact: "",
      email: "",
      id: "",
      qualification: "",
      reason: "",
      parent_id: "",
    });
  };

  //select the users id for edit user
  const handleEditClick = (id: string) => {
    const filteredUserDetails = userData.filter((item) => item.slno === id);
    const selectedIds = filteredUserDetails
      .map((item) => item.user_id)
      .join(",");
    getUserByUserId(selectedIds);
    setShowAddModal(true);
    setModalName("Edit User");
    setIsUserIdDisabled(true);
  };

  //api call to get user details by id
  const getUserByUserId = async (userId: string) => {
    try {
      const response = await userService.getUserByUserId(userId);
      if (response.status && response.statusCode === 200) {
        setAddUserData({
          userId: response.data.user_id,
          firstName: response.data.first_name,
          lastName: response.data.last_name,
          role: response.data.role_master_id,
          department: response.data.department_id,
          contact: response.data.mobile_number,
          email: response.data.email_id,
          id: response.data.id,
          qualification: response.data.qlf_required_flag,
          reason: response.data.reason,
          parent_id: response.data.parent_id,
        });
      }
    } catch (error) {
      console.error("user response", error);
    }
  };

  //handle reset password click
  const handleResetPassClick = (id: any) => {
    setResetPassId(id);
    setShowResetPassAlert(true);
  };

  //api call for reset user password
  const handleResetPassword = async () => {
    try {
      const response = await authenticationService.ressetPassword(
        resetPassId,
        "User Management"
      );
      if (response.status && response.statusCode === 200) {
        setToastType("success");
        setToastMessage(response.message);
        getUserList();
      } else {
        setToastType("error");
        setToastMessage(response.message);
      }
      setShowToast(true);
      setShowResetPassAlert(false);
    } catch (error) {
      console.error(error);
    }
  };

  //select onclick department change
  const onRoleDEpartmentClick = (selectedRows: string[]) => {
    const filteredPlateDetails = userData.filter((item) =>
      selectedRows.includes(item.slno)
    );
    const selectedIds = filteredPlateDetails.map((item) => item.id).join(",");
    setUserId(selectedIds);
    setShowRoleModal(true);
  };

  //handle on change in role and department
  const handleRoleChange = (e: any) => {
    const { name, value } = e.target;
    setChangeRoleData((prevFormData: any) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  //handle errors for role and department modal
  const handleSubmitBtn = () => {
    const errors: Errors = {};
    if (!changeRoleData.role) {
      errors.role = "Please select Role";
    }
    if (!changeRoleData.department) {
      errors.department = "Please select Department";
    }
    if (!changeRoleData.reason) {
      errors.roleReason = "Please enter Reason";
    }
    if (Object.keys(errors).length > 1) {
      setErrors(errors);
      return;
    }
    setshowChangeUserRole(true);
    setErrors({});
  };

  //api call for change role and department
  const handleSubmitRoleChange = async () => {
    const dataToSend = {
      user_ids: userId,
      role_id: changeRoleData.role,
      department_id: changeRoleData.department,
      interface_name: "User Management",
      reason: changeRoleData.reason,
    };

    try {
      const response = await userService.changeUserRoleDepartment(dataToSend);
      if (response.status && response.statusCode === 200) {
        setToastType("success");
        setToastMessage(response.message);
        getUserList();
        setshowChangeUserRole(false);
        setShowRoleModal(false);
        setChangeRoleData({
          role: "",
          department: "",
          reason: "",
        });
        setUserId("");
      } else {
        setToastType("error");
        setToastMessage(response.message);
      }
      // setshowChangeUserRole(false);
      // setShowRoleModal(false)
      // setChangeRoleData({
      //   role: "",
      //   department : "",
      //   reason: ""
      // })
      // setUserId("")
      setShowToast(true);
    } catch (error) {
      console.error(error);
    }
  };

  //handle delete user
  const handleTempDelete = async (id: string) => {
    setTempDeleteId(id);
    setShowTempDeleteAreaAlert(true);
  };

  //api call for delete user temporarly
  const deleteUserTempSubmit = async () => {
    try {
      const response = await userService.deleteUserTemp(
        tempDeletId,
        "User Management"
      );
      if (response.status && response.statusCode === 200) {
        setToastType("success");
        setToastMessage(response.message);
        getUserList();
      } else {
        setToastType("error");
        setToastMessage(response.message);
      }
      setShowTempDeleteAreaAlert(false);
      setShowToast(true);
      console.log(response, "response");
    } catch (error) {
      console.error(error);
    }
  };

  // function to sort table
  const handleSort = (key: string, value: boolean) => {
    setSort(key);
    if (value) {
      setOrder("asc");
    } else {
      setOrder("desc");
    }
    setUseEffectRequired(true);
  };

  //get permission by role
  const getPermissionByRole = async (role: number) => {
    try {
      const response = await userService.getPermissionByRole(role);
      if (response.status && response.statusCode === 200) {
        console.log(response.data.permissions, "permissions");
        setPermission(response.data.permissions);
        // try {
        //     if ( Array.isArray(response.data) && response.data?.length > 0) {
        //       let rowData: any = response.data.map((element: any, index: number) => {

        //         return {
        //           // slno: index + 1,
        //           label: element.title,
        //           value : element.id,
        //           // status : element.status,
        //         };
        //       });
        //       // setUserRoleList(rowData);
        //     }
        //   } catch (error) {
        //     console.log(error);
        //   }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <HeaderSection />
      <PageHeader pageHeader={t("userManagement.userManagement")} />
      <CustomToast
        show={showToast}
        onClose={() => setShowToast(false)}
        delay={5000}
        message={toastMessage}
        toastType={toastType}
      />
      <div className="me-3" style={{ marginTop: "20px" }}>
        <div className=" mx-3 pe-2" style={{ height: "45vh" }}>
          <CustomTable
            tableHead={t("userManagement.users")}
            data={userData}
            columns={columns}
            isEditable={permission?.includes("edit_user")}
            isTempDeletable={permission?.includes("edit_user")}
            totalElements={totalElements}
            isActionVisible={permission?.includes("edit_user")}
            isViewVisible={false}
            isSeachable={true}
            isToggleStatus={permission?.includes("edit_user")}
            isResetPassword={permission?.includes("edit_user")}
            addButton={permission?.includes("add_user")}
            addButtonText={t("userManagement.add_user")}
            onUseEffectRequired={() => setUseEffectRequired(true)}
            onFilterClick={handleFilter}
            onStatusChange={handleStatusAlert}
            onAddClick={handleAddClick}
            onSortClick={handleSort}
            onEditClick={handleEditClick}
            onResetPassClick={handleResetPassClick}
            changeRoleButton={permission?.includes("edit_user")}
            changeRoleButtonText={t("userManagement.change_role")}
            onRoleDEpartmentClick={onRoleDEpartmentClick}
            onTempDeleteClick={handleTempDelete}
          />
        </div>
        <div>
          <Pagination
            page={page}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
          />
        </div>
      </div>

      {/*modal for add/edit user */}
      <Modal className="top-right-modal" centered={false} show={showAddModal}>
        <div>
          <div className="modalHead">
            <p className="modalHeadText ms-3 pt-3 p-2">{modalName}</p>
            <div onClick={() => addUserClose()} className="p-2 mx-3 my-2 ">
              <TfiClose fill="#FFFFFF" />
            </div>
          </div>
          <div>
            <div className="selectWrap">
              <div className="form-group my-1">
                <label htmlFor="">
                  {t("userManagement.user_id")}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <br />
                <input
                  id="userId"
                  value={addUserData.userId}
                  name="userId"
                  type="text"
                  placeholder="User ID"
                  className="placeholder-style"
                  onChange={handleAddUserChange}
                  pattern="^[a-zA-Z][a-zA-Z0-9]*$"
                  maxLength={100}
                  disabled={isUserIdDisabled}
                />
                {errors.userId && <p className="errorText">{errors.userId}</p>}
              </div>

              <div className="form-group my-1">
                <label>
                  {t("userManagement.first_name")}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <br />
                <input
                  id="firstName"
                  value={addUserData.firstName}
                  name="firstName"
                  type="text"
                  placeholder="First Name"
                  className="placeholder-style"
                  onChange={handleAddUserChange}
                  pattern="^[a-zA-Z]*$"
                  maxLength={100}
                />
                {errors.firstName && (
                  <p className="errorText">{errors.firstName}</p>
                )}
              </div>
            </div>
            <div className="selectWrap">
              <div className="form-group my-1">
                <label htmlFor="">
                  {t("userManagement.last_name")}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <br />
                <input
                  id="lastName"
                  value={addUserData.lastName}
                  name="lastName"
                  type="text"
                  placeholder="Last Name"
                  className="placeholder-style"
                  onChange={handleAddUserChange}
                  maxLength={100}
                />
                {errors.lastName && (
                  <p className="errorText">{errors.lastName}</p>
                )}
              </div>

              <div className="form-group my-1">
                <label htmlFor="">{t("userManagement.contact")}</label>
                <br />
                <input
                  id="contact"
                  value={addUserData.contact}
                  name="contact"
                  type="text"
                  placeholder="Contact"
                  className="placeholder-style"
                  onChange={handleAddUserChange}
                />
                {errors.contact && (
                  <p className="errorText">{errors.contact}</p>
                )}
              </div>
            </div>
            <div className="selectWrap">
              <div className="form-group my-1">
                <label htmlFor="">
                  {t("userManagement.role")}{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <br />
                <select
                  id="role"
                  value={addUserData.role}
                  name="role"
                  onChange={handleAddUserChange}
                >
                  <option value="">Select</option>
                  {userRoleList.map((obj, index) => (
                    <option key={index} value={obj?.id}>
                      {obj?.title}
                    </option>
                  ))}
                </select>
                {errors.role && <p className="errorText">{errors.role}</p>}
              </div>

              <div className="form-group my-1">
                <label htmlFor="">
                  {t("userManagement.department")}{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <br />
                <select
                  id="department"
                  value={addUserData.department}
                  name="department"
                  onChange={handleAddUserChange}
                >
                  <option value="">Select</option>
                  {departmentList.map((obj, index) => (
                    <option key={index} value={obj?.id}>
                      {obj?.department_name}
                    </option>
                  ))}
                </select>
                {errors.department && (
                  <p className="errorText">{errors.department}</p>
                )}
              </div>
            </div>
            <div className="selectWrap">
              <div className="form-group my-1 ">
                <label htmlFor="">
                  {t("userManagement.email")}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <br />
                <input
                  id="email"
                  value={addUserData.email}
                  name="email"
                  type="email"
                  placeholder="Email"
                  className="placeholder-style"
                  onChange={handleAddUserChange}
                />
                {errors.email && <p className="errorText">{errors.email}</p>}
              </div>
              <div className="form-group my-1">
                <label htmlFor="">
                  {t("userManagement.qualification")}{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <br />
                <select
                  id="qualification"
                  value={addUserData.qualification}
                  name="qualification"
                  onChange={handleAddUserChange}
                >
                  <option value="">Select</option>
                  <option value="1">Required</option>
                  <option value="0">Not Required</option>
                  {/* {userRoleList.map((obj, index) => (
                        <option key={index} value={obj?.id}>
                          {obj?.title}
                        </option>
                      ))} */}
                </select>
                {errors.qualification && (
                  <p className="errorText">{errors.qualification}</p>
                )}
              </div>
            </div>
            <div className="selectWrap">
              <div className="form-group my-1">
                <label htmlFor="">{t("userManagement.parent_id")} </label>
                <br />
                <select
                  id="parent_id"
                  value={addUserData.parent_id}
                  name="parent_id"
                  onChange={handleAddUserChange}
                >
                  <option value="">Select</option>
                  {userSupervisors.map((obj, index) => (
                    <option key={index} value={obj?.id}>
                      {obj?.title}
                    </option>
                  ))}
                </select>
                {errors.parent_id && (
                  <p className="errorText">{errors.parent_id}</p>
                )}
              </div>
              {addUserData.id && (
                <div className="form-group my-1 ">
                  <label htmlFor="" className="ReasonLabel">
                    {t("userManagement.reason")}{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <br />
                  <input
                    id="reason"
                    value={addUserData.reason}
                    name="reason"
                    type="text"
                    placeholder={t("userManagement.reason")}
                    className="reasonClass"
                    onChange={handleAddUserChange}
                  />
                  {errors.reason && (
                    <p className="errorText">{errors.reason}</p>
                  )}
                </div>
              )}
            </div>
            {/* <div className="selectWrap">
              {addUserData.id && (
                <div className="form-group my-1 ">
                  <label htmlFor="" className="ReasonLabel">
                    {t("userManagement.reason")}{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <br />
                  <input
                    id="reason"
                    value={addUserData.reason}
                    name="reason"
                    type="text"
                    placeholder={t("userManagement.reason")}
                    className="reasonClass"
                    onChange={handleAddUserChange}
                  />
                  {errors.reason && (
                    <p className="errorText">{errors.reason}</p>
                  )}
                </div>
              )}
              <div
                className="form-group my-1 "
                style={{ visibility: "hidden" }}
              >
                <label htmlFor="">{t("userManagement.reason")}</label>
                <br />
                <input
                  id="reason1"
                  // value={addUserData.reason}
                  name="reason1"
                  type="text"
                  placeholder="Reason"
                  className="placeholder-style"
                  // onChange={handleAddUserChange}
                />
              </div>
            </div> */}
          </div>
          <div className="bottomArea">
            <button onClick={() => addUserClose()} className="modalCancelBtn">
              {t("buttons.cancel")}
            </button>
            <button
              onClick={() => handleUserAddAlert()}
              className="squareOrangeBtn3"
            >
              {t("buttons.submit")}
            </button>
            {/* <button className=' squareOrangeBtn3Disabled'>Submit</button> */}
          </div>
        </div>
      </Modal>

      {/* Alert modal for Add user */}
      <AlertModal
        show={showAddUserAlert}
        onHide={() => setShowAddUserAlert(false)}
        title="Alert"
        message={modalName}
        onConfirm={handleAddUserSubmit}
      />

      {/* Alert modal for reset password */}
      <AlertModal
        show={showResetPassAlert}
        onHide={() => setShowResetPassAlert(false)}
        title="Alert"
        message={`Reset password of UserID ${resetPassId} ?`}
        onConfirm={handleResetPassword}
      />

      {/* Alert modal for user status change */}
      <AlertModal
        show={showUserStatusChangeAlert}
        onHide={() => setShowUserStatusChangeAlert(false)}
        title="Alert"
        message={`change user status ?`}
        onConfirm={handleStatusChange}
      />

      <AlertModal
        show={showChangeUserRole}
        onHide={() => setshowChangeUserRole(false)}
        title="Alert"
        message={`change Role and Department?`}
        onConfirm={handleSubmitRoleChange}
      />

      {/* Alert modal for active/inactive user */}
      <AlertModal
        show={showTempDeleteAreaAlert}
        onHide={() => setShowTempDeleteAreaAlert(false)}
        title="Alert"
        message={"Make user active/inactive"}
        onConfirm={deleteUserTempSubmit}
      />

      {/* modal for change role and department */}
      <Modal
        show={showRoleModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="modalHead">
          <p className="modalHeadText ms-3 pt-3 p-2">
            {t("userManagement.select_role_dept")}
          </p>
          <div
            onClick={() => setShowRoleModal(false)}
            className="p-2 mx-3 my-2 "
          >
            <TfiClose fill="#FFFFFF" />
          </div>
        </div>
        <div className="row m-3 py-3" style={{ minHeight: "20vh" }}>
          <div className="form-group col-md-6 text-start">
            <label
              className="my-3 mx-1"
              htmlFor="role"
              style={{ color: "black" }}
            >
              {t("userManagement.role")} <span style={{ color: "red" }}>*</span>
            </label>
            <br />
            <select
              id="role"
              value={changeRoleData.role}
              name="role"
              onChange={handleRoleChange}
              className="form-control"
            >
              <option value="">Select</option>
              {userRoleList.map((obj, index) => (
                <option key={index} value={obj?.id}>
                  {obj?.title}
                </option>
              ))}
            </select>
            {errors.role && <p className="errorText">{errors.role}</p>}
          </div>

          <div className="form-group col-md-6 text-start">
            <label
              className="my-3 mx-1"
              htmlFor="department"
              style={{ color: "black" }}
            >
              {t("userManagement.department")}{" "}
              <span style={{ color: "red" }}>*</span>
            </label>
            <br />
            <select
              id="department"
              value={changeRoleData.department}
              name="department"
              className="form-control"
              onChange={handleRoleChange}
            >
              <option value="">Select</option>
              {departmentList.map((obj, index) => (
                <option key={index} value={obj?.id}>
                  {obj?.department_name}
                </option>
              ))}
            </select>
            {errors.department && (
              <p className="errorText">{errors.department}</p>
            )}
          </div>

          <div className="form-group col-md-6 text-start">
            <label htmlFor="" className="my-3 mx-1 ReasonLabel">
              {t("userManagement.reason")}{" "}
              <span style={{ color: "red" }}>*</span>
            </label>
            <br />
            <input
              id="reason"
              value={changeRoleData.reason}
              name="reason"
              type="text"
              placeholder={t("userManagement.reason")}
              className="form-control"
              onChange={handleRoleChange}
            />
            {errors.roleReason && (
              <p className="errorText">{errors.roleReason}</p>
            )}
          </div>
        </div>
        <div className="m-2 p-3 d-flex justify-content-between">
          <button
            onClick={() => setShowRoleModal(false)}
            className="modalCancelBtn mx-3"
          >
            {t("buttons.cancel")}
          </button>
          <button
            onClick={() => handleSubmitBtn()}
            className="squareOrangeBtn3"
          >
            {t("buttons.submit")}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default UserManagement;
