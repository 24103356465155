import React, { useEffect, useState } from 'react'
import { IncidentOrDeviationApi } from '../../../service/deviation';
import { areaService } from '../../../service/areaServices';
import HeaderSection from '../../common/headerSection';
import PageHeader from '../../common/pageHeader';
import CustomTable from '../../../widgets/table';
import Pagination from '../../../widgets/pagination';
import { useTranslation } from 'react-i18next';
import baseUrl from '../../../service';
import { visualInspection } from '../../../service/visualinspection';
import Select, { ActionMeta, SingleValue } from 'react-select';
import { userService } from '../../../service/plateServices';
import {userService as userRoleServive} from '../../../service/userService';
import { reportServices } from '../../../service/reportServices';
import { DEFAULT_PAGE_SIZE } from '../../../constants/constants';
import AlertModal from '../../../widgets/alertModal';
interface TableColumn {
    Header: string;
    accessor: string;
}

type Filters = {
  operator: string;
  batchCode: string;
  lotCode: string;
  fromDate: string;
  toDate: string;
};
type OptionType = {
  value: string;
  label: string;
};
export const columns: TableColumn[] = [
  {
      Header: 'Sl.No',
      accessor: 'slno',
    },
    {
      Header: 'tableCommon.batch_code',
      accessor: 'batch_number',
    },
    {
      Header: 'Lot Code ',
      accessor: 'lot_code',
    },
    {
      Header: 'Total Plates',
      accessor: 'total_plates',
    },
    {
      Header: 'Passed Plates',
      accessor: 'passed_plates',
    },
    {
      Header: 'Failed Plates',
      accessor: 'failed_plates',
    },
    {
      Header: 'Operator Name',
      accessor: 'first_name',
    },
    {
      Header: 'Operator ID',
      accessor: 'user_id',
    },
    {
      Header: 'Date',
      accessor: 'inspected_on',
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Inspection Reason',
      accessor: 'reason',
    },
    {
      Header: 'Remarks',
      accessor: 'remarks',
    },
  
];

interface ActiveAirSamplingReportProps {
  ishide?: boolean;
}


const VisualInspection: React.FC<ActiveAirSamplingReportProps> = ({ ishide }) => {  

    const { t } = useTranslation()
    const [plateDetails, setPlateDetails] = useState<Array<any>>([]);
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE)
    const [totalPages, setTotalPages] = useState(0)
    const [totalElements, setTotalElements] = useState(0)
    const [keyWord, setkeyWord] = useState("")
    const [useEffectRequired, setUseEffectRequired] = useState(false);
    const [batchCode, setBatchCode] = useState("");
    const [lotCode, setLotCode] = useState([]);
    const [showDownloadCSV, setShowDownloadCSV] = useState(true);
    const [isApplyClicked, setIsApplyClicked] = useState(true);
    const [addFilterData, setAddFilterData] = useState({
      batchCode: "",     
      lotCode : "",
      operator:"",
      fromDate: "",
      toDate: "",
    });
    const [plateBatches, setPlateBatches] = useState([]);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [userData , setUserData] = useState<Array<any>>([]); 
    const [authUserID, setAuthUserID] = useState("");
    const [sort, setSort] = useState("id");
    const [order, setOrder] = useState("desc");
    const [pdfDownload, setpdfDownload] = useState(false);
    const [toDateConstraints, setToDateConstraints] = useState({
      min: "",
      max: "",
    });
   const [isToDateEnabled, setIsToDateEnabled] = useState(false);

//api call to list exception plate detail in request tab 
const getExceptionPlates = async () => {
try {
  const response = await reportServices.visualInspectionReport(page, pageSize, addFilterData.fromDate, addFilterData.toDate, addFilterData.operator, addFilterData.batchCode, addFilterData.lotCode, sort , order);

  if(response.status && response.statusCode === 200){
    try {
        if ( Array.isArray(response.data) && response.data?.length > 0) {
            setTotalPages(response?.totalPages)
            setTotalElements(response?.totalElements)
            let rowData: any = response.data.map((element: any, index: number) => {

            const statusNumber = parseInt(element.location_type, 10); 
            return { 
              id : element.id,
              slno: index + 1,
              batch_number: element.batch_code,
              lot_code: element.lot_code,
              passed_plates : element.passed_plates,
              failed_plates :element.failed_plates_count,
              first_name : element.operator_name,
              user_id : element.operator_id,
              inspected_on : element.date,
              status : element.status,
              reason : element.reason,
              remarks : element.remarks,
              total_plates : element.total_plates
            };
            });
            setPlateDetails(rowData);
        }
        } catch (error) {
        console.log(error); 
        }
    } else {
    setPlateDetails([])
    setTotalPages(0)
    setTotalElements(0)
    }
} catch (error) {
    console.error('Plate response', error);
}
};

const handlePageChange = (newPage : any, pageSize?: number) => {
    setPage(newPage);
    if(pageSize) {
      setPageSize(pageSize)
    }
    setUseEffectRequired(true)
  };

    useEffect(() => {
      var storedData = localStorage.getItem("userDetails");

      if (storedData) {
        var storedObject = JSON.parse(storedData);
        setAuthUserID(storedObject[0].x_userid);
      }

        getExceptionPlates();
        listPlateBatch();
        getOperatorList();
        setUseEffectRequired(false)
    }, [useEffectRequired])

    const handleApplyClick =() =>{
        setShowDownloadCSV(false)
        setIsApplyClicked(true)
        getExceptionPlates()
        // listAuditTrialReport();
    }

    const listPlateBatch = async () => {
      try {
        const response = await userService.listPlateBatch();
        if(response.status && response.statusCode === 200){
          try {
              if ( Array.isArray(response.data) && response.data?.length > 0) {
                let rowData: any = response.data.map((element: any, index: number) => {

                  return { 
                      value: element.batch_number,
                      label: element.batch_number ,
                  };
                });
                setPlateBatches(rowData);
              }
            } catch (error) {
              console.log(error); 
            }
        }
      } catch (error) {
        console.error('Plate response', error);
      }
  }

  const lotCodeApi = async (batchNo: string) => {
    try {
      const response = await visualInspection.listFilterLots(batchNo);
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              let rowData: any = response.data.map((element: any, index: number) => {

                return { 
                    value: element.id,
                    label: element.lot_code ,
                };
              });
              setLotCode(rowData);
            }
          } catch (error) {
            console.log(error); 
          }
      }
    } catch (error) {
      console.error('Plate response', error);
    }
  }

    const handleFilterDataChange = (
      eventOrValue: React.ChangeEvent<HTMLSelectElement | HTMLInputElement> | SingleValue<OptionType>,
      actionOrUndefined?: ActionMeta<OptionType>
    ) => {
      let newFilters: Filters;
    
      if (actionOrUndefined) {
        // For react-select
        const { name } = actionOrUndefined as { name: string };
        if (name === "batchCode") {
          lotCodeApi((eventOrValue as SingleValue<OptionType>)?.value || '');
        }
        newFilters = { ...addFilterData, [name]: (eventOrValue as SingleValue<OptionType>)?.value || '' };
      } else {
        // For native select and input elements
        const { name, value } = (eventOrValue as React.ChangeEvent<HTMLSelectElement | HTMLInputElement>).target;
        newFilters = { ...addFilterData, [name]: value };
      }
    
      setAddFilterData(newFilters);
    
      // Enable button if any filter is changed
      const anyFilterChanged = Object.values(newFilters).some(val => val !== '');
      setIsButtonDisabled(!anyFilterChanged);
      
        setShowDownloadCSV(true)
        setIsApplyClicked(false)
        // const { name, value } = e.target;
        // setAddFilterData((prevFormData : any) => ({
        //     ...prevFormData, 
        //     [name]: value,
        // }));
    };

    const handleCancel = () => {
      const initialFormData = {
      batchCode: "",     
      lotCode : "",
      operator:"",
      fromDate: "",
      toDate: "",
      };
      setAddFilterData(initialFormData);
      setUseEffectRequired(true)
      // listAuditTrialReport();
      setToDateConstraints({
        min: "",
        max: "",
      })
      setIsToDateEnabled(false);
  
  }

  const downloadAuditCSV = async () => {
    setpdfDownload(false)
    const link = `${baseUrl}/summary/visual_inspection?sort=id&order=desc&batch_code=${addFilterData.batchCode}&lot_id=${addFilterData.lotCode}&operator_id=${addFilterData.operator}&from_date_time=${addFilterData.fromDate}&to_date_time=${addFilterData.toDate}&auth_id=${authUserID}`
    window.location.href = link;
    setShowDownloadCSV(true)
    setIsApplyClicked(true)
  };
    
  // function to get operator api list
const getOperatorList = async () => {
  try {
    // const userRole = parseInt(addFilterData.role, 10);  
    const response = await userRoleServive.getUserNameList("");
    if(response.status && response.statusCode === 200){
      try {
          if ( Array.isArray(response.data) && response.data?.length > 0) {
            // setTotalPages(response?.totalPages)
            // setTotalElements(response?.totalElements)
            let rowData: any = response.data.map((element: any, index: number) => {

              return { 
                  slno: index + 1, 
                  id: element.id,
                  name: `${element.first_name} ${element.last_name}`,
              };
            });
            setUserData(rowData);
          }
        } catch (error) {
          console.log(error);
        }
    } else {
      setUserData([]);
    }
  } catch (error) {
    console.error(error);
  }
};

const handleSort = (key: string, value: boolean) => {
  setSort(key)
  if (value) {
    setOrder("asc")
  } else {
    setOrder("desc")
  }
  setUseEffectRequired(true)
}

const formatDateTime = (date: string | Date): string => {
  if (!date) return "";
  const d = new Date(date);
  return (
    d.getFullYear() +
    "-" +
    String(d.getMonth() + 1).padStart(2, "0") +
    "-" +
    String(d.getDate()).padStart(2, "0") +
    "T" +
    String(d.getHours()).padStart(2, "0") +
    ":" +
    String(d.getMinutes()).padStart(2, "0")
  );
};


  return (
    <div>
       {!ishide && (
        <>
       <HeaderSection />
       <PageHeader pageHeader="Visual Inspection Report" />
       </>
      )}
       <div className="d-flex justify-content-around my-4">

        <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
          <label htmlFor="areaTypeSelect" className='filterLabelLeft'>Batch Code</label> 
            <div style={{ zIndex: '10' }}>
              <Select
                options={plateBatches}
                onChange={(selectedOption, actionMeta) => handleFilterDataChange(selectedOption as SingleValue<OptionType>, actionMeta as ActionMeta<OptionType>)}
                placeholder="Batch code"
                isSearchable={true}
                isClearable
                value={plateBatches.find((option: any) => option.value === addFilterData.batchCode) || null}
                name="batchCode"
              />
            </div>        
        </div>

      <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
      <label htmlFor="areaTypeSelect" className='filterLabelLeft'>Lot Code</label> 
        <div style={{ zIndex: '10' }}>
          <Select
            options={lotCode}
            onChange={(selectedOption, actionMeta) => handleFilterDataChange(selectedOption as SingleValue<OptionType>, actionMeta as ActionMeta<OptionType>)}
            placeholder="Lot Code"
            isSearchable={true}
            isClearable
            value={lotCode.find((option: any) => option.value === addFilterData.lotCode) || null}
            name="lotCode"
          />
        </div>        
      </div>

        <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
        <label htmlFor="" style={{color: "black", fontSize: "12px"}}>Operator</label>
        <select name="operator" className='areaTypeSelect' value={addFilterData.operator}  onChange={handleFilterDataChange}  >
            <option value="">Select</option>
            {userData.map((type, index) => (
                        <option key={index} value={type?.id}>
                          {type?.name}
                        </option>
            ))}
        </select>
        </div>

        <div className="d-flex" style={{marginRight: "4%"}} >

        <div className="select-container auditTrialReportDate " 
            style={{display: "flex", flexDirection: "column", marginRight:"15%"}}>
            <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t("common.from_date")}</label>
            <input
            id="selectedDate"
            className="form-control"
            name="fromDate"
            type="datetime-local"
            value={formatDateTime(addFilterData.fromDate)}  
            onChange={(e) => {
              handleFilterDataChange(e);
        
              const fromDate = new Date(e.target.value);
              if (!isNaN(fromDate.getTime())) {
                // Calculate the min and max for the toDate field
                const minDate = new Date(fromDate);
                const maxDate = new Date(fromDate);
                maxDate.setDate(maxDate.getDate() + 14);
        
                // Update state with min and max dates
                setToDateConstraints({
                  min: formatDateTime(minDate),
                  max: formatDateTime(maxDate),
                });
                setAddFilterData((prevData) => ({
                  ...prevData,
                  fromDate: formatDateTime(fromDate),
                  toDate: formatDateTime(minDate),
                }));
                setIsToDateEnabled(true);
              }
            }}
            onKeyDown={(e) => {
              const allowedKeys = [
                "Tab",
                "Enter",
                "Escape",
              ];
              if (!allowedKeys.includes(e.key)) {
                e.preventDefault();
              }
            }}
            onPaste={(e) => e.preventDefault()}
        />
        </div>

        <div className="select-container auditTrialReportDate" 
            style={{display: "flex", flexDirection: "column"}}>
            <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t("common.to_date")}</label>
            <input
            id="selectedDate"
            className="form-control"
            name="toDate"
            type="datetime-local"
            value={formatDateTime(addFilterData.toDate)} 
            min={toDateConstraints.min}
            max={toDateConstraints.max} 
            disabled={!isToDateEnabled}
            onChange={handleFilterDataChange}
            onKeyDown={(e) => {
              const allowedKeys = [
                "Tab",
                "Enter",
                "Escape",
              ];
              if (!allowedKeys.includes(e.key)) {
                e.preventDefault();
              }
            }}
            onPaste={(e) => e.preventDefault()}
            />
        </div>
        </div>
        </div>

        <div className="mt-3 mx-2 d-flex justify-content-end" >
            <button className="analyticalFilterClose" title="Click here to Reset Filter" onClick={handleCancel}>{t("buttons.cancel")}</button>
            <button 
            className={isApplyClicked ? "analyticalFilterApplyDisabledDownload" : "analyticalFilterApply"}
            disabled={isApplyClicked} 
            onClick={handleApplyClick} 
            title="Click here to Apply Filter" >
                {t("buttons.apply")}
            </button>
            <button 
            className={showDownloadCSV ? "analyticalFilterDisabledDownload" : "analyticalFilterDownload"}
            disabled={showDownloadCSV} 
            onClick={()=>setpdfDownload(true)}
            title="Click here to Download CSV" >
            {t("buttons.download_pdf")}
            </button>

        </div>



       <div className=" mx-3 pe-2" style={{"height":"45vh"}}>
          <CustomTable
          data={plateDetails}
          columns={columns}
          isEditable={false}
          totalElements={totalElements}
          isActionVisible={false}
          isViewVisible={false}
          isSeachable={false}
          tableHead={'Visual Inspection'}
          showBatchFilter={false}
          showLotCodeFilter={false}
          onSortClick={handleSort}
          // onUseEffectRequired={setUseEffectRequired(false)}
        />
      </div>
      <div>
        <Pagination
          page={page}
          totalPages={totalPages}
          handlePageChange={handlePageChange}
        />
      </div>

      <AlertModal
        show={pdfDownload}
        onHide={() => setpdfDownload(false)}
        title="Alert"
        message={t('downloadPdf.download')}
        onConfirm={downloadAuditCSV}
      />

    </div>
  )
}

export default VisualInspection
