import React, { useState } from 'react';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';

interface Props {
    baseUrl: string;
    imageIds: string;
  }

const ImageSlider: React.FC<Props> = ({ baseUrl, imageIds }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const images = imageIds.split(',');

  const prevImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <>
        <div 
            className='px-1' 
            onClick={prevImage}
            style={{"background": "#e5e5e5", borderRadius: "25px"}}>
                <FaAngleLeft />
        </div>
        <div className="view-image-count">
            <img
            src={`${baseUrl}/files/download?file_id=${images[currentIndex]}`}
            alt="Plate Image"
            width="100%"
            height="100%"
            // style={{ maxWidth: '100%' }}
            />
        </div>
        <div 
            className='px-1'
            onClick={nextImage}
            style={{"background": "#e5e5e5", borderRadius: "25px"}}>
                <FaAngleRight /> 
        </div>
    </>
  );
};

export default ImageSlider;
