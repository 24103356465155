

// import React from 'react';
// import { DEFAULT_PAGE_SIZE } from '../../constants/constants';

// interface PaginationProps {
//   page: number;
//   totalPages: number;
//   handlePageChange: (page: number, pageSize?: number ) => void;
// }

// const Pagination: React.FC<PaginationProps> = ({ page, totalPages, handlePageChange }) => {
//   console.log(totalPages, "totalpages")
//   return (
//     <div className="tablePagination">
//       <div>
//       <select className='paginationSelect'  onChange={(e) => handlePageChange(page, parseInt(e.target.value))}>
//         <option value={DEFAULT_PAGE_SIZE}>{DEFAULT_PAGE_SIZE}</option>
//         <option value={25}>25</option>
//         <option value={50}>50</option>
//         <option value={75}>75</option>
//         <option value={100}>100</option>
//       </select>
//       </div>
//       <div className='paginationText1'>
//         Page <span className='paginationBtn2 mx-2 py-1 px-2'>{page}</span> of <span className='paginationText2 mx-2'>{totalPages}</span>
//       </div>
//       <button
//         className='paginationBtn'
//         onClick={() => handlePageChange(page - 1)}
//         disabled={page === 1}
//       >
//         Previous
//       </button>|
//       <button
//         className='paginationBtn'
//         onClick={() => handlePageChange(page + 1)}
//         disabled={page >= totalPages}
//       >
//         Next
//       </button>
//     </div>
//   );
// };

// export default Pagination;

import React from 'react';
import { DEFAULT_PAGE_SIZE } from '../../constants/constants';

interface PaginationProps {
  page: number;
  totalPages: number;
  handlePageChange: (page: number, pageSize?: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ page, totalPages, handlePageChange }) => {
  console.log(totalPages, "totalpages");
  
  return (
    <div className="tablePagination">
      <div>
        <select
          className="paginationSelect"
          onChange={(e) => handlePageChange(1, parseInt(e.target.value))} // Reset page to 1
        >
          <option value={DEFAULT_PAGE_SIZE}>{DEFAULT_PAGE_SIZE}</option>
          <option value={25}>25</option>
          <option value={50}>50</option>
          <option value={75}>75</option>
          <option value={100}>100</option>
        </select>
      </div>
      <div className="paginationText1">
        Page <span className="paginationBtn2 mx-2 py-1 px-2">{page}</span> of{' '}
        <span className="paginationText2 mx-2">{totalPages}</span>
      </div>
      <button
        className="paginationBtn"
        onClick={() => handlePageChange(page - 1)}
        disabled={page === 1}
      >
        Previous
      </button>
      |
      <button
        className="paginationBtn"
        onClick={() => handlePageChange(page + 1)}
        disabled={page >= totalPages}
      >
        Next
      </button>
    </div>
  );
};

export default Pagination;

