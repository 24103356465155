//incubation main table columns
export const getColumns = (status: string): TableColumn[] => {
  const columns: TableColumn[] = [
    {
      Header: 'operatorManagement.tableColumns.slNo',
      accessor: 'slno',
    },
    {
      Header: 'operatorManagement.tableColumns.serialNo',
      accessor: 'serial_number',
    },
    {
      Header: 'tableCommon.batch_code',
      accessor: 'batch_number',
    },
    {
      Header: 'operatorManagement.tableColumns.activityType',
      accessor: 'plate_type',
    },
    {
      Header: 'operatorManagement.tableColumns.incEndsIn',
      accessor: 'remainingTime',
    },
    {
      Header: 'operatorManagement.tableColumns.status',
      accessor: 'status',
    },
    {
      Header: 'operatorManagement.tableColumns.incubatedBy',
      accessor: 'incubated_by',
    },
  ];

  // Conditionally add the 'Incubation Duration' column
  if (status !== "Pending" && status !== "In Progress") {
    columns.splice(5, 0, {
      Header: 'operatorManagement.tableColumns.incDuration',
      accessor: 'incubation_duration',
    });
  }


  return columns;
};

//incubation history columns
export const columnsHistory: TableColumn[] = [
  {
    Header: 'operatorManagement.tableColumns.slNo',
    accessor: 'slno',
  },
  {
    Header: 'tableCommon.batch_code',
    accessor: 'batch_number',
  },
  {
    Header: 'operatorManagement.tableColumns.serialNo',
    accessor: 'serial_number',
  },
  {
    Header: "operatorManagement.tableColumns.activityType",
    accessor: "plate_type",
  },
  {
    Header: 'operatorManagement.tableColumns.incDuration',
    accessor: 'incubation_duration',
  },
]


interface TableColumn {
  Header: string;
  accessor: string;
}