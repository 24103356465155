import { useState, useEffect, useRef } from "react";
import { BsQrCodeScan } from "react-icons/bs";
import Modal from "react-bootstrap/Modal";
import { TfiClose } from "react-icons/tfi";
import HeaderSection from "../../common/headerSection";
import PageHeader from "../../common/pageHeader";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import AddException from "../../common/addException";
import { userService } from "../../../service/plateServices";
import Pagination from "../../../widgets/pagination";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { areaService } from "../../../service/areaServices";
import { BatchService } from "../../../service/plateServices/discard";
import moment from "moment";
import AlertModal from "../../../widgets/alertModal";
import { SerielNumberScanner } from "../common/serielNumberScanner";
import { columns, HistoryColumns } from "./partials/columns";
import { useTranslation } from "react-i18next";
import AutoclaveTimer from "../../common/countDownTimer/autoclaveCounter";
import { IoSearchSharp } from "react-icons/io5";
import { DEFAULT_PAGE_SIZE } from "../../../constants/constants";
import CustomToast from "../../../widgets/toaster";
import CustomTable from "../../../widgets/table";

interface Errors {
  endDate?: string;
  duration?: string;
}

const AutoClave = () => {
  const { t } = useTranslation();
  const scanAreaRef = useRef<HTMLInputElement>(null);
  const [tab, setTab] = useState("1");
  const [show, setShow] = useState(false);
  const [showDateModal, setShowDateModal] = useState(false);
  const [scanCode, setScanCode] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState("");
  const [toastMessage, setToastMessage] = useState("");

  const areaId: string | undefined = useSelector(
    (state: RootState) => state.area.areaId
  );
  // const areaName = useSelector((state: RootState) => state.area.areaName);
  const [userId, setUserId] = useState<number>(0);
  const [useEffectRequired, setUseEffectRequired] = useState(false);
  const [serielScanShow, setSerielScanShow] = useState(false);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [activityId, setactivityId] = useState("");
  const [plateDetails, setPlateDetails] = useState<Array<any>>([]);
  const [plateStatus, setPlateStatus] = useState("30,17,18,7,4,23,10,22");
  const [nextProcess, setnextProcess] = useState("autoclave");
  const [plateBatches, setPlateBatches] = useState([]);
  const [plateSerielNum, setPlateSerielNums] = useState([]);
  const [scannedSerielNum, setScannedPlateSerielNums] = useState("");
  const [startDate, setStartDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState<any>(null);
  const [autoclaveDuration, setAutoclaveDuration] = useState("");
  const [autoclaveEndFlag, setAutoclaveEndFlag] = useState("");
  const [errors, setErrors] = useState<Errors>({});
  const [plateIds, setPlateIds] = useState<any>([]);
  const [capacityDetails, setCapacityDetails] = useState("");
  const [scanAreaName, setScanAreaName] = useState("");

  //history pagination
  const [historyPage, setHistoryPage] = useState(1);
  const [historyPageSize, setHistoryPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [historyTotalPages, setHistoryTotalPages] = useState(0);
  const [historyTotalElements, setHistoryTotalElements] = useState(0);
  const [historyKeyWord, setHistoryKeyWord] = useState("");
  const [plateDetailsHistory, setPlateDetailsHistory] = useState<Array<any>>(
    []
  );
  const [showStartAlert, setShowStartAlert] = useState(false);
  const [startFormattedDate, setstartFormatedDate] = useState("");
  const [endFormattedDate, setendFormatedDate] = useState("");
  const data: Record<string, string | number> = {
    [t("operatorManagement.areaName")]: scanAreaName,
    [t("operatorManagement.capacity")]: capacityDetails,
    [t("operatorManagement.duration")]: autoclaveDuration,
  };
  const [isSerielScanned, setIsSerielScanned] = useState(false);
  const [locationId, setLocationId] = useState<string | undefined>("");
  const [sort, setSort] = useState("id");
  const [order, setOrder] = useState("desc");
  const [selectedSerielNo, setSelectedSerielNo] = useState("");
  const [debouncedScanCode, setDebouncedScanCode] = useState("");
  const [activityType, setActivityType] = useState<Array<any>>([]);
  const [activityStatus, setActivityStatus] = useState("");

  //start autoclave section
  const handleStartButton = (selectedRows: string[]) => {
    const filteredPlateDetails = plateDetails.filter((item) =>
      selectedRows.includes(item.slno)
    );
    const selectedIds = filteredPlateDetails.map((item) => item.id).join(",");
    setPlateIds(selectedIds);
    setShowDateModal(true);
  };

  //handle end autoclave section
  const handleStopButton = (selectedRows: string[]) => {
    const filteredPlateDetails = plateDetails.filter((item) =>
      selectedRows.includes(item.slno)
    );
    const selectedIds = filteredPlateDetails.map((item) => item.id).join(",");
    // setPlateIds(selectedIds)
    const expectedEndTime = filteredPlateDetails.map(
      (item) => item.expectedEndTime
    );
    stopAutoClave(selectedIds, new Date(expectedEndTime[0]));
  };

  //list autoclave plates in main table
  const ListAutoclaveDetails = async () => {
    setIsSerielScanned(false);
    try {
      const response = await userService.ListAutoclaveDetails(
        page,
        pageSize,
        sort,
        order,
        plateStatus,
        keyword,
        scannedSerielNum,
        locationId,
        activityStatus,
        nextProcess
      );
      if (response.status && response.statusCode === 200) {
        setTotalPages(response?.totalPages);
        setTotalElements(response?.totalElements);
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                const statusNumber = parseInt(element.plate_status, 10);
                let status: string;
                if (element.plate_status === null) {
                  status = "Pending";
                } else {
                  switch (statusNumber) {
                    case 22:
                    case 23:
                    case 17:
                    case 10:
                    case 7:
                    case 4:
                    case 30:
                    case 18:
                      status = "Pending";
                      break;
                    case 14:
                      status = "In Progress";
                      break;
                    case 15:
                      status = "Completed";
                      break;
                    default:
                      status = "Unknown status";
                  }
                }
                interface Exposure {
                  id: string;
                  start_date_time: string;
                  end_date_time: string;
                  updated_by_first_name: string;
                  updated_by_last_name: string;
                  exposure_area_name: string;
                }
                // Calculate total exposed time
                const exposureDetails: Exposure[] =
                  element.exposure_details || [];
                let totalExposedTime = 0;

                exposureDetails.forEach((exposure) => {
                  const startDate = new Date(exposure.start_date_time);
                  const endDate = new Date(exposure.end_date_time);
                  const timeDifferenceMs =
                    endDate.getTime() - startDate.getTime();
                  totalExposedTime += timeDifferenceMs;
                });
                const totalExposedTimeHours = Math.floor(
                  totalExposedTime / 3600000
                );
                const totalExposedTimeMinutes = Math.floor(
                  (totalExposedTime % 3600000) / 60000
                );
                const totalExposedTimeSeconds = Math.floor(
                  (totalExposedTime % 60000) / 1000
                );

                const fullNames = exposureDetails.map((exposure: Exposure) => {
                  return `${exposure.updated_by_first_name} ${exposure.updated_by_last_name}`;
                });
                const exposed_user = fullNames.join(", ");
                const exposureAreaName =
                  element.exposure_details[0]?.exposure_area_name || "-";

                //caluculate the total incubated time
                interface Incubation {
                  id: string;
                  start_date_time: string;
                  end_date_time: string;
                  created_by_first_name: string;
                  updated_by_first_name: string;
                  updated_by_last_name: string;
                }
                const incubationDetails: Incubation[] =
                  element.incubation_details || [];

                const incubationDuration = incubationDetails.map(
                  (incubation) => {
                    const startDatetime = new Date(incubation.start_date_time);
                    const endDatetime = new Date(incubation.end_date_time);

                    const timeDifference =
                      endDatetime.getTime() - startDatetime.getTime();
                    const hours = Math.floor(timeDifference / 3600000); // 1 hour = 3600000 milliseconds
                    const minutes = Math.floor(
                      (timeDifference % 3600000) / 60000
                    ); // 1 minute = 60000 milliseconds
                    const seconds = Math.floor((timeDifference % 60000) / 1000);
                    return `${hours} : ${minutes} : ${seconds}`;
                  }
                );

                const fullNamesIncubation = incubationDetails.map(
                  (incubation: Incubation) => {
                    return `${incubation.updated_by_first_name} ${incubation.updated_by_last_name}`;
                  }
                );
                const incubated_user = fullNamesIncubation.join(", ");
                const incubationAreaName =
                  element.incubation_details[0]?.incubator_name || "-";

                interface LabArea {
                  colony_count: string;
                }
                const labAreaDetails: LabArea[] = element.lab_details || [];
                const colony_count = labAreaDetails.map(
                  (labDetail) => labDetail.colony_count
                );

                interface AutoClaveArea {
                  start_time: any;
                  ended_user_firstname: string;
                  ended_user_lastname: string;
                }
                const autoClaveDetails: AutoClaveArea[] =
                  element.autoclave_details || [];

                const fullNamesAutoclave = autoClaveDetails.map(
                  (autoclave: AutoClaveArea) => {
                    if (
                      autoclave.ended_user_firstname != null ||
                      autoclave.ended_user_lastname != null
                    ) {
                      return `${autoclave.ended_user_firstname} ${autoclave.ended_user_lastname}`;
                    }
                  }
                );
                const autoclave_user = fullNamesAutoclave.join(", ");

                // const endTime = new Date(start_time.getTime() + autoclaveDuration * 60000);
                const startAutoclaveTime = moment(
                  element?.autoclave_details[0]?.start_time,
                  "YYYY-MM-DD HH:mm:ss"
                ).format("DD-MM-YYYY - HH:mm:ss");
                const expectedEndTime = moment(
                  element?.autoclave_details[0]?.expected_end_time,
                  "YYYY-MM-DD HH:mm:ss"
                ).format("YYYY-MM-DD HH:mm:ss");
                const [startHours, startMinutes] = startAutoclaveTime
                  .split(":")
                  .map(Number);
                let endAutoclaveTime = "";

                if (autoclaveDuration) {
                  const duration = Number(autoclaveDuration);
                  let endHour = startHours;
                  let endMinute = startMinutes + duration;

                  if (endMinute >= 60) {
                    endHour += Math.floor(endMinute / 60);
                    endMinute %= 60;
                  }

                  endAutoclaveTime = `${String(endHour).padStart(
                    2,
                    "0"
                  )}:${String(endMinute).padStart(2, "0")}`;
                  // endAutoclaveTime = "18:31"
                }
                return {
                  slno: index + 1,
                  batch_number: element.batch_number,
                  id: element.id,
                  serial_number: element.serial_number,
                  plate_type: element.assigned_activity_name,
                  exposed_time: `${totalExposedTimeHours} : ${totalExposedTimeMinutes} : ${totalExposedTimeSeconds}`,
                  exposed_by: exposed_user,
                  exposure_area_name: exposureAreaName,
                  incubation_duration: incubationDuration,
                  incubated_by: incubated_user,
                  incubation_area_name: incubationAreaName,
                  status: status,
                  colony_count: colony_count,
                  autoclave_user: autoclave_user,
                  autoClaveStartTime: element?.autoclave_details[0]?.start_time,
                  expectedEndTime:
                    element?.autoclave_details[0]?.expected_end_time,
                  autoclaveEndTime: (
                    <AutoclaveTimer endTime={expectedEndTime} status={status} />
                  ),
                };
              }
            );
            setPlateDetails(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        setPlateDetails([]);
        setTotalPages(0);
        setTotalElements(0);
      }
    } catch (error) {
      console.error("Plate response", error);
    }
  };

  //get area details for mapping
  const getAreaByScan = async (id: any, location_type: any) => {
    try {
      const response = await areaService.getAreaByScan(id, location_type);
      if (response.status && response.statusCode === 200) {
        try {
          setAutoclaveDuration(response.data.autoclave_duration);
          setAutoclaveEndFlag(response.data.autoclave_end_flag);
          setScanAreaName(response.data.autoclave_area_name);
          setCapacityDetails(
            `${response.data.busy_node_count} / ${response.data.capacity}`
          );
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.error("user response", error);
    }
  };

  //handle pagination
  const handlePageChange = (newPage: any, pageSize?: number) => {
    setPage(newPage);
    if (pageSize) {
      setPageSize(pageSize);
    }
    setUseEffectRequired(true);
  };

  //handle filter section
  const handleFilter = (key: string, value: any) => {
    if (key === "Area Name") {
      if (value === "9" || value === "Status") {
        setPlateStatus("30,17,18,7,4,23,10,22");
        setnextProcess("autoclave");
        // setPlateStatus("22,17,18,7,4,23,10");
        setLocationId("");
      } else if (value === "12") {
        setPlateStatus("14");
        setLocationId(areaId);
        setnextProcess("");
      } else if (value === "13") {
        setPlateStatus("15");
        setLocationId("");
        setnextProcess("");
      }
    } else if (key === "search") {
      setKeyword(value);
    } else if (key === "Activity Type") {
      if (value === "Select") {
        setPlateStatus("22,17,18,7,4,23,10");
        setActivityStatus("");
        setnextProcess("");
      } else {
        setActivityStatus(value);
      }
    }
    setUseEffectRequired(true);
  };

  //handle remove button in table section
  const handleRemoveClick = (rowId: string) => {
    const updatedData = plateDetails.filter((row) => row.slno !== rowId);
    const updatedDataWithSlno = updatedData.map((row, index) => ({
      ...row,
      slno: index + 1,
    }));
    setPlateDetails(updatedDataWithSlno);
  };

  //list plate batch dropdown
  const listPlateBatch = async () => {
    try {
      const response = await userService.listPlateBatch();
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                return {
                  value: element.batch_number,
                  label: element.batch_number,
                };
              }
            );
            setPlateBatches(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.error("Plate response", error);
    }
  };

  //list serial number dropdown
  const listSerialNumber = async () => {
    try {
      const response = await userService.listSerialNumber();
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                return {
                  value: element.serial_number,
                  label: element.serial_number,
                };
              }
            );
            setPlateSerielNums(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.error("Plate response", error);
    }
  };

  //handle scan search plate change in autoclave plates
  const handleScanCodeChange = async (e: any) => {
    if (!isSerielScanned) {
      setPlateDetails([]);
    }
    setScanCode(e);
    if (e.trim() === "") {
    } else {
      try {
        const response = await userService.listAutoClavePlateBySerielNo(
          e,
          "autoclave"
        );
        if (response.status && response.statusCode === 200) {
          try {
            const dataArray = Array.isArray(response.data)
              ? response.data
              : [response.data];

            let rowData: any = dataArray.map((element: any, index: number) => {
              const plateType = parseInt(element.plate_type, 10);
              if (plateType == 1) {
                var plate_type = "Q A Plate";
              } else if (plateType == 2) {
                var plate_type = "Exposure Plate";
              } else if (plateType == 3) {
                var plate_type = "Personnel Monitoring Plate";
              } else if (plateType == 4) {
                var plate_type = "Sampling Plate";
              } else if (plateType == 5) {
                var plate_type = "Material Test Plate";
              } else if (plateType == 6) {
                var plate_type = "Surface Monitoring Plate";
              } else if (plateType == 7) {
                var plate_type = "Surface Monitoring - SWAB Collection";
              } else {
                var plate_type = "-";
              }

              const statusNumber = parseInt(element.plate_status, 10);
              let status: string;
              if (element.plate_status === null) {
                status = "Pending";
              } else {
                switch (statusNumber) {
                  case 22:
                  case 23:
                  case 17:
                  case 10:
                  case 7:
                  case 4:
                  case 30:
                  case 18:
                    status = "Pending";
                    break;
                  case 14:
                    status = "In Progress";
                    break;
                  case 15:
                    status = "Completed";
                    break;
                  default:
                    status = "Unknown status";
                }
              }
              interface Exposure {
                id: string;
                start_date_time: string;
                end_date_time: string;
                updated_by_first_name: string;
                updated_by_last_name: string;
                exposure_area_name: string;
              }
              // Calculate total exposed time
              const exposureDetails: Exposure[] =
                element.exposure_details || [];
              let totalExposedTime = 0;

              exposureDetails.forEach((exposure) => {
                const startDate = new Date(exposure.start_date_time);
                const endDate = new Date(exposure.end_date_time);
                const timeDifferenceMs =
                  endDate.getTime() - startDate.getTime();
                totalExposedTime += timeDifferenceMs;
              });
              const totalExposedTimeHours = Math.floor(
                totalExposedTime / 3600000
              );
              const totalExposedTimeMinutes = Math.floor(
                (totalExposedTime % 3600000) / 60000
              );
              const totalExposedTimeSeconds = Math.floor(
                (totalExposedTime % 60000) / 1000
              );

              const fullNames = exposureDetails.map((exposure: Exposure) => {
                return `${exposure.updated_by_first_name} ${exposure.updated_by_last_name}`;
              });
              const exposed_user = fullNames.join(", ");
              const exposureAreaName =
                element.exposure_details[0]?.exposure_area_name || "-";

              //caluculate the total incubated time
              interface Incubation {
                id: string;
                start_date_time: string;
                end_date_time: string;
                created_by_first_name: string;
                updated_by_first_name: string;
                updated_by_last_name: string;
              }
              const incubationDetails: Incubation[] =
                element.incubation_details || [];

              const incubationDuration = incubationDetails.map((incubation) => {
                const startDatetime = new Date(incubation.start_date_time);
                const endDatetime = new Date(incubation.end_date_time);

                const timeDifference =
                  endDatetime.getTime() - startDatetime.getTime();
                const hours = Math.floor(timeDifference / 3600000); // 1 hour = 3600000 milliseconds
                const minutes = Math.floor((timeDifference % 3600000) / 60000); // 1 minute = 60000 milliseconds
                const seconds = Math.floor((timeDifference % 60000) / 1000);
                return `${hours} : ${minutes} : ${seconds}`;
              });

              const fullNamesIncubation = incubationDetails.map(
                (incubation: Incubation) => {
                  return `${incubation.updated_by_first_name} ${incubation.updated_by_last_name}`;
                }
              );
              const incubated_user = fullNamesIncubation.join(", ");
              const incubationAreaName =
                element.incubation_details[0]?.incubator_name || "-";

              interface LabArea {
                colony_count: string;
              }
              const labAreaDetails: LabArea[] = element.lab_details || [];
              const colony_count = labAreaDetails.map(
                (labDetail) => labDetail.colony_count
              );

              interface AutoClaveArea {
                start_time: any;
                ended_user_firstname: string;
                ended_user_lastname: string;
              }
              const autoClaveDetails: AutoClaveArea[] =
                element.autoclave_details || [];

              const fullNamesAutoclave = autoClaveDetails.map(
                (autoclave: AutoClaveArea) => {
                  if (
                    autoclave.ended_user_firstname != null ||
                    autoclave.ended_user_lastname != null
                  ) {
                    return `${autoclave.ended_user_firstname} ${autoclave.ended_user_lastname}`;
                  }
                }
              );
              const autoclave_user = fullNamesAutoclave.join(", ");
              const startAutoclaveTime = moment(
                element?.autoclave_details[0]?.start_time,
                "YYYY-MM-DD HH:mm:ss"
              ).format("DD-MM-YYYY - HH:mm:ss");
              const expectedEndTime = moment(
                element?.autoclave_details[0]?.expected_end_time,
                "YYYY-MM-DD HH:mm:ss"
              ).format("HH:mm:ss");
              const [startHours, startMinutes] = startAutoclaveTime
                .split(":")
                .map(Number);
              let endAutoclaveTime = "";

              if (autoclaveDuration) {
                const duration = Number(autoclaveDuration);
                let endHour = startHours;
                let endMinute = startMinutes + duration;

                if (endMinute >= 60) {
                  endHour += Math.floor(endMinute / 60);
                  endMinute %= 60;
                }

                endAutoclaveTime = `${String(endHour).padStart(
                  2,
                  "0"
                )}:${String(endMinute).padStart(2, "0")}`;
                // endAutoclaveTime = "18:31"
              }
              const slno =
                plateDetails.length > 0
                  ? isSerielScanned
                    ? plateDetails.length + 1
                    : 1
                  : index + 1;

              return {
                slno: slno,
                batch_number: element.batch_number,
                id: element.id,
                serial_number: element.serial_number,
                plate_type: plate_type,
                exposed_time: `${totalExposedTimeHours} : ${totalExposedTimeMinutes} : ${totalExposedTimeSeconds}`,
                exposed_by: exposed_user,
                exposure_area_name: exposureAreaName,
                incubation_duration: incubationDuration,
                incubated_by: incubated_user,
                incubation_area_name: incubationAreaName,
                status: status,
                colony_count: colony_count,
                autoclave_user: autoclave_user,
                autoClaveStartTime: element?.autoclave_details[0]?.start_time,
                expectedEndTime:
                  element?.autoclave_details[0]?.expected_end_time,
                autoclaveEndTime: (
                  <AutoclaveTimer endTime={expectedEndTime} status={status} />
                ),
              };
            });

            const uniqueUpdatedDataWithSlno = rowData.filter(
              (newElement: any) => {
                return !plateDetails.some(
                  (existingElement) =>
                    existingElement.serial_number === newElement.serial_number
                );
              }
            );

            setPlateDetails((prevUserRoleList) => [
              ...prevUserRoleList,
              ...uniqueUpdatedDataWithSlno,
            ]);
            setIsSerielScanned(true);
          } catch (error) {
            console.log(error);
          }
        } else {
          // setPlateDetails([])
          setToastMessage(response.message);
          setToastType("error");
          setShowToast(true);
        }
      } catch (error) {
        console.error("Plate response", error);
      }
    }
  };

  //handle submit api call
  const handleSubmitBtn = () => {
    const startDateObject: any = new Date(startDate.toString());
    var endDateObject: any = null;
    const endDuration = parseInt(autoclaveDuration);
    const errors: Errors = {};

    if (endDate) {
      endDateObject = new Date(endDate.toString());
    }

    // Calculate the difference in minutes
    const timeDifference = (endDateObject - startDateObject) / 60000; // 60000 milliseconds in a minute

    if (autoclaveEndFlag === "0") {
      const startparsedDate = moment(
        startDateObject,
        "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"
      );
      const startformattedDate = startparsedDate.format("YYYY-MM-DD HH:mm:ss");
      const endparsedDate = moment(
        endDateObject,
        "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"
      );
      const endformattedDate = endparsedDate.format("YYYY-MM-DD HH:mm:ss");

      if (startDateObject < endDateObject) {
        if (endDate && timeDifference === endDuration) {
          startAutoClave(startformattedDate, endformattedDate);
        } else if (endDate && timeDifference < endDuration) {
          errors.duration = `* End time less than Duration limit `;
        } else {
          setendFormatedDate(endformattedDate);
          setstartFormatedDate(startformattedDate);
          setShowStartAlert(true);
          // startAutoClave(startformattedDate, endformattedDate)
          setErrors({});
        }
      } else {
        errors.endDate = `* End date must be greater than Start date`;
      }

      if (Object.keys(errors).length > 0) {
        setErrors(errors);
        return;
      }
    } else {
      const startparsedDate = moment(
        startDateObject,
        "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"
      );
      const startformattedDate = startparsedDate.format("YYYY-MM-DD HH:mm:ss");
      setendFormatedDate("");
      setstartFormatedDate(startformattedDate);
      startAutoClave(startformattedDate, "");
    }
  };

  //handle datatosend api call
  const startAutoClave = async (
    startFormattedDate: any,
    endFormattedDate: any
  ) => {
    const dataToSend = {
      user_id: userId,
      plate_ids: plateIds,
      location_id: areaId,
      start_date_time: startFormattedDate,
      end_date_time: endFormattedDate,
      interface_name: "Start Autoclave",
    };

    try {
      const response = await BatchService.startAutoClave(dataToSend);
      if (response.status && response.statusCode === 200) {
        setToastType("success");
        setToastMessage(response.message);
        setPlateStatus("14");
      } else {
        setToastType("error");
        setToastMessage(response.message);
      }
      setShowToast(true);
      handleCloseAutoClaveDuration();
      setUseEffectRequired(true);
      setShowStartAlert(false);
    } catch (error) {
      return error;
    }
  };

  //handle stop autoclave api call
  const stopAutoClave = async (plateIds: any, expectedEndTime: any) => {
    const currentDate = new Date();
    const currentparsedDate = moment(
      currentDate,
      "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"
    );
    const currentformattedDate = currentparsedDate.format(
      "YYYY-MM-DD HH:mm:ss"
    );

    const expectedEndTimetest = new Date(expectedEndTime);
    const currentDatetest = new Date(currentDate);

    if (expectedEndTimetest >= currentDatetest) {
      setToastType("error");
      setToastMessage(t("operatorManagement.endTimeAlert"));
    } else {
      const dataToSend = {
        plate_ids: plateIds,
        user_id: userId,
        end_date_time: currentformattedDate,
        location_id: areaId,
        interface_name: "Stop Autoclave",
      };

      try {
        const response = await BatchService.stopAutoClave(dataToSend);
        if (response.status && response.statusCode === 200) {
          setToastType("success");
          setToastMessage(response.message);
        } else {
          setToastType("error");
          setToastMessage(response.message);
        }
        handleCloseAutoClaveDuration();
        setUseEffectRequired(true);
      } catch (error) {
        return error;
      }
    }
    setShowToast(true);
    setUseEffectRequired(true);
  };

  //handle pagination in history table
  const handleHistoryPageChange = (newPage: any, pageSize?: number) => {
    setHistoryPage(newPage);
    if (pageSize) {
      setHistoryPageSize(pageSize);
    }
    setUseEffectRequired(true);
  };

  //list autoclave history table details
  const ListAutoclaveHistory = async () => {
    try {
      const response = await userService.ListAutoclaveHistory(
        historyPage,
        historyPageSize,
        sort,
        order,
        historyKeyWord,
        areaId,
        activityId
      );
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            setHistoryTotalPages(response?.totalPages);
            setHistoryTotalElements(response?.totalElements);
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                let totalIncubatedTime = 0;

                const startDate = new Date(element.start_time);
                const endDate = element.end_time
                  ? new Date(element.end_time)
                  : null;
                if (endDate) {
                  const timeDifferenceMs =
                    endDate.getTime() - startDate.getTime();
                  totalIncubatedTime += timeDifferenceMs;
                }

                const totalIncubatedTimeHours = Math.floor(
                  totalIncubatedTime / 3600000
                );
                const totalIncubatedTimeMinutes = Math.floor(
                  (totalIncubatedTime % 3600000) / 60000
                );
                const totalIncubatedTimeSeconds = Math.floor(
                  (totalIncubatedTime % 60000) / 1000
                );

                return {
                  slno: index + 1,
                  batch_number: element.batch_number,
                  id: element.id,
                  serial_number: element.serial_number,
                  plate_type: element.activity_type,
                  autoclave_end_time: `${totalIncubatedTimeHours} : ${totalIncubatedTimeMinutes} : ${totalIncubatedTimeSeconds}`,
                  autoclave_user: element.user_name,
                };
              }
            );
            setPlateDetailsHistory(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        setPlateDetailsHistory([]);
      }
    } catch (error) {
      console.error("Plate response", error);
    }
  };

  //handle filter in history table section
  const handleFilterHistory = (key: string, value: any) => {
    if (key === "search") {
      setHistoryKeyWord(value);
    } else if (key === "Activity Type") {
      if (value === "Select") {
        // setPlateStatus("22,17,18,7,4,23,10");
        setactivityId("");
      } else {
        setactivityId(value);
      }
    }
    setUseEffectRequired(true);
  };

  //handle api call when page load
  useEffect(() => {
    setUseEffectRequired(false);
    var storedData = localStorage.getItem("userDetails");

    if (storedData) {
      var storedObject = JSON.parse(storedData);
      setUserId(storedObject[0].id);
    }
    listSerialNumber();
    listPlateBatch();
    getActivityTypeList();
    ListAutoclaveDetails();
    getAreaByScan(areaId, "");

    ListAutoclaveHistory();
    setUseEffectRequired(false);
  }, [useEffectRequired]);

  const handleStartDateChange = (e: any) => {
    const start_time = new Date(e);

    const endTime = new Date(
      start_time.getTime() + parseInt(autoclaveDuration, 10) * 60000
    );
    setEndDate(endTime);
    setStartDate(start_time);
  };

  //handle plate add by scan serial number
  const handleScanCode = (serielNumber: any) => {
    if (serielNumber) {
      handleScanCodeChange(serielNumber);
      setToastMessage(`Plate Seriel scan successfull`);
      setToastType("success");
    } else {
      setToastMessage("Plate Seriel scan failed");
      setToastType("error");
    }
    setShowToast(true);
  };

  //handle serial number manual entry section
  const handleScanCodeInput = (result: any) => {
    try {
      const parsedResult = JSON.parse(result);
      if (typeof parsedResult === "object") {
        if (parsedResult.hasOwnProperty("serial_number")) {
          handleScanCodeChange(parsedResult.serial_number);
        }
      }
    } catch (error) {
      handleScanCodeChange(result);
      console.error("Error parsing JSON:", error);
    }
  };

  // function to sort table
  const handleSort = (key: string, value: boolean) => {
    setSort(key);
    if (value) {
      setOrder("asc");
    } else {
      setOrder("desc");
    }
    setUseEffectRequired(true);
  };

  // function to sort table
  const handleSortHistory = (key: string, value: boolean) => {
    setSort(key);
    if (value) {
      setOrder("asc");
    } else {
      setOrder("desc");
    }
    setUseEffectRequired(true);
  };

  //handle autoclave duration close
  const handleCloseAutoClaveDuration = () => {
    setStartDate(new Date());
    setEndDate(null);
    setShowDateModal(false);
    setErrors({});
  };

  //trigger start autoclave
  const handleStartAutoClave = () => {
    startAutoClave(startFormattedDate, endFormattedDate);
  };

  //use effect to hover to the input field automatically while loading the component
  useEffect(() => {
    // Set a timer to update debouncedScanCode after 10 seconds
    const timerId = setTimeout(() => {
      setDebouncedScanCode(scanCode);
    }, 3000);

    // Cleanup the timer if the component is unmounted or scanCode changes
    return () => clearTimeout(timerId);
  }, [scanCode]);

  //set a delay for 3 sec if anything wrong happend
  useEffect(() => {
    if (debouncedScanCode) {
      handleScanCodeInput(debouncedScanCode);
    }
  }, [debouncedScanCode]);

  //focus over the input field
  useEffect(() => {
    if (scanAreaRef.current) {
      scanAreaRef.current.focus();
    }
  }, []);

  //list activity type api on filter
  const getActivityTypeList = async () => {
    try {
      const response = await userService.getActivityTypeList();
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                return {
                  slno: index + 1,
                  id: element.id,
                  name: element.name,
                };
              }
            );
            setActivityType(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        setActivityType([]);
      }
    } catch (error) {
      console.error("user response", error);
    }
  };

  return (
    <div>
      <HeaderSection />
      <PageHeader pageHeader={t("operatorManagement.autoclave")} />
      <CustomToast
        show={showToast}
        onClose={() => setShowToast(false)}
        delay={5000}
        message={toastMessage}
        toastType={toastType}
      />

      <div className="exceptionHandlingTab">
        <button
          onClick={() => setTab("1")}
          className={tab === "1" ? "colTabBtnSel" : "colTabBtn"}
        >
          {t("operatorManagement.autoclave")}
        </button>
        <button
          onClick={() => setTab("2")}
          className={tab === "2" ? "colTabBtnSel" : "colTabBtn"}
        >
          {t("operatorManagement.history")}
        </button>
      </div>

      {tab === "1" && (
        <div>
          <div className="scanWrapLg">
            <div
              className="scanInputWrapLg p-2"
              style={{ width: "30%", height: "20%" }}
            >
              <input
                type="text"
                value={scanCode}
                ref={scanAreaRef}
                onChange={(e) => setScanCode(e.target.value)}
                placeholder={t("operatorManagement.scanCode")}
              />
              {scanCode ? (
                <p
                  onClick={() => handleScanCodeChange("")}
                  className="scanAddText py-2 me-2 my-0 "
                >
                  {t("operatorManagement.clear")}
                </p>
              ) : (
                <div
                  onClick={() => setSerielScanShow(true)}
                  className="scanBg "
                >
                  <BsQrCodeScan />
                </div>
              )}
            </div>

            <div
              className="scanInputWrapLg p-2 ms-3"
              style={{ width: "30%", height: "20%" }}
            >
              <input
                type="text"
                value={selectedSerielNo}
                onChange={(e) => setSelectedSerielNo(e.target.value)}
                placeholder={t("operatorManagement.searchSlNo")}
              // ref={textFieldRef}
              />

              <IoSearchSharp
                fontSize={25}
                className="m-1"
                color="grey"
                style={{ cursor: "pointer" }}
                onClick={() => handleScanCodeChange(selectedSerielNo)}
              />
            </div>

            {/* {scanCode && ( */}
            <div className="pt-3 mx-2">
              <p onClick={() => setShow(true)} className="exceptionBtn ms-3">
                {t("operatorManagement.exception")}
              </p>
            </div>
            {/* )} */}
            <div
              className="scannedDetailsWrap ps-3"
              style={{ marginLeft: "5%" }}
            >
              <table className="">
                <tbody>
                  {Object.keys(data).map((key) => (
                    <tr key={key}>
                      <td className="keyText">{key}</td>
                      <td className="px-3">:</td>
                      <td className="valueText">{data[key]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className=" mx-3 pe-2">
            <CustomTable
              data={plateDetails}
              columns={columns}
              isEditable={false}
              totalElements={totalElements}
              isActionVisible={true}
              isViewVisible={false}
              removable={true}
              plateBatch={plateBatches}
              plateSerialNumber={plateSerielNum}
              ActivityType={activityType}
              isSeachable={true}
              tableHead={t("operatorManagement.autoclave")}
              endButton={true}
              endButtonText={t("operatorManagement.end_autoclave")}
              startButton={true}
              startButtonText={t("operatorManagement.start_autoclave")}
              onStartClick={handleStartButton}
              onEndClick={handleStopButton}
              onFilterClick={handleFilter}
              onRemoveClick={handleRemoveClick}
              onSortClick={handleSort}
            />
          </div>
          <div>
            <Pagination
              page={page}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
            />
          </div>
        </div>
      )}

      {tab === "2" && (
        <div>
          <div className=" mx-3 pe-2">
            <CustomTable
              data={plateDetailsHistory}
              columns={HistoryColumns}
              isEditable={false}
              totalElements={historyTotalElements}
              plateBatch={plateBatches}
              plateSerialNumber={plateSerielNum}
              isActionVisible={false}
              isViewVisible={false}
              isSeachable={true}
              ActivityType={activityType}
              onFilterClick={handleFilterHistory}
              onSortClick={handleSortHistory}
              tableHead={t("operatorManagement.history")}
            />
          </div>
          <div>
            <Pagination
              page={historyPage}
              totalPages={historyTotalPages}
              handlePageChange={handleHistoryPageChange}
            />
          </div>
        </div>
      )}

      {/* add exception modal */}
      <AddException showModel={show} setShowModel={() => setShow(false)} />

      <Modal
        show={showDateModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="modalHead">
          <p className="modalHeadText ms-3 pt-3 p-2">
            {t("operatorManagement.autoclave_duration")}
          </p>
          <div
            onClick={() => handleCloseAutoClaveDuration()}
            className="p-2 mx-3 my-2 "
          >
            <TfiClose fill="#FFFFFF" />
          </div>
        </div>
        <div className="row m-3">
          <div className="col-md-6">
            <div className="form-group">
              <label style={{ color: "black" }} htmlFor="date1">
                {t("operatorManagement.start_date")}
              </label>
              {/* <Datetime value={startDate} onChange={handleStartDateChange} inputProps={inputProps} /> */}
              {autoclaveEndFlag === "0" ? (
                <Datetime value={startDate} onChange={handleStartDateChange} />
              ) : (
                <input type="text" value={startDate} disabled />
              )}
            </div>
          </div>
          <div className="col-md-6">
            {autoclaveEndFlag === "0" && (
              <div className="form-group">
                <label style={{ color: "black" }} htmlFor="date2">
                  {t("operatorManagement.end_date")}
                </label>
                <Datetime value={endDate} onChange={setEndDate} />
              </div>
            )}
          </div>
        </div>
        {errors.duration && (
          <p className="errorText text-center ">{errors.duration}</p>
        )}
        {errors.endDate && (
          <p className="errorText text-center">{errors.endDate}</p>
        )}
        <div className="m-2 d-flex justify-content-between">
          <button
            onClick={() => handleCloseAutoClaveDuration()}
            className="modalCancelBtn mx-3"
          >
            {t("buttons.cancel")}
          </button>
          <button
            onClick={() => handleSubmitBtn()}
            className="squareOrangeBtn3"
          >
            {t("buttons.submit")}
          </button>
        </div>
      </Modal>

      <AlertModal
        show={showStartAlert}
        onHide={() => setShowStartAlert(false)}
        title="Alert"
        message={t("operatorManagement.startAutoClaveAlert")}
        onConfirm={handleStartAutoClave}
      />

      <SerielNumberScanner
        show={serielScanShow}
        setShow={setSerielScanShow}
        handleScanCode={handleScanCode}
      />
    </div>
  );
};

export default AutoClave;
