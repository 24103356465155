import React, { useEffect, useState } from 'react'
import PageHeader from '../../common/pageHeader'
import HeaderSection from '../../common/headerSection'
import { Modal} from 'react-bootstrap'
import CustomTable from '../../../widgets/table'
import { columns, columnsHistory } from './partials/column'
import { TfiClose } from 'react-icons/tfi'
import { useTranslation } from 'react-i18next';
import { rawMaterials } from '../../../service/rawMaterials'
import AlertModal from '../../../widgets/alertModal'
import CustomToast from '../../../widgets/toaster'
import Pagination from '../../../widgets/pagination';
import { userService } from '../../../service/userService'
import TemporaryManagementModal from './partials/modal'
import { BatchService } from '../../../service/plateServices/discard'
import { userService as plateRelatedService } from '../../../service/plateServices';
import { DEFAULT_PAGE_SIZE } from "../../../constants/constants";

const TemporaryManagement = () => {

  const { t } = useTranslation();

  interface RawMaterial {
    id: number;
    sl_no: number;
    batch_no: string;
    manufacturer_details: string;
    created_at: string;
    plate_size_type_name: string;
    plate_count: number;
    lot_id: string;
    status: string;
    batch_grn: string;
    remaining_for_lot_assign: number;
    media_type_name: string;
  }
  const [rawMaterialsType, setRawMaterialsType] = useState<RawMaterial[]>([]);
  const [tcmHistoryData, setTcmHistoryData] = useState<RawMaterial[]>([]);
  const [plateBySerielNo, setPlateBySerielNo] = useState<RawMaterial[]>([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [modalName, setModalName] = useState(t('configuration.addSampleType'));
  const [modalEdit, setModalEdit] = useState(false);
  const [addSampleData, setAddSampleData] = useState({
    materialName: "",
    materialId: "",
    type: "",
    subType: "",
    id: "",
    reason: ""
  });
  const [rawMaterialsDropDown, setRawMaterialsDropDown] = useState([]);
  const [addRawMaterialAlert, setAddRawMaterialAlert] = useState(false);
  const [idAndStatus, setIdAndStatus] = useState({ 
    id: "",
    status: "" 
  });
  const [tab, setTab] = useState("1")
  const [useEffectRequired, setuseEffectRequired] = useState(false)
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
 
   // table pagination for sample type
   const [sampleTypePage, setSampleTypePage] = useState(1);
   const [sampleTypepageSize, setSampleTypePageSize] = useState(DEFAULT_PAGE_SIZE);
   const [sampleTypetotalPages, setSampleTypeTotalPages] = useState(0);
   const [sampleTypetotalElements, setSampleTypeTotalElements] = useState(0);
   // table pagination for sample Sub type
   const [tcmTablePage, setTcmTablePage] = useState(1);
   const [tcmPageSize, setTcmPageSize] = useState(DEFAULT_PAGE_SIZE);
   const [tcmTotalPages, setTcmTotalPages] = useState(0);
   const [tcmMotalElements, setTcmTotalElements] = useState(0);

   const [keyWord, setkeyWord] = useState("")

   interface Errors {
    materialName?: string,
    materialId?: string,
    type?: string,
    subType?: string,
    reason?: string
  }
  
  const [errors, setErrors] = useState<Errors>({});
  const [userId, setUserId] = useState("");
  const [authToken , setAuthToken] = useState("");
  const [authUserID , setAuthUserID] = useState("");
  const [permission, setPermission] = useState<any>([]);
  const [sort, setSort] = useState("id");
  const [order, setOrder] = useState("desc");
  const [show, setShow] = useState(false);
  const [plateBatches, setPlateBatches] = useState([])
  const [batchCode, setBatchCode] = useState("");
  const [batchCodeHistory, setBatchCodeHistory] = useState("");
  const [sortHistory, setSortHistory] = useState("id");
  const [orderHistory, setOrderHistory] = useState("desc");
  const [actionClearAll, setActionClearAll] = useState(false);

  useEffect(() => {

    var storedData = localStorage.getItem('userDetails');

    if(storedData) {
      var storedObject = JSON.parse(storedData);
      setUserId(storedObject[0].id);
      setAuthToken(storedObject[0].x_auth_token);
      setAuthUserID(storedObject[0].x_userid);
      getPermissionByRole(storedObject[0].role_master_id);
    }

    listBatchLIstforTCM();
    listBatchLIstforTCMHistory();
    listDropDownType();
    listPlateBatch();
    setuseEffectRequired(false)
    setActionClearAll(false)
  }, [useEffectRequired])
  

  //get permission by role 
  const getPermissionByRole = async (role : number) => {
    try {
      const response = await userService.getPermissionByRole(role);
      if(response.status && response.statusCode === 200){
        setPermission(response.data.permissions)
      }
    } catch (error) {
      console.error(error);
    }
  }

  //get all_raw_material_type list from api  
  const listBatchLIstforTCM = async () => {
    try {
      // const response = await userService.getPlateList(page, pageSize, sort, order, plateStatus, keyWord);
      const response = await BatchService.getBatchDetailsTCM(sampleTypePage, sampleTypepageSize, sort,order , keyWord, 0, "", "", batchCode, "disabled")
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              setSampleTypeTotalPages(response?.totalPages)
              setSampleTypeTotalElements(response?.totalElements)
              let rowData: any = response.data.map((element: any, index: number) => {
  
                const StatusConstants = {
                  QUARANTINE: "0",
                  QA_APPROVED: "1",
                  READY_TO_USE: "2",
                  ACTIVITY_INPROGRESS: "3",
                  QA_REJECTED : "4",
                  UNDER_TEST:"5",
                  BATCH_DISCARD:"6"
                };
  
                let status;
  
                // Mapping numeric status to string representation
                switch (element.batch_status) {
                    case StatusConstants.UNDER_TEST:
                        status = "Waiting for QA Approval";
                        break;
                    case StatusConstants.QA_APPROVED:
                        status = "QA APPROVED";
                        break;
                    case StatusConstants.READY_TO_USE:
                        status = "READY TO USE";
                        break;
                    case StatusConstants.ACTIVITY_INPROGRESS:
                        status = "ACTIVITY INPROGRESS";
                        break;
                    case StatusConstants.QA_REJECTED:
                        status = "QA REJECTED";
                        break;
                    case StatusConstants.QUARANTINE:
                        status = "QUARANTINE";
                        break;
                    case StatusConstants.BATCH_DISCARD:
                        status = "DISCARD";
                        break;
                    default:
                        status = "UNKNOWN STATUS";
                }
  
                return { 
                    id : element.id,
                    slno: index + 1, 
                    batch_number: element.batch_no,
                    manufacturer_details: element.manufacturer_details,
                    manufacturing_date: element.created_at,
                    plate_type: element.plate_size_type_name,
                    quantity: element.plate_count,
                    lot_id: element.lot_id,
                    status: status,
                    batch_grn:element.batch_grn,
                    remaining_for_lot_assign:element.remaining_for_lot_assign,
                    media_type_name:element.media_type_name
                };
              });
              setRawMaterialsType(rowData);
            }
          } catch (error) {
            console.log(error); 
          }
      } else {
        setRawMaterialsType([]);
      }
    } catch (error) {
      console.error('Plate response', error);
    }
  
  }

  const listBatchLIstforTCMHistory = async () => {
    try {
      // const response = await userService.getPlateList(page, pageSize, sort, order, plateStatus, keyWord);
      const response = await BatchService.getBatchDetailsTCM(tcmTablePage, tcmPageSize, sortHistory,orderHistory , keyWord, "", "", "", batchCodeHistory, "enabled")
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              setTcmTotalPages(response?.totalPages)
              setTcmTotalElements(response?.totalElements)
              let rowData: any = response.data.map((element: any, index: number) => {
  
                const StatusConstants = {
                  QUARANTINE: "0",
                  QA_APPROVED: "1",
                  READY_TO_USE: "2",
                  ACTIVITY_INPROGRESS: "3",
                  QA_REJECTED : "4",
                  UNDER_TEST:"5",
                  BATCH_DISCARD:"6"
                };
  
                let status;
  
                // Mapping numeric status to string representation
                switch (element.batch_status) {
                    case StatusConstants.UNDER_TEST:
                        status = "Waiting for QA Approval";
                        break;
                    case StatusConstants.QA_APPROVED:
                        status = "QA APPROVED";
                        break;
                    case StatusConstants.READY_TO_USE:
                        status = "READY TO USE";
                        break;
                    case StatusConstants.ACTIVITY_INPROGRESS:
                        status = "ACTIVITY INPROGRESS";
                        break;
                    case StatusConstants.QA_REJECTED:
                        status = "QA REJECTED";
                        break;
                    case StatusConstants.QUARANTINE:
                        status = "QUARANTINE";
                        break;
                    case StatusConstants.BATCH_DISCARD:
                        status = "DISCARD";
                        break;
                    default:
                        status = "UNKNOWN STATUS";
                }
  
                return { 
                    id : element.id,
                    slno: index + 1, 
                    batch_number: element.batch_no,
                    manufacturer_details: element.manufacturer_details,
                    manufacturing_date: element.created_at,
                    plate_type: element.plate_size_type_name,
                    quantity: element.plate_count,
                    lot_id: element.lot_id,
                    status: status,
                    batch_grn:element.batch_grn,
                    remaining_for_lot_assign:element.remaining_for_lot_assign,
                    media_type_name:element.media_type_name,
                    tcm_ref : element.tcm_ref,
                    tcm_remarks : element.tcm_remarks
                };
              });
              setTcmHistoryData(rowData);
            }
          } catch (error) {
            console.log(error); 
          }
      } else {
        setTcmHistoryData([]);
      }
    } catch (error) {
      console.error('Plate response', error);
    }
  
  }

  const listDropDownType = async () => {
    try {
      const response = await rawMaterials.listDropDownType(authToken ,authUserID);
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              // setSampleTypeTotalPages(response?.totalPages)
              // setSampleTypeTotalElements(response?.totalElements)
              let rowData: any = response.data.map((element: any, index: number) => {

                return { 
                    slno: index + 1, 
                    material_type: element.material_type,
                    material_type_code: element.material_type_code,
                    id : element.id,
                    status : element.status,
                };
              });
              setRawMaterialsDropDown(rowData);
            }
          } catch (error) {
            console.log(error);
          }
      }
    } catch (error) {
      console.error('user response', error);
    }
  }

  const listPlateBatch = async () => {
    try {
      const response = await plateRelatedService.listPlateBatch();
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              let rowData: any = response.data.map((element: any, index: number) => {
  
                return { 
                    value: element.batch_number,
                    label: element.batch_number ,
                };
              });
              setPlateBatches(rowData);
            }
          } catch (error) {
            console.log(error); 
          }
      }
    } catch (error) {
      console.error('Plate response', error);
    }
  }

  
  const addEquipmentBtnClick = () => {
    setModalName(t('configuration.addSampleType'))
    setModalEdit(false)
    setShowAddModal(true)
  }

  //handle table pagination 
  const handlePageChange = (newPage : any, pageSize?: number) => {
    setSampleTypePage(newPage)
    if(pageSize) {
      setSampleTypePageSize(pageSize)
    }
    setActionClearAll(true)
    setuseEffectRequired(true)
  };

  //handle table pagination history
  const handlePageChangeHistory = (newPage : any, pageSize?: number) => {
    setTcmTablePage(newPage)
    if(pageSize) {
      setTcmPageSize(pageSize)
    }
    setuseEffectRequired(true)
  };

  //handle filter section in sample type
  const handleFilter = (key: string, value: any) => {
    if(key === "batch_code") {
      setBatchCodeHistory(value)
    } else if (key === "search") {
      setkeyWord(value)
    }
    setuseEffectRequired(true)
  }

   //handle filter section in sample type
   const handleFilterHistory = (key: string, value: any) => {
    if(key === "batch_code") {
      setBatchCode(value)
    } else if (key === "search") {
      setkeyWord(value)
    }
    setuseEffectRequired(true)
  }

  const handleAddbutton = (ids: string[]) => {
    const filteredData = rawMaterialsType.filter((item : any) => ids.includes(item.slno));
    setPlateBySerielNo(filteredData);
    setShow(true);
  };

  const handleSort = (key: string, value: boolean) => {
    setSort(key);
    if (value) {
      setOrder("asc");
    } else {
      setOrder("desc");
    }
    setuseEffectRequired(true);
  };

  const handleSortHistory = (key: string, value: boolean) => {
    setSortHistory(key);
    if (value) {
      setOrderHistory("asc");
    } else {
      setOrderHistory("desc");
    }
    setuseEffectRequired(true);
  };

  return (
    <div>
      <HeaderSection />
      <PageHeader pageHeader={t('temporaryManagement.pageTitle')} />
      <CustomToast
            show={showToast}
            onClose={() => setShowToast(false)}
            delay={5000}
            message={toastMessage}
            toastType={toastType}
        /> 

      <div className='exceptionHandlingTab'>
              <button onClick={() => setTab("1")} className={tab === "1" ? 'colTabBtnSel': 'colTabBtn'}>{t('temporaryManagement.pageTitle')}</button>
              <button onClick={() => setTab("2")} className={tab === "2" ? 'colTabBtnSel': 'colTabBtn'}>{t('temporaryManagement.history')}</button>
        </div>

        {tab === "1" && (
        <div className='me-3' style={{marginTop : "20px"}}>
            <div className=' mx-3 pe-2' style={{"height":"45vh"}}>
                <CustomTable 
                    tableHead={t('temporaryManagement.batches')}
                    data={rawMaterialsType} 
                    columns={columns} 
                    isEditable={false} 
                    isTempDeletable={false}
                    totalElements={sampleTypetotalElements} 
                    isActionVisible={true} 
                    isViewVisible={false} 
                    isSeachable={false}
                    isToggleStatus={false}
                    isResetPassword={false}
                    addButton={false}
                    actionCheckOrangeButton={permission?.includes("add_tcm")}
                    actionCheckOrangeBtnText={t('temporaryManagement.pageTitle')}
                    onActionCheckOrangeButtonClick={handleAddbutton}
                    onUseEffectRequired={() => setuseEffectRequired(true)}
                    onFilterClick={handleFilter}          
                    onAddClick={addEquipmentBtnClick}
                    changeRoleButton={false}
                    onSortClick={handleSort}
                    plateBatch={plateBatches}
                    clearAll={actionClearAll}
                />
            </div>
            <div>
              <Pagination
                page={sampleTypePage}
                totalPages={sampleTypetotalPages}
                handlePageChange={handlePageChange}
              />
            </div>
        </div>
        )}

      <TemporaryManagementModal
        show={show}
        plateBySerielNo={plateBySerielNo}
        setPlateBySerielNo={setPlateBySerielNo}
        setShow={setShow}
        setuseEffectRequired={setuseEffectRequired}
        setActionClearAll={setActionClearAll}
      />

        {tab === "2" && (
          <div className='me-3' style={{marginTop : "20px"}}>
            <div className=' mx-3 pe-2' style={{"height":"45vh"}}>
                <CustomTable 
                    tableHead={t('temporaryManagement.history')}
                    data={tcmHistoryData} 
                    columns={columnsHistory} 
                    isEditable={false} 
                    isTempDeletable={false}
                    totalElements={tcmMotalElements} 
                    isActionVisible={false} 
                    isViewVisible={false} 
                    isSeachable={false}
                    isResetPassword={false}
                    addButton={false}
                    plateBatch={plateBatches}
                    onUseEffectRequired={() => setuseEffectRequired(true)}
                    onFilterClick={handleFilterHistory}
                    onSortClick={handleSortHistory}
                   
                />
            </div>
            <div>
              <Pagination
                page={tcmTablePage}
                totalPages={tcmTotalPages}
                handlePageChange={handlePageChangeHistory}
              />
            </div>
        </div>
        )}
      
    </div>
  )
}

export default TemporaryManagement