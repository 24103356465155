import React, { useState , useEffect} from 'react'
import { Link } from 'react-router-dom'
import HeaderSection from '../../../common/headerSection'
import PageHeader from '../../../common/pageHeader'
import CustomTable from '../../../../widgets/table'
import Pagination from '../../../../widgets/pagination'
import { TfiClose } from 'react-icons/tfi'
import { Modal } from 'react-bootstrap'
import { dashboardServices } from '../../../../service/dashboard'
import { userService } from '../../../../service/plateServices'
import { useTranslation } from 'react-i18next';
import { setLoading } from '../../../../store/store'
import { useDispatch } from 'react-redux'
import DownloadPdf from '../../../../widgets/downloadPdf'
import { autoClavecolumns as columns } from './partials/columns'
import { DEFAULT_PAGE_SIZE } from '../../../../constants/constants'


const moment = require('moment');


//define autoclave stat keys
type AutoclaveStatType = {
  total_autoclave_areas: number;
  inprogress_plates: number;
  completed_plates: number;
  deviations: number;
  autoclave_occupancy_or_capacity: number;
};

//define inner view page keys 
type PlateViewType = {
  autoclave_point_name: string;
  autoclave_start_date: string;
  autoclave_end_date : string;
  serial_number: string;
  exceptionStatus: string;
  exception_added : string;
  started_by : string;
  ended_by : string;
  status : string;
  escallation : string;
};


const AutoclaveTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showModal , setShowModal] = useState(false);
  const [exposureStat, setExposureStat] = useState<AutoclaveStatType | null>(null);
  const [exposureAreaNames, setExposureAreaNames] = useState<Array<any>>([]);
  const [exposurePlateDetails, setExposurePlateDetails] = useState<Array<any>>([]);
  const [plateView, setPlateView] = useState<PlateViewType | null>(null);
  const [page, setpage] = useState(1)
  const [pagesize, setpagesize] = useState(DEFAULT_PAGE_SIZE)
  const [totalPages, settotalPages] = useState(0)
  const [totalElements, setTotalElements] = useState(0)
  const [selExposureAreaName, setSelExposureAreaName] = useState("Autoclave Details");

//handle pagination
  const handleSchedulePageChange = (newPage : any, pageSize?: number) => {   
    setpage(newPage);
    if(pageSize) {
      setpagesize(pageSize)
    }
};

//api call for autoclave stat value fetch 
const getAutoclaveStat = async () => {
  try {
    const response = await dashboardServices.getAutoclaveStat(); 
      if(response.status && response.statusCode === 200){
        setExposureStat({
            total_autoclave_areas: response.data.total_autoclave_areas,
            autoclave_occupancy_or_capacity: response.data.occupancy_capacity,
            inprogress_plates : response.data.inprogress_plates,
            completed_plates : response.data.completed_plates,
            deviations : response.data.escalations
          });
          dispatch(setLoading(false));
    }
  } catch (error) {
    console.error('user response', error);
  }
};

//api call for area name listing 
const getAutoclaveAreaNames = async () => {
  try {
    const response = await dashboardServices.getAutoclaveAreaNames();
    if(response.status && response.statusCode === 200){
      try {
          if ( Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map((element: any, index: number) => {
              return { 
                  slno: index + 1, 
                  id: element.id,
                  name: element.name,  
              };
            });
            setExposurePlateDetails([])
            const firstId = rowData.length > 0 ? rowData[0].id : null;
            ListAutoclavePlates(firstId)
            setExposureAreaNames(rowData);
          }
        } catch (error) {
          console.log(error);
        }
    }
  } catch (error) {
    console.error('exposure response', error);
  }
};

//api call for listing data table 
const ListAutoclavePlates = async (id : number) => {
  try {
    const response = await dashboardServices.ListAutoclavePlates(id);
    if(response.status && response.statusCode === 200){
      try {
          if ( Array.isArray(response.data) && response.data?.length > 0) {
            settotalPages(response?.totalPages)
            setTotalElements(response?.totalElements)
            let rowData: any = response.data.map((element: any, index: number) => {

              const plateType = parseInt(element.plate_type, 10); 
              if (plateType == 1){
                var plate_type = "Q A Plate"
              }else if (plateType == 2){
                var plate_type = "Exposure Plate"
              }else if (plateType == 3){
                var plate_type = "Personnel Monitoring Plate"
              } else{
                var plate_type = "-"
              }

              const statusNumber = parseInt(element.plate_status, 10); 
              const plateStatus = userService.getPlateStatus(statusNumber);

              const createdFirstName = element.autoclave_details[0]?.started_by_user_firstname || '-';
              const createdLastName = element.autoclave_details[0]?.started_by_user_lastname || '-';
              const fullName = createdFirstName + ' ' + createdLastName;

              const updatedFirstName = element.autoclave_details[0]?.ended_user_firstname || '-';
              const updatedLastName = element.autoclave_details[0]?.ended_user_lastname || '-';
              let updatedFullName = '';
              if (updatedFirstName !== null) {
                updatedFullName = updatedFirstName;
              if (updatedLastName !== null) {
              updatedFullName += ' ' + updatedLastName;
              }
              } else {
              updatedFullName = '-';
              }

              const endDateTime = element.autoclave_details[0]?.end_time;
              const formattedEndDate = endDateTime
              ? moment(endDateTime, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY - HH:mm:ss')
              : '-';
              return { 
                slno: index + 1, 
                id: element.id,
                plate_serial_number : element.serial_number,
                plate_status : plateStatus,
                plate_type : plate_type,
                lab_start_time : moment(element.autoclave_details[0]?.start_time, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY - HH:mm:ss'),
                lab_start_by : fullName,
                lab_end_time : formattedEndDate,
                lab_end_by : updatedFullName,
                colony_count : element.lab_details[0]?.colony_count
              };
            });
            setExposurePlateDetails(rowData);
          }
        } catch (error) {
         
          console.log(error);
        }
    }else{
      setExposurePlateDetails([])
    }
  } catch (error) {
    console.error('exposure response', error);
  }
};

//api for plate view details 
const getPlateView = async (id: any) => {
  const dataToSend = {
    "id" : id
  }
  try {
    const response = await dashboardServices.scanPlateById(dataToSend);
    if (response.status && response.statusCode === 200) {

      const endDateTime = response.data.autoclave_details[0]?.end_time;
      const formattedEndDate = endDateTime
      ? moment(endDateTime, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY - HH:mm:ss')
      : '-';
      const status = endDateTime ? 'Completed' : 'Pending';

    const createdFirstName = response.data.autoclave_details[0]?.started_by_user_firstname;
    const createdLastName = response.data.autoclave_details[0]?.started_by_user_lastname;
    const fullName = createdFirstName + ' ' + createdLastName;

    const updatedFirstName = response.data.autoclave_details[0]?.ended_user_firstname;
    const updatedLastName = response.data.autoclave_details[0]?.ended_user_lastname;
    let updatedFullName = '';
    if (updatedFirstName !== null) {
      updatedFullName = updatedFirstName;
    if (updatedLastName !== null) {
    updatedFullName += ' ' + updatedLastName;
    }
    } else {
    updatedFullName = '-';
    }
      setPlateView({
        autoclave_point_name: response.data.autoclave_details[0]?.location_name,
        serial_number : response.data.serial_number,
        autoclave_start_date : moment(response.data.autoclave_details[0]?.start_time, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY - HH:mm:ss'),
        autoclave_end_date :  formattedEndDate,
        exceptionStatus : response.data.autoclave_details[0]?.exception_status || "-",
        exception_added : response.data.autoclave_details[0]?.exception_added || "-",
        escallation : response.data.autoclave_details[0]?.escallation || "-",
        started_by : fullName,
        ended_by : updatedFullName,
        status  : status
      });
    }
  } catch (error) {
    console.error('view response', error);
  }
};

//onclick on area name list 
const onExpAreaNameClick = (id : number, name: string) => {
  setTotalElements(0)
  ListAutoclavePlates(id)
  setSelExposureAreaName(name)
  
}

//handle view click 
const handleViewClick = (id: string, viewValue: boolean) => {
  const updatedData = exposurePlateDetails.filter((row) => row.slno === id);
  getPlateView(updatedData[0]?.id)
  setShowModal(viewValue)
  
};

useEffect(() => {
  dispatch(setLoading(true));
  getAutoclaveStat();
  getAutoclaveAreaNames();
}, [])

  return (
   <div>
    <HeaderSection />
    <PageHeader pageHeader="Dashboard" />
    <div id='dashWrap'>
    <nav aria-label="breadcrumb" className="breadcrumb-container row">
       <div className='col-10 d-flex'>
           <ol className="breadcrumb">
            <li className="breadcrumb-item dashboard-hover"><Link to="/*">{t('dashboard.title')}</Link></li>
            <li className="breadcrumb-item active" aria-current="page">{t('dashboard.autoclave')}</li>
          </ol>
        </div>
        <div className='col-2 d-flex'>
          <DownloadPdf divId="dashWrap" reportHead="Autoclave Report" />
        </div>
    </nav>

     {/*Autoclave Stat listing*/}
      <div className="key-values-container">
      {exposureStat && Object.entries(exposureStat).map(([key, value]) => (
          <div  key={key} className="key-value">
            <div className="key">
              {key.replace(/_/g, ' ')}
            </div>
            <div className='value'>{value}</div>
          </div>
        ))}
      </div>

   {/*Autoclave Area listing*/}
    <div className='row'>
        <div className='col-md-2 area-names ml-5'>
             <h3 className='filter-head mb-3'>{t('dashboard.autoclave-areas')}</h3> 
             <div className='outer-area' style={{overflowY: "scroll", height: "60vh"}}>
             {exposureAreaNames && exposureAreaNames.map((exp_names) => (
              <div 
                className={exposurePlateDetails[0]?.parent_area === exp_names.id ?'selAreaDetails': 'area-detail'}
                key={exp_names.id} 
                onClick={() => onExpAreaNameClick(exp_names.id, exp_names.name)}>
                  {exp_names.name}
              </div>       
             ))}        
             </div>        
        </div>

        <div className='col-md-9 table-footer'>
                <CustomTable
                    tableHead={selExposureAreaName}
                    data={exposurePlateDetails} 
                    columns={columns} 
                    isEditable={false} 
                    isActionVisible={false} 
                    isViewVisible={true} 
                    totalElements={totalElements}
                    isSeachable={false}
                    isToggleStatus={false}      
                    onViewClick={handleViewClick}
                />
        </div>
        <div>
              <Pagination
                page={page}
                totalPages={totalPages}
                handlePageChange={handleSchedulePageChange}
              />
        </div>
    </div>
    </div>

    {/*Modal for autoclave view page*/}
    <Modal
              className="top-right-modal"
              centered={false}
              show={showModal}
          >
            <div>
                <div className="modalHead">
                <p className="modalHeadText ms-3 pt-3 p-2">{plateView?.autoclave_point_name}</p>
                <div onClick={() => setShowModal(false)} className="p-2 mx-3 my-2 ">
                  <TfiClose fill="#FFFFFF" />
                </div>
              </div>
              <div>

              <div className="selectWrap view-exposure-head">
                  <div className="form-group my-1">
                    <label htmlFor="">{t('dashboard.plate_serial_id')}</label>
                    <br />
                    <input 
                       id="serial_id"
                       value={plateView?.serial_number}
                       name="serial_id"
                       type='text'
                       placeholder='Serial ID'
                       className='view-exposure-head'
                       disabled
                    />
                  </div>

                  <div className="form-group my-1">
                    <label>{t('dashboard.status')}</label>
                    <br />
                    <input 
                       id="status"
                       value={plateView?.status}
                       name="status"
                       type='text'
                       placeholder='Status'
                       className='view-exposure-head'
                       disabled 
                    />  
                  </div>
                </div>
                  <div className="selectWrap mt-4">
                  <div className="form-group my-1">
                    <label htmlFor="">{t('dashboard.autoclave_start')}</label>
                    <br />
                    <input 
                       id="autoclave_start"
                       value={plateView?.autoclave_start_date}
                       name="autoclave_start"
                       type='text'
                       placeholder='Autoclave Start Time'
                       className='view-exposure-details'
                       disabled
                    />
                  </div>

                  <div className="form-group my-1">
                    <label>{t('dashboard.autoclave_by')}</label>
                    <br />
                    <input 
                       id="autoclave_by"
                       value={plateView?.started_by}
                       name="autoclave_by"
                       type='text'
                       placeholder='Autoclave Started By'
                       className='view-exposure-details'
                       disabled
                    />  
                  </div>
                </div>

                <div className="selectWrap mt-4">
                  <div className="form-group my-1">
                    <label htmlFor="">{t('dashboard.autoclave_end')}</label>
                    <br />
                    <input 
                       id="autoclave_end"
                       value={plateView?.autoclave_end_date}
                       name="autoclave_end"
                       type='text'
                       placeholder='Autoclave End'
                       className='view-exposure-details'
                       disabled
                    />
                  </div>

                  <div className="form-group my-1">
                    <label>{t('dashboard.autoclave_ended_by')}</label>
                    <br />
                    <input 
                       id="autoclave_ended_by"
                       value={plateView?.ended_by}
                       name="autoclave_ended_by"
                       type='text'
                       placeholder='Autoclave Ended By'
                       className='view-exposure-details'
                       disabled
                    />  
                  </div>
                </div>

                <div className="selectWrap mt-4">
                  <div className="form-group my-1">
                    <label htmlFor="">{t('dashboard.Exception_added')}</label>
                    <br />
                    <input 
                       id="Exception_added"
                       value={plateView?.exception_added}
                       name="Exception_added"
                       type='text'
                       placeholder='Exception Added'
                       className='view-exposure-details'
                       disabled
                    />
                  </div>

                  <div className="form-group my-1">
                    <label>{t('dashboard.exception_status')}</label>
                    <br />
                    <input 
                       id="exception_status"
                       value={plateView?.exceptionStatus}
                       name="exception_status"
                       type='text'
                       placeholder='Exception Status'
                       className='view-exposure-details'
                       disabled 
                    />  
                  </div>
                </div>

                <div className="selectWrap-email mt-4">
                  <div className="form-group my-1">
                    <label htmlFor="">{t('dashboard.escalations')}</label>
                    <br />
                    <input 
                       id="escalations"
                       value={plateView?.escallation}
                       type='text'
                       placeholder='Escallation'
                       className='view-exposure-details'
                       disabled
                    />
                  </div>
                </div>
               
              </div>
              <div className="bottomArea">
            <button onClick={() => setShowModal(false)} className="modalCancelBtn">
            {t('buttons.cancel')}
            </button>        
          </div>
            </div>
    </Modal>

 </div>

  )
}

export default AutoclaveTable;
