import React, { useState, useEffect } from "react";
import HeaderSection from "../../../common/headerSection";
import PageHeader from "../../../common/pageHeader";
import { Link } from "react-router-dom";
import CustomTable from "../../../../widgets/table";
import Pagination from "../../../../widgets/pagination";
import { TfiClose } from "react-icons/tfi";
import { Modal } from "react-bootstrap";
import { dashboardServices } from "../../../../service/dashboard";
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import translationData from '../../../../locales/dashboardTranslation.json'
import DownloadPdf from "../../../../widgets/downloadPdf";
import { DEFAULT_PAGE_SIZE } from "../../../../constants/constants";




const InnerPageIncubation = () => {
    const { incubationId } = useParams();
    const { t } = useTranslation();
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const [totalPages, setTotalPages] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [scheduleDetails, setScheduleDetails] = useState([]);
    const displayName = getDisplayName(Number(incubationId));
    const [useEffectRequired, setUseEffectRequired] = useState(false)
    const [sort, setSort] = useState("id");
    const [order, setOrder] = useState("desc");

    function getDisplayName(id: any) {
        const incubationDataHead = translationData.IncubationDataHead;
        const exposure = incubationDataHead.find(item => item.id === id);
        return exposure ? exposure.displayName : "";
    }

    //column header for incubation view
    const columns : any  = [
    {
      Header: "tableCommon.sl_no",
      accessor: "slno",
    },
    {
      Header: "dashboard.incubationViewTableColumns.plateSerialNumber",
      accessor: "plate_serial_number",
    },
    {
      Header: "dashboard.incubationViewTableColumns.batchNumber",
      accessor: "plate_batch_number",
    },
    {
      Header: "dashboard.incubationViewTableColumns.incubatorNumber",
      accessor: "location_name",
    },
    {
      Header: "dashboard.incubationViewTableColumns.incStartDateTime",
      accessor: "start_date_time",
    },
    {
      Header: "dashboard.incubationViewTableColumns.incStartedBy",
      accessor: "started_user",
    },
    {
      Header: "dashboard.incubationViewTableColumns.incEndDateTime",
      accessor: "end_date_time",
    },
    {
        Header: "dashboard.incubationViewTableColumns.incEndedBy",
        accessor: "ended_user",
    },
    {
        Header: "dashboard.incubationViewTableColumns.discrepanciesIfAny",
        accessor: "descrepencies_any",
    },
    {
        Header: "dashboard.incubationViewTableColumns.deviationsIfAny",
        accessor: "deviations_any",
    },
    {
        Header: "dashboard.incubationViewTableColumns.status",
        accessor: "status",
    },
  ]
  
  //handle pagination 
    const handleSchedulePageChange = (newPage :any, newPageSize?: number) => {
      setPage(newPage);
      if (newPageSize) {
        setPageSize(newPageSize);  
      }
      setUseEffectRequired(true)
    };

    // function to sort table
    const handleSort = (key: string, value: boolean) => {
      setSort(key)
      if (value) {
        setOrder("asc")
      } else {
        setOrder("desc")
      }
      setUseEffectRequired(true)
    }


    //handle incubation inner page table listing
    const fetchData = async () => {
      try {
        let response;
        switch (incubationId) {
          case "3":
            response = await dashboardServices.getPlateInIncubationDetails(page, pageSize,sort,order);
            break;
          case "4":
            response = await dashboardServices.IncubationCollectionDetails(page, pageSize,sort,order);
            break;
          case "5":
            response = await dashboardServices.getIncubationOverduePlatesDetails(page, pageSize,sort,order);
            break;
          default:
            break;
        }
  
        if (response.status && response.statusCode === 200) {
          try {
            if (Array.isArray(response.data) && response.data?.length > 0) {
              setTotalPages(response?.totalPages);
              setTotalElements(response?.totalElements);
              let rowData = response.data.map((element:any, index: any) => ({
                slno: index + 1,
                location_name: element.location_name,
                plate_serial_number: element.plate_serial_number,
                plate_batch_number: element.plate_batch_number,
                start_date_time: element.start_date_time,
                started_user: element.started_user,
                end_date_time: element.end_date_time,
                ended_user: element.ended_user,
                descrepencies_any: element.descrepencies_any,
                deviations_any: element.deviations_any,
                status: element.status,
              }));
              setScheduleDetails(rowData);
            }
          } catch (error) {
            console.log(error);
          }
        } else {
          setScheduleDetails([]);
        }
      } catch (error) {
        console.error('API call error', error);
      }
    };

  
    useEffect(() => {
        fetchData();
        setUseEffectRequired(true)
      }, [incubationId, useEffectRequired]);

      return (
        <div>
          <HeaderSection />
          <PageHeader pageHeader={t('dashboard.title')} />
          <div id="dashWrap">
            <nav aria-label="breadcrumb" className="breadcrumb-container row">
              <div className='col-10 d-flex'>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item dashboard-hover">
                    <Link to="/*">{t('dashboard.title')}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                  {displayName}
                  </li>
                </ol>
              </div>
              <div className='col-2 d-flex'>
                <DownloadPdf divId="dashWrap" reportHead="Incubation Report" />
              </div>
            </nav>
    
            <div className='col-md-12 mx-3 pe-5' style={{"height":"45vh"}}>
                    <CustomTable
                        tableHead={displayName} 
                        data={scheduleDetails} 
                        columns={columns} 
                        isEditable={false} 
                        isActionVisible={false} 
                        isViewVisible={false} 
                        totalElements={totalElements}
                        isSeachable={false}
                        isToggleStatus={false}
                        onSortClick={handleSort}
                        onUseEffectRequired={() => setUseEffectRequired(true)}       
                    />
            </div>
            <div>
                  <Pagination
                    page={page}
                    totalPages={totalPages}
                    handlePageChange={handleSchedulePageChange}
                  />
            </div>
          </div>
        </div>
      );
  };
  
  export default InnerPageIncubation;
  