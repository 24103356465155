

//table columns for lab Report
export const columnsNew: TableColumn[] = [
    {
        Header: "tableCommon.sl_no",
        accessor: "slno",
    },
    {
        Header: "tableCommon.seriel_number",
        accessor: "serial_number",
    },
    {
        Header: "tableCommon.batch_code",
        accessor: "batch_code",
    },
    {
        Header: "labreport.tableColumns.lotCode",
        accessor: "lot_code",
    },
    {
        Header: "scheduleFrequency.tableColumns.activity_type_name",
        accessor: "activity",
    },
    {
        Header: "scheduleProduction.tableColumns.area_name",
        accessor: "area_name",
    },
    {
        Header: "labreport.tableColumns.location",
        accessor: "location",
    },
    {
        Header: "labreport.tableColumns.testTubeSlNo",
        accessor: "test_tube_serial_no",
    },
   
    {
        Header: "labreport.tableColumns.equipmentCode",
        accessor: "equipment_code",
    },
    {
        Header: "labreport.tableColumns.activityStartedBy",
        accessor: "sampling_started_by",
    },
    {
        Header: "labreport.tableColumns.activityStartedDate",
        accessor: "sampling_started_date",
    },
    {
        Header: "labreport.tableColumns.activityEndedBy",
        accessor: "sampling_ended_by",
    },
    {
        Header: "labreport.tableColumns.activityEndedDate",
        accessor: "sampling_ended_date",
    },
    {
        Header: "labreport.tableColumns.activityDuration",
        accessor: "sampling_duration",
    },
    {
        Header: "labreport.tableColumns.monitoredUser",
        accessor: "monitored_user",
    },
    {
        Header: "labreport.tableColumns.monitoredLocation",
        accessor: "monitored_location",
    },
    {
        Header: "labreport.tableColumns.firstIncStartBy",
        accessor: "first_incubation_start_by",
    },
    {
        Header: "labreport.tableColumns.firstIncStartDate",
        accessor: "first_incubation_start_date",
    },
    {
        Header: "labreport.tableColumns.firstIncEndBy",
        accessor: "first_incubation_end_by",
    },
    {
        Header: "labreport.tableColumns.firstIncEndDate",
        accessor: "first_incubation_end_date",
    },
    {
        Header: "labreport.tableColumns.firstIncDuration",
        accessor: "first_incubation_duration",
    },
    {
        Header: "labreport.tableColumns.secondIncStartBy",
        accessor: "second_incubation_start_by",
    },
    {
        Header: "labreport.tableColumns.secondIncStartDate",
        accessor: "second_incubation_start_date",
    },
    {
        Header: "labreport.tableColumns.secondIncEndBy",
        accessor: "second_incubation_end_by",
    },
    {
        Header: "labreport.tableColumns.secondIncEndDate",
        accessor: "second_incubation_end_date",
    },
    {
        Header: "labreport.tableColumns.secondIncDuration",
        accessor: "second_incubation_duration",
    },
    {
        Header: "labreport.tableColumns.obs1Name",
        accessor: "observer1_name",
    },
    {
        Header: "labreport.tableColumns.count1",
        accessor: "count1",
    },
    {
        Header: "labreport.tableColumns.obs2Name",
        accessor: "observer2_name",
    },
    {
        Header: "labreport.tableColumns.count2",
        accessor: "count2",
    },
    {
        Header: "labreport.tableColumns.obs3Name",
        accessor: "observer3_name",
    },
    {
        Header: "labreport.tableColumns.count3",
        accessor: "count3",
    },
    {
        Header: "labreport.tableColumns.colonyType",
        accessor: "colony_type",
    },
    {
        Header: "labreport.tableColumns.organismType",
        accessor: "organism_type",
    },
    {
        Header: "labreport.tableColumns.genus",
        accessor: "genus",
    },
    {
        Header: "labreport.tableColumns.species",
        accessor: "species",
    },
    {
        Header: "labreport.tableColumns.strain",
        accessor: "strain",
    },
    {
        Header: "labreport.tableColumns.plateStatus",
        accessor: "plate_status",
    },
    {
        Header: "labreport.tableColumns.approvedBy",
        accessor: "approve_id",
    },
];

  
  interface TableColumn {
    Header: string;
    accessor: string;
  }