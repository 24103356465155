import axios from "axios";
import baseUrl from "..";
import { handleLogOut } from "../../utils/oathUtils";

    let authToken = ""
    let authUserID = ""

    const storedData = localStorage.getItem('userDetails');

    if (storedData) {
    try {
        const storedObject = JSON.parse(storedData);
        if (storedObject && Array.isArray(storedObject) && storedObject.length > 0) {
        authToken = storedObject[0].x_auth_token
        authUserID = storedObject[0].x_userid
        }
    } catch (error) {
        console.error('Failed to parse user details:', error);
    }
    }

    // Create an Axios instance
    const axiosInstance = axios.create({
    baseURL: baseUrl
    });

    // Add a response interceptor
    axiosInstance.interceptors.response.use(
    response => {
    // If the response is successful, just return the response
    return response;
    },
    error => {
    // Check for 400 or 401 status codes
    if (axios.isAxiosError(error) && (error.response?.status === 408 || error.response?.status === 401)) {
        // Redirect to login page
        handleLogOut()
    }
    // Return any other errors
    return Promise.reject(error);
    }
    );



    const activeSamplingReport = async (page : any, pageSize: any, fromDate: any, toDate: any, location: any, batchCode: any, lotCode:any, plateSerielNo: any , sort : any , order:any) => {
        try {
        const response = await axiosInstance.get(`${baseUrl}/report/air_sampling?page=${page}&size=${pageSize}&sort=${sort}&order=${order}&batch_number=${batchCode}&lot_id=${lotCode}&serial_number=${plateSerielNo}&from_date=${fromDate}&to_date=${toDate}&area_master_id=${location}`,
        {
            headers: {
            'x-auth-token': authToken,
            'x-userid': authUserID
            }
        });
        return response.data;
        } catch (error) {
        if (axios.isAxiosError(error)) {
            return error.response?.data
        }
        }
    };

    const passiveSamplingReport = async (page : any, pageSize: any, fromDate: any, toDate: any, location: any, batchCode: any, lotCode:any, plateSerielNo: any, sort : any , order:any) => {
        try {
        const response = await axiosInstance.get(`${baseUrl}/report/passive_air_sampling?page=${page}&size=${pageSize}&sort=${sort}&order=${order}&batch_number=${batchCode}&lot_id=${lotCode}&serial_number=${plateSerielNo}&from_date=${fromDate}&to_date=${toDate}&area_master_id=${location}`,
        {
            headers: {
            'x-auth-token': authToken,
            'x-userid': authUserID
            }
        });
        return response.data;
        } catch (error) {
        if (axios.isAxiosError(error)) {
            return error.response?.data
        }
        }
    };

    const personnelMonitoringReport = async (page : any, pageSize: any, fromDate: any, toDate: any, location: any, batchCode: any, lotCode:any, plateSerielNo: any, sort : any , order:any) => {
        try {
        const response = await axiosInstance.get(`${baseUrl}/report/personal_monitoring?page=${page}&size=${pageSize}&sort=${sort}&order=${order}&batch_number=${batchCode}&lot_id=${lotCode}&serial_number=${plateSerielNo}&from_date=${fromDate}&to_date=${toDate}&area_master_id=${location}`,
        {
            headers: {
            'x-auth-token': authToken,
            'x-userid': authUserID
            }
        });
        return response.data;
        } catch (error) {
        if (axios.isAxiosError(error)) {
            return error.response?.data
        }
        }
    };

    const surfaceMonitoringReport = async (page : any, pageSize: any, fromDate: any, toDate: any, location: any, batchCode: any, lotCode:any, plateSerielNo: any, sort : any , order:any) => {
        try {
        const response = await axiosInstance.get(`${baseUrl}/report/surface_monitoring_swab?page=${page}&size=${pageSize}&sort=${sort}&order=${order}&batch_number=${batchCode}&lot_id=${lotCode}&serial_number=${plateSerielNo}&from_date=${fromDate}&to_date=${toDate}&area_master_id=${location}`,
        {
            headers: {
            'x-auth-token': authToken,
            'x-userid': authUserID
            }
        });
        return response.data;
        } catch (error) {
        if (axios.isAxiosError(error)) {
            return error.response?.data
        }
        }
    };

    const surfaceMonitoringListReport = async (page : any, pageSize: any, fromDate: any, toDate: any, location: any, batchCode: any, lotCode:any, plateSerielNo: any, sort : any , order:any) => {
        try {
        const response = await axiosInstance.get(`${baseUrl}/report/surface_monitoring?page=${page}&size=${pageSize}&sort=${sort}&order=${order}&batch_number=${batchCode}&lot_code=${lotCode}&serial_number=${plateSerielNo}&from_date=${fromDate}&to_date=${toDate}&area_master_id=${location}`,
        {
            headers: {
            'x-auth-token': authToken,
            'x-userid': authUserID
            }
        });
        return response.data;
        } catch (error) {
        if (axios.isAxiosError(error)) {
            return error.response?.data
        }
        }
    };

    const equipmentConfigurationReport = async (page : any, pageSize: any, fromDate: any, toDate: any, equipmentId: any, sort : any , order:any) => {
        try {
        const response = await axiosInstance.get(`${baseUrl}/report/equipment?page=${page}&size=${pageSize}&sort=${sort}&order=${order}&equipment_id=${equipmentId}&from_date=${fromDate}&to_date=${toDate}`,
        {
            headers: {
            'x-auth-token': authToken,
            'x-userid': authUserID
            }
        });
        return response.data;
        } catch (error) {
        if (axios.isAxiosError(error)) {
            return error.response?.data
        }
        }
    };

    const plateActivityReport = async (page : any, pageSize: any, fromDate: any, toDate: any, locationId: any, userId: any, action: any) => {
        try {
        const response = await axiosInstance.get(`${baseUrl}/report/plate_activity?page=${page}&size=${pageSize}&sort=id&order=desc&location_id=${locationId}&user_id=${userId}&from_date=${fromDate}&to_date=${toDate}&action=${action}`,
        {
            headers: {
            'x-auth-token': authToken,
            'x-userid': authUserID
            }
        });
        return response.data;
        } catch (error) {
        if (axios.isAxiosError(error)) {
            return error.response?.data
        }
        }
    };

    const visualInspectionReport = async (page : any, pageSize: any, fromDate: any, toDate: any, operatorId: any, batchCode: any, lotCode:any, sort : any , order:any) => {
        try {
        const response = await axiosInstance.get(`${baseUrl}/report/visual_inspection?page=${page}&size=${pageSize}&sort=${sort}&order=${order}&lot_id=${lotCode}&batch_code=${batchCode}&operator_id=${operatorId}&from_date_time=${fromDate}&to_date_time=${toDate}`,
        {
            headers: {
            'x-auth-token': authToken,
            'x-userid': authUserID
            }
        });
        return response.data;
        } catch (error) {
        if (axios.isAxiosError(error)) {
            return error.response?.data
        }
        }
    };

    const deviationReport = async (page : any, pageSize: any, fromDate: any, toDate: any, equipmentId: any, operatorId: any, location: any, sort : any , order:any) => {
        try {
        const response = await axiosInstance.get(`${baseUrl}/report/deviation?page=${page}&size=${pageSize}&order=${order}&sort=${sort}&status=&area_id=${location}&operator_id=${operatorId}&from_date_time=${fromDate}&to_date_time=${toDate}&equipment_id=${equipmentId}`,
        {
            headers: {
            'x-auth-token': authToken,
            'x-userid': authUserID
            }
        });
        return response.data;
        } catch (error) {
        if (axios.isAxiosError(error)) {
            return error.response?.data
        }
        }
    };

    const batchSummaryReport = async (page : any, pageSize: any, batchCode: any, activityType: any, area: any) => {
        try {
        const response = await axiosInstance.get(`${baseUrl}/plates/list_plate_details_materialized_view?page=${page}&size=${pageSize}&sort=id&order=desc&status=active&keyword=&batch_number=${batchCode}&area_id=${area}&batch_id=&serial_number=&lot_id=&lot_code&master_activities_id=${activityType}&fingerdabbing_user_id=&operator_id=&fingerdabbing_guest_name=`,
        {
            headers: {
            'x-auth-token': authToken,
            'x-userid': authUserID
            }
        });
        return response.data;
        } catch (error) {
        if (axios.isAxiosError(error)) {
            return error.response?.data
        }
        }
    };



    const reportServices= {
        activeSamplingReport,
        passiveSamplingReport,
        personnelMonitoringReport,
        surfaceMonitoringReport,
        equipmentConfigurationReport,
        visualInspectionReport,
        deviationReport,
        plateActivityReport,
        surfaceMonitoringListReport,
        batchSummaryReport
    };
  
    export { reportServices };