import React, { useEffect, useState } from 'react'
import PageHeader from '../../../common/pageHeader'
import HeaderSection from '../../../common/headerSection'
import { Modal } from 'react-bootstrap'
import CustomTable from '../../../../widgets/table'
import { columns } from './partials/columns'
import { TfiClose } from 'react-icons/tfi'
import { useTranslation } from 'react-i18next';
import AlertModal from '../../../../widgets/alertModal'
import CustomToast from '../../../../widgets/toaster'
import Pagination from '../../../../widgets/pagination';
import { userService } from '../../../../service/userService'
import { deviceRegistration } from '../../../../service/deviceRegistration'
import DateRangeModal from './partials/weekOffModal'
import WeekHoliday from './partials/weekHoliday'
import HolidayName from './partials/holidayName'
import moment from 'moment';
import { DEFAULT_PAGE_SIZE } from '../../../../constants/constants'


const HolidayRegister = () => {

  const { t } = useTranslation();


  const [showAddModal, setShowAddModal] = useState(false);
  const [modalName, setModalName] = useState(t('applicationConfiguration.addDeviceRegistration'));
  const [holidayRegister, setHolidayRegister] = useState({
    dropDown: "",
    date: "",
    id: "",
    reason: "",
    description: "",
  });
  const [deviceRegister, setDeviceRegister] = useState([]);
  const [addHolidayAlert, setAddHolidayAlert] = useState(false);
  const [useEffectRequired, setuseEffectRequired] = useState(false)
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  // table pagination for sample type
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  // table pagination for sample Sub type
  interface Errors {
    dropDown?: string,
    date?: string,
    reason?: string
  }

  const [errors, setErrors] = useState<Errors>({});
  const [permission, setPermission] = useState<any>([]);
  const [HolidayOptionsList, setHolidayOptionsList] = useState<Array<any>>([]);
  const currentYear = new Date().getFullYear();
  const minDate = `${currentYear}-01-01`;
  // const maxDate = `${currentYear}-12-31`;
  const [keyWord, setkeyWord] = useState("")
  const [year, setYear] = useState("")
  const [statusChangeFlag, setStatusChangeFlag] = useState(false);
  const [tab, setTab] = useState("1")
  const [statusAlert, setStatusAlert] = useState(false)
  const [updatedStatus, setUpdatedStatus] = useState("")
  const [statusId, setStatusId] = useState("")


  // Render initial api calls 
  useEffect(() => {

    var storedData = localStorage.getItem('userDetails');

    if (storedData) {
      var storedObject = JSON.parse(storedData);
      getPermissionByRole(storedObject[0].role_master_id);
    }

    listHoliday();
    getMasterDropDown();
    setuseEffectRequired(false)
  }, [useEffectRequired])

  // api calls while change in holiday status 
  useEffect(() => {
    getMasterDropDown();
    setStatusChangeFlag(false)
  }, [statusChangeFlag])

  //get permission by role 
  const getPermissionByRole = async (role: number) => {
    try {
      const response = await userService.getPermissionByRole(role);
      if (response.status && response.statusCode === 200) {
        setPermission(response.data.permissions)
      }
    } catch (error) {
      console.error(error);
    }
  }

  //get all_raw_material_type list from api  
  const listHoliday = async () => {
    try {
      const response = await deviceRegistration.listHolidays(page, pageSize, year, keyWord);
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            setTotalPages(response?.totalPages)
            setTotalElements(response?.totalElements)
            let rowData: any = response.data.map((element: any, index: number) => {

              return {
                slno: index + 1,
                name: element.name,
                id: element.id,
                date: element.date,
                holiday_master_id: element.holiday_master_id,
                reason: element.reason,
                status: element.status,
                description: element.description
              };
            });
            setDeviceRegister(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        setDeviceRegister([]);
      }
    } catch (error) {
      console.error('user response', error);
    }
  }

  // input changes in add holiday form
  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setHolidayRegister((prevFormData: any) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  //create raw material type 
  const createHoliday = async () => {
    setAddHolidayAlert(false)
    const dataToSend = {
      holiday_master_id: holidayRegister.dropDown,
      date: holidayRegister.date,
      id: holidayRegister.id,
      reason: holidayRegister.reason,
      description: holidayRegister.description,
      interface_name: "Add Holiday",
    }
    try {
      const response = await deviceRegistration.SubmitHoliday(dataToSend);
      if (response.status && response.statusCode === 200) {
        setToastType("success")
        setToastMessage(response.message)
        addModalCloseEvent()
      } else {
        setToastType("error")
        setToastMessage(response.message)
      }
      setShowToast(true)
      setuseEffectRequired(true)
    } catch (error) {
      console.error('user response', error);
    }
  }

  //handle policy management alert
  const handleAddAlert = () => {
    const errors: Errors = {};
    if (!holidayRegister.dropDown) {
      errors.dropDown = t('applicationConfiguration.errorsHoliday');
    }
    if (!holidayRegister.date) {
      errors.date = t('applicationConfiguration.errorsDate');
    }
    if (!holidayRegister.reason && holidayRegister.id) {
      errors.reason = t('applicationConfiguration.errorsReason');
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      console.log(errors)
      return;
    }
    setAddHolidayAlert(true)
    setErrors({});
  }

  // add holiday submit button click
  const handleSubmit = () => {
    setAddHolidayAlert(false)
    createHoliday()
  }

  // add holiday click
  const addHolidayClick = () => {
    setModalName(t('applicationConfiguration.addHolidayRegistration'))
    setShowAddModal(true)
  }

  // handle modal close
  const addModalCloseEvent = () => {
    setShowAddModal(false)
    setHolidayRegister({
      dropDown: "",
      date: "",
      id: "",
      reason: "",
      description: ""
    })
    setErrors({})
  }

  //handle table pagination 
  const handlePageChange = (newPage: any, pageSize?: number) => {
    setPage(newPage)
    if (pageSize) {
      setPageSize(pageSize)
    }
    setuseEffectRequired(true)
  };

  //handle filter section in sample type
  const handleFilter = (key: string, value: any) => {
    if (key === "Role") {
      // setRole(value)
    } else if (key === "search") {
      setkeyWord(value)
    } else if (key === "selectedYear") {
      setYear(value)
    }
    setuseEffectRequired(true)
  }

  // holiday options drop down listing api
  const getMasterDropDown = async () => {
    try {
      const response = await deviceRegistration.getMasterDropDown();
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                return {
                  slno: index + 1,
                  title: element.name,
                  id: element.id,
                  status: element.status,
                };
              }
            );
            setHolidayOptionsList(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  // holiday edit click
  const handleEditClick = (id: string) => {
    setModalName(t('applicationConfiguration.editHolidayRegistration'))
    const filteredUserDetails: any = deviceRegister.filter((item: any) => item.slno === id);
    const formattedDate = moment(filteredUserDetails[0]?.date, 'DD-MM-YYYY').format('YYYY-MM-DD');
    setHolidayRegister({
      dropDown: filteredUserDetails[0]?.holiday_master_id,
      date: formattedDate,
      id: filteredUserDetails[0]?.id,
      reason: filteredUserDetails[0]?.reason,
      description: filteredUserDetails[0]?.description
    })
    setShowAddModal(true)
  }

  //  function to handle status change
  const handleStatusChange = (policy_id: any, status: string) => {
    setStatusAlert(true)
    setStatusId(policy_id)
    if (status === "active") {
      setUpdatedStatus("inactive")
    } else {
      setUpdatedStatus("active")
    }
  }

  // api to handle status change
  const handleStatusChangeSubmit = async () => {
    try {
      const response = await deviceRegistration.changeYearlyHolidayStatus(statusId, updatedStatus, "Holiday Register Status");

      if (response.status && response.statusCode === 200) {
        setToastType("success")
        setToastMessage(response.message)
      }
      else {
        setToastType("error")
        setToastMessage(response.message)
      }
      setShowToast(true)
      listHoliday();
      setUpdatedStatus("")
      setStatusId("")
      setStatusAlert(false)
    } catch (error) {
      console.error(error);
    }
  }


  return (
    <div>
      <HeaderSection />
      <PageHeader pageHeader={t('applicationConfiguration.holidayRegistration')} />
      <CustomToast
        show={showToast}
        onClose={() => setShowToast(false)}
        delay={5000}
        message={toastMessage}
        toastType={toastType}
      />
      <div className="exceptionHandlingTab">
        <button
          onClick={() => setTab("1")}
          className={tab === "1" ? "colTabBtnSel" : "colTabBtn"}
        >
          {t("applicationConfiguration.holidayName")}
        </button>
        <button
          onClick={() => { setTab("2"); setStatusChangeFlag(true); }}
          className={tab === "2" ? "colTabBtnSel" : "colTabBtn"}
        >
          {t("applicationConfiguration.holidayRegistration")}
        </button>
        <button
          onClick={() => setTab("3")}
          className={tab === "3" ? "colTabBtnSel" : "colTabBtn"}
        >
          {t("applicationConfiguration.weekHoliday")}
        </button>
      </div>


      {tab === "1" && (
        <HolidayName setStatusChangeFlag={setStatusChangeFlag} />
      )}
      {tab === "2" && (
        <div className='me-3' style={{ marginTop: "20px" }}>
          <div className=' mx-3 pe-2' style={{ "height": "45vh" }}>
            <CustomTable
              tableHead={t('applicationConfiguration.holidayRegistration')}
              data={deviceRegister}
              columns={columns}
              isEditable={permission?.includes("edit_holiday")}
              isTempDeletable={false}
              totalElements={totalElements}
              isActionVisible={false}
              isViewVisible={false}
              isSeachable={true}
              isToggleStatus={permission?.includes("edit_holiday")}
              isResetPassword={false}
              addButton={permission?.includes("add_holiday")}
              addButtonText={t('applicationConfiguration.addHolidayRegistration')}
              onUseEffectRequired={() => setuseEffectRequired(true)}
              onFilterClick={handleFilter}
              onAddClick={addHolidayClick}
              changeRoleButton={false}
              onEditClick={handleEditClick}
              onStatusChange={handleStatusChange}
              showYearFilter={true}
            />
          </div>
          <div>
            <Pagination
              page={page}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
            />
          </div>
        </div>
      )}
      {tab === "3" && (
        <WeekHoliday />
      )}

      <Modal
        className="top-right-modal"
        centered={false}
        show={showAddModal}
      >
        <div>
          <div className="modalHead">
            <p className="modalHeadText ms-3 pt-3 p-2">{modalName}</p>
            <div onClick={() => addModalCloseEvent()} className="p-2 mx-3 my-2 ">
              <TfiClose fill="#FFFFFF" />
            </div>
          </div>

          <div>
            <div className="selectWrap">
              <div className="form-group my-1">
                <label htmlFor="">
                  {t('applicationConfiguration.selectHoliday')}{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <br />
                <select
                  id="dropDown"
                  value={holidayRegister.dropDown}
                  name="dropDown"
                  onChange={handleInputChange}
                >
                  <option value="">{t('applicationConfiguration.select')}</option>
                  {HolidayOptionsList.map((obj, index) => (
                    <option key={index} value={obj?.id}>
                      {obj?.title}
                    </option>
                  ))}
                </select>
                {errors.dropDown && <p className="errorText">{errors.dropDown}</p>}
              </div>

              <div className="form-group my-1">
                <label htmlFor="" >{t('common.date')}<span style={{ color: 'red' }}>*</span></label>
                <br />
                <input
                  id="date"
                  value={holidayRegister.date}
                  name="date"
                  type='date'
                  placeholder={t('common.deviceName')}
                  className="placeholder-style"
                  onChange={handleInputChange}
                  maxLength={100}
                  min={new Date().toISOString().split('T')[0]}
                  // max={maxDate}
                  onKeyDown={(e) => {
                    // Allow navigation keys (e.g., Tab, Arrow keys) and Enter to open the date picker
                    const allowedKeys = [
                      "Tab",
                      "Enter",
                      "Escape",
                    ];
                    if (!allowedKeys.includes(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  onPaste={(e) => e.preventDefault()}
                />
                {errors.date && <p className="errorText">{errors.date}</p>}
              </div>
            </div>

            <div className={holidayRegister.id ? "selectWrap" : "selectWrap-email"}>
              <div className="form-group my-1 ">
                <label htmlFor="">
                  {t("areaManagement.description")}{" "}
                </label>
                <br />
                <input
                  id="description"
                  value={holidayRegister.description}
                  name="description"
                  type="text"
                  placeholder={t("areaManagement.description")}
                  onChange={handleInputChange}
                />
                {/* {errors.reason && (
                    <p className="errorText">{errors.reason}</p>
                  )} */}
              </div>
              {/* </div> */}

              {/* <div className="selectWrap"> */}
              {holidayRegister.id && (
                <div className="form-group my-1 ">
                  <label htmlFor="" className="ReasonLabel">
                    {t("userManagement.reason")}{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <br />
                  <input
                    id="reason"
                    value={holidayRegister.reason}
                    name="reason"
                    type="text"
                    placeholder={t("userManagement.reason")}
                    className="reasonClass"
                    onChange={handleInputChange}
                  />
                  {errors.reason && (
                    <p className="errorText">{errors.reason}</p>
                  )}
                </div>
              )}
            </div>

          </div>
          <div className="bottomArea">
            <button onClick={() => addModalCloseEvent()} className="modalCancelBtn">{t('buttons.cancel')}</button>
            <button onClick={() => handleAddAlert()} className="squareOrangeBtn3">{t('buttons.submit')}</button>
          </div>
        </div>

      </Modal>

      {/* Alert modal for Add policy */}
      <AlertModal
        show={addHolidayAlert}
        onHide={() => setAddHolidayAlert(false)}
        title={t('common.alert')}
        message={`${modalName}`}
        onConfirm={handleSubmit}
      />

      <AlertModal
        show={statusAlert}
        onHide={() => setStatusAlert(false)}
        title={t('common.alert')}
        message={t('applicationConfiguration.holidatRegStatusChangeAlert')}
        onConfirm={handleStatusChangeSubmit}
      />

    </div>
  )
}

export default HolidayRegister