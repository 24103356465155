export const columns: TableColumn[] = [
    {
        Header: "tableCommon.sl_no",
        accessor: "slno",
      },
      {
        Header: "applicationConfiguration.tableColumns.deviceId",
        accessor: "device_code",
      },
      {
        Header: "applicationConfiguration.tableColumns.deviceName",
        accessor: "device_name",
      },
      {
        Header: "applicationConfiguration.tableColumns.remarks",
        accessor: "remarks",
      },
      
]


interface TableColumn {
    Header: string;
    accessor: string;
  }