import React, { useState, useEffect, useRef } from "react";

import { BsQrCodeScan } from "react-icons/bs";
import CustomTable from "../../../widgets/table/index";
import HeaderSection from "../../common/headerSection";
import PageHeader from "../../common/pageHeader";
import AddException from "../../common/addException";
import { userService } from "../../../service/plateServices";
import { useSelector, useDispatch } from "react-redux";
import { RootState, setLoading } from "../../../store/store";
import { areaService } from "../../../service/areaServices";
import { FileService } from "../../../service/fileServices";
import moment from "moment";
import CameraApp from "./partials/cameraModal";
import { SerielNumberScanner } from "../common/serielNumberScanner";
import Pagination from "../../../widgets/pagination";
import AlertModal, { EsignModal } from "../../../widgets/alertModal";
import CustomToast from "../../../widgets/toaster";
import { columns } from "./partials/columns";
import { useTranslation } from "react-i18next";
import CustomLoader from "../../../widgets/loader/loader";
import { Col, Container, Form, Row } from "react-bootstrap";
import PlateDetailsViewModel from "../../lab_operator/labReport/models/plateDetailsModel";
import PendingTableLabtest from "./partials/pendingTable";
import { DEFAULT_PAGE_SIZE } from "../../../constants/constants";

const LabTestArea = () => {
  const { t } = useTranslation();
  const [tab, setTab] = useState("1");
  const [show, setShow] = useState(false);
  const [serielScanShow, setSerielScanShow] = useState(false);
  const [scanCode, setScanCode] = useState("");
  const areaId = useSelector((state: RootState) => state.area.areaId);
  const areaName = useSelector((state: RootState) => state.area.areaName);
  const [keyWord, setkeyWord] = useState("");
  const [batchCode, setBatchCode] = useState("");
  const [userId, setUserId] = useState<number>(0);
  const [plateDetails, setPlateDetails] = useState<Array<any>>([]);
  const [plateId, setPlateId] = useState("");
  const [useEffectRequired, setUseEffectRequired] = useState(false);
  const [labAreaName, setLabAreaName] = useState("");
  const [countAuto, setCountAuto] = useState(false);
  const [colonyCount, setColonyCount] = useState("-");
  const [remark, setRemark] = useState("");
  const [growth, setGrowth] = useState("1");
  const [genus, setGenus] = useState("");
  const [species, setSpecies] = useState("");
  const [imageId, setImageId] = useState<string | null>("");
  const [imageIds, setImageIds] = useState<any>([]);
  const [labSubmitModal, setLabSubmitModal] = useState(false);
  const [Modalwarning, setModalwarning] = useState(false);
  const [labTestStartModal, setLabTestStartModal] = useState(false);
  const [labCounter, setLabCounter] = useState("");
  interface Errors {
    colonyCount?: string;
    remarks?: string;
  }
  interface LabAreaPolicyData {
    lab_area_policy_min_range: string;
    lab_area_policy_max_range: string;
  }
  const [errors, setErrors] = useState<Errors>({});
  const [countTypeErrors, setCountTypeErrors] = useState<any>([]);

  // pagination
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [plateDetailsCompleted, setPlateDetailsCompleted] = useState<
    Array<any>
  >([]);
  const [plateBatches, setPlateBatches] = useState([]);
  const [plateSerielNum, setPlateSerielNums] = useState([]);
  const [labAreaPolicyData, setLabAreaPolicyData] = useState<LabAreaPolicyData>(
    {
      lab_area_policy_min_range: "",
      lab_area_policy_max_range: "",
    }
  );
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const [spots, setSpots] = useState<any>([]);
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [capturedImage, setCapturedImage] = useState<any | null>("");
  const [authToken, setAuthToken] = useState("");
  const [authUserID, setAuthUserID] = useState("");
  const [sort, setSort] = useState("id");
  const [order, setOrder] = useState("desc");
  const [plateType, setPlateType] = useState("");
  const [showColCountAlert, setShowColCountAlert] = useState(false);
  const [customPlateResponse, setCustomPlateResponse] = useState("");
  const [showViewMoreBtn, setShowViewMoreBtn] = useState(false);
  const [showView, setShowView] = useState(false);
  const [colonyDetails, setColonyDetails] = useState<any>([]);
  const [debouncedScanCode, setDebouncedScanCode] = useState("");
  const [colonyTypes, setColonyTypes] = useState<any>([]);
  const [modalName, setModalName] = useState("");
  const [showEsignAlert, setShowEsignAlert] = useState(false);

  const dispatch = useDispatch();
  const loading = useSelector((state: RootState) => state.loader.loading);
  const scanAreaRef = useRef<HTMLInputElement>(null);

  //logged in user details
  useEffect(() => {
    var storedData = localStorage.getItem("userDetails");

    if (storedData) {
      var storedObject = JSON.parse(storedData);
      setUserId(storedObject[0].id);
      setAuthToken(storedObject[0].x_auth_token);
      setAuthUserID(storedObject[0].x_userid);
    }
    ListLabTestHistory();
    // ListPlateDetails()
    getAreaByScan(areaId, "");
    setUseEffectRequired(false);
    listPlateBatch();
    listSerialNumber();
    listColonyTypes();
    // getImage(5)
  }, [useEffectRequired]);

  //direct focus on component loading
  useEffect(() => {
    if (scanAreaRef.current) {
      scanAreaRef.current.focus();
    }
  }, []);

  // on colony count change event
  useEffect(() => {
    // Initialize colonyDetails state with the correct length and default values
    setColonyDetails(
      Array.from({ length: parseInt(colonyCount) }).map((_, index) => ({
        colony_order: index + 1,
        type: "",
      }))
    );

    setCountTypeErrors(
      Array.from({ length: parseInt(colonyCount) }).map(() => false)
    );
  }, [colonyCount]);

  //get area details from scan qr
  const getAreaByScan = async (id: any, location_type: any) => {
    try {
      const response = await areaService.getAreaByScan(id, location_type);
      if (response.status && response.statusCode === 200) {
        try {
          setLabAreaName(response.data.lab_area_name);
          if (response.data.count_entry_flag === "1") {
            setCountAuto(false);
          } else if (response.data.count_entry_flag === "0") {
            setCountAuto(true);
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.error("user response", error);
    }
  };

  //get labtest history details
  const ListLabTestHistory = async () => {
    try {
      const response = await userService.ListLabTestHistory(
        page,
        pageSize,
        sort,
        order,
        keyWord,
        areaId,
        batchCode
      );
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            setTotalPages(response?.totalPages);
            setTotalElements(response?.totalElements);
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                const statusNumber = parseInt(element.plate_status, 10);
                const status = userService.getPlateStatus(statusNumber);
                interface Exposure {
                  id: string;
                  start_date_time: string;
                  end_date_time: string;
                  updated_by_first_name: string;
                  updated_by_last_name: string;
                  exposure_area_name: string;
                }
                // Calculate total exposed time
                const exposureDetails: Exposure[] =
                  element.exposure_details || [];
                let totalExposedTime = 0;

                exposureDetails.forEach((exposure) => {
                  const startDate = new Date(exposure.start_date_time);
                  const endDate = new Date(exposure.end_date_time);
                  const timeDifferenceMs =
                    endDate.getTime() - startDate.getTime();
                  totalExposedTime += timeDifferenceMs;
                });
                const totalExposedTimeHours = Math.floor(
                  totalExposedTime / 3600000
                );
                const totalExposedTimeMinutes = Math.floor(
                  (totalExposedTime % 3600000) / 60000
                );
                const totalExposedTimeSeconds = Math.floor(
                  (totalExposedTime % 60000) / 1000
                );

                const fullNames = exposureDetails.map((exposure: Exposure) => {
                  return `${exposure.updated_by_first_name} ${exposure.updated_by_last_name}`;
                });
                const exposure_user = fullNames.join(", ");

                const exposureAreaName = exposureDetails.map(
                  (exposure: Exposure) => {
                    return exposure.exposure_area_name;
                  }
                );

                //caluculate the total incubated time
                interface Incubation {
                  id: string;
                  start_date_time: string;
                  end_date_time: string;
                  created_by_first_name: string;
                  updated_by_first_name: string;
                  updated_by_last_name: string;
                  incubation_area_name: string;
                }
                const incubationDetails: Incubation[] =
                  element.incubation_details || [];
                let totalIncubatedTime = 0;
                incubationDetails?.forEach((incubation) => {
                  const startDate = new Date(incubation.start_date_time);
                  const endDate = incubation.end_date_time
                    ? new Date(incubation.end_date_time)
                    : null;
                  if (endDate) {
                    const timeDifferenceMs =
                      endDate.getTime() - startDate.getTime();
                    totalIncubatedTime += timeDifferenceMs;
                  }
                });
                const totalIncubatedTimeHours = Math.floor(
                  totalIncubatedTime / 3600000
                );
                const totalIncubatedTimeMinutes = Math.floor(
                  (totalIncubatedTime % 3600000) / 60000
                );
                const totalIncubatedTimeSeconds = Math.floor(
                  (totalIncubatedTime % 60000) / 1000
                );

                const fullNamesIncubation = incubationDetails.map(
                  (incubation: Incubation) => {
                    return `${incubation.updated_by_first_name} ${incubation.updated_by_last_name}`;
                  }
                );
                const incubated_user = fullNamesIncubation.join(", ");

                const incubationAreaName = incubationDetails.map(
                  (incubation: Incubation) => {
                    return incubation.incubation_area_name;
                  }
                );

                interface Labtest {
                  id: string;
                  completed_by_user_firstname: string;
                  completed_by_user_lastname: string;
                  completed_date_time: string;
                }
                const labtestDetails: Labtest[] = element.lab_details || [];
                const fullNamesLabtest = labtestDetails.map(
                  (labtest: Labtest) => {
                    return `${labtest.completed_by_user_firstname} ${labtest.completed_by_user_lastname}`;
                  }
                );
                const labtest_user = fullNamesLabtest.join(", ");
                // setPlateId(element.id)
                const labtestCompletedDate = labtestDetails.map(
                  (labtest: Labtest) => {
                    return labtest.completed_date_time;
                  }
                );

                const plateType = parseInt(element.plate_type, 10);
                if (plateType == 1) {
                  var plate_type = "Q A Plate";
                } else if (plateType == 2) {
                  var plate_type = "Exposure Plate";
                } else if (plateType == 3) {
                  var plate_type = "Personnel Monitoring Plate";
                } else {
                  var plate_type = "-";
                }
                // setLabAreaStatus(plate_type);
                return {
                  slno: index + 1,
                  batch_number: element.batch_number,
                  serial_number: element.serial_number,
                  plate_type: plate_type,
                  labTestDoneOn: element.completed_date_time,
                  colonyCount: element.colony_count,
                  testRemarks: element.test_remarks,
                  exposedTime: `${totalExposedTimeHours} : ${totalExposedTimeMinutes} : ${totalExposedTimeSeconds}`,
                  exposedBy: exposure_user,
                  exposureAreaName: exposureAreaName,
                  incubationDuration: `${totalIncubatedTimeHours} : ${totalIncubatedTimeMinutes} : ${totalIncubatedTimeSeconds}`,
                  incubatedBy: incubated_user,
                  incubationAreaName: incubationAreaName,
                  labtestUser: labtest_user,
                  labTestCompletionDateTime: labtestCompletedDate,
                  PlateStatus: status,
                };
              }
            );
            setPlateDetailsCompleted(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        setPlateDetailsCompleted([]);
        setTotalPages(0);
        setTotalElements(0);
      }
    } catch (error) {
      console.error("Plate response", error);
    }
  };

  //handle submit api
  const handleSubmit = () => {
    setShowColCountAlert(false);

    const newErrors: any[] = colonyDetails.map(
      (colony: any) => colony.type === ""
    );
    setCountTypeErrors(newErrors);

    const errors: Errors = {};
    const colonyCountAsNumber = parseInt(colonyCount, 10);

    // Ensure min and max ranges are not empty strings before converting
    if (
      labAreaPolicyData.lab_area_policy_max_range === "" ||
      labAreaPolicyData.lab_area_policy_min_range === ""
    ) {
      setLabTestStartModal(true);
      setErrors({});
      return;
    }

    // Convert ranges to numbers for further comparisons
    const maxRange = Number(labAreaPolicyData.lab_area_policy_max_range);
    const minRange = Number(labAreaPolicyData.lab_area_policy_min_range);

    // Validate the colony count against the lab area policy
    if (colonyCountAsNumber > maxRange) {
      // errors.colonyCount = `* only accept colony count ${colonyRage} `;
    }

    // Validate remarks
    if (!remark) {
      errors.remarks = t("labtestManagement.remarkError");
    }
    if (colonyCount < "1" && growth === "1") {
      errors.colonyCount = t("labtestManagement.colonyCountError");
    }

    const hasTrue = newErrors.some((error) => error === true);

    // Handle modals based on conditions
    if (colonyCountAsNumber > maxRange && remark && !hasTrue) {
      setLabSubmitModal(true);
    } else if (colonyCountAsNumber < minRange && remark && !hasTrue) {
      setModalwarning(true);
    } else if (Object.keys(errors).length > 0 || hasTrue) {
      setErrors(errors);
      return;
    } else {
      setLabTestStartModal(true);
    }

    setErrors({});
  };

  //on changing the type selection dropdown
  const handleTypeSelectChange = (index: number, event: any) => {
    const newColonyDetails = [...colonyDetails];
    newColonyDetails[index].type = event.target.value;
    setColonyDetails(newColonyDetails);
  };

  //on confirming esign in modal
  const handleESignConfirm = async (remarks: string) => {
    startLabTest();
  };

  //start labtest api call
  const startLabTest = async () => {
    const dataToSend = {
      colony_details: colonyDetails,
      user_id: userId,
      plate_ids: plateId,
      location_id: areaId,
      plate_image_id: imageIds.join(","),
      colony_count: colonyCount,
      test_remarks: remark,
      lab_test_counter: labCounter,
      genus_details: genus,
      strain_details: species,
      interface_name: "Start LabTest",
    };

    try {
      const response = await userService.startPlateLabTest(
        dataToSend,
        authToken,
        authUserID
      );
      if (response.status && response.statusCode === 200) {
        setToastType("success");
        setToastMessage(response.message);
        setShowEsignAlert(false);
      } else {
        setToastType("error");
        setToastMessage(response.message);
      }
      setShowToast(true);
      handleClearBtn();
    } catch (error) {
      console.error("user response", error);
    }
  };

  //handle pagination in labtest history
  const handlePageChange = (newPage: any, pageSize?: number) => {
    setPage(newPage);
    if (pageSize) {
      setPageSize(pageSize);
    }
    setUseEffectRequired(true);
  };

  //top bar area name listing
  const data: Record<string, string | number> = {
    [t('operatorManagement.areaName')]: labAreaName,
  };

  //customised function to get the plate status
  const getPlateStatus = (status: number): string => {
    switch (status) {
      case 1:
        return "Plate in Quarantine Status, Details not found";
      case 2:
      case 5:
      case 11:
      case 8:
      case 9:
      case 12:
      case 27:
      case 28:
      case 24:
      case 25:
        return "Activity In Progress, Incubation not completed";
      case 3:
      case 6:
      case 14:
      case 15:
      // case 21:
      case 22:
      case 26:
      case 29:
      case 30:
        return "Plate Lab Test Completed.";
      case 4:
      case 7:
      case 10:
      case 23:
        return "Plate in Rejected Status";
      case 16:
      case 19:
        return "Plate in Exception Status";
      case 17:
        return "Plate in Damaged Status";
      case 18:
        return "Plate in Discarded Status";
      default:
        return "";
    }
  };

  //fetch plate details by serial number in scan plate area
  const handleScanCodeChange = async (e: any) => {
    setScanCode(e);
    setPlateDetails([]);
    if (e.trim() === "") {
      setPlateDetails([]);
      return;
    } else {
      // dispatch(setLoading(true));
      try {
        const response = await userService.listAutoClavePlateBySerielNo(
          e,
          "LabTestArea"
        );
        setPlateType(response?.data?.plate_type);
        const plateStatusInt = parseInt(response?.data?.plate_status, 10);
        setCustomPlateResponse(getPlateStatus(plateStatusInt));
        if (response.status && response.statusCode === 200) {
          // dispatch(setLoading(false));
          try {
            let rowData: any;
            const checkIfSameUser =
              response.data.lab_test_details[0]?.test_done_by_user_id === userId
                ? 1
                : 0;
            if (response?.data && checkIfSameUser === 0) {
              const element = response.data;
              interface Exposure {
                id: string;
                start_date_time: string;
                end_date_time: string;
                updated_by_first_name: string;
                updated_by_last_name: string;
                exposure_area_name: string;
              }
              // Calculate total exposed time
              const exposureDetails: Exposure[] =
                element.exposure_details || [];
              let totalExposedTime = 0;

              exposureDetails.forEach((exposure) => {
                const startDate = new Date(exposure.start_date_time);
                const endDate = new Date(exposure.end_date_time);
                const timeDifferenceMs =
                  endDate.getTime() - startDate.getTime();
                totalExposedTime += timeDifferenceMs;
              });
              const totalExposedTimeHours = Math.floor(
                totalExposedTime / 3600000
              );
              const totalExposedTimeMinutes = Math.floor(
                (totalExposedTime % 3600000) / 60000
              );
              const totalExposedTimeSeconds = Math.floor(
                (totalExposedTime % 60000) / 1000
              );

              const fullNames = exposureDetails.map((exposure: Exposure) => {
                return `${exposure.updated_by_first_name} ${exposure.updated_by_last_name}`;
              });

              //caluculate the total incubated time
              interface Incubation {
                id: string;
                start_date_time: string;
                end_date_time: string;
                created_by_first_name: string;
                updated_by_first_name: string;
                updated_by_last_name: string;
              }
              const incubationDetails: Incubation[] =
                element.incubation_details || [];
              let totalIncubatedTime = 0;
              incubationDetails?.forEach((incubation) => {
                const startDate = new Date(incubation.start_date_time);
                const endDate = incubation.end_date_time
                  ? new Date(incubation.end_date_time)
                  : null;
                if (endDate) {
                  const timeDifferenceMs =
                    endDate.getTime() - startDate.getTime();
                  totalIncubatedTime += timeDifferenceMs;
                }
              });

              const exposure_name = exposureDetails.map(
                (exposure: Exposure) => {
                  return `${exposure.updated_by_first_name} ${exposure.updated_by_last_name}`;
                }
              );
              const exposed_user_name = exposure_name.join(", ");
              setPlateId(element.id);
              setLabCounter(String(Number(element.lab_test_counter) + 1));

              interface Labtest {
                colony_count: string;
                test_done_by_user_id: string;
              }
              // Calculate total exposed time
              const labDetails: Labtest[] = element.lab_test_details || [];

              setLabAreaPolicyData({
                lab_area_policy_min_range: response.data.min_count,
                lab_area_policy_max_range: response.data.max_count,
              });

              if (
                element.plate_status === "13" ||
                element.plate_status === "20" ||
                element.plate_status === "24" ||
                element.plate_status === "25" ||
                element.plate_status === "21"
              ) {
                rowData = {
                  "Plate Serial Num": element.serial_number,
                  "Batch ID": element.batch_number,
                };
                if (response.data.min_count && response.data.max_count) {
                  rowData["Accepted Colony Count"] = `B/W ${
                    response.data.min_count
                  } - ${response.data.max_count}${
                    response.data.uom_name ? ` ${response.data.uom_name}` : ""
                  }`;
                } else {
                  rowData["Accepted Colony Count"] = "-";
                }
              }
            } else if (checkIfSameUser === 1) {
              setToastMessage(
                `You have already conducted the test for the Plate Serial No. ${e}`
              );
              setToastType("error");
              setShowToast(true);
            }
            setPlateDetails(rowData);
            setShowViewMoreBtn(true);
          } catch (error) {
            console.log(error);
          }
        } else {
          setToastMessage(response.message);
          setToastType("error");
          setShowToast(true);
        }
      } catch (error) {
        console.error("Plate response", error);
      }
    }
  };

  //handle filter section
  const handleScheduleFilter = (key: string, value: any) => {
    if (key === "batch_code") {
      setBatchCode(value);
    }
    if (key === "search") {
      setkeyWord(value);
    }
    setUseEffectRequired(true);
  };

  //list batch details drop down
  const listPlateBatch = async () => {
    try {
      const response = await userService.listPlateBatch();
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                return {
                  value: element.batch_number,
                  label: element.batch_number,
                };
              }
            );
            setPlateBatches(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.error("Plate response", error);
    }
  };

  //list serial number details drop down
  const listSerialNumber = async () => {
    try {
      const response = await userService.listSerialNumber();
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                return {
                  value: element.serial_number,
                  label: element.serial_number,
                };
              }
            );
            setPlateSerielNums(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.error("Plate response", error);
    }
  };

  //list colony types
  const listColonyTypes = async () => {
    try {
      const response = await userService.listColonyTypes();
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                return {
                  id: element.id,
                  colony_type_name: element.colony_type_name,
                  colony_type_prefix: element.colony_type_prefix,
                };
              }
            );
            setColonyTypes(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.error("Plate response", error);
    }
  };

  //toaster message after scaning plate
  const handleScanCode = (serielNumber: any) => {
    if (serielNumber) {
      handleScanCodeChange(serielNumber);
      setToastMessage(t('operatorManagement.plateScanSuccess'));
      setToastType("success");
    } else {
      setToastMessage(t('operatorManagement.plateScanFail'));
      setToastType("error");
    }
    setShowToast(true);
  };

  //pass the serial number if it is entered in text area
  const handleScanCodeInput = (result: any) => {
    // Check if the result is not empty
    if (result.trim() !== "") {
      // If the result is in JSON format
      try {
        const parsedResult = JSON.parse(result);
        if (typeof parsedResult === "object") {
          // Extract serial number from the parsed JSON
          const serialNumber = parsedResult.serial_number;
          if (serialNumber) {
            handleScanCodeChange(serialNumber);
            return; // Exit the function after getting plate details
          }
        } else {
        }
      } catch (error) {}
      // If the result is not in JSON format or parsing failed, treat it as a serial number string
      handleScanCodeChange(result);
    } else {
    }
  };

  //handle submit button
  const handleAddAreaSubmit = () => {
    setLabSubmitModal(false);
    setModalwarning(false);
    setLabTestStartModal(false);
    // startLabTest();
    setShowEsignAlert(true);
  };

  //on click cancel button in labtest
  const handleClearBtn = () => {
    handleScanCodeChange("");
    setColonyCount("-");
    setGrowth("1");
    setRemark("");
    setSpecies("");
    setGenus("");
    setLabCounter("");
    setIsCameraOpen(false);
    setSpots([]);
    setCapturedImage(null);
    setImageIds([]);
    setPlateId("");
    setErrors({});
    setTab("1");
    setDebouncedScanCode("");
  };

  // function to sort table
  const handleSort = (key: string, value: boolean) => {
    setSort(key);
    if (value) {
      setOrder("asc");
    } else {
      setOrder("desc");
    }
    setUseEffectRequired(true);
  };

  //on click on handle view button
  const handleViewBtnClick = () => {
    setShowView(true);
  };

  const handleChange = (event: any) => {
    setGrowth(event.target.value);
    if (event.target.value === "2") {
      // handleScanCodeChange("")
      setColonyCount("0");
      // setRemark("")
      setSpecies("");
      setGenus("");
      // setLabCounter("")
      // setIsCameraOpen(false)
      setSpots([]);
      setCapturedImage(null);
      setImageIds([]);
      // setPlateId("")
      setErrors({});
      // setTab("1")
      // setDebouncedScanCode("")
    } else {
      setColonyCount("-");
    }
  };

  useEffect(() => {
    // Set a timer to update debouncedScanCode after 10 seconds
    const timerId = setTimeout(() => {
      setDebouncedScanCode(scanCode);
      setScanCode(scanCode);
    }, 3000);

    // Cleanup the timer if the component is unmounted or scanCode changes
    return () => clearTimeout(timerId);
  }, [scanCode]);

  useEffect(() => {
    if (debouncedScanCode) {
      setTab("2");
      handleScanCodeInput(debouncedScanCode);
    }
  }, [debouncedScanCode]);

  return (
    <div>
      <HeaderSection />

      <PageHeader pageHeader={t("labtestManagement.pageTitle")} />
      <CustomToast
        show={showToast}
        onClose={() => setShowToast(false)}
        delay={5000}
        message={toastMessage}
        toastType={toastType}
      />
      <div className="exceptionHandlingTab">
        <button
          onClick={() => handleClearBtn()}
          className={tab === "1" ? "colTabBtnSel" : "colTabBtn"}
        >
          {t("labtestManagement.pending")}
        </button>
        <button
          onClick={() => setTab("2")}
          className={tab === "2" ? "colTabBtnSel" : "colTabBtn"}
        >
          {t("labtestManagement.test_details")}
        </button>
        <button
          onClick={() => setTab("3")}
          className={tab === "3" ? "colTabBtnSel" : "colTabBtn"}
        >
          {t("labtestManagement.history")}
        </button>
      </div>

      {tab === "1" && (
        <PendingTableLabtest setDebouncedScanCode={setDebouncedScanCode} />
      )}

      {tab === "2" && (
        <>
          {loading ? (
            <CustomLoader />
          ) : (
            <div>
              <div className="scanWrapLg">
                <div className="scanInputWrapLg p-1">
                  <input
                    type="text"
                    ref={scanAreaRef}
                    value={scanCode}
                    onChange={(e) => setScanCode(e.target.value)}
                    placeholder={t("operatorManagement.scanCode")}
                  />
                  {scanCode ? (
                    <p
                      onClick={() => handleClearBtn()}
                      className="scanAddText py-2 me-2 my-0 "
                    >
                      {t("buttons.cancel")}
                    </p>
                  ) : (
                    <div
                      onClick={() => setSerielScanShow(true)}
                      className="scanBg "
                    >
                      <BsQrCodeScan />
                    </div>
                  )}
                </div>
                <div className="pt-3 mx-2">
                  <p
                    onClick={() => setShow(true)}
                    className="exceptionBtn ms-3"
                  >
                    {t("Exception.exception")}
                  </p>
                </div>
                <div
                  className="scannedDetailsWrap ps-3"
                  style={{ marginLeft: "15%" }}
                >
                  <table className="">
                    <tbody>
                      {Object.keys(data).map((key) => (
                        <tr key={key}>
                          <td className="keyText">{key}</td>
                          <td className="px-3">:</td>
                          <td className="valueText">{data[key]}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="row">
                <div className="col-4 ">
                  {plateDetails && plateId ? (
                    <p className="plateDetailHead m-2 ps-3">
                      {t("labtestManagement.plate_details")}
                    </p>
                  ) : (
                    // <p className='plateDetailHead m-2'>{t('labtestManagement.plate_details_found')}</p>
                    <p className="plateDetailHead m-2">
                      <span className="text-danger"> </span>
                      {customPlateResponse}
                    </p>
                  )}
                  <table className="mx-2">
                    <tbody>
                      {plateDetails &&
                        scanCode !== "" &&
                        Object.keys(plateDetails).map((key: any) => (
                          <tr key={key}>
                            <td className="plateDetailText py-1 w-50">{key}</td>
                            <td className="px-3">:</td>
                            <td className="plateDetailBoldText">
                              {plateDetails[key]}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  {showViewMoreBtn &&
                    plateDetails &&
                    scanCode !== "" &&
                    plateId && (
                      <div className="px-2 mt-3">
                        <p
                          onClick={() => handleViewBtnClick()}
                          className="plate-detail-flora-modal"
                        >
                          {t("operatorManagement.viewMore")}
                        </p>
                      </div>
                    )}
                </div>
                <div className="labtestRightWrap col-8 p-2">
                  {plateDetails && plateId && (
                    <div className="my-2">
                      <CameraApp
                        setColonyCount={setColonyCount}
                        colonyCount={colonyCount}
                        setImageId={setImageId}
                        imageIds={imageIds}
                        setImageIds={setImageIds}
                        countAuto={countAuto}
                        setSpots={setSpots}
                        growth={growth}
                        spots={spots}
                        isCameraOpen={isCameraOpen}
                        setIsCameraOpen={setIsCameraOpen}
                        capturedImage={capturedImage}
                        setCapturedImage={setCapturedImage}
                      />
                    </div>
                  )}
                  {plateDetails && plateId && (
                    <div className="labtestInputWrap p-2">
                      <div className="d-flex">
                        <div className="form-check me-2">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault1"
                            value="1"
                            onChange={handleChange}
                            checked={growth === "1"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault1"
                          >
                            {t("labtestManagement.colonyGrowth")}
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault2"
                            onChange={handleChange}
                            value="2"
                            checked={growth === "2"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault2"
                          >
                            {t("labtestManagement.noGrowth")}
                          </label>
                        </div>
                      </div>
                      <div className="d-flex mt-3 ">
                        <div className="colonyCountWrap p-2">
                          <p className={`colonyCountText m-0 `}>
                            {t("labtestManagement.colony_count")}
                          </p>
                          {growth === "1" ? (
                            <div>
                              <input
                                readOnly={countAuto}
                                className={`colonyCount m-0`}
                                type="number"
                                placeholder="-"
                                value={colonyCount}
                                onChange={(e) => {
                                  const inputVal = parseInt(e.target.value);
                                  if (!isNaN(inputVal) && inputVal > 0) {
                                    setColonyCount(inputVal.toString());
                                  }
                                }}
                              />
                            </div>
                          ) : (
                            <div>
                              <input
                                disabled={true}
                                className={`colonyCount m-0`}
                                type="text"
                                value="NG"
                              />
                            </div>
                          )}
                        </div>
                        <div className="w-75 mx-3">
                          <textarea
                            value={remark}
                            onChange={(e) => setRemark(e.target.value)}
                            className="colonyRemarkTextArea"
                            placeholder={t("labtestManagement.remarkPlaceholder")}
                          ></textarea>
                        </div>
                      </div>
                      <div className="d-flex mt-3 ">
                        {/* <div className='w-50 mx-2'>
                                  <textarea value={species} onChange={(e) => (setSpecies(e.target.value))} className="colonyRemarkTextArea" placeholder='Strain'></textarea>
                              </div>
                              <div className='w-50 mx-2'>
                                  <textarea value={genus} onChange={(e) => (setGenus(e.target.value))} className="colonyRemarkTextArea" placeholder='Genus'></textarea>
                              </div> */}
                        {/* <div className='w-15 mx-2'>
                                  <textarea value={textarea1} onChange={(e) => (setTextarea1(e.target.value))} className="colonyRemarkTextArea" placeholder='Field 1'></textarea>
                              </div>
                              <div className='w-15 mx-2'>
                                  <textarea value={textarea2} onChange={(e) => (setTextarea2(e.target.value))} className="colonyRemarkTextArea" placeholder='Field 2'></textarea>
                              </div>
                              <div className='w-15 mx-2'>
                                  <textarea value={textarea3} onChange={(e) => (setTextarea3(e.target.value))} className="colonyRemarkTextArea" placeholder='Field 3'></textarea>
                              </div> */}
                      </div>
                      <div className="" style={{ height: "7vh" }}>
                        {errors.colonyCount && (
                          <p className="errorText p-0 m-0">
                            {errors.colonyCount}
                          </p>
                        )}
                        {errors.remarks && (
                          <p className="errorText p-0 m-0">{errors.remarks}</p>
                        )}
                      </div>

                      {/* <Container> */}
                      {parseInt(colonyCount) >= 1 && (
                        <Row
                          className="me-2"
                          style={{ overflow: "scroll", height: "200px" }}
                        >
                          {Array.from({ length: parseInt(colonyCount) }).map(
                            (_, index) => (
                              <Col
                                xs={12}
                                sm={6}
                                md={4}
                                lg={3}
                                key={index}
                                className="mb-3 py-1"
                              >
                                <Form.Group controlId={`selectField${index}`}>
                                  <Form.Label
                                    style={{
                                      fontSize: "small",
                                      color: "#16425b",
                                    }}
                                  >
                                    {t("labtestManagement.colonyCountType")}
                                    <span
                                      style={{
                                        fontWeight: "blod",
                                        color: "#16425b",
                                      }}
                                      className="mx-1"
                                    >
                                      {index + 1}{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </span>
                                  </Form.Label>
                                  <Form.Control
                                    as="select"
                                    size="sm"
                                    value={colonyDetails[index]?.type}
                                    onChange={(e) =>
                                      handleTypeSelectChange(index, e)
                                    }
                                    isInvalid={countTypeErrors[index]}
                                  >
                                    <option value="">{t("common.select")}</option>
                                    {colonyTypes?.map((type: any) => (
                                      <option key={type.id} value={type.id}>
                                        {type.colony_type_name}
                                      </option>
                                    ))}
                                  </Form.Control>
                                  {countTypeErrors[index] && (
                                    <Form.Control.Feedback type="invalid">
                                      {t("labtestManagement.typeSelect")}
                                    </Form.Control.Feedback>
                                  )}
                                </Form.Group>
                              </Col>
                            )
                          )}
                        </Row>
                      )}
                      {/* </Container> */}

                      {plateDetails && plateId && (
                        <div className="colonyCountBottomSec pe-3 py-1">
                          <button
                            onClick={() => handleClearBtn()}
                            className="modalCancelBtn"
                          >
                            {t("buttons.cancel")}
                          </button>
                          <button
                            onClick={() => handleSubmit()}
                            className="squareOrangeBtn3"
                          >
                            {t("buttons.submit")}
                          </button>
                          {/* <button className=' squareOrangeBtn3Disabled'>Submit</button> */}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      )}

      {tab === "3" && (
        <div>
          <div className=" mx-3 pe-2">
            <CustomTable
              data={plateDetailsCompleted}
              columns={columns}
              isEditable={false}
              totalElements={totalElements}
              isActionVisible={false}
              isViewVisible={false}
              isSeachable={true}
              plateSerialNumber={plateSerielNum}
              plateBatch={plateBatches}
              tableHead={t("labtestManagement.history")}
              onUseEffectRequired={() => setUseEffectRequired(true)}
              onFilterClick={handleScheduleFilter}
              onSortClick={handleSort}
            />
          </div>
          <div>
            <Pagination
              page={page}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
            />
          </div>
        </div>
      )}

      {/* add exception modal */}
      <AddException showModel={show} setShowModel={() => setShow(false)} />

      {/* serial number scanner modal */}
      <SerielNumberScanner
        show={serielScanShow}
        setShow={setSerielScanShow}
        handleScanCode={handleScanCode}
      />

      {/* plate detail view  modal */}
      <PlateDetailsViewModel
        showView={showView}
        setShowView={(value: boolean) => setShowView(value)}
        apiType="plateById"
        plateId={plateId}
        interfaceName="LabtestArea"
      />

      {/*Two factor authentication modal */}
      <EsignModal
        show={showEsignAlert}
        onHide={() => setShowEsignAlert(false)}
        onConfirm={handleESignConfirm}
        needRemraks={false}
      />

      {/*alert modal for count exceeded warning*/}
      <AlertModal
        show={labSubmitModal}
        onHide={() => setLabSubmitModal(false)}
        title={t("common.alert")}
        message={t("labtestManagement.exceededWarning")}
        onConfirm={handleAddAreaSubmit}
      />

      {/*alert modal for count lesser warning*/}
      <AlertModal
        show={Modalwarning}
        onHide={() => setModalwarning(false)}
        title={t("common.alert")}
        message={t("labtestManagement.lesserWarning")}
        onConfirm={handleAddAreaSubmit}
      />

      {/*alert modal for submit lab test*/}
      <AlertModal
        show={labTestStartModal}
        onHide={() => setLabTestStartModal(false)}
        title={t("common.alert")}
        message={t("labtestManagement.lesserWarning")}
        onConfirm={handleAddAreaSubmit}
      />
    </div>
  );
};

export default LabTestArea;
