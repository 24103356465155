import { useEffect, useState } from "react";
import CustomTable from "../../../widgets/table/index";
import HeaderSection from "../../common/headerSection";
import PageHeader from "../../common/pageHeader";
import { userService } from "../../../service/plateServices";
import Pagination from "../../../widgets/pagination";
import { EsignModal } from "../../../widgets/alertModal";
import { columnsNew } from './partials/columns';
import { dashboardServices } from "../../../service/dashboard";
import PlateDetailsViewModel from "./models/plateDetailsModel";
import { scheduleService } from "../../../service/scheduleService";
import { DEFAULT_PAGE_SIZE } from "../../../constants/constants";
import { useTranslation } from "react-i18next";

type LabtestStatType = {
  pending_plates_for_lab_approval: number;
  lab_approved_plates: number;
  escalations: number;
};


const LabReport = () => {

  const { t } = useTranslation();
  
  const currentDate = new Date().toISOString().split('T')[0];
  const [tab, setTab] = useState("1");
  const [showView, setShowView] = useState(false);
  const [historyView, setHistoryView] = useState(false);
  const [plateDetails, setPlateDetails] = useState<Array<any>>([]);
  const [plateDetailsHistory, setPlateDetailsHistory] = useState<Array<any>>([]);
  const [useEffectRequired, setUseEffectRequired] = useState(false);
  const [plateBatches, setPlateBatches] = useState([]);
  const [plateSerielNum, setPlateSerielNums] = useState([]);
  const [keyWord, setkeyWord] = useState("");
  const [historyKeyWord, setHistoryKeyWord] = useState("");
  const [userId, setUserId] = useState<number>(0);
  const [showEsignAlert, setShowEsignAlert] = useState(false)
  const [labtestStat, setLabTestStat] = useState<LabtestStatType | null>(null);
  const [selectedTab, setSelectedTab] = useState<number | null>(null);
  const [plateId, setPlateId] = useState("");
  const [activityType, setActivityType] = useState<Array<any>>([]);
  const [areaList, setAreaList] = useState<Array<any>>([]);
  const [activityTypeId, setActivityTypeId] = useState("");
  const [areaId, setAreaId] = useState("");
  const [batchCode, setBatchCode] = useState("");
  const [fromDate, setFromDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 7))
      .toISOString()
      .split("T")[0]
  );
  const [toDate, setToDate] = useState(currentDate);
  const [activityTypeIdHistory, setActivityTypeIdHistory] = useState("");
  const [areaIdHistory, setAreaIdHistory] = useState("");
  const [batchCodeHistory, setBatchCodeHistory] = useState("");
  const [fromDateHistory, setFromDateHistory] = useState(currentDate);
  const [toDateHistory, setToDateHistory] = useState(currentDate);



  // pagination
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);

  //history pagination
  const [historyPage, setHistoryPage] = useState(1);
  const [historyPageSize, setHistoryPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [historyTotalPages, setHistoryTotalPages] = useState(0);
  const [historyTotalElements, setHistoryTotalElements] = useState(0);
  const [esignParams, setEsignParams] = useState<{ selectedIds: string; status: string } | null>(null);


  // Render initial api calls 
  useEffect (() => {
    var storedData = localStorage.getItem('userDetails');

    if(storedData) {
      var storedObject = JSON.parse(storedData);
      setUserId(storedObject[0].id)
    }
    getLabtestStatuDetails();
    getLabApprovedPlates();
    getLabPlatesHistory();
    listPlateBatch();
    listSerialNumber();
    getActivityTypeList();
    getAreaList();

    setUseEffectRequired(false)
  }, [useEffectRequired]) 

  //get lab report stat details on top 
  const getLabtestStatuDetails = async () => {
    try {
      const response = await dashboardServices.getLabtestStatuDetails(); 
        if(response.status && response.statusCode === 200){
          setLabTestStat({
  
            pending_plates_for_lab_approval: response.data.pending_plates_for_lab_approval,
            lab_approved_plates : response.data.lab_approved_plates,
            escalations : response.data.escalations,
            });
      }
    } catch (error) {
      console.error(error);
    }
    };
  
  // on click request table 
  const handleViewClick = (id: string, viewValue: boolean) => {
    const updatedData = plateDetails.filter((row) => row.slno === id);
    setPlateId(updatedData[0]?.plate_id)
    setShowView(viewValue);
  };

  // on click history table 
  const handleHistoryView = (id: string, viewValue: boolean) => {
    const updatedData = plateDetailsHistory?.filter((row) => row.slno === id);
    setPlateId(updatedData[0]?.plate_id)
    setHistoryView(viewValue);
  };

  // get Activity Type List
    const getActivityTypeList = async () => {
      try {
      const response = await scheduleService.getActivityTypeList();
      if (response.status && response.statusCode === 200) {
          try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
              let rowData: any = response.data.map(
              (element: any, index: number) => {
                  return {
                  slno: index + 1,
                  id: element.id,
                  name: element.name,
                  };
              }
              );
              setActivityType(rowData);
          }
          } catch (error) {
          console.log(error);
          }
      } else {
          setActivityType([]);
      }
      } catch (error) {
      console.error("user response", error);
      }
    };

  //api call to get area list
  const getAreaList = async () => {
    try {
      const response = await scheduleService.getAreaList("id", "desc", "6");
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                return {
                  id: element.id,
                  name: element.name,
                  grade_name: element.grade_name,
                };
              }
            );
            setAreaList(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        setAreaList([]);
      }
    } catch (error) {
      console.error("user response", error);
    }
  };

  //to get request main table listing 
  const getLabApprovedPlates = async () => {
      try {
        const response = await userService.getLabApprovedPlates(page, pageSize, keyWord, activityTypeId, areaId, batchCode, fromDate, toDate);
        if(response.status && response.statusCode === 200){
          try {
              if ( Array.isArray(response.data) && response.data?.length > 0) {
                setTotalPages(response?.totalPages)
                setTotalElements(response?.totalElements)
                let rowData: any = [];
                let emptySpace = " "

                response.data.map((element: any, indexMain: number) => {
                  if (element.colony_data.length > 0) {
                    element.colony_data.forEach((detail: any, index: number) => {
                      rowData.push({
                        slno: index === 0 ? indexMain + 1 : emptySpace,
                        id : index === 0 ? element.id : emptySpace,
                        plate_id: index === 0 ? element.plate_id : emptySpace,
                        area_name: index === 0 ? element.area_name : emptySpace,
                        location: index === 0 ? element.location_name : emptySpace,
                        activity: index === 0 ? element.activity_type : emptySpace,
                        test_tube_serial_no: index === 0 ? element.test_tube_serial_number : emptySpace,
                        serial_number: index === 0 ? element.serial_number : emptySpace,
                        batch_code: index === 0 ? element.batch_number : emptySpace,
                        lot_code: index === 0 ? element.lot_code : emptySpace,
                        equipment_code: index === 0 ? element.equipment_code : emptySpace,
                        sampling_started_by: index === 0 ? element.activity_started_by : emptySpace,
                        sampling_started_date: index === 0 ? element.activity_started_date : emptySpace,
                        sampling_ended_by: index === 0 ? element.activity_ended_by : emptySpace,
                        sampling_ended_date: index === 0 ? element.activity_ended_date : emptySpace,
                        sampling_duration: index === 0 ? element.activity_duration : emptySpace,
                        monitored_user: index === 0 ? element.monitored_user : emptySpace,
                        monitored_location: index === 0 ? element.monitored_location : emptySpace,
                        first_incubation_start_by: index === 0 ? element.incubation_started_by : emptySpace,
                        first_incubation_start_date: index === 0 ? element.incubation_start_date : emptySpace,
                        first_incubation_end_by: index === 0 ? element.incubation_end_by : emptySpace,
                        first_incubation_end_date: index === 0 ? element.incubation_end_date : emptySpace,
                        first_incubation_duration: index === 0 ? element.incubation_duration : emptySpace,
                        second_incubation_start_by: index === 0 ? element.incubation2_started_by : emptySpace,
                        second_incubation_start_date: index === 0 ? element.incubation2_start_date : emptySpace,
                        second_incubation_end_by: index === 0 ? element.incubation2_end_by : emptySpace,
                        second_incubation_end_date: index === 0 ? element.incubation2_end_date : emptySpace,
                        second_incubation_duration: index === 0 ? element.incubation2_duration : emptySpace,
                        observer1_name: index === 0 ? element.observer1_name : emptySpace,
                        count1: index === 0 ? element.count1 : emptySpace,
                        observer2_name: index === 0 ? element.observer2_name : emptySpace,
                        count2: index === 0 ? element.count2 : emptySpace,
                        observer3_name: index === 0 ? element.observer3_name : emptySpace,
                        count3: index === 0 ? element.count3 : emptySpace,
                        colony_type: detail.colony_type_name,
                        organism_type: detail.organism_type,
                        genus: detail.genus_name,
                        species: detail.species_name,
                        strain: detail.strain_name,
                        plate_status: index === 0 ? element.status : emptySpace,
                        approve_id: index === 0 ? element.approver_firstname + element.approver_lastname : emptySpace,
                      });
                    });
                  } else {
                    rowData.push({
                      slno: indexMain + 1, 
                      id : element.id,
                      plate_id: element.plate_id,
                      area_name: element.area_name,
                      location: element.location_name,
                      activity: element.activity_type,
                      test_tube_serial_no: element.test_tube_serial_number,
                      serial_number: element.serial_number,
                      batch_code: element.batch_number,
                      lot_code: element.lot_code,
                      equipment_code: element.equipment_code,
                      sampling_started_by: element.activity_started_by,
                      sampling_started_date: element.activity_started_date,
                      sampling_ended_by: element.activity_ended_by,
                      sampling_ended_date: element.activity_ended_date,
                      sampling_duration: element.activity_duration,
                      monitored_user: element.monitored_user,
                      monitored_location: element.monitored_location,
                      first_incubation_start_by: element.incubation_started_by,
                      first_incubation_start_date: element.incubation_start_date,
                      first_incubation_end_by: element.incubation_end_by,
                      first_incubation_end_date: element.incubation_end_date,
                      first_incubation_duration: element.incubation_duration,
                      second_incubation_start_by: element.incubation2_started_by,
                      second_incubation_start_date: element.incubation2_start_date,
                      second_incubation_end_by: element.incubation2_end_by,
                      second_incubation_end_date: element.incubation2_end_date,
                      second_incubation_duration: element.incubation2_duration,
                      observer1_name: element.observer1_name,
                      count1: element.count1,
                      observer2_name: element.observer2_name,
                      count2: element.count2,
                      observer3_name: element.observer3_name,
                      count3: element.count3,
                      colony_type: element.colony_type_name,
                      organism_type: element.organism_type,
                      genus: element.genus_name,
                      species: element.species_name,
                      strain: element.strain_name,
                      plate_status: element.status,
                      approve_id: element.approver_firstname + element.approver_lastname 
                    });
                  }


                });
                setPlateDetails(rowData);
              }
            } catch (error) {
              console.log(error); 
            }
        } else {
          setPlateDetails([])
          setTotalPages(0)
          setTotalElements(0)
        }
      } catch (error) {
        console.error('Plate response', error);
      }
    };

  //to get history table listing 
  const getLabPlatesHistory = async () => {
      try {
        const response = await userService.getLabPlatesHistory(historyPage, historyPageSize, historyKeyWord, activityTypeIdHistory, areaIdHistory, batchCodeHistory, fromDateHistory, toDateHistory);
        if(response.status && response.statusCode === 200){
          try {
              if ( Array.isArray(response.data) && response.data?.length > 0) {
                setHistoryTotalPages(response?.totalPages)
                setHistoryTotalElements(response?.totalElements)
                let rowData: any = [];
                let emptySpace = " "
               
                response.data.map((element: any, indexMain: number) => {
                     
                  if (element.colony_data.length > 0) {
                    element.colony_data.forEach((detail: any, index: number) => {
                      rowData.push({
                        slno: index === 0 ? indexMain + 1 : emptySpace,
                        plate_id: index === 0 ? element.plate_id : emptySpace,
                        area_name: index === 0 ? element.area_name : emptySpace,
                        location: index === 0 ? element.location_name : emptySpace,
                        activity: index === 0 ? element.activity_type : emptySpace,
                        test_tube_serial_no: index === 0 ? element.test_tube_serial_number : emptySpace,
                        serial_number: index === 0 ? element.serial_number : emptySpace,
                        batch_code: index === 0 ? element.batch_number : emptySpace,
                        lot_code: index === 0 ? element.lot_code : emptySpace,
                        equipment_code: index === 0 ? element.equipment_code : emptySpace,
                        sampling_started_by: index === 0 ? element.activity_started_by : emptySpace,
                        sampling_started_date: index === 0 ? element.activity_started_date : emptySpace,
                        sampling_ended_by: index === 0 ? element.activity_ended_by : emptySpace,
                        sampling_ended_date: index === 0 ? element.activity_ended_date : emptySpace,
                        sampling_duration: index === 0 ? element.activity_duration : emptySpace,
                        monitored_user: index === 0 ? element.monitored_user : emptySpace,
                        monitored_location: index === 0 ? element.monitored_location : emptySpace,
                        first_incubation_start_by: index === 0 ? element.incubation_started_by : emptySpace,
                        first_incubation_start_date: index === 0 ? element.incubation_start_date : emptySpace,
                        first_incubation_end_by: index === 0 ? element.incubation_end_by : emptySpace,
                        first_incubation_end_date: index === 0 ? element.incubation_end_date : emptySpace,
                        first_incubation_duration: index === 0 ? element.incubation_duration : emptySpace,
                        second_incubation_start_by: index === 0 ? element.incubation2_started_by : emptySpace,
                        second_incubation_start_date: index === 0 ? element.incubation2_start_date : emptySpace,
                        second_incubation_end_by: index === 0 ? element.incubation2_end_by : emptySpace,
                        second_incubation_end_date: index === 0 ? element.incubation2_end_date : emptySpace,
                        second_incubation_duration: index === 0 ? element.incubation2_duration : emptySpace,
                        observer1_name: index === 0 ? element.observer1_name : emptySpace,
                        count1: index === 0 ? element.count1 : emptySpace,
                        observer2_name: index === 0 ? element.observer2_name : emptySpace,
                        count2: index === 0 ? element.count2 : emptySpace,
                        observer3_name: index === 0 ? element.observer3_name : emptySpace,
                        count3: index === 0 ? element.count3 : emptySpace,
                        colony_type: detail.colony_type_name,
                        organism_type: detail.organism_type,
                        genus: detail.genus_name,
                        species: detail.species_name,
                        strain: detail.strain_name,
                        plate_status: index === 0 ? element.status : emptySpace,
                        approve_id: index === 0 ? element.approver_firstname + element.approver_lastname : emptySpace,
                      });
                    });
                  } else {
                    rowData.push({
                      slno: indexMain + 1, 
                      plate_id: element.plate_id,
                      area_name: element.area_name,
                      location: element.location_name,
                      activity: element.activity_type,
                      test_tube_serial_no: element.test_tube_serial_number,
                      serial_number: element.serial_number,
                      batch_code: element.batch_number,
                      lot_code: element.lot_code,
                      equipment_code: element.equipment_code,
                      sampling_started_by: element.activity_started_by,
                      sampling_started_date: element.activity_started_date,
                      sampling_ended_by: element.activity_ended_by,
                      sampling_ended_date: element.activity_ended_date,
                      sampling_duration: element.activity_duration,
                      monitored_user: element.monitored_user,
                      monitored_location: element.monitored_location,
                      first_incubation_start_by: element.incubation_started_by,
                      first_incubation_start_date: element.incubation_start_date,
                      first_incubation_end_by: element.incubation_end_by,
                      first_incubation_end_date: element.incubation_end_date,
                      first_incubation_duration: element.incubation_duration,
                      second_incubation_start_by: element.incubation2_started_by,
                      second_incubation_start_date: element.incubation2_start_date,
                      second_incubation_end_by: element.incubation2_end_by,
                      second_incubation_end_date: element.incubation2_end_date,
                      second_incubation_duration: element.incubation2_duration,
                      observer1_name: element.observer1_name,
                      count1: element.count1,
                      observer2_name: element.observer2_name,
                      count2: element.count2,
                      observer3_name: element.observer3_name,
                      count3: element.count3,
                      colony_type: element.colony_type_name,
                      organism_type: element.organism_type,
                      genus: element.genus_name,
                      species: element.species_name,
                      strain: element.strain_name,
                      plate_status: element.status,
                      approve_id:element.approver_firstname + element.approver_lastname 
                    });
                  }

                });
                setPlateDetailsHistory(rowData);
              }
            } catch (error) {
              console.log(error); 
            }
        } else {
          setPlateDetailsHistory([])
          setHistoryTotalPages(0)
          setHistoryTotalElements(0)
        }
      } catch (error) {
        console.error('Plate response', error);
      }
    };

  //request table pagination 
  const handlePageChange = (newPage : any, pageSize?: number) => {
    setPage(newPage);
    if(pageSize) {
      setPageSize(pageSize)
    }
    setUseEffectRequired(true)
  };

  //history table pagination 
  const handleHistoryPageChange = (newPage : any, pageSize?: number) => {
    setHistoryPage(newPage);
    if(pageSize) {
      setHistoryPageSize(pageSize)
    }
    setUseEffectRequired(true)
  };

  //history table filter options 
  const handleFilter = (key: string, value: any) => {
    if (key === "search") {
      setkeyWord(value)
    } else if (key === "activityType") {
      setActivityTypeId(value)
    } else if (key === "FromDate") {
      setFromDate(value)
      setToDate("")
    } else if (key === "ToDate") {
      setToDate(value)
    } else if (key === "area") {
      setAreaId(value)
    } else if (key === "batch_code") {
      setBatchCode(value)
    }

    setUseEffectRequired(true)
  }

  //handle history table filter options 
  const handleHistoryFilter = (key: string, value: any) => {
    if (key === "search") {
      setHistoryKeyWord(value)
    } else if (key === "activityType") {
      setActivityTypeIdHistory(value)
    } else if (key === "FromDate") {
      setFromDateHistory(value)
      setToDateHistory("")
    } else if (key === "ToDate") {
      setToDateHistory(value)
    } else if (key === "area") {
      setAreaIdHistory(value)
    } else if (key === "batch_code") {
      setBatchCodeHistory(value)
    }
    setUseEffectRequired(true)
  }

  //list plate batch api for filter
  const listPlateBatch = async () => {
    try {
      const response = await userService.listPlateBatch();
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              let rowData: any = response.data.map((element: any, index: number) => {
  
                return { 
                    value: element.batch_number,
                    label: element.batch_number ,
                };
              });
              setPlateBatches(rowData);
            }
          } catch (error) {
            console.log(error); 
          }
      }
    } catch (error) {
      console.error('Plate response', error);
    }
  }

  //list serial number api for filter 
  const listSerialNumber = async () => {
    try {
      const response = await userService.listSerialNumber();
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              let rowData: any = response.data.map((element: any, index: number) => {
  
                return { 
                    value: element.serial_number,
                    label: element.serial_number ,
                };
              });
              setPlateSerielNums(rowData);
            }
          } catch (error) {
            console.log(error); 
          }
      }
    } catch (error) {
      console.error('Plate response', error);
    }
  }

  //on click on approve button in request 
  const handleApproveBtn = (selectedRows : any[]) => {
    const filteredPlateDetails = plateDetails.filter((item) => selectedRows.includes(item.slno));
    const selectedIds = filteredPlateDetails
        .filter((item) => item.id.trim() !== '')
        .map((item) => item.id)
        .join(',');
    setShowEsignAlert(true)
    const status = "22"
    setEsignParams({ selectedIds, status });
  }

  //on click on reject button in history 
  const handleRejectBtn = (selectedRows : any[]) => {
    const filteredPlateDetails = plateDetails.filter((item) => selectedRows.includes(item.slno));
    const selectedIds = filteredPlateDetails
        .filter((item) => item.id.trim() !== '')
        .map((item) => item.id)
        .join(',');
    const status = "23"
    setShowEsignAlert(true)
    setEsignParams({ selectedIds, status });
  }

  //on password confirmation modal trigger 
  const handleESignConfirm = async (remarks: string) => {
    if (!esignParams) return;
    const { selectedIds, status } = esignParams;
    await changeLabReportStatus(selectedIds, status, remarks);
  };
  
  //on trigger approve or reject button function call 
  const changeLabReportStatus = async (selectedIds: any, status : any, approverRemarks: string) => {

    const dataToSend = {
        lab_test_ids : selectedIds,
        user_id : userId,
        lab_test_status : status,
        approver_remarks : approverRemarks,
        interface_name : "Lab Report"
    }

    try {
      const response = await userService.changeLabReportStatus(dataToSend);
      if(response.status && response.statusCode === 200){
      }
    } catch (error) {
      console.error('Plate response', error);
    }
    setkeyWord("")
    setShowEsignAlert(false)
    setEsignParams(null);
    setUseEffectRequired(true)
  }

  //on box click 
  const handleBoxClick = (value : number) => {
    setSelectedTab(value)
  };

  return (
    <div>
      <HeaderSection />
      <PageHeader pageHeader={t("labreport.pageTitle")} />
      <div className="container-lab">      
              <div className="box-display" style={{ backgroundColor: selectedTab === 1 ? '#e0e0e0' : '' }} onClick={() => handleBoxClick(1)}>
                    <h3 className='lab-dash-head'>{t("labreport.pendingPlates")}</h3>
                    <p className='lab-dash-value' style={{color : "#CBB613"}} >{labtestStat?.pending_plates_for_lab_approval}</p>
              </div>
              <div className="box-display" style={{ backgroundColor: selectedTab === 2 ? '#e0e0e0' : '' }} onClick={() => handleBoxClick(2)}>
                    <h3 className='lab-dash-head'>{t("labreport.approvedPlates")}</h3>
                    <p className='lab-dash-value'style={{color : "#148E04"}} >{labtestStat?.lab_approved_plates}</p>
              </div>
      </div>
       
      <div className='exceptionHandlingTab'>
        <button
          onClick={() => setTab("1")}
          className={tab === "1" ? "selectedNavBtn" : "navBtn"}
        >
          {t("labreport.requests")}
        </button>
        <button
          onClick={() => setTab("2")}
          className={tab === "2" ? "selectedNavBtn" : "navBtn"}
        >
          {t("labreport.history")}
        </button>
      </div>
      

      {/* lab report request */}
      {tab === "1" && (
        <div>    
          <div className=" mx-3 pe-2">
            <CustomTable
              data={plateDetails}
              columns={columnsNew}
              isEditable={false}
              totalElements={totalElements}
              isActionVisible={true}
              isViewVisible={true}
              onViewClick={handleViewClick}
              onFilterClick={handleFilter}
              approveButton={true}
              approveButtonText={t("buttons.approve")}
              rejectButton={true}
              rejectButtonText={t("buttons.reject")}
              tableHead={t("labreport.approvals")}
              isSeachable={true}
              plateBatch={plateBatches}
              plateSerialNumber={plateSerielNum}
              onStartClick={handleApproveBtn}
              onEndClick={handleRejectBtn}
              ActivityType={activityType}
              areaDropList={areaList}
              showToDateLabApproval={true}
              showFromDateLabApproval={true}
              dateType='date'
              showFullFilters={true}
              showLotCodeFilter={false}
              showSerielNoFilter={false}
            />
          </div>
          <div>
            <Pagination
              page={page}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
            />
          </div>

          <EsignModal 
            show={showEsignAlert}
            onHide={() => setShowEsignAlert(false)}
            onConfirm={handleESignConfirm}
            needRemraks={true}
           />

          <PlateDetailsViewModel 
            showView={showView} 
            setShowView={(value: boolean) => setShowView(value)}
            apiType='plateById'
            plateId={plateId}
          />
        </div>
      )}

      {/* lab report history */}
      {tab === "2" && (
        <div>
          <div className=" mx-3 pe-2">
            <CustomTable
              data={plateDetailsHistory}
              columns={columnsNew}
              isEditable={false}
              totalElements={historyTotalElements}
              isActionVisible={false}
              isViewVisible={true}
              onViewClick={handleHistoryView}
              tableHead={t("labreport.pageTitle")}
              onFilterClick={handleHistoryFilter}
              isSeachable={true}
              plateBatch={plateBatches}
              plateSerialNumber={plateSerielNum}
              ActivityType={activityType}
              areaDropList={areaList}
              showToDateFilter={true}
              showFromDateFilter={true}
              dateType='date'
              showFullFilters={true}
              showLotCodeFilter={false}
              showSerielNoFilter={false}
            />
          </div>
          <div>
            <Pagination
              page={historyPage}
              totalPages={historyTotalPages}
              handlePageChange={handleHistoryPageChange}
            />
          </div>

          <PlateDetailsViewModel 
            showView={historyView} 
            setShowView={(value: boolean) => setHistoryView(value)}
            apiType='plateById'
            plateId={plateId}
          />
      </div>
      )}
    </div>
  );
};


export default LabReport;



