import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { TfiClose } from 'react-icons/tfi';
import QrReader from 'react-qr-reader';


interface SerielNumberProps {
  show: boolean;
  handleScanCode: (serielNumber: any) => void;
  setShow: any;
}

export const SerielNumberScanner: React.FC<SerielNumberProps> = ({
  show,
  setShow,
  handleScanCode,
}) => {

  const { t } = useTranslation();

  const [webcamResult, setWebCamResult] = useState<string | null>(null);
  const [stream, setStream] = useState(false);
  const [videoDimensions, setVideoDimensions] = useState<{
    width: number;
    height: number;
  } | null>(null);

  //handle api call when page load
  useEffect(() => {
    if (show) {
      handleModalOpen();
    }
  }, [show])

  // handle webcam error
  const webCamError = (error: any) => {
    console.log(error);
  };

  // handle web cam scan
  const webCamScan = (result: any) => {
    if (result) {
      const scanData = JSON.parse(result);
      setWebCamResult(result);
      setShow(false);
      closeCamera();
      handleScanCode(scanData?.serial_number);
    }
  };

  // close web cam
  const closeCamera = () => {
    // Access the MediaStream from the video element
    const videoElement = document.querySelector('video');
    const mediaStream = videoElement?.srcObject;

    if (mediaStream instanceof MediaStream) {
      // Get the tracks and stop them if the mediaStream exists
      const tracks = mediaStream.getTracks();

      tracks.forEach((track) => track.stop());

      // Close the camera
      setStream(false);
    } else {
      console.error("Video element or media stream not found.");
    }
  };

  // open QR reader modal
  const handleModalOpen = () => {
    setWebCamResult("");
    openCamera();
  };

  // open web cam
  const openCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
      });
      const track = stream.getVideoTracks()[0];

      if (track) {
        const settings = track.getSettings();
        const width = settings.width || 0;
        const height = settings.height || 0;
        setVideoDimensions({ width, height });
        setStream(true);
      } else {
        console.error("Video track not found.");
      }
    } catch (error) {
      console.error(error);
    }
  };


  return (
    <Modal show={show} >
      <div className="scanModal">
        <div className="modalHead">
          <p className="modalHeadText2 mx-3 my-2">{t("operatorManagement.scan")}</p>
          <div onClick={() => setShow(false)} className="p-2 mx-2 ">
            <TfiClose fill="#FFFFFF" />
          </div>
        </div>

        <QrReader
          className="scan-web"
          delay={300}
          onError={webCamError}
          onScan={webCamScan}
          facingMode={"user"}
        />
      </div>
    </Modal>
  )
}