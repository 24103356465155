import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ReactBigCalendar from '../../../../../widgets/calendar/bigCalendar';
import Select from "react-select";
import { scheduleService } from '../../../../../service/scheduleService';
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Views } from "react-big-calendar";



interface CustomTableProps {
    setTab: (value: string) => void;
}

const ViewSchedule: React.FC<CustomTableProps> = ({
    setTab,
}) => {

    const { t } = useTranslation();

    const [usersList, setUsersList] = useState<Array<any>>([]);
    const [view, setView] = useState<"day" | "week" | "month">(Views.MONTH);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [area, setArea] = useState("");
    const [user, setUser] = useState("");
    const [areaList, setAreaList] = useState<Array<any>>([]);



    useEffect(() => {

        getMasterUserList();
        getAreaList();

    }, []);




    // getMasterUserList()
    const getMasterUserList = async () => {
        try {
        const response = await scheduleService.getMasterUserList();
        if (response.status && response.statusCode === 200) {
            try {
            if (Array.isArray(response.data) && response.data?.length > 0) {
                let rowData: any = response.data.map(
                (element: any, index: number) => {
                    return {
                    slno: index + 1,
                    id: element.id,
                    name: (
                        (element?.first_name).trim() +
                        " " +
                        (element?.last_name).trim()
                    ).trim(),
                    };
                }
                );
                setUsersList(rowData);
            }
            } catch (error) {
            console.log(error);
            }
        } else {
            setUsersList([]);
        }
        } catch (error) {
        console.error("user response", error);
        }
    };

     //api call to get area list
    const getAreaList = async () => {
    try {
        const response = await scheduleService.getAreaList("id", "desc", "6");
        if (response.status && response.statusCode === 200) {
        try {
            if (Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map(
                (element: any, index: number) => {
                return {
                    id: element.id,
                    name: element.name,
                    grade_name: element.grade_name,
                };
                }
            );
            setAreaList(rowData);
            }
        } catch (error) {
            console.log(error);
        }
        } else {
        setAreaList([]);
        }
    } catch (error) {
        console.error("user response", error);
    }
    };

    const handleDatePickerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newDate = new Date(e.target.value);
        if (!isNaN(newDate.getTime())) {
             setSelectedDate(newDate);
          }
      };


    //handle onchange input QR fields
    const handleCalenderChange = async (e: any) => {
        const { name, value } = e.target;
        if (name === "area") {
            setArea(value)
        }
        if (name === "user") {
            setUser(value)
        }
    };




  return (
    <div>
        <nav aria-label="breadcrumb" className="breadcrumb-container row">
            <div className="col-10 d-flex">
            <ol className="breadcrumb">
                <li onClick={() => setTab("1")} className="breadcrumb-item dashboard-hover" style={{cursor: "pointer"}}>
                    {t("scheduleProduction.pageTitle")}
                </li>
                <li className="breadcrumb-item active" aria-current="page" style={{cursor: "no-drop"}}>
                    {t("scheduleProduction.productionCalendar")}
                </li>
            </ol>
            </div>
            <div className="col-2 d-flex"></div>
        </nav>

        <div className="d-flex justify-content-end">
            <div className="custom-date-picker-wrapper" style={{"maxHeight": "10vh"}}>
                <label htmlFor="custom-date-picker" className="custom-date-picker-label">
                Pick a date:
                </label>
                <input
                className="custom-date-picker-input"
                type={view === "month" ? "month" : "date"}
                id="custom-date-picker"
                value={
                    view === "month"
                    ? `${selectedDate.getFullYear()}-${String(
                        selectedDate.getMonth() + 1
                        ).padStart(2, "0")}`
                    : selectedDate.toISOString().split("T")[0]
                }
                onChange={handleDatePickerChange}
                />
            </div>
          <div style={{ zIndex: "10" }} className="form-group mx-2 my-3">
              <label> Production Area</label>
              <br />
              <select id="area" name="area" className="areaTypeSelect me-2" onChange={handleCalenderChange}>
                <option value="">Select</option>
                {areaList?.map((val) => (
                  <option key={val.id} value={val.id}>
                    {val.name}
                  </option>
                ))}
              </select>
          </div>
          <div style={{ zIndex: "10" }} className="form-group mx-2 my-3">
              <label>Operator</label>
              <br />
              <select id="user" name="user" className="areaTypeSelect me-2" onChange={handleCalenderChange}>
                <option value="">Select</option>
                {usersList.map((val) => (
                    <option key={val.id} value={val.id}>
                      {val.name}
                    </option>
                ))}
              </select>
          </div>
        </div>

        <div className='p-2 m-2'>
            <ReactBigCalendar 
                view={view}
                setView={setView}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                user={user}
                area={area}
            />
        </div>
    </div>
  )
}

export default ViewSchedule;