import React, { useEffect, useRef, useState } from 'react'
import HeaderSection from '../../common/headerSection'
import PageHeader from '../../common/pageHeader'
import CustomToast from '../../../widgets/toaster'
import { useTranslation } from 'react-i18next'
import { BsQrCodeScan } from 'react-icons/bs'
import CustomTable from '../../../widgets/table'
import { columns, columnsModalTable } from './partials/columns'
import Pagination from '../../../widgets/pagination'
import { TfiClose } from 'react-icons/tfi'
import { Modal } from 'react-bootstrap'
import { visualInspection } from '../../../service/visualinspection'
import { userService as plateRelatedService } from '../../../service/plateServices';
import AlertModal from '../../../widgets/alertModal'
import { CiCircleRemove } from 'react-icons/ci'
import { IoSearchSharp } from 'react-icons/io5'
import { userService } from '../../../service/userService'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import { DEFAULT_PAGE_SIZE } from '../../../constants/constants'




const VisualInspectionTest = () => {

    const { t } = useTranslation();
    const scanAreaRef = useRef<HTMLInputElement>(null);

    const serielNumbers : any = useSelector((state: RootState) => state.visualInspection.serielNumbers);

    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("success");
    const [toastType, setToastType] = useState("success");
    const [tab, setTab] = useState("1");
    const [scanCode, setScanCode] = useState("");
    const [plateDetails, setPlateDetails] = useState<Array<any>>([]);
    const [useEffectRequired, setUseEffectRequired] = useState(false);
    const [plateBySerielNo, setPlateBySerielNo] = useState<Array<any>>([]);
    const [show, setShow] = useState(false);
    const [plateBatches, setPlateBatches] = useState([]);
    const [plateSerielNum, setPlateSerielNums] = useState([]);
    const [plateLotCode, setPlateLotCode] = useState([]);
    const [plateStatus, setPlateStatus] = useState<any | null>("");
    const [userId, setUserId] = useState<number>(0);
    const [keyWord, setkeyWord] = useState("");
    const [showVisualInspectionTestAlert, setShowVisualInspectionTestAlert] = useState(false);
    const [batchCode, setBatchCode] = useState("");
    const [lotCode, setLotCode] = useState("");
    const [inspectionReasons, setInspectionReasons] = useState([]);
    const [selectedSerielNo, setSelectedSerielNo] = useState("");
    const [sort, setSort] = useState("id");
    const [order, setOrder] = useState("desc");
    const [selectedBatchCode, setSelectedBatchCode] = useState("");

    const [formData, setFormData] = useState({
      plate_inspection_status: '',
      reason: '',
      remarks: '',
    });

    // pagination
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const [totalPages, setTotalPages] = useState(0);
    const [totalElements, setTotalElements] = useState(0);

    interface Errors {
      reason?: string;
      plateDetails?: string;
    }
    const [errors, setErrors] = useState<Errors>({});
    const [permission, setPermission] = useState<any>([]);


    //api call for visualinspection plate listing 
    const listVisualInspectionPlates = async () => {
      try {
        const response = await visualInspection.listVisualInspectionPlates(page, pageSize, "passed,failed", keyWord, batchCode, lotCode, sort, order);
        if(response.status && response.statusCode === 200){
          try {
              if ( Array.isArray(response.data) && response.data?.length > 0) {
                setTotalPages(response?.totalPages)
                setTotalElements(response?.totalElements)
                let rowData: any = response.data.map((element: any, index: number) => {
                  return { 
                      id : element.id,
                      slno: index + 1,
                      batch_number: element.batch_no,
                      lot_code: element.lot_code,
                      total_plates : element.plate_count,
                      passed_plates : element.passed_plate_count,
                      failed_plates : element.failed_plate_count,
                      operator_name : element.inspected_by_name,
                      operator_id : element.inspected_by,
                      operator_code : element.inspected_by_user_id,
                      date : element.inspected_on,
                      status : element.inspection_status,
                      reason : element.reason,
                      remarks : element.remarks
                  };
                });
                setPlateDetails(rowData);
              }
            } catch (error) {
              console.log(error); 
            }
        } else {
          setPlateDetails([])
        }
      } catch (error) {
        console.error('Plate response', error);
      }
    };

    const getPermissionByRole = async (role : number) => {
      try {
        const response = await userService.getPermissionByRole(role);
        if(response.status && response.statusCode === 200){
          setPermission(response.data.permissions)
          // try {
          //     if ( Array.isArray(response.data) && response.data?.length > 0) {
          //       let rowData: any = response.data.map((element: any, index: number) => {
  
          //         return { 
          //           // slno: index + 1, 
          //           label: element.title,
          //           value : element.id,
          //           // status : element.status,
          //         };
          //       });
          //       // setUserRoleList(rowData);
          //     }
          //   } catch (error) {
          //     console.log(error);
          //   }
        }
      } catch (error) {
        console.error(error);
      }
    }

    //batch list for filter dropdown 
    const listPlateBatch = async () => {
      try {
        const response = await plateRelatedService.listPlateBatch();
        if(response.status && response.statusCode === 200){
          try {
              if ( Array.isArray(response.data) && response.data?.length > 0) {
                let rowData: any = response.data.map((element: any, index: number) => {

                  return { 
                      value: element.batch_number,
                      label: element.batch_number ,
                  };
                });
                setPlateBatches(rowData);
              }
            } catch (error) {
              console.log(error); 
            }
        }
      } catch (error) {
        console.error('Plate response', error);
      }
    }

    //serial number listing for table drop down 
    const listSerialNumber = async (batchCode: any) => {
      try {
        const response = await visualInspection.listFilterLots(batchCode);
        if(response.status && response.statusCode === 200){
          try {
              if ( Array.isArray(response.data) && response.data?.length > 0) {
                let rowData: any = response.data.map((element: any, index: number) => {

                  return { 
                      value: element.id,
                      label: element.lot_code ,
                  };
                });

                rowData.unshift({
                  value: "1",
                  label: "Select all",
                });

                setPlateSerielNums(rowData);
              }
            } catch (error) {
              console.log(error); 
            }
        }
      } catch (error) {
        console.error('Plate response', error);
      }
    }

    //serial number listing for table drop down 
    const listLotCode = async (batchCode: any) => {
      try {
        const response = await visualInspection.listFilterLots(batchCode);
        if(response.status && response.statusCode === 200){
          try {
              if ( Array.isArray(response.data) && response.data?.length > 0) {
                let rowData: any = response.data.map((element: any, index: number) => {

                  return { 
                      value: element.lot_code,
                      label: element.lot_code ,
                  };
                });

                setPlateLotCode(rowData)
              }
            } catch (error) {
              console.log(error); 
            }
        }
      } catch (error) {
        console.error('Plate response', error);
      }
    }

    //serial number listing for table drop down 
    const listInspectionReasons = async () => {
      try {
        const response = await visualInspection.listInspectionReasons();
        console.log(response.data, "data-1212")
        if(response.status && response.statusCode === 200){
          try {
              if ( Array.isArray(response.data) && response.data?.length > 0) {
                let rowData: any = response.data.map((element: any, index: number) => {

                  return { 
                      id: element.id,
                      reason: element.reasons,
                      status: element.status,
                  };
                });
                setInspectionReasons(rowData);
              }
            } catch (error) {
              console.log(error); 
            }
        }
      } catch (error) {
        console.error('Plate response', error);
      }
    }

    // function to sort table
    const handleSort = (key: string, value: boolean) => {
      setSort(key);
      if (value) {
        setOrder("asc");
      } else {
        setOrder("desc");
      }
      setUseEffectRequired(true);
    };

    //handle page change in pagination 
    const handlePageChange = (newPage : any, pageSize?: number) => {
        setPage(newPage);
        if(pageSize) {
          setPageSize(pageSize)
        }
        setUseEffectRequired(true)
    };

    //pass the obtained id to the api to get plate details lot id
    const handleScanSerielNo = (result: any) => {
        console.log('Original Result:', result);
        if (result.trim() !== "") {
          setScanCode(result);
          try {
            const parsedResult = JSON.parse(result);
            console.log('Parsed Result:', parsedResult);
      
            if (typeof parsedResult === 'object') {
              let lotId;
      
              if (parsedResult.hasOwnProperty('id')) {
                lotId = parsedResult.id;
              } else {
               
                console.log('No serial number property found in the parsed result.');
              }
              if (lotId) {
                getPlateDetailsByLotId(lotId)
              }
            } else {
              console.log('Parsed result is not an object.');    
            }
          } catch (error) {
            // getPlateDetailsByLotId(result);
            console.error('Error parsing JSON:', error);
          }
        }
      };

    //get plate details by lot id
    const getPlateDetailsByLotId = async (slNo : string) => {
      let process = "test"
      if(slNo !== ""){
        try {
          const response = await visualInspection.getPlateDetailsByLotId(slNo);
          if(response.status && response.statusCode === 200){
            try {
                if ( Array.isArray(response.data) && response.data?.length > 0) {
                  let rowData: any = response.data.map((element: any, index: number) => {
                    return { 
                      slno: 1,
                      plateSerielId: element.id,
                      batch_number: element.batch_no,
                      lot_code: element.lot_code,
                      plate_count: element.plate_count,
                      lot_pass_count: 0
                    };
                  });
                // if (response.data && typeof response.data === 'object') {
                //   let rowData = [{
                //       slno: 1,
                //       plateSerielId: response.data.id,
                //       batch_number: response.data.batch_no,
                //       lot_code: response.data.lot_code,
                //       plate_count: response.data.plate_count,
                //       lot_pass_count: 0
                //   }];
                  // setPlateBySerielNo(rowData);

                  const startingSlno = plateBySerielNo.length + 1;
                  rowData = rowData.map((item : any, index: number) => ({
                      ...item,
                      slno: startingSlno + index
                  }));

                  const uniqueUpdatedDataWithSlno = rowData.filter((newElement : any) => {
                    return !plateBySerielNo.some((existingElement) => existingElement.plateSerielId === newElement.plateSerielId);
                  });

                  setPlateBySerielNo((prevUserRoleList) => [...prevUserRoleList, ...uniqueUpdatedDataWithSlno]);
                }
              } catch (error) {
                console.log(error); 
              }
          } else {
            // setPlateBySerielNo([]);
            setToastMessage(response.message);
            setToastType("error")
            setShowToast(true)
          }
        } catch (error) {
          console.error('Plate response', error);
        }
      } else {
      console.log("no slno")
      }
    }

    //get plate details by lot id
    const getPlateDetailsByLotCode = async (loteCode : string, batchCode : string, lotId?: string) => {
      let process = "test"
      let newLotId = lotId
      if(loteCode !== "" || lotId !== "" && batchCode !== ""){
        if (lotId === "1") {
          newLotId = ""
        }
        try {
          const response = await visualInspection.getPlateDetailsByVisualInspectionLotCode(loteCode, batchCode, newLotId || "");
          if(response.status && response.statusCode === 200){
            try {
                if ( Array.isArray(response.data) && response.data?.length > 0) {
                  let rowData: any = response.data.map((element: any, index: number) => {
                    return { 
                      slno: 1,
                      plateSerielId: element.id,
                      batch_number: element.batch_no,
                      lot_code: element.lot_code,
                      plate_count: element.plate_count,
                      lot_pass_count: 0,
                      failed_plates: element.plate_count,
                      remark: "",
                    };
                  });
                // if (response.data && typeof response.data === 'object') {
                //   let rowData = [{
                //       slno: 1,
                //       plateSerielId: response.data[0].id,
                //       batch_number: response.data[0].batch_no,
                //       lot_code: response.data[0].lot_code,
                //       plate_count: response[0].data.plate_count,
                //       lot_pass_count: 0
                //   }];
                  // setPlateBySerielNo(rowData);

                  const startingSlno = plateBySerielNo.length + 1;
                  rowData = rowData.map((item : any, index:number) => ({
                      ...item,
                      slno: startingSlno + index
                  }));

                  const uniqueUpdatedDataWithSlno = rowData.filter((newElement : any) => {
                    return !plateBySerielNo.some((existingElement) => existingElement.plateSerielId === newElement.plateSerielId);
                  });

                  setPlateBySerielNo((prevUserRoleList) => [...prevUserRoleList, ...uniqueUpdatedDataWithSlno]);
                }
              } catch (error) {
                console.log(error); 
              }
          } else {
            // setPlateBySerielNo([]);
            setToastMessage(response.message);
            setToastType("error")
            setShowToast(true)
          }
        } catch (error) {
          console.error('Plate response', error);
        }
      } else {
      console.log("no slno")
      }
    }

    // visual inspection listing filter change
    const handleVisualInspectionFilter = (key: string, value: string) => {
        if(key === "status") {
          setPlateStatus(value)
        } else if (key === "batch_code") {
          if(value === "") {
            setPlateLotCode([]);
          } else {
            listLotCode(value);
          }
          setBatchCode(value)
          listLotCode(value);
        } else if (key === "lot_code") {
          setLotCode(value)
        }
        setUseEffectRequired(true)
    }

    // visual inspection test submit api
    const handleVisualTestSubmit = async () => {
      setShowVisualInspectionTestAlert(false)

      const generateLotDetails = (plates : any) => {
        return plates.map((plate : any) => ({
            lot_id: plate.plateSerielId,
            passed_plates: plate.lot_pass_count.toString(),
            remarks: plate.remark,
            reason_id: plate.reason,
          }));
      };

      const lot_details = generateLotDetails(plateBySerielNo);

      const dataToSend = {
        lot_details,
        // "plate_inspection_status": formData.plate_inspection_status,
        // "reason_id": formData.reason,  
        // "remarks": formData.remarks,
      }


      try {
        const response = await visualInspection.saveLotVisualInspection(dataToSend);
        if (response.status && response.statusCode === 200) {
          setToastMessage(response.message)
          setToastType("success")
          setUseEffectRequired(true)
          visualInspectionClose()
        } else {
          setToastMessage(response.message)
          setToastType("error")
        }
        setShowToast(true)
        // visualInspectionClose()
      } catch (error) {
        console.error("user response", error);
      }
    }

    //handle plate alert 
    const handleVisualInspectionTestAlert = () => {
      const errors: Errors = {};

      if (plateBySerielNo.length < 1) {
        errors.plateDetails = t("visualInspectionTest.scanAreaError");
      }
      
      if (Object.keys(errors).length > 0) {
        setErrors(errors);
        return;
      }
      setShowVisualInspectionTestAlert(true)
      setErrors({});
    }

    // visual inspection test model close
    const visualInspectionClose = () => {
      setShow(false)
      setScanCode("")
      setPlateBySerielNo([])
      setFormData({
        plate_inspection_status: '',
        reason: '',
        remarks: '',
      })
      setErrors({})
      listPlateBatch();
      setPlateSerielNums([]);
      // listSerialNumber("");
    }

    //function to remove scanned plate from visual inspection test table
    const handleRemoveClick = (rowId: string) => {
      // const startingSlno = plateBySerielNo.length + 1;
      // setPlateBySerielNo((prevState: any) => prevState.filter((plate: any )=> plate.slno !== rowId));

      setPlateBySerielNo((prevState: any) => {
        // Filter out the plate with the specified rowId
        const filteredPlates = prevState.filter((plate: any) => plate.slno !== rowId);
    
        // Update the slno of the remaining plates
        const updatedPlates = filteredPlates.map((plate: any, index: number) => ({
          ...plate,
          slno: index + 1
        }));
    
        return updatedPlates;
      });

    }

    const handleCountChange = (slno : any, value : any) => {
      const updatedPlateBySerielNo = plateBySerielNo.map(plate => {
        let newFailedPlateCount = plate.plate_count - value
        if (plate.slno === slno) {
            return { 
              ...plate, 
              lot_pass_count: value, 
              failed_plates: newFailedPlateCount
            };
        }
        return plate;
    });

    setPlateBySerielNo(updatedPlateBySerielNo);
    }

    const handleRemarkChange = (slno : any, value : any, label : string) => {
      const updatedPlateBySerielNo = plateBySerielNo.map(plate => {
        if (plate.slno === slno) {
            return { ...plate, [label]: value };
        }
        return plate;
    });
    setPlateBySerielNo(updatedPlateBySerielNo);
    }

    const handleVisualInspectionModalFilter = (key: string, value: string) => {
      if(key === "status") {
        // setPlateStatus(value)
      } else if (key === "batch_code") {
        if(value === "") {
          setPlateSerielNums([]);
        } else {
          listSerialNumber(value);
          setSelectedBatchCode(value);  
        }
      } else if (key === "lot_code") {
        getPlateDetailsByLotCode("", selectedBatchCode, value)
      }
    }

    const handleAddClick = () => {
      setShow(true);
      listPlateBatch();
      setPlateSerielNums([]);
      // listSerialNumber("");
    }


    useEffect(() => {
        if (show === true && scanAreaRef.current) {
          scanAreaRef.current.focus();
        }
      }, [show])

    useEffect(() => {
      if (serielNumbers?.length > 0) {
        const lotIds = serielNumbers.map((obj : any) => obj.id)
        .join(",");
        setShow(true)
        getPlateDetailsByLotCode("", serielNumbers[0].batch_no, lotIds)
      }
    }, [serielNumbers])

    useEffect(() => {
        // const data = generateSampleData(25);
        // setPlateDetails(data);
        var storedData = localStorage.getItem('userDetails');

        if(storedData) {
          var storedObject = JSON.parse(storedData);
          setUserId(storedObject[0].id)
          getPermissionByRole(storedObject[0].role_master_id);
        }

        listVisualInspectionPlates();
        listPlateBatch();
        // listSerialNumber(batchCode);
        listInspectionReasons();

        setUseEffectRequired(false);

      }, [useEffectRequired]);

  

  return (
    <div>
        <HeaderSection />
        <PageHeader pageHeader={t("visualInspectionTest.visualInspectionTest")} />
        <CustomToast
                show={showToast}
                onClose={() => setShowToast(false)}
                delay={5000}
                message={toastMessage}
                toastType={toastType}
            /> 

      {tab === "1" && (
            <div>
                <div className=' mx-3 pe-2' style={{"height":"35vh"}}>
                        <CustomTable  
                            data={plateDetails} 
                            columns={columns} 
                            isEditable={false} 
                            totalElements={totalElements}  
                            isActionVisible={false} 
                            isViewVisible={false} 
                            removable={false}
                            isSeachable={true}
                            tableHead={t("visualInspectionTest.visualInspectionTest")}
                            addButton={permission?.includes("add_visual_inspection")}
                            addButtonText={t("visualInspectionTest.visualInspectionTest")}
                            onAddClick={handleAddClick}
                            plateBatch={plateBatches}
                            plateSerialNumber={plateSerielNum}
                            showLotCodeFilter={true}
                            lotCodeNumber={plateLotCode}
                            onFilterClick={handleVisualInspectionFilter}
                            onSortClick={handleSort}
                        />
                    </div>
                    <div>
                      <Pagination
                        page={page}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                      />
                    </div>

            </div>
      )}

<Modal
      className="top-right-modal"
      centered={false}
      show={show}
      size="lg"
    >
      <div>
        <div className="modalHead">
          <p className="modalHeadText ms-3 pt-3 p-2" >{t("visualInspectionTest.visualInspectionTest")}</p>
          <div onClick={() => visualInspectionClose()} className="p-2 mx-3 my-2 ">
            <TfiClose fill="#FFFFFF" />
          </div>
        </div>
        <div>

        <div className='d-flex'>
        <div className="scanWrap p-3" style={{"width": "45%"}}>
          <label style={{"display": "flex"}}>{t("visualInspectionTest.scan")}
            <span style={{ color: "red" }}> *</span>
            </label>
          <div className="scanInputWrap p-2">
            <input
              onChange={(e) => handleScanSerielNo(e.target.value)}
              placeholder={t("visualInspectionTest.scanOnly")}
              value={scanCode}
              ref={scanAreaRef}
            />
            {scanCode && (
                <CiCircleRemove 
                  onClick={() => setScanCode("")} 
                  fontSize={30} 
                  color="red"
                  className="mx-2 my-1"
                  style={{"cursor": "pointer"}}
                />
              )}

            <div className="scanBg ">
              <BsQrCodeScan />
            </div>
          </div>
          {errors.plateDetails && (
            <p className="errorText m-0 p-0">{errors.plateDetails}</p>
          )}
        </div>

        <div className="scanWrap p-3" style={{"width": "55%"}}>
                <label style={{"display": "flex"}}>{t("visualInspectionTest.search")} <span style={{ color: "red" }}> *</span></label>
                <div className="scanInputWrap p-2">
                <input
                    onChange={(e) => setSelectedSerielNo(e.target.value)}
                    placeholder={t("visualInspectionTest.type&Search")}
                />
                < IoSearchSharp 
                    fontSize={25} 
                    className='mt-1' 
                    color='grey'
                    style={{"cursor":"pointer"}} 
                    onClick={() => getPlateDetailsByLotCode(selectedSerielNo, "")}
                />
                </div>
            </div>
        </div>

        <div className=' mx-3 pe-2' style={{"height":"45vh"}}>
                        <CustomTable  
                            data={plateBySerielNo} 
                            columns={columnsModalTable} 
                            isEditable={false} 
                            totalElements={plateBySerielNo.length}  
                            isActionVisible={false} 
                            isViewVisible={false} 
                            removable={true}
                            showReasonField={true}
                            showRemarkField={true}
                            isSeachable={false}
                            tableHead={t("visualInspectionTest.visualInspectionTest")}
                            plateBatch={plateBatches}
                            // plateSerialNumber={plateSerielNum}
                            lotCodeNumber={plateSerielNum}
                            // areaListNumber={areaTypeNum}
                            // onStartClick={handleStartClick}
                            // onEndClick={handleEndClick}
                            onFilterClick={handleVisualInspectionModalFilter}
                            onRemoveClick={handleRemoveClick}
                            onCountChange={handleCountChange}
                            onRemarkChange={handleRemarkChange}
                            inspectionReasons={inspectionReasons}
                            // onSortClick={handleSort}
                        />
                    </div>

                    <div style={{visibility:"hidden"}}>
                      <Pagination
                        page={page}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                      />
                    </div>
       
        <div className="bottomArea">
          <button
            onClick={() => visualInspectionClose()}
            className="modalCancelBtn"
          >
            {t("buttons.cancel")}
          </button>
          <button
            onClick={() => handleVisualInspectionTestAlert()}
            className="squareOrangeBtn3"
          >
            {t("buttons.submit")}
          </button>
          {/* <button className=' squareOrangeBtn3Disabled'>Submit</button> */}
        </div>
      </div>
      </div>
    </Modal>

    {/* <PlateDetailsViewModel 
      showView={show} 
      setShowView={(value: boolean) => setShow(value)}
      plateId='52'
    /> */}


     {/*Alert for Bulk upload */}
     <AlertModal 
          show={showVisualInspectionTestAlert}
          onHide={() => setShowVisualInspectionTestAlert(false)}
          title='Alert'
          message={t("visualInspectionTest.visualInspectionTestAlert")}
          onConfirm={handleVisualTestSubmit}
        />
    </div>
  )
}

export default VisualInspectionTest;