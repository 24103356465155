import axios from "axios";
import baseUrl from "..";
import { handleLogOut } from "../../utils/oathUtils";
let authToken = ""
let authUserID = ""

const storedData = localStorage.getItem('userDetails');

if (storedData) {
  try {
    const storedObject = JSON.parse(storedData);
    if (storedObject && Array.isArray(storedObject) && storedObject.length > 0) {
      authToken = storedObject[0].x_auth_token
      authUserID = storedObject[0].x_userid
    }
  } catch (error) {
    console.error('Failed to parse user details:', error);
  }
}

  // Create an Axios instance
  const axiosInstance = axios.create({
    baseURL: baseUrl
  });

  // Add a response interceptor
  axiosInstance.interceptors.response.use(
    response => {
      // If the response is successful, just return the response
      return response;
    },
    error => {
      // Check for 400 or 401 status codes
      if (axios.isAxiosError(error) && (error.response?.status === 408 || error.response?.status === 401)) {
        // Redirect to login page
        handleLogOut()
      }
      // Return any other errors
      return Promise.reject(error);
    }
  );

const getExposureStatus = async (formattedDate: string) => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/dashboard/exposure_stat?filter_date=${formattedDate}`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
}

const getExposureAreaNames = async () => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/location/list_all?sort=id&size=0&order=asc&status=active&keyword=&type=6`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
}

const getExposurePlateDetails = async (exposure_area : number, page: number, pageSize: number) => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/dashboard/exposure_points_stat?page=${page}&size=${pageSize}&sort=id&order=asc&status=active&keyword=&filter_date=&exposure_area_id=${exposure_area}`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
}

const getExposureStatuDetails = async () => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/dashboard/exposure_stat?filter_date=`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
}

const getPlateView = async (
  id: any,
) => {
  try {
    const response = await axiosInstance.post(`${baseUrl}/scan/location` ,{
      id: id,
      location_type: 11,
    },
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
}

//incubation api call starts here 

const getIncubationStatus = async (formattedDate: string) => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/dashboard/incubation_stat?filter_date=${formattedDate}`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
}

const getIncubationStatDetails = async () => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/dashboard/incubation_stat?filter_date=`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
}

const getIncubationAreaNames = async () => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/location/list_area_equipments?sort=id&order=asc&size=0&status=active&keyword=&type=12`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
}

const getIncubationPlateDetails = async (page: any, pageSize: any) => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/dashboard/incubator_stat_by_area?page=${page}&size${pageSize}=&sort=id&order=asc&status=active&keyword=&filter_date=&incubation_area_id=12`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
}

const ListIncubatorPlates =  async (page :number, size :number, current_location_id:any) => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/plates/list_plate_details?page=${page}&size=${size}&sort=id&order=asc&status=active&plate_status=12,13&keyword=&current_location_id=${current_location_id}&location=`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
}

const scanPlateById = async (dataToSend :any) => {
  try {
    const response = await axiosInstance.post(`${baseUrl}/scan/plate`, dataToSend,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
}

//lab test api call starts here 
const getLabtestStatus = async (formattedDate: string) => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/dashboard/lab_stat?filter_date=${formattedDate}`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
}

const getLabtestStatuDetails = async () => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/dashboard/lab_stat?filter_date=`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
}

const ListLabtestPlates =  async (page :number, size :number) => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/plates/list_plate_details?page=${page}&size=${size}&sort=id&order=asc&status=active&plate_status=13,20,21,22&keyword=&current_location_id=&location=`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
}

//Auto clave api call starts here 
const getAutoclaveStatus = async (formattedDate: string) => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/dashboard/autoclave_stat?filter_date=${formattedDate}`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
}

const getAutoclaveStat = async () => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/dashboard/autoclave_stat?filter_date=`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
}

const getAutoclaveAreaNames = async () => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/location/list_area_equipments?sort=id&size=0&order=asc&status=active&keyword=&type=9`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
}

const ListAutoclavePlates =  async (current_location_id:any) => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/plates/list_plate_details?page=1&size=10&sort=id&order=asc&status=active&plate_status=14,15&keyword=&current_location_id=${current_location_id}&location=`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
}

//missed schedule api call 
const getMissedScheduleDetails = async ( page: number, pageSize: number,sort :string, order : string) => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/schedules/missed?page=${page}&size=${pageSize}&sort=${sort}&order=${order}&location_id=&date=`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    return error;
  }
}

//missed schedule api call 
const getUpcomingScheduleDetails = async ( page: number, pageSize: number,sort :string, order : string) => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/schedules/upcoming?page=${page}&size=${pageSize}&sort=${sort}&order=${order}&location_id=&date=`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    return error;
  }
}

//missed schedule api call 
const getScheduleDetails = async () => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/schedules/upcoming?page=1&size=10&sort=id&order=asc&location_id=&date=`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    return error;
  }
}

//api call for Exposure console inner page table listing 
const getExposedInprogress = async ( page: number, pageSize: number,sort :string, order : string) => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/dashboard/list_exposure_inprogress_plates?page=${page}&size=${pageSize}&sort=${sort}&order=${order}`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    return error;
  }
}

const getReadyForCollectionDetails = async ( page: number, pageSize: number,sort :string, order : string) => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/dashboard/list_exposure_ready_collection_plates?page=${page}&size=${pageSize}&sort=${sort}&order=${order}`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    return error;
  }
}

const getOverduePlatesDetails = async ( page: number, pageSize: number,sort :string, order : string) => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/dashboard/list_exposure_overdue_plates?page=${page}&size=${pageSize}&sort=${sort}&order=${order}`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    return error;
  }
}


const getPlateInExposureDetails = async ( page: number, pageSize: number,sort :string, order : string) => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/dashboard/list_exposed_plates?page=${page}&size=${pageSize}&sort=${sort}&order=${order}`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    return error;
  }
}

//api call for Incubation console inner page table listing 
const getPlateInIncubationDetails = async ( page: number, pageSize: number,sort :string, order : string) => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/dashboard/list_incubation_inprogress_plates?page=${page}&size=${pageSize}&sort=${sort}&order=${order}`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    return error;
  }
}


const IncubationCollectionDetails = async ( page: number, pageSize: number, sort :string, order : string) => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/dashboard/list_incubation_ready_collection_plates?page=${page}&size=${pageSize}&sort=${sort}&order=${order}`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    return error;
  }
}


const getIncubationOverduePlatesDetails = async ( page: number, pageSize: number , sort :string, order : string) => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/dashboard/list_incubation_overdue_plates?page=${page}&size=${pageSize}&sort=${sort}&order=${order}`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    return error;
  }
}


//api call for descrepency area 
const getDescrepancyStatus = async (formattedDate: string) => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/dashboard/discrepancies_stat?filter_date=${formattedDate}`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
}

//api call for inventory stat 
const getInventoryStatus = async (formattedDate: string) => {
  try {
    // const response = await axiosInstance.get(`${baseUrl}/dashboard/inventory_stat?filter_date=${formattedDate}`,
    const response = await axiosInstance.get(`${baseUrl}/reconciliation/batches_total_stat?sort=id&order=asc`,

      {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
}


const getDescrepencyDetails = async ( page: number, pageSize: number, status:number, x_auth_token: string, x_user_id: string,sort :string, order : string) => {
  try {
    const headers = {
      'x-auth-token': x_auth_token,
      'x-userid': x_user_id,
    };
    const response = await axiosInstance.get(`${baseUrl}/dashboard/list_discrepancie?page=${page}&size=${pageSize}&sort=${sort}&order=${order}&exception_status=${status}&batch_number=&serial_number=&requested_by=&keyword=&reason_id`, { headers });
    return response.data;
  } catch (error) {
    return error;
  }
}


//api for autoclave inner page listing 
const getAutoclaveInprogressPageView = async ( page: number, pageSize: number ,sort :string, order : string) => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/dashboard/list_autoclave_inprogress_plates?page=${page}&size=${pageSize}&sort=${sort}&order=${order}`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    return error;
  }
}



const getAutoclaveCompletedPageView = async ( page: number, pageSize: number, sort :string, order : string) => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/dashboard/list_autoclave_completed_plates?page=${page}&size=${pageSize}&sort=${sort}&order=${order}`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    return error;
  }
}


//api for labtest inner page details 
const ListPlateForFlora =  async (page :number, pageSize :number, sort :string, order : string) => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/dashboard/list_ready_for_env_flora_plates?page=${page}&size=${pageSize}&sort=${sort}&order=${order}`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
}


const ListFirstLabtest =  async (page :number, pageSize :number, sort :string, order : string) => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/dashboard/list_first_lab_plates?page=${page}&size=${pageSize}&sort=${sort}&order=${order}`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
}


const ListSecondLabtest =  async (page :number, pageSize :number, sort :string, order : string) => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/dashboard/list_second_lab_plates?page=${page}&size=${pageSize}&sort=${sort}&order=${order}`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
}


const ListThirdLabtest =  async (page :number, pageSize :number, sort :string, order : string) => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/dashboard/list_third_lab_plates?page=${page}&size=${pageSize}&sort=${sort}&order=${order}`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
}



const ListReviewLabtest =  async (page :number, pageSize :number, sort :string, order : string) => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/dashboard/list_lab_reviewed_plates?page=${page}&size=${pageSize}&sort=${sort}&order=${order}`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
}


const ListLabtestWithinRange =  async (page :number, pageSize :number, sort :string, order : string) => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/dashboard/list_within_range_plates?page=${page}&size=${pageSize}&sort=${sort}&order=${order}`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
}


const ListLabtestOutsideRange =  async (page :number, pageSize :number, sort :string, order : string) => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/dashboard/list_outside_range_plates?page=${page}&size=${pageSize}&sort=${sort}&order=${order}`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data
    }
  }
}

const getArrowCount = async () => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/dashboard/process_stat`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    console.log(response, "getArrowCount--")
    return response.data;
  } catch (error) {
    return error;
  }
}


const getReconciliationStat = async () => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/dashboard/reconciliation_stat`,
    {
      headers: {
        'x-auth-token': authToken,
        'x-userid': authUserID
      }
    });
    return response.data;
  } catch (error) {
    return error;
  }
}



  const dashboardServices = {
    getExposureStatus,
    getExposureAreaNames,
    getExposurePlateDetails,
    getExposureStatuDetails,
    getPlateView,
    getIncubationStatus,
    getIncubationStatDetails,
    getIncubationAreaNames,
    getIncubationPlateDetails,
    ListIncubatorPlates,
    scanPlateById,
    getLabtestStatus,
    getLabtestStatuDetails,
    ListLabtestPlates,
    getAutoclaveStatus,
    getAutoclaveStat,
    getAutoclaveAreaNames,
    ListAutoclavePlates,
    getMissedScheduleDetails,
    getUpcomingScheduleDetails,
    getScheduleDetails,
    getExposedInprogress,
    getReadyForCollectionDetails,
    getOverduePlatesDetails,
    getPlateInExposureDetails,
    getPlateInIncubationDetails,
    IncubationCollectionDetails,
    getIncubationOverduePlatesDetails,
    getDescrepancyStatus,
    getDescrepencyDetails,
    getAutoclaveInprogressPageView,
    getAutoclaveCompletedPageView,
    ListPlateForFlora,
    ListFirstLabtest,
    ListSecondLabtest,
    ListThirdLabtest,
    ListReviewLabtest,
    ListLabtestWithinRange,
    ListLabtestOutsideRange,
    getArrowCount,
    getReconciliationStat,
    getInventoryStatus
  };
  
  export { dashboardServices };