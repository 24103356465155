import React, { useState, useEffect } from "react";
import HeaderSection from "../../common/headerSection";
import PageHeader from "../../common/pageHeader";
import CustomToast from "../../../widgets/toaster";
import { userService } from "../../../service/userService";
import CustomTable from "../../../widgets/table";
import { columns } from "./partials/columns";
import { auditTrialReport } from "../../../service/auditTrialReport";
import Pagination from "../../../widgets/pagination";
import axios from "axios";
import baseUrl from "../../../service";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { DEFAULT_PAGE_SIZE } from "../../../constants/constants";
import AlertModal from "../../../widgets/alertModal";

const AuditTrialReport = () => {
  const { t } = useTranslation();

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const [userRoleList, setUserRoleList] = useState<Array<any>>([]);
  const [auditLogList, setAuditLogList] = useState<Array<any>>([]);
  const [totalElements, setTotalElements] = useState(0);
  const [userData, setUserData] = useState<Array<any>>([]);
  const [addFilterData, setAddFilterData] = useState({
    event: "",
    interface: "",
    role: "",
    employee: "",
    fromDate: "",
    toDate: "",
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [totalPages, setTotalPages] = useState(0);
  const [useEffectRequired, setUseEffectRequired] = useState(false);
  const [showDownloadCSV, setShowDownloadCSV] = useState(true);
  const [isApplyClicked, setIsApplyClicked] = useState(true);
  const [userId, setUserId] = useState("");
  const [authToken, setAuthToken] = useState("");
  const [authUserID, setAuthUserID] = useState("");
  const [modalShow, setModalShow] = React.useState(false);
  interface RowData {
    user: string;
    dateAndTime: string;
    reason: string;
    apiGuiName: string;
    action:string;
  }
  const [rowData, setRowData] = useState<RowData>({
    user: "",
    dateAndTime: "",
    reason: "",
    apiGuiName: "",
    action: ""
  });
  const [tableData, setTableData] = useState<any[]>([]);
  const [pdfDownload, setpdfDownload] = useState(false);

  const handleFilterDataChange = (e: any) => {
    setShowDownloadCSV(true);
    setIsApplyClicked(false);
    const { name, value } = e.target;
    setAddFilterData((prevFormData: any) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const [toDateConstraints, setToDateConstraints] = useState({
    min: "",
    max: "",
  });
  const [isToDateEnabled, setIsToDateEnabled] = useState(false);


  useEffect(() => {
    var storedData = localStorage.getItem("userDetails");

    if (storedData) {
      var storedObject = JSON.parse(storedData);
      setUserId(storedObject[0].id);
      setAuthToken(storedObject[0].x_auth_token);
      setAuthUserID(storedObject[0].x_userid);
    }

    getUserRoleList();
    // listAuditTrialReport();
  }, []);

  useEffect(() => {
    getUserNameList();
  }, [addFilterData.role]);

  //get user list for dropdown
  const getUserRoleList = async () => {
    try {
      const response = await userService.getUserRoleList();
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                return {
                  label: element.title,
                  value: element.id,
                };
              }
            );
            setUserRoleList(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  //get users list for table listing
  const getUserNameList = async () => {
    try {
      const userRole = parseInt(addFilterData.role, 10);
      const response = await userService.getUserNameList(userRole);
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            // setTotalPages(response?.totalPages)
            // setTotalElements(response?.totalElements)
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                return {
                  slno: index + 1,
                  id: element.id,
                  name: `${element.first_name} ${element.last_name}`,
                };
              }
            );
            setUserData(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        setUserData([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //get listAuditTrialReport list from api
  const listAuditTrialReport = async () => {
    try {
      const response = await auditTrialReport.listAuditReport(
        addFilterData,
        page,
        pageSize
      );
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            setTotalPages(response?.totalPages);
            setTotalElements(response?.totalElements);
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                return {
                  slno: index + 1,
                  user: element.action_done_by,
                  interface: element.interface_type,
                  apiGuiName: element.interface_name,
                  typeOfOperation: element.action? element.action.charAt(0).toUpperCase() + element.action.slice(1): "-",
                  oldValue: element.old_data,
                  newValue: element.new_data,
                  dateAndTime: element.action_time,
                  id: element.id,
                  reason: element.reason || "-",
                  dataLabels: element.data_labels,
                  action : element.action
                };
              }
            );
            setAuditLogList(rowData);
          } else {
            setAuditLogList([]);
            setTotalPages(0);
            setTotalElements(0);
          }
        } catch (error) {
          console.log(error);
        }
      }else{
        setAuditLogList([]);
        setTotalPages(0);
        setTotalElements(0);
      }
    } catch (error) {
      console.error("user response", error);
    }
  };

  //download audit trial csv
  const downloadAuditCSV = async () => {
    setpdfDownload(false)
    const link = `${baseUrl}/audit_log/generate_pdf?&sort=id&order=desc&keyword=&action=${addFilterData.event}&interface_type=${addFilterData.interface}&user_id=${addFilterData.employee}&to_date_time=${addFilterData.toDate}&from_date_time=${addFilterData.fromDate}&auth_id=${authUserID}`;
    window.location.href = link; // Redirect to the link
  };

  const handleCancel = () => {
    const initialFormData = {
      event: "",
      interface: "",
      role: "",
      employee: "",
      fromDate: "",
      toDate: "",
    };
    setToDateConstraints({
      min: "",
      max: "",
    })
    setAddFilterData(initialFormData);
    setUseEffectRequired(true);
    setIsToDateEnabled(false);
    // listAuditTrialReport();
  };

  const handleApplyClick = () => {
    setShowDownloadCSV(false);
    setIsApplyClicked(true);
    listAuditTrialReport();
  };

  const handlePageChange = (newPage: any, pageSize?: number) => {
    setPage(newPage);
    if (pageSize) {
      setPageSize(pageSize);
    }
    setUseEffectRequired(true);
  };

  useEffect(() => {
    listAuditTrialReport();
    setUseEffectRequired(false);
  }, [useEffectRequired]);

  // const handleViewClick = (id: string, viewValue: boolean) => {
  //   const updatedData = auditLogList.find((row) => row.slno === id);
  //   if (!updatedData) return;

  //   const oldData = JSON.parse(updatedData.oldValue);
  //   const newData = JSON.parse(updatedData.newValue);

  //   // Get all keys present in the newValue
  //   const keys = Object.keys(newData);

  //   // Construct table data
  //   const tableData = keys.map(key => ({
  //       key,
  //       description: key,
  //       oldValue: oldData[key] !== undefined ? oldData[key] : '-', // Fill with empty string if key not present in oldValue
  //       newValue: newData[key]
  //   }));

  //   setTableData(tableData);
  //   setRowData(updatedData);
  //   setModalShow(viewValue);
  // };

  // const handleViewClick = (id: string, viewValue: boolean) => {
  //   const updatedData = auditLogList.find((row) => row.slno === id);
  //   if (!updatedData) return;

  //   const oldData = JSON.parse(updatedData.oldValue);
  //   const newData = JSON.parse(updatedData.newValue);
  //   const dataLabels = JSON.parse(updatedData.dataLabels);

  //   // Get all keys present in the newValue
  //   const keys = Object.keys(newData);

  //   // Construct table data
  //   const tableData = keys.map((key) => {
  //     const oldValue = oldData[key] !== undefined ? oldData[key] : "-";
  //     const newValue = newData[key];

  //     // Check if the type of operation is "update" and if the new value is different from the old value
  //     const highlightDifference =
  //       updatedData.typeOfOperation === "update" && oldValue !== newValue;

  //     const description = dataLabels.hasOwnProperty(key)
  //       ? dataLabels[key]
  //       : undefined;

  //     return {
  //       key,
  //       description,
  //       oldValue,
  //       newValue,
  //       highlightDifference,
  //     };
  //   });

  //   setTableData(tableData);
  //   setRowData(updatedData);
  //   setModalShow(viewValue);
  // };

  // const handleViewClick = (id: string, viewValue: boolean) => {
  //   const updatedData = auditLogList.find((row) => row.slno === id);
  //   if (!updatedData) return;
  
  //   const oldData = JSON.parse(updatedData.oldValue);
  //   const newData = JSON.parse(updatedData.newValue);
  //   const dataLabels = JSON.parse(updatedData.dataLabels);
  
  //   // Get all keys present in the newValue
  //   const keys = Object.keys(newData);
  
  //   // Construct table data
  //   const tableData = keys.map((key) => {
  //     const oldValue = oldData[key] !== undefined ? oldData[key] : "-";

  //     const value = newData[key];

  //     // Ensure the value is a string
  //     const valueStr = typeof value === 'string' ? value : String(value);

  //     const newValue = valueStr.includes('<br>')
  //       ? valueStr.split('<br>').join('\n')
  //       : valueStr;

  //     newData[key] = newValue;
      
  //     // Split the newValue by <br> and join with new line character
  //     // const newValue = newData[key].includes('<br>')
  //     //   ? newData[key].split('<br>').join('\n')
  //     //   : newData[key];

  //     // const newValue = newData[key]
  
  //     // Check if the type of operation is "update" and if the new value is different from the old value
  //     const highlightDifference =
  //       updatedData.typeOfOperation === "update" && oldValue !== newValue;
  
  //     const description = dataLabels.hasOwnProperty(key)
  //       ? dataLabels[key]
  //       : undefined;
  
  //     return {
  //       key,
  //       description,
  //       oldValue,
  //       newValue,
  //       highlightDifference,
  //     };
  //   });
  
  //   setTableData(tableData);
  //   setRowData(updatedData);
  //   setModalShow(viewValue);
  // };
  
  const handleViewClick = (id: string, viewValue: boolean) => {
    const updatedData = auditLogList.find((row) => row.slno === id);
    if (!updatedData) return;
  
    const oldData = updatedData.oldValue ? JSON.parse(updatedData.oldValue) : null;
    const newData = JSON.parse(updatedData.newValue);
    const dataLabels = JSON.parse(updatedData.dataLabels);
  
    // Get all keys present in the newValue
    const keys = Object.keys(newData);
  
    // Construct table data
    const tableData = keys.map((key) => {
      const oldValue = oldData ? oldData[key] : "-";
  
      const value = newData[key];
      const valueStr = typeof value === 'string' ? value : String(value);
  
      const newValue = valueStr.includes('<br>')
        ? valueStr.split('<br>').join('\n')
        : valueStr;
  
      // Determine if comparison should be highlighted
      const highlightDifference =
        updatedData.typeOfOperation === "update" && oldData && oldValue !== newValue;
  
      const description = dataLabels.hasOwnProperty(key)
        ? dataLabels[key]
        : undefined;
  
      return {
        key,
        description,
        oldValue,
        newValue,
        highlightDifference,
      };
    });
  
    setTableData(tableData);
    setRowData(updatedData);
    setModalShow(viewValue);
  };
  

  const formatDateTime = (date: string | Date): string => {
    if (!date) return "";
    const d = new Date(date);
    return (
      d.getFullYear() +
      "-" +
      String(d.getMonth() + 1).padStart(2, "0") +
      "-" +
      String(d.getDate()).padStart(2, "0") +
      "T" +
      String(d.getHours()).padStart(2, "0") +
      ":" +
      String(d.getMinutes()).padStart(2, "0")
    );
  };
  
  return (
    <div>
      <HeaderSection />
      <PageHeader
        pageHeader={t("auditTrialReport.configurationChangeReport")}
      />
      <CustomToast
        show={showToast}
        onClose={() => setShowToast(false)}
        delay={5000}
        message={toastMessage}
        toastType={toastType}
      />

      <div className="d-flex justify-content-around my-4">
        <div
          className="select-container"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <label htmlFor="" style={{ color: "black", fontSize: "12px" }}>
            {t("common.event")}:
          </label>
          <select
            name="event"
            className="areaTypeSelect"
            value={addFilterData.event}
            onChange={handleFilterDataChange}
          >
            <option value="">Select</option>
            <option value="create">{t("auditTrialReport.create")}</option>
            <option value="update">{t("auditTrialReport.update")}</option>
            <option value="delete">{t("auditTrialReport.statusChange")}</option>
            {/* <option value="microbiologist" >Microbiologist</option> */}
          </select>
        </div>

        {/* <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
        <label htmlFor="" style={{color: "black", fontSize: "12px"}}>Interface :</label>
        <select name="interface" className='areaTypeSelect'  value={addFilterData.interface}  onChange={handleFilterDataChange} >
            <option value="">Select</option>
            <option value="ui" >GUI</option>
            <option value="api" >API</option>
        </select>
        </div> */}

        <div
          className="select-container"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <label htmlFor="" style={{ color: "black", fontSize: "12px" }}>
            {t("common.role")}:
          </label>
          <select
            className="areaTypeSelect"
            name="role"
            value={addFilterData.role}
            onChange={handleFilterDataChange}
          >
            <option value="">Select</option>
            {userRoleList.map((type) => (
              <option key={type.value} value={type.value}>
                {type.label}
              </option>
            ))}
          </select>
        </div>

        <div
          className="select-container"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <label htmlFor="" style={{ color: "black", fontSize: "12px" }}>
            {t("common.user")}:
          </label>
          <select
            name="employee"
            className="areaTypeSelect"
            value={addFilterData.employee}
            onChange={handleFilterDataChange}
          >
            <option value="">Select</option>
            {userData.map((type) => (
              <option key={type.id} value={type.id}>
                {type.name}
              </option>
            ))}
          </select>
        </div>

        <div className="d-flex" style={{ marginRight: "4%" }}>
          <div
            className="select-container auditTrialReportDate "
            style={{
              display: "flex",
              flexDirection: "column",
              marginRight: "15%",
            }}
          >
            <label htmlFor="" style={{ color: "black", fontSize: "12px" }}>
              {t("common.from_date")}:
            </label>
            <input
              id="selectedDate"
              className="form-control"
              name="fromDate"
              type="datetime-local"
              value={formatDateTime(addFilterData.fromDate)}  
              onChange={(e) => {
                handleFilterDataChange(e);
          
                const fromDate = new Date(e.target.value);
                if (!isNaN(fromDate.getTime())) {
                  // Calculate the min and max for the toDate field
                  const minDate = new Date(fromDate);
                  const maxDate = new Date(fromDate);
                  maxDate.setDate(maxDate.getDate() + 14);
          
                  // Update state with min and max dates
                  setToDateConstraints({
                    min: formatDateTime(minDate),
                    max: formatDateTime(maxDate),
                  });
                  setAddFilterData((prevData) => ({
                    ...prevData,
                    fromDate: formatDateTime(fromDate),
                    toDate: formatDateTime(minDate),
                  }));
                  setIsToDateEnabled(true);
                }
              }}
              onKeyDown={(e) => {
                // Allow navigation keys (e.g., Tab, Arrow keys) and Enter to open the date picker
                const allowedKeys = [
                  "Tab",
                  "Enter",
                  "Escape",
                ];
                if (!allowedKeys.includes(e.key)) {
                  e.preventDefault();
                }
              }}
              onPaste={(e) => e.preventDefault()}
            />
          </div>

          <div
            className="select-container auditTrialReportDate"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <label htmlFor="" style={{ color: "black", fontSize: "12px" }}>
              {t("common.to_date")}:
            </label>
            <input
              id="selectedDate"
              className="form-control"
              name="toDate"
              type="datetime-local"
              value={formatDateTime(addFilterData.toDate)} 
              onChange={handleFilterDataChange}
              min={toDateConstraints.min}
              disabled={!isToDateEnabled}
              max={toDateConstraints.max}
              onKeyDown={(e) => {
                // Allow navigation keys (e.g., Tab, Arrow keys) and Enter to open the date picker
                const allowedKeys = [
                  "Tab",
                  "Enter",
                  "Escape",
                ];
                if (!allowedKeys.includes(e.key)) {
                  e.preventDefault();
                }
              }}
              onPaste={(e) => e.preventDefault()}
            />
          </div>
        </div>
      </div>

      <div className="mt-3 mx-2 d-flex justify-content-end">
        <button
          className="analyticalFilterClose"
          title="Click here to Reset Filter"
          onClick={handleCancel}
        >
          {t("buttons.cancel")}
        </button>
        <button
          className={
            isApplyClicked
              ? "analyticalFilterApplyDisabledDownload"
              : "analyticalFilterApply"
          }
          disabled={isApplyClicked}
          onClick={handleApplyClick}
          title="Click here to Apply Filter"
        >
          {t("buttons.apply")}
        </button>
        <button
          className={
            showDownloadCSV
              ? "analyticalFilterDisabledDownload"
              : "analyticalFilterDownload"
          }
          disabled={showDownloadCSV}
          onClick={()=>setpdfDownload(true)}
          title="Click here to Download CSV"
        >
          {t("buttons.download_pdf")}
        </button>
      </div>

      <div className="me-3">
        <div className=" mx-3 pe-2" style={{ height: "45vh" }}>
          <CustomTable
            data={auditLogList}
            columns={columns}
            isEditable={false}
            totalElements={totalElements}
            isActionVisible={false}
            isViewVisible={true}
            isSeachable={false}
            tableHead={t("auditTrialReport.configurationChangeReport")}
            showBatchFilter={false}
            showSerielNoFilter={false}
            onUseEffectRequired={() => setUseEffectRequired(true)}
            onViewClick={handleViewClick}
          />
        </div>
        <div>
          <Pagination
            page={page}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
          />
        </div>
      </div>

      <Modal
        show={modalShow}
        className="modal-view-audit"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
      >
        <Modal.Header closeButton onClick={() => setModalShow(false)}>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("auditTrialReport.view")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 style={{ textAlign: "left" }}>
            {t("auditTrialReport.configurationChangeReport")}
          </h4>
          {rowData && (
            <div
              className="key-value-pairs"
              style={{
                display: "flex",
                padding: "10px",
                width: "100%",
                textAlign: "left",
              }}
            >
              <div className="column" style={{ flex: "1" }}>
                <div className="audit-key">
                  {rowData?.action === "create"
                    ? t("auditTrialReport.createdBy")
                    : t("auditTrialReport.updatedBy")}
                </div>
                <div className="audit-value">{rowData?.user}</div>
              </div>
              <div className="column" style={{ flex: "1" }}>
                <div className="audit-key">
                  {t("auditTrialReport.dateAndTime")}
                </div>
                <div className="audit-value">{rowData?.dateAndTime}</div>
              </div>
              <div className="column" style={{ flex: "1" }}>
                <div className="audit-key">{t("auditTrialReport.reason")}</div>
                <div className="audit-value">{rowData?.reason}</div>
              </div>
              <div className="column" style={{ flex: "1" }}>
                <div className="audit-key">{t("auditTrialReport.guiName")}</div>
                <div className="audit-value">{rowData?.apiGuiName}</div>
              </div>
            </div>
          )}
          <div className="Audit-trial-table-container">
            <table
              className="table"
              style={{ width: "100%", textAlign: "left" }}
            >
              <thead className="Audit-trial-table-sticky-header">
                <tr>
                  <th style={{ width: "33%" }}>
                    {t("auditTrialReport.description")}
                  </th>
                  <th style={{ width: "33%" }}>
                    {t("auditTrialReport.newValue")}
                  </th>
                  <th style={{ width: "33%" }}>
                    {t("auditTrialReport.oldValue")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((rowData: any, index: any) => (
                  <tr key={index} className="audit-view-table">
                    <td style={{ width: "33%" }}>{rowData.description}</td>
                    <td
                      style={{ width: "33%" }}
                      className={
                        rowData.highlightDifference ? "highlightDifference" : ""
                      }
                    >
                      {rowData.newValue}
                    </td>
                    <td style={{ width: "33%" }}>{rowData.oldValue}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-start">
          <button
            onClick={() => setModalShow(false)}
            className="custom-close-button"
          >
            {t("buttons.close")}
          </button>
        </Modal.Footer>
      </Modal>

      <AlertModal
        show={pdfDownload}
        onHide={() => setpdfDownload(false)}
        title="Alert"
        message={t('downloadPdf.download')}
        onConfirm={downloadAuditCSV}
      />
      
    </div>
  );
};

export default AuditTrialReport;
