import React, { useState } from 'react'
import HeaderSection from '../../../../common/headerSection'
import PageHeader from '../../../../common/pageHeader'
import ViewSchedule from './viewSchedule'
import { useTranslation } from 'react-i18next'

const ScheduleCalendar = () => {

    const { t } = useTranslation();
    const [showViewSchedule, setShowViewSchedule] = useState("1");

  return (
    <div>
        <HeaderSection />
        <PageHeader pageHeader={t("scheduleProduction.productionCalendar")} />

        <div className="m-3">
              <ViewSchedule setTab={setShowViewSchedule} />
            </div>
    </div>
  )
}

export default ScheduleCalendar