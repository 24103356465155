export const columns: TableColumn[] = [
    {
        Header: "tableCommon.sl_no",
        accessor: "slno",
      },
      {
        Header: "applicationConfiguration.tableColumns.name",
        accessor: "name",
      },
      {
        Header: "applicationConfiguration.tableColumns.date",
        accessor: "date",
      },
]

export const HolidayNamecolumns: TableColumn[] = [
  {
      Header: "tableCommon.sl_no",
      accessor: "slno",
    },
    {
      Header: "applicationConfiguration.tableColumns.name",
      accessor: "name",
    },
]

export const weakDayColumns: TableColumn[] = [
  {
      Header: "tableCommon.sl_no",
      accessor: "slno",
    },
    {
      Header: "applicationConfiguration.tableColumns.weakdaysName",
      accessor: "weekDaysName",
    },
    {
      Header: "applicationConfiguration.tableColumns.fromDate",
      accessor: "fromDate",
    },
    {
      Header: "applicationConfiguration.tableColumns.toDate",
      accessor: "toDate",
    },
]

interface TableColumn {
    Header: string;
    accessor: string;
  }