export const columns: TableColumn[] = [
    {
        Header: 'tableCommon.sl_no',
        accessor: 'slno',
      },
      {
        Header: 'personnelMonitoring.tableColumns.personnelTextName',
        accessor: 'personnelLocName',
      },
      {
        Header: 'personnelMonitoring.tableColumns.locationCode',
        accessor: 'locationCode',
      },
]

interface TableColumn {
    Header: string;
    accessor: string;
  }