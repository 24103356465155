import React, { useEffect, useState } from "react";

import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Modal } from "react-bootstrap";

import "react-big-calendar/lib/css/react-big-calendar.css";
import { scheduleService } from "../../service/scheduleService";

interface ReactBigCalendarProps {
  view: "day" | "week" | "month";
  setView: React.Dispatch<React.SetStateAction<"day" | "week" | "month">>;
  selectedDate: Date;
  setSelectedDate: (date : Date) => void ;
  user: string;
  area: string;
  // setSelectedDate: React.Dispatch<React.SetStateAction<Date>>;
}

export default function ReactBigCalendar({
  view,
  setView,
  selectedDate,
  setSelectedDate,
  user,
  area
  // setSelectedDate,
}: ReactBigCalendarProps) {
  const localizer = momentLocalizer(moment);
  const { t } = useTranslation();
  const [eventsData, setEventsData] = useState<any>([]);
  const [showEventDetails, setShowEventDetails] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<any>();

  const handleViewChange = (newView: "day" | "week" | "month") => {
    setView(newView);
  };
  const [dateRange, setDateRange] = useState<{ start: Date; end: Date } | null>(
    null
  );

  const CustomToolbar = ({ label, onView, onNavigate }: any) => {
    return (
      <div className="rbc-toolbar">
        {/* Label showing current month/year */}
        <div className="rbc-toolbar-label">{label}</div>
        
        {/* View Buttons: Day, Week, Month */}
        <div className="rbc-btn-group">
          <button onClick={() => onView('day')}>Day</button>
          <button onClick={() => onView('week')}>Week</button>
          <button onClick={() => onView('month')}>Month</button>
        </div>
      </div>
    );
  };

  const getCalenderScheduleList = async () => {
    let fromDate = dateRange?.start ? moment(dateRange?.start).format("YYYY-MM-DD 00:00:00") : "";
    let toDate = dateRange?.end ? moment(dateRange?.end).format("YYYY-MM-DD 23:59:59") : "";
    try {
    const response = await scheduleService.getCalenderScheduleList(area, user, fromDate, toDate);
    if (response.status && response.statusCode === 200) {
        try {
        if (Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map(
            (element: any, index: number) => {

              let userName = element.user_first_name + element.user_last_name

                return {
                slno: index + 1,
                id: element.id,
                title: element.production_schedule_name,
                start: moment(element.start_date_time).toDate(),
                end: moment(element.end_date_time).toDate(),
                activityType: element.activity_type_name,
                area: element.area_name,
                location: element.location_name,
                startDate: element.start_date_time,
                endDate: element.end_date_time,
                assignee: userName || "",
            }}
            );
            setEventsData(rowData);
        }
        } catch (error) {
        console.log(error);
        }
    } else {
      setEventsData([]);
    }
    } catch (error) {
    console.error("user response", error);
    }
  };

  const handleSelectEvent = (event: any) => {
    setShowEventDetails(true)
    setSelectedEvent(event)
  };

  const handleShowMore = (events: any, date :any) => {
    // Set a default time, e.g., 14:30:00 (2:30 PM)
    date.setHours(14, 30, 0);
    setSelectedDate(date)
  };

  const calculateDefaultRange = (view: "day" | "week" | "month", date: Date) => {
    let start: Date = new Date(); // Default to current date
    let end: Date = new Date();   // Default to current date

    if (view === "day") {
      start = new Date(date);
      end = new Date(date);
    } else if (view === "week") {
      const day = date.getDay();
      const diff = date.getDate() - day + (day === 0 ? -6 : 1); // Adjust for ISO week
      start = new Date(date.setDate(diff));
      end = new Date(start);
      end.setDate(start.getDate() + 6);
    } else if (view === "month") {
      start = new Date(date.getFullYear(), date.getMonth(), 1);
      end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    }

    return { start, end };
  };

  const onRangeChange = (range: any, view: any ) => {
    let start : Date = new Date();
    let end : Date =  new Date();
      if (view === "month") {
        start = range.start
        end = range.end
      } else if (view === "week") {
        start = range[0]
        end = range[range.length - 1]
      } else if (view === "day") {
        start = end = range[0]
      }
      setDateRange({ start, end });
    }

  useEffect(() => {
    // getCalenderScheduleList()
    if (dateRange && dateRange.start && dateRange.end) {
      getCalenderScheduleList();
    }
  }, [area, user, dateRange]);

  useEffect(() => {
    // Initialize the default range
    const defaultRange = calculateDefaultRange(view, selectedDate);
    setDateRange(defaultRange);
  }, [selectedDate]);
  
  return (
    <div className="calender-view-schedule">
      <Calendar
        views={["day", "week", "month"]}
        selectable
        localizer={localizer}
        defaultDate={new Date()}
        defaultView={view}
        events={eventsData}
        style={{ height: "80vh" }}
        date={selectedDate}
        onView={(newView) => handleViewChange(newView as "day" | "week" | "month")}
        onShowMore={handleShowMore}
        components={{
          toolbar: CustomToolbar, // Use custom toolbar
        }}
        onSelectEvent={handleSelectEvent}
        onRangeChange={onRangeChange}
      />

      {/*modal for download QR*/}
      <Modal
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showEventDetails}
            size="lg"
        >
            <Modal.Header closeButton onClick={() => setShowEventDetails(false)}>
            <Modal.Title id="contained-modal-title-vcenter">
                Schedule Details
            </Modal.Title>
            </Modal.Header>
            <div className="m-3">
              <div
                className="key-value-pairs1"
                style={{
                  display: "flex",
                  padding: "10px",
                  width: "100%",
                  textAlign: "left",
                  backgroundColor: "rgb(107 152 189)"
                }}
              >
                <div className="column" style={{ flex: "1" }}>
                  <div className="calender-view-key">
                    Production Schedule
                  </div>
                  <div className="calender-view-value">{selectedEvent?.title}</div>
                </div>
                <div className="column" style={{ flex: "1" }}>
                  <div className="calender-view-key">
                    Activity Type
                  </div>
                  <div className="calender-view-value">{selectedEvent?.activityType}</div>
                </div>
                <div className="column" style={{ flex: "1" }}>
                  <div className="calender-view-key">
                    Area
                  </div>
                  <div className="calender-view-value">{selectedEvent?.area}</div>
                </div>
                <div className="column" style={{ flex: "1" }}>
                  <div className="calender-view-key">
                    Location
                  </div>
                  <div className="calender-view-value">{selectedEvent?.location}</div>
                </div>
              </div>
              <div
                className="key-value-pairs1"
                style={{
                  display: "flex",
                  padding: "10px",
                  width: "100%",
                  textAlign: "left",
                  backgroundColor: "rgb(107 152 189)"
                }}
              >
                <div className="column" style={{ flex: "1" }}>
                  <div className="calender-view-key">
                    Start Date & Time
                  </div>
                  <div className="calender-view-value">{selectedEvent?.startDate}</div>
                </div>
                <div className="column" style={{ flex: "1" }}>
                  <div className="calender-view-key">
                    End Date & Time
                  </div>
                  <div className="calender-view-value">{selectedEvent?.endDate}</div>
                </div>
                <div className="column" style={{ flex: "1" }}>
                  <div className="calender-view-key">
                    Assignee</div>
                  <div className="calender-view-value">{selectedEvent?.assignee}</div>
                </div>
                <div className="column" style={{ flex: "1", visibility: "hidden" }}>
                  <div className="calender-view-key">
                    Assignee</div>
                  <div className="calender-view-value">{selectedEvent?.assignee}</div>
                </div>
              </div>
            </div>
            <Modal.Footer className="justify-content-start">
              <button
                onClick={() => setShowEventDetails(false)}
                className="custom-close-button"
              >
                {t("buttons.close")}
              </button>
            </Modal.Footer>
           
        </Modal>

    </div>


  );
}





