//table columns for user management table listing 
export const columns: TableColumn[] = [
    {
        Header: 'tableCommon.sl_no',
        accessor: 'slno',
      },
      {
        Header: "tableCommon.batch_code",
        accessor: 'batch_number',
      },
      {
        Header: 'temporaryManagement.tableColumns.batch_grn',
        accessor: 'batch_grn',
      },
      {
        Header: 'temporaryManagement.tableColumns.quantity',
        accessor: 'quantity',
      },
      {
        Header: 'temporaryManagement.tableColumns.status',
        accessor: 'status',
      },
]

interface TableColumn {
    Header: string;
    accessor: string;
  }


export const columnsHistory: TableColumn[] = [
  {
    Header: 'tableCommon.sl_no',
    accessor: 'slno',
  },
  {
    Header: "tableCommon.batch_code",
    accessor: 'batch_number',
  },
  {
    Header: 'temporaryManagement.tableColumns.batch_grn',
    accessor: 'batch_grn',
  },
  {
    Header: 'temporaryManagement.tableColumns.quantity',
    accessor: 'quantity',
  },
  {
    Header: 'temporaryManagement.tableColumns.ref',
    accessor: 'tcm_ref',
  },
  {
    Header: 'temporaryManagement.tableColumns.remarks',
    accessor: 'tcm_remarks',
  },
]