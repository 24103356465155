import React, {useState, useEffect} from 'react'
import HeaderSection from '../../common/headerSection';
import PageHeader from '../../common/pageHeader';
import CustomToast from '../../../widgets/toaster';
import { userService } from '../../../service/userService';
import CustomTable from '../../../widgets/table';
import { columns } from './partials/columns';
import { auditTrialReport } from '../../../service/auditTrialReport';
import Pagination from '../../../widgets/pagination';
import { useTranslation } from 'react-i18next';
import baseUrl from '../../../service';
import { DEFAULT_PAGE_SIZE } from '../../../constants/constants';
import AlertModal from '../../../widgets/alertModal';

const UserAccessReport = () => {

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const [userRoleList, setUserRoleList] = useState<Array<any>>([]); 
  const [auditLogList, setAuditLogList] = useState([])
  const [totalElements, setTotalElements] = useState(0)
  const [userData , setUserData] = useState<Array<any>>([]); 
  const [addFilterData, setAddFilterData] = useState({
    role : "",
    employee:"",
    fromDate: "",
    toDate: "",
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [totalPages, setTotalPages] = useState(0);
  const [useEffectRequired, setUseEffectRequired] = useState(false)
  const [showDownloadCSV, setShowDownloadCSV] = useState(true);
  const [isApplyClicked, setIsApplyClicked] = useState(true);
  const [userId , setUserId] = useState("");
  const [authToken , setAuthToken] = useState("");
  const [authUserID , setAuthUserID] = useState("");
  const { t } = useTranslation()
  const [pdfDownload, setpdfDownload] = useState(false);

  const handleFilterDataChange = (e : any) => {
    setShowDownloadCSV(true)
    setIsApplyClicked(false)
    const { name, value } = e.target;
    setAddFilterData((prevFormData : any) => ({
      ...prevFormData, 
      [name]: value,
    }));
  };

  const [toDateConstraints, setToDateConstraints] = useState({
    min: "",
    max: "",
  });
  const [isToDateEnabled, setIsToDateEnabled] = useState(false);

  useEffect(() => {
    var storedData = localStorage.getItem('userDetails');

    if(storedData) {
      var storedObject = JSON.parse(storedData);
      setUserId(storedObject[0].id)
      setAuthToken(storedObject[0].x_auth_token)
      setAuthUserID(storedObject[0].x_userid)
    }

    getUserRoleList();
    listUserAccessReport();
  }, [])

  useEffect(() => {
    getUserNameList();
  }, [addFilterData.role])

  //get user list for dropdown 
  const getUserRoleList = async () => {
    try {
      const response = await userService.getUserRoleList();
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              let rowData: any = response.data.map((element: any, index: number) => {

                return { 
                  label: element.title,
                  value : element.id,
                };
              });
              setUserRoleList(rowData);
            }
          } catch (error) {
            console.log(error);
          }
      }
    } catch (error) {
      console.error(error);
    }
  }

  //get users list for table listing 
  const getUserNameList = async () => {
    try {
      const userRole = parseInt(addFilterData.role, 10);  
      const response = await userService.getUserNameList(userRole);
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              // setTotalPages(response?.totalPages)
              // setTotalElements(response?.totalElements)
              let rowData: any = response.data.map((element: any, index: number) => {

                return { 
                    slno: index + 1, 
                    id: element.id,
                    name: `${element.first_name} ${element.last_name}`,
                };
              });
              setUserData(rowData);
            }
          } catch (error) {
            console.log(error);
          }
      } else {
        setUserData([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

 
  const listUserAccessReport = async () => {
    try {
      const response = await auditTrialReport.listUserAccessReport(addFilterData, page , pageSize);
      console.log(response.data, "response.data---")
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              setTotalPages(response?.totalPages)
              setTotalElements(response?.totalElements)
              let rowData: any = response.data.map((element: any, index: number) => {

                return { 
                    slno: index + 1, 
                    action_done_by: element.action_done_by,
                    created_at: element.created_at,
                    action: element.action,
                    ip_address: element.ip_address,
                    title: element.title
                
                };
              });
              setAuditLogList(rowData);
            }
          } catch (error) {
            console.log(error);
          }
      }else{
        setAuditLogList([])
        setTotalPages(0)
        setTotalElements(0)
      }
    } catch (error) {
      console.error('user response', error);
    }
  }

   //download audit trial csv  
   const downloadAuditCSV = async () => {
    setpdfDownload(false)
    const link = `${baseUrl}/user_access_log/generate_pdf?sort=id&order=desc&keyword=&&user_id=${addFilterData.employee}&to_date_time=${addFilterData.toDate}&from_date_time=${addFilterData.fromDate}&auth_id=${authUserID}`
    window.location.href = link; // Redirect to the link
  }

  const handleCancel = () => {
    const initialFormData = {
    role : "",
    employee:"",
    fromDate: "",
    toDate: "",
    };
    setToDateConstraints({
      min: "",
      max: "",
    })
    setIsToDateEnabled(false);
    setAddFilterData(initialFormData);
    setShowDownloadCSV(true)
    setUseEffectRequired(true)
  }

  const handleApplyClick =() =>{
    setShowDownloadCSV(false)
    setIsApplyClicked(true)
    listUserAccessReport();
  }

  const handlePageChange = (newPage : any, pageSize?: number) => {
    setPage(newPage);
    if(pageSize) {
      setPageSize(pageSize)
    }
    setUseEffectRequired(true)
  };

  useEffect(() => {
    listUserAccessReport();
    setUseEffectRequired(false)
}, [useEffectRequired])


const formatDateTime = (date: string | Date): string => {
  if (!date) return "";
  const d = new Date(date);
  return (
    d.getFullYear() +
    "-" +
    String(d.getMonth() + 1).padStart(2, "0") +
    "-" +
    String(d.getDate()).padStart(2, "0") +
    "T" +
    String(d.getHours()).padStart(2, "0") +
    ":" +
    String(d.getMinutes()).padStart(2, "0")
  );
};

  return (
    <div>
        <HeaderSection />
        <PageHeader pageHeader={t("auditTrialReport.userAccessReport")} />
        <CustomToast
                show={showToast}
                onClose={() => setShowToast(false)}
                delay={5000}
                message={toastMessage}
                toastType={toastType}
            />

        <div className="d-flex justify-content-around my-4">

        <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
        <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t("common.role")} :</label>
        <select className='areaTypeSelect' name="role" value={addFilterData.role}  onChange={handleFilterDataChange} >
            <option value="">Select</option>
            {userRoleList.map((type) => (
            <option key={type.value} value={type.value}>
                {type.label}
            </option>
            ))}
        </select>
        </div>

        <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
        <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t("common.user")}:</label>
        <select name="employee" className='areaTypeSelect' value={addFilterData.employee}  onChange={handleFilterDataChange}  >
            <option value="">Select</option>
            {userData.map((type) => (
            <option key={type.id} value={type.id}>
                {type.name}
            </option>
            ))}
        </select>
        </div>
        
        <div className="select-container auditTrialReportDate " 
            style={{display: "flex", flexDirection: "column"}}>
            <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t("common.from_date")}:</label>
            <input
            id="selectedDate"
            className="form-control"
            name="fromDate"
            type="datetime-local"
            value={formatDateTime(addFilterData.fromDate)}  
            onChange={(e) => {
              handleFilterDataChange(e);
        
              const fromDate = new Date(e.target.value);
              if (!isNaN(fromDate.getTime())) {
                // Calculate the min and max for the toDate field
                const minDate = new Date(fromDate);
                const maxDate = new Date(fromDate);
                maxDate.setDate(maxDate.getDate() + 14);
        
                // Update state with min and max dates
                setToDateConstraints({
                  min: formatDateTime(minDate),
                  max: formatDateTime(maxDate),
                });
                setAddFilterData((prevData) => ({
                  ...prevData,
                  fromDate: formatDateTime(fromDate),
                  toDate: formatDateTime(minDate),
                }));
                setIsToDateEnabled(true);
              }
            }}
            onKeyDown={(e) => {
              const allowedKeys = [
                "Tab",
                "Enter",
                "Escape",
              ];
              if (!allowedKeys.includes(e.key)) {
                e.preventDefault();
              }
            }}
            onPaste={(e) => e.preventDefault()}
        />
        </div>

        <div className="select-container auditTrialReportDate" 
            style={{display: "flex", flexDirection: "column"}}>
            <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t("common.to_date")}:</label>
            <input
            id="selectedDate"
            className="form-control"
            name="toDate"
            type="datetime-local"
            value={formatDateTime(addFilterData.toDate)}  
            onChange={handleFilterDataChange}
            min={toDateConstraints.min}
            max={toDateConstraints.max}
            disabled={!isToDateEnabled}
            onKeyDown={(e) => {
              const allowedKeys = [
                "Tab",
                "Enter",
                "Escape",
              ];
              if (!allowedKeys.includes(e.key)) {
                e.preventDefault();
              }
            }}
            onPaste={(e) => e.preventDefault()}
            />
        </div>
        <div className="mt-4 mx-2 d-flex justify-content-end" >
        <button className="analyticalFilterClose" title="Click here to Reset Filter"  onClick={handleCancel}>{t("buttons.cancel")}</button>
            <button 
                className={isApplyClicked ? "analyticalFilterApplyDisabledDownload" : "analyticalFilterApply"}
                disabled={isApplyClicked} 
                onClick={handleApplyClick} title="Click here to Apply Filter" >
                  {t("buttons.apply")}
            </button>
            <button 
              className={showDownloadCSV ? "analyticalFilterDisabledDownload" : "analyticalFilterDownload"}
              disabled={showDownloadCSV} 
              onClick={()=>setpdfDownload(true)}
              title="Click here to Download CSV" >
                {t("buttons.download_pdf")}
            </button>
        </div>
        </div>
       
        <div className='me-3'>
            <div className=' mx-3 pe-2' style={{"height":"45vh"}}>
                <CustomTable 
                    data={auditLogList}
                    columns={columns}
                    isEditable={false}
                    totalElements={totalElements}
                    isActionVisible={false}
                    isViewVisible={false}
                    isSeachable={false}
                    tableHead={t("auditTrialReport.userAccessReport")}
                    showBatchFilter={false}
                    showSerielNoFilter={false}
                    onUseEffectRequired={() => setUseEffectRequired(true)} 
                />
            </div>
            <div>
                <Pagination
                  page={page}
                  totalPages={totalPages}
                  handlePageChange={handlePageChange}
                />
            </div>
        </div>

        <AlertModal
        show={pdfDownload}
        onHide={() => setpdfDownload(false)}
        title="Alert"
        message={t('downloadPdf.download')}
        onConfirm={downloadAuditCSV}
      />
    </div>
  )
}

export default UserAccessReport