import React, { useEffect, useState } from "react";
import moment from "moment";
import CustomTable from "../../../../widgets/table/index";
import HeaderSection from "../../../common/headerSection";
import PageHeader from "../../../common/pageHeader";
import {
  columns,
  opColumns,
  activityFrequencyColumns,
} from "./partials/columns";
import { scheduleService } from "../../../../service/scheduleService";
import Pagination from "../../../../widgets/pagination";
import { Button, Modal } from "react-bootstrap";
import { TfiClose } from "react-icons/tfi";
import AlertModal from "../../../../widgets/alertModal";
import CustomToast from "../../../../widgets/toaster";
import { userService } from "../../../../service/userService";
import { useTranslation } from "react-i18next";
import { gradeTypes } from "../../../../service/gradeType";
import Select from "react-select";
import { helperFunctions as _h } from "../../../../utils/helper";
import baseUrl from "../../../../service";
import { DEFAULT_PAGE_SIZE } from "../../../../constants/constants";
import ViewSchedule from "./partials/viewSchedule";

const ScheduleProduction = () => {
  const { t } = useTranslation();

  const currentDate = new Date();
  const formattedDate = currentDate.toISOString().split('T')[0]; // YYYY-MM-DD

  const date7DaysAgo = new Date();
  date7DaysAgo.setDate(date7DaysAgo.getDate() - 7);
  const formattedDate7DaysAgo = date7DaysAgo.toISOString().split("T")[0];

  const formattedTime = currentDate.toTimeString().split(' ')[0]; // HH:MM:SS
  const dateTime = `${formattedDate7DaysAgo} 00:00:00`;

  // end date should be 1 week ahead from currentDate
  const endDate = new Date(currentDate);
  const formattedEndDate = endDate.toISOString().split('T')[0]; // YYYY-MM-DD
  const formattedEndTime = currentDate.toTimeString().split(' ')[0]; // HH:MM:SS
  const endDateTime = `${formattedEndDate} 23:59:59`;

  const defaultFormData = {
    id: "",
    activity_type_id: "",
    reason: "",
    status: "",
    created_at: "",
    updated_at: "",
    created_by: "",
    updated_by: "",
    activity_type_name: "",
    area_grade_name: "",
    production_schedule_name: "",
    product_name: "",
    production_type: "",
    area_id: "",
    from_date_time: "",
    to_date_time: "",
    area_name: "",
  };
  const [tableData, setTableData] = useState<Array<any>>([]);
  const [useEffectRequired, setUseEffectRequired] = useState(false);

  // table pagination
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);

  const [keyWord, setkeyWord] = useState("");
  const [fromDate, setFromDate] = useState(dateTime);
  const [toDate, setToDate] = useState(endDateTime);
  const [productionType, setProductionType] = useState("");
  const [areaId, setAreaId] = useState("");
  const [showAddModal, setShowAddModal] = useState(false);
  const [showAddConfirmationAlert, setShowAddConfirmationAlert] =
    useState(false);
  const [statusChangeAlert, setStatusChangeAlert] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(defaultFormData);
  const [showViewSchedule, setShowViewSchedule] = useState(false);
  const [tab, setTab] = useState("1")

  //toaster section
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const [errors, setErrors] = useState<Errors>({});
  const [userId, setUserId] = useState<number>(0);

  //handle error section
  interface Errors {
    id?: string;
    activity_type_id?: string;
    reason?: string;
    status?: string;
    created_at?: string;
    updated_at?: string;
    created_by?: string;
    updated_by?: string;
    activity_type_name?: string;
    area_grade_name?: string;
    production_schedule_name?: string;
    product_name?: string;
    production_type?: string;
    area_id?: string;
    from_date_time?: string;
    to_date_time?: string;
    area_name?: string;
    batchId?: string;
    lotId?: string;
    nextSerialNo?: string;
    plateCount?: string;
  }
  const [masterFormData, setMasterFormData] = useState(defaultFormData);
  const [modalName, setModalName] = useState("");
  const [updatedStatus, setUpdatedStatus] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [sort, setSort] = useState("id");
  const [order, setOrder] = useState("desc");
  const [permission, setPermission] = useState<any>([]);
  const [activityType, setActivityType] = useState<Array<any>>([]);
  const [areaList, setAreaList] = useState<Array<any>>([]);

  const defaultQrFormData = {
    id: "",
    activity_type_id: "",
    batchId: "",
    lotId: "",
    nextSerialNo: "",
    plateCount: "",
  };

  const [showGenerateQrModal, setShowGenerateQrModal] = useState(false);
  // table pagination
  const [opPage, setOpPage] = useState(1);
  const [opPageSize, setOpPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [opTotalPages, setOpTotalPages] = useState(0);
  const [opTotalElements, setOpTotalElements] = useState(0);
  const [opTableData, setOpTableData] = useState<Array<any>>([]);
  const [qrFormData, setQrFormData] = useState(defaultQrFormData);
  const [batchList, setBatchList] = useState<Array<any>>([]);
  const [lotList, setLotList] = useState<Array<any>>([]);
  const [totalPlateCount, setTotalPlateCount] = useState(0);
  const [selectedOpSlNo, setSelectedOpSlNo] = useState<Array<any>>([]);
  const [cellWidth, setCellWidth] = useState(1);
  const [qrModalShow, setQrModalShow] = useState(false);
  const [selectedSlnoLen, setSelectedSlnoLen] = useState(0);
  const [qrFiles, setQrFiles] = useState<string[]>([]);
  const [selectedOperatorIds, setSelectedOperatorIds] = useState([]);
  // const [tab, setTab] = useState("1")


  const [frequencyPreviewTableData, setFrequencyPreviewTableData] = useState<
    Array<any>
  >([]);

  //handle user details for submit handling
  useEffect(() => {
    var storedData = localStorage.getItem("userDetails");
    if (storedData) {
      var storedObject = JSON.parse(storedData);
      setUserId(storedObject[0].id);
      getPermissionByRole(storedObject[0].role_master_id);
    }
    getActivityTypeList();
    getAreaList();
    getProductionScheduleList();
    setUseEffectRequired(false);
    setFrequencyPreviewTableData([]);
  }, [useEffectRequired]);

  //get permission by role
  const getPermissionByRole = async (role: number) => {
    try {
      const response = await userService.getPermissionByRole(role);
      if (response.status && response.statusCode === 200) {
        setPermission(response.data.permissions);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getFrequencyPreviewTableData = async (data: any) => {
    try {
      const response = await scheduleService.check_area_activity_frequencies(
        data
      );
      if (response.status && response.statusCode === 200) {
        console.log("getFrequencyPreviewTableData=>response", response.data);
        let columns: any = [];
        Object.values(response.data).forEach((val: any, index: any) => {
          let critical_frequency = "NA";
          let noncritical_frequency = "NA";
          let activity_frequencies_id = "NA";
          let location_name = "NA";
          // let activity_workflow_definition_name = "NA";
          if (_h.arrayHasData(val?.activity_frequencies)) {
            Object.values(val?.activity_frequencies).forEach((frq: any) => {
              activity_frequencies_id = frq.id
              // activity_workflow_definition_name = frq.activity_workflow_definition_name
              location_name = frq.location_name
              let freq =
                "Once in every " +
                frq?.frequency_period +
                " " +
                frq?.frequency
                  .replace("Hourly", "Hour")
                  .replace("Daily", "Day")
                  .replace("Weekly", "Week")
                  .replace("Monthly", "Month")
                  .replace("Yearly", "Year");
              // frq?.frequency + " " + frq?.frequency_period + " " + "Times";
              if (_h.arrayHasData(frq?.day)) {
                freq += " / " + frq?.day.join(", ");
              }
              if (_h.arrayHasData(frq?.week)) {
                freq += " / " + "Week- " + frq?.week.join(", ");
              }
              if (_h.arrayHasData(frq?.month)) {
                freq += " / " + frq?.month.join(", ");
              }
              if (frq?.location_category_id == 1) {
                critical_frequency = freq;
              }
              if (frq?.location_category_id == 2) {
                noncritical_frequency = freq;
              }
            });
          }
          columns.push({
            id: activity_frequencies_id,
            slno: index + 1,
            name: val?.name,
            location_name: location_name,
            // activity_workflow_definition_name: activity_workflow_definition_name,
            critical_frequency: critical_frequency,
            noncritical_frequency: noncritical_frequency,
          });
        });
        setFrequencyPreviewTableData(columns);
      } else {
        setFrequencyPreviewTableData([]);
      }
    } catch (error) {
      setFrequencyPreviewTableData([]);
      console.error(error);
    }
  };

  //handle onchange input fields
  const handleFieldOnChange = (e: any) => {
    const { name, value } = e.target;
    console.log("handleFieldOnChange=>{ name, value }", { name, value });
    if (name == "area_id") {
      let area = areaList.filter((val) => value == val?.id);
      if (_h.arrayHasData(area) && _h.objectHasValue(area[0])) {
        setMasterFormData((prevFormData: any) => ({
          ...prevFormData,
          area_grade_name: area[0]?.grade_name,
        }));
      } else {
        setMasterFormData((prevFormData: any) => ({
          ...prevFormData,
          area_grade_name: "",
        }));
      }
    }
    if (name == "from_date_time" && _h.isNotEmpty(value)) {
      if (moment(value, "YYYY-MM-DD").format("DD-MM-YYYY") > masterFormData.to_date_time) {
        setMasterFormData((prevFormData: any) => ({
          ...prevFormData,
          to_date_time: "",
        }));
      }
      setMasterFormData((prevFormData: any) => ({
        ...prevFormData,
        from_date_time: moment(value, "YYYY-MM-DD").format(
          "DD-MM-YYYY"
        ),
      }));
    } else if (name == "to_date_time" && _h.isNotEmpty(value)) {
      setMasterFormData((prevFormData: any) => ({
        ...prevFormData,
        to_date_time: moment(value, "YYYY-MM-DD").format(
          "DD-MM-YYYY"
        ),
      }));
    } else {
      setMasterFormData((prevFormData: any) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
    if (name == "production_type" || name == "area_id") {
      if (name == "area_id") {
        getFrequencyPreviewTableData({
          area_id: value,
          production_type: masterFormData?.production_type,
          sort: sort,
          order: order
        });
      } else if (name == "production_type") {
        getFrequencyPreviewTableData({
          production_type: value,
          area_id: masterFormData?.area_id,
          sort: sort,
          order: order
        });
      } else {
        setFrequencyPreviewTableData([]);
      }
    }
    console.log("handleFieldOnChange=>masterFormData", masterFormData);
  };

  //clear modal on close
  const handleFormClose = () => {
    setShowAddModal(false);
    setErrors({});
    setMasterFormData(defaultFormData);
  };

  //handle area validation starts here
  const handleFormValidation = () => {
    const errors: Errors = {};
    // if (!masterFormData.activity_type_id) {
    //   errors.activity_type_id = "Please select activity type";
    // }
    if (!masterFormData.production_schedule_name) {
      errors.production_schedule_name = "Please enter production schedule name";
    }
    if (!masterFormData.product_name) {
      errors.product_name = "Please enter product name";
    }
    if (!masterFormData.production_type) {
      errors.production_type = "Please enter product type";
    }
    if (!masterFormData.area_id) {
      errors.area_id = "Please select area";
    }
    if (!masterFormData.from_date_time) {
      errors.from_date_time = "Please select from date time";
    }
    if (!masterFormData.to_date_time) {
      errors.to_date_time = "Please select to date time";
    }
    // if (
    //   _h.isNotEmpty(masterFormData.from_date_time) &&
    //   _h.isNotEmpty(masterFormData.to_date_time)
    // ) {
    //   let fromEpoch = new Date(masterFormData.from_date_time).valueOf();
    //   let toEpoch = new Date(masterFormData.to_date_time).valueOf();
    //   if (fromEpoch >= toEpoch) {
    //     errors.from_date_time = "From date should be less than To date";
    //     errors.to_date_time = "To date should be greater than From date";
    //   }
    // }

    if (masterFormData.id && !masterFormData.reason) {
      errors.reason = "Please enter reason for edit";
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    setShowAddConfirmationAlert(true);
    setErrors({});
  };

  //handle add area submit api call
  const handleFormSubmit = async () => {
    const dataToSend = {
      schedule_frequency_ids: selectedOperatorIds,
      id: masterFormData.id,
      production_schedule_name: masterFormData.production_schedule_name,
      product_name: masterFormData.product_name,
      production_type: masterFormData.production_type,
      area_id: masterFormData.area_id,
      activity_type_id: masterFormData.activity_type_id,
      from_date_time: _h.isNotEmpty(masterFormData.from_date_time)
        ? moment(masterFormData.from_date_time, "DD-MM-YYYY - HH:mm:ss").format(
            "YYYY-MM-DD HH:mm:ss"
          )
        : "",
      to_date_time: _h.isNotEmpty(masterFormData.to_date_time)
        ? moment(masterFormData.to_date_time, "DD-MM-YYYY - HH:mm:ss").format(
            "YYYY-MM-DD 23:59:59"
          )
        : "",
      interface_name: "Production Schedule",
      reason: masterFormData.reason,
    };

    try {
      const response = await scheduleService.createScheduleProduction(
        dataToSend
      );
      if (response.status && response.statusCode === 200) {
        setToastType("success");
        setToastMessage(response.message);
        getProductionScheduleList();
        handleFormClose();
      } else {
        setToastType("error");
        setToastMessage(response.message);
      }
      setShowAddConfirmationAlert(false);
      setShowToast(true);
    } catch (error) {
      console.error(error);
    }
  };

  //table filter section
  const handleFilter = (key: string, value: any) => {
    if (key === "search") {
      setkeyWord(value);
    } else if (key === "productionType") {
      setProductionType(value)
    } else if (key === "area") {
      setAreaId(value)
    } else if (key === "FromDate") {
      const formattedDate = value ? moment(value).format("YYYY-MM-DD 00:00:00") : "";
      setFromDate(formattedDate)
      setToDate("")
    } else if (key === "ToDate") {
      const formattedDate = value ? moment(value).format("YYYY-MM-DD 23:59:59") : "";
      setToDate(formattedDate)
    }
    setUseEffectRequired(true);
  };

  // function to sort table
  const handleSort = (key: string, value: boolean) => {
    setSort(key);
    if (value) {
      setOrder("asc");
    } else {
      setOrder("desc");
    }
    setUseEffectRequired(true);
  };

  // function to sort table
  const handleAddProTableSort = (key: string, value: boolean) => {
    setSort(key);
    let newOrder = order
    if (value) {
      setOrder("asc");
      newOrder = "asc"
    } else {
      setOrder("desc");
      newOrder = "desc"
    }
    getFrequencyPreviewTableData({
      production_type: masterFormData?.production_type,
      area_id: masterFormData?.area_id,
      sort: key,
      order: newOrder
    });
  };

  //handle table pagination
  const handlePageChange = (newPage: any, pageSize?: number) => {
    setPage(newPage);
    if (pageSize) {
      setPageSize(pageSize);
    }
    setUseEffectRequired(true);
  };

  //open add area modal
  const handleAddClick = () => {
    setShowAddModal(true);
    setModalName("Add Production Schedule");
    setFrequencyPreviewTableData([]);
  };

  const handleStatusChange = (id: string, status: string) => {
    setSelectedId(id);
    if (status === "active") {
      setUpdatedStatus("inactive");
    } else {
      setUpdatedStatus("active");
    }
    setStatusChangeAlert(true);
  };

  //change area status api call
  const handleStatusChangeSubmit = async () => {
    try {
      const response = await scheduleService.changeProductionScheduleStatus(
        selectedId,
        updatedStatus,
        "Production Schedule"
      );

      if (response.status && response.statusCode === 200) {
        setToastType("success");
        setToastMessage(response.message);
      } else {
        setToastType("error");
        setToastMessage(response.message);
      }
      setShowToast(true);
      setUseEffectRequired(true);
      setUpdatedStatus("");
      setSelectedId("");
      setStatusChangeAlert(false);
    } catch (error) {
      console.error(error);
    }
  };

  //filter selected id based on the row selected while editing
  const handleEditClick = (id: string) => {
    const row = tableData.filter((item) => item.slno === id);
    if (_h.arrayHasData(row) && _h.objectHasValue(row[0])) {
      console.log("handleEditClick=>row[0]", row[0]);
      setMasterFormData(row[0]);
      setShowAddModal(true);
      setModalName("Edit Production Schedule");
      setFrequencyPreviewTableData([]);
      getFrequencyPreviewTableData({
        area_id: row[0]?.area_id,
        production_type: row[0]?.production_type,
        sort: sort,
        order: order
      });
    }
  };

  const handleViewClick = (id: string, viewValue: boolean) => {
    const row = tableData.filter((item) => item.slno === id);
    if (_h.arrayHasData(row) && _h.objectHasValue(row[0])) {
      console.log("handleEditClick=>row[0]", row[0]);
      setSelectedRow(row[0]);
      setShowViewModal(true);
    }
  };

  // get table data
  const getProductionScheduleList = async () => {
    try {
      const response = await scheduleService.getProductionScheduleList(
        page,
        pageSize,
        sort,
        order,
        keyWord,
        fromDate,
        toDate,
        productionType,
        areaId,
      );
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            setTotalPages(response?.totalPages);
            setTotalElements(response?.totalElements);
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                return {
                  slno: index + 1,
                  id: element.id,
                  activity_type_name: element.activity_type_name,
                  status: element.status,
                  activity_type_id: element.activity_type_id,
                  created_at: element?.created_at
                    ? moment(element?.created_at, "YYYY-MM-DD HH:mm:ss").format(
                        "DD-MM-YYYY - HH:mm:ss"
                      )
                    : "-",
                  updated_at: element?.updated_at
                    ? moment(element?.updated_at, "YYYY-MM-DD HH:mm:ss").format(
                        "DD-MM-YYYY - HH:mm:ss"
                      )
                    : "-",
                  production_schedule_name: element?.production_schedule_name,
                  product_name: element?.product_name,
                  production_type: element?.production_type,
                  area_id: element?.area_id,
                  from_date_time: element?.from_date_time
                    ? moment(
                        element?.from_date_time,
                        "YYYY-MM-DD HH:mm:ss"
                      ).format("DD-MM-YYYY")
                    : "-",
                  to_date_time: element?.to_date_time
                    ? moment(
                        element?.to_date_time,
                        "YYYY-MM-DD HH:mm:ss"
                      ).format("DD-MM-YYYY")
                    : "-",
                  area_name: element?.area_name,
                  area_grade_name: element?.area_grade_name,
                };
              }
            );
            setTableData(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        setTableData([]);
        setTotalPages(0);
        setTotalElements(0);
      }
    } catch (error) {
      console.error("user response", error);
    }
  };

  // get Activity Type List
  const getActivityTypeList = async () => {
    try {
      const response = await scheduleService.getActivityTypeList();
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                return {
                  slno: index + 1,
                  id: element.id,
                  name: element.name,
                };
              }
            );
            setActivityType(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        setActivityType([]);
      }
    } catch (error) {
      console.error("user response", error);
    }
  };

  //api call to get area list
  const getAreaList = async () => {
    try {
      const response = await scheduleService.getAreaList(sort, order, "6");
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                return {
                  id: element.id,
                  name: element.name,
                  grade_name: element.grade_name,
                };
              }
            );
            setAreaList(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        setAreaList([]);
      }
    } catch (error) {
      console.error("user response", error);
    }
  };

  //clear modal on close - QR
  const handleQRGenerate = (row: any) => {
    setSelectedRow(row);
    setQrFormData(defaultQrFormData);
    getBatchList();
    getProductionOperators(row?.id);
    setShowGenerateQrModal(true);
  };

  //clear modal on close - QR
  const handleQRFormClose = () => {
    setQrFormData(defaultQrFormData);
    setSelectedRow(defaultFormData);
    setShowGenerateQrModal(false);
  };

  //handle onchange input QR fields
  const handleQRFieldOnChange = async (e: any) => {
    const { name, value } = e.target;
    console.log("handleFieldOnChange=>{ name, value }", { name, value });

    if (name == "batchId") {
      let lots = await getLotList(value);
      setLotList(lots);
    }
    setQrFormData((prevFormData: any) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  //handle single and multi QR download button click
  // const handleQrDownload = async (slno: string[], type: string) => {
  //   const selectedOperators = opTableData.filter((item: any) =>
  //     slno.includes(item.slno)
  //   );
  //   if (!_h.arrayHasData(selectedOperators)) {
  //     setToastType("error");
  //     setToastMessage("Please select atleast one operator");
  //     setShowToast(true);
  //     return;
  //   }
  //   handleQRFormValidation(selectedOperators);
  // };

  //handle single and multi QR download button click
  const handleQrDownload = async () => {
    const selectedOperators = opTableData.filter((item: any) =>
      selectedOpSlNo.includes(item.slno)
    );
    handleQRFormValidation(selectedOperators);
  };

  //handle area validation starts here
  const handleQRFormValidation = (selectedOperators: any[]) => {
    const errors: Errors = {};
    // if (!qrFormData.activity_type_id) {
    //   errors.activity_type_id = "Please select an activity type";
    // }
    if (!qrFormData.batchId) {
      errors.batchId = "Please select a batch";
    }
    if (!qrFormData.lotId) {
      errors.lotId = "Please select a lot";
    }
    // if (!qrFormData.nextSerialNo) {
    //   errors.nextSerialNo = "Please enter next serial No";
    // }
    // if (!qrFormData.plateCount) {
    //   errors.plateCount = "Please enter plate count";
    // }
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    if (!_h.arrayHasData(selectedOperators)) {
      setToastType("error");
      setToastMessage("Please select atleast one operator");
      setShowToast(true);
      return;
    }
    setErrors({});
    handleQRFormSubmit(selectedOperators);
  };

  //handle add area submit api call
  const handleQRFormSubmit = async (selectedOperators: any[]) => {
    if (_h.isNotEmpty(selectedOperators)) {
      // let i = 0;
      let totalQuantity = 0;
      let request: any = [];
      Object.values(selectedOperators).forEach(async (val) => {
        request.push({
          lot_id: qrFormData.lotId,
          quantity: val.plateCount,
          master_activity_id: val?.activity_type_id,
          location_id: val.location_id,
          operator_id: val.user_master_id,
          schedule_operator_id: val.id,
        });
        totalQuantity += val.plateCount;
        // const dataToSend = {
        //   lot_id: qrFormData.lotId,
        //   quantity: val.plateCount,
        //   master_activity_id: val?.activity_type_id,
        //   location_id: val.location_id,
        //   operator_id: val.user_master_id,
        //   schedule_operator_id: val.id,
        // };
        // try {
        //   const response = await scheduleService.generatePlateQr(dataToSend);
        //   if (response.status && response.statusCode === 200) {
        //     i++;
        //     if (selectedOperators.length <= i) {
        //       setToastType("success");
        //       setToastMessage(response.message);
        //       getProductionOperators(selectedRow?.id);
        //       setShowToast(true);
        //       // handleFormClose();
        //     }
        //   } else {
        //     setToastType("error");
        //     setToastMessage(response.message);
        //     getProductionScheduleList();
        //     setShowToast(true);
        //     return;
        //   }
        // } catch (error) {
        //   console.error(error);
        //   getProductionScheduleList();
        // }
      });
      try {
        const response = await scheduleService.generateBulkPlateQr({
          operators: request,
          lot_id: qrFormData.lotId,
          totalQuantity: totalQuantity,
          interface_name: modalName,
        });
        if (response.status && response.statusCode === 200) {
          setToastType("success");
          setToastMessage(response.message);
        } else {
          setToastType("error");
          setToastMessage(response.message);
        }
        getProductionOperators(selectedRow?.id);
        setShowToast(true);
      } catch (error) {
        console.error(error);
        getProductionScheduleList();
      }
    }
  };

  //api call to get batch list
  const getBatchList = async () => {
    try {
      const response = await scheduleService.getBatchList();
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                return {
                  id: element.id,
                  name: element.batch_no,
                };
              }
            );
            setBatchList(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        setBatchList([]);
      }
    } catch (error) {
      console.error("user response", error);
    }
  };

  //api call to get lot list
  const getLotList = async (batchId: string) => {
    let rowData: any = [];
    try {
      const response = await scheduleService.getLotList(
        _h.isNotEmpty(batchId) ? batchId : defaultQrFormData?.batchId
      );
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            rowData = response.data.map((element: any, index: number) => {
              return {
                id: element.id,
                name: element.lot_code,
              };
            });
            setLotList(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        setLotList([]);
      }
    } catch (error) {
      console.error("user response", error);
    }
    return rowData;
  };

  // get table data
  const getProductionOperators = async (schedule_production_id: string) => {
    try {
      const response = await scheduleService.getProductionOperators({
        schedule_production_id: _h.isNotEmpty(selectedRow?.id)
          ? selectedRow?.id
          : schedule_production_id,
      });
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            setOpTotalPages(response?.totalPages);
            setOpTotalElements(response?.totalElements);
            let plateCount = 0;
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                plateCount += parseInt(element?.plateCount);
                return {
                  slno: index + 1,
                  id: element.id,
                  plateCount: element?.plateCount,
                  location_name: element?.location_name || "-",
                  location_id: element?.location_id,
                  status: element.status,
                  activity_type_id: element.activity_type_id,
                  created_at: element?.created_at
                    ? moment(element?.created_at, "YYYY-MM-DD HH:mm:ss").format(
                        "DD-MM-YYYY - HH:mm:ss"
                      )
                    : "-",
                  updated_at: element?.updated_at
                    ? moment(element?.updated_at, "YYYY-MM-DD HH:mm:ss").format(
                        "DD-MM-YYYY - HH:mm:ss"
                      )
                    : "-",
                  schedule_production_id: element?.schedule_production_id,
                  start_date_time: element?.start_date_time
                    ? moment(
                        element?.start_date_time,
                        "YYYY-MM-DD HH:mm:ss"
                      ).format("DD-MM-YYYY - HH:mm:ss")
                    : "-",
                  end_date_time: element?.end_date_time
                    ? moment(
                        element?.end_date_time,
                        "YYYY-MM-DD HH:mm:ss"
                      ).format("DD-MM-YYYY - HH:mm:ss")
                    : "-",
                  process_id: element?.process_id,
                  user_master_id: element?.user_master_id,
                  created_by: element?.created_by,
                  updated_by: element?.updated_by,
                  production_schedule_name: element?.production_schedule_name,
                  product_name: element?.product_name,
                  production_type: element?.production_type,
                  user_first_name: element?.user_first_name,
                  user_last_name: element?.user_last_name,
                  operator_name: (
                    (element?.user_first_name).trim() +
                    " " +
                    (element?.user_last_name).trim()
                  ).trim(),
                  process_name: element?.process_name,
                  activity_type_name: element?.activity_type_name,
                  work_shift_name: element?.work_shift_name,
                };
              }
            );
            setTotalPlateCount(plateCount);
            setOpTableData(rowData);
          } else {
            setTotalPlateCount(0);
            setOpTableData([]);
          }
        } catch (error) {
          console.log(error);
          setTotalPlateCount(0);
          setOpTableData([]);
        }
      } else {
        setTotalPlateCount(0);
        setOpTableData([]);
      }
    } catch (error) {
      console.error("user response", error);
      setTotalPlateCount(0);
      setOpTableData([]);
    }
  };

  //handle op table pagination
  const handleOpPageChange = (newPage: any, pageSize?: number) => {
    setOpPage(newPage);
    if (pageSize) {
      setOpPageSize(pageSize);
    }
    getProductionOperators("");
  };

  const downloadPlateQr = async (row: any) => {
    try {
      const response = await scheduleService.getGeneratedProductionPlateQr({
        schedule_production_id: row?.id,
      });
      if (response.status && response.statusCode === 200) {
        if (Array.isArray(response.data) && response.data?.length > 0) {
          setSelectedSlnoLen(response.data?.length);
          setQrFiles(response.data);
          setQrModalShow(true);
        } else {
          // setToastType("success");
          setToastType("error");
          setToastMessage("No plates are generated yet.");
          setShowToast(true);
        }
      } else {
        setToastType("error");
        setToastMessage(response.message);
        setShowToast(true);
      }
    } catch (error) {
      console.error("user response", error);
    }
  };

  const checkSpecialChar =(e : any)=>{
    if(!/[0-9a-zA-Z]/.test(e.key)){
     e.preventDefault();
    }
   };

   const addProductionActionClick = (slno: string[]) => {
    const filteredPlateDetailsIds : any = frequencyPreviewTableData
        .filter((item: any) => slno.includes(item.slno))
        .map((item: any) => Number(item.id));
    setSelectedOperatorIds(filteredPlateDetailsIds)
  }

  //handle download qr button
  const handleDownloadBtn = () => {
    const content: any = document.getElementById("innerDiv");
    const pri: any = window.open("", "", "height=600,width=800");
    pri.document.write(content.innerHTML);
    pri.onafterprint = () => {
      pri.close();
      setQrModalShow(false);
    };
    pri.print();
  };

  //create grid for qr section
  const createGrid = () => {
    return (
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {qrFiles.map((cellIndex: any, index: number) => (
          <div
            key={index}
            style={{
              border: "0.5px solid grey",
              margin: "15px 15px",
              alignItems: "center",
            }}
          >
            <img
              src={baseUrl + "/" + cellIndex?.qr_file_path}
              alt={`Image ${cellIndex?.production_schedule_name}`}
              style={{
                width: `${cellWidth}cm`,
                height: `${cellWidth}cm`,
                pageBreakInside: "avoid",
              }}
            />
            <p
              style={{
                fontSize: `${cellWidth + 10}px`,
                fontWeight: "400",
                margin: "2%",
                textAlign: "center",
              }}
            >
              {/* Production: <u>{cellIndex?.production_schedule_name}</u>
              <br></br>
              Activity: <u>{cellIndex?.activity_type_name}</u>
              <br></br>
              Process: <u>{cellIndex?.process_name}</u>
              <br></br> */}
              {cellIndex?.location_name}
              <br></br>
              {cellIndex?.user_first_name + " " + cellIndex?.user_last_name}
              <br></br>
              {cellIndex?.serial_number}
              {/* Use Within:{" "}
              <u>
                {moment(
                  cellIndex.start_date_time,
                  "YYYY-MM-DD HH:mm:ss"
                ).format("DD-MM-YYYY - HH:mm:ss") +
                  " and " +
                  moment(cellIndex.end_date_time, "YYYY-MM-DD HH:mm:ss").format(
                    "DD-MM-YYYY - HH:mm:ss"
                  )}
              </u> */}
            </p>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
      <HeaderSection />
      <PageHeader pageHeader={t("scheduleProduction.pageTitle")} />
      <CustomToast
        show={showToast}
        onClose={() => setShowToast(false)}
        delay={5000}
        message={toastMessage}
        toastType={toastType}
      />
      {tab === "1" && (
        <div className="me-3" style={{ marginTop: "10px" }}>
          <div className=" mx-3 pe-2" style={{ height: "45vh" }}>
            <CustomTable
              tableHead={t("scheduleProduction.tableHead")}
              data={tableData}
              columns={columns}
              isEditable={permission?.includes("edit_production_schedule")}
              totalElements={totalElements}
              isActionVisible={false}
              isViewVisible={true}
              onViewClick={handleViewClick}
              isSeachable={true}
              addButton={permission?.includes("add_production_schedule")}
              addButtonText={t("scheduleProduction.add")}
              onFilterClick={handleFilter}
              onSortClick={handleSort}
              onAddClick={handleAddClick}
              isToggleStatus={true}
              onStatusChange={handleStatusChange}
              isQrDownloadable={false}
              onEditClick={handleEditClick}
              showQrGenerateButton={false}
              areaDropList={areaList}
              showToDateFilter={true}
              showFromDateFilter={true}
              dateType='date'
              onBlueBtnClick={() => setTab("2")}
              blueBtnText="Production Calendar"
              showBlueBtn={permission?.includes("view_production_calendar")}
              // actionButtons={[
              //   {
              //     thLabel: t("scheduleProduction.tableColumns.generatePlateQr"),
              //     buttonLabel: t(
              //       "scheduleProduction.tableColumns.generatePlateQr"
              //     ),
              //     handleAction: (row: any) => {
              //       console.log("customRowButton=>handleAction", row);
              //       handleQRGenerate(row);
              //     },
              //   },
              //   {
              //     thLabel: t("scheduleProduction.tableColumns.downloadPlateQr"),
              //     buttonLabel: t(
              //       "scheduleProduction.tableColumns.downloadPlateQr"
              //     ),
              //     handleAction: (row: any) => {
              //       console.log("customRowButton=>handleAction", row);
              //       downloadPlateQr(row);
              //     },
              //   },
              // ]}
            />
          </div>
          <div>
            <Pagination
              page={page}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
            />
          </div>
        </div>
      )}

      {tab === "2" && (
        <ViewSchedule setTab={setTab} />
      )}


      {/* Alert modal for Add/edit Schedule */}
      <AlertModal
        show={showAddConfirmationAlert}
        onHide={() => setShowAddConfirmationAlert(false)}
        title="Alert"
        message={modalName}
        onConfirm={handleFormSubmit}
      />

      {/* Alert modal for change Schedule status */}
      <AlertModal
        show={statusChangeAlert}
        onHide={() => setStatusChangeAlert(false)}
        title="Alert"
        message={`Change Production Schedule Status?`}
        onConfirm={handleStatusChangeSubmit}
      />

      {/*modal for Add/edit Schedule */}
      <Modal
        className="top-right-modal-md"
        centered={false}
        show={showAddModal}
        // size='lg'
      >
        <div>
          <div className="modalHead">
            <p className="modalHeadText ms-3 pt-3 p-2">{modalName}</p>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => handleFormClose()}
              className="p-2 mx-3 my-2 "
            >
              <TfiClose fill="#FFFFFF" />
            </div>
          </div>
          <div className="selectWrap-new">
            <div className="form-group my-1 mx-3 px-2">
              <label>
                {t("scheduleProduction.production_schedule_name")}
                <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <input
                id="production_schedule_name"
                value={masterFormData.production_schedule_name}
                name="production_schedule_name"
                type="text"
                placeholder={t("scheduleProduction.production_schedule_name")}
                className="placeholder-style"
                onChange={handleFieldOnChange}
              />
              {errors.production_schedule_name && (
                <p className="errorText">{errors.production_schedule_name}</p>
              )}
            </div>
            <div className="form-group my-1 mx-3 px-2">
              <label>
                {t("scheduleProduction.product_name")}
                <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <input
                id="product_name"
                value={masterFormData.product_name}
                name="product_name"
                type="text"
                placeholder={t("scheduleProduction.product_name")}
                className="placeholder-style"
                onChange={handleFieldOnChange}
              />
              {errors.product_name && (
                <p className="errorText">{errors.product_name}</p>
              )}
            </div>
            <div className="form-group my-1 mx-3 px-2">
              <label>
                {t("scheduleProduction.production_type")}
                <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              {/* <input
                id="production_type"
                value={masterFormData.production_type}
                name="production_type"
                type="text"
                placeholder={t("scheduleProduction.production_type")}
                className="filter-input"
                onChange={handleFieldOnChange}
              /> */}
              <select
                id="production_type"
                value={masterFormData?.production_type}
                name="production_type"
                onChange={handleFieldOnChange}
              >
                <option value="">Select</option>
                <option key={"operational"} value={"operational"}>
                  {"Operational"}
                </option>
                <option key={"non-operational"} value={"non-operational"}>
                  {"Non-operational"}
                </option>
                {/* <option key={"ongoing"} value={"ongoing"}>
                  {"Ongoing"}
                </option> */}
              </select>
              {errors.production_type && (
                <p className="errorText">{errors.production_type}</p>
              )}
            </div>
          </div>
          <div className="selectWrap-new">
            <div className="form-group my-1 mx-3 px-2">
              <label>
                {t("scheduleProduction.area")}
                <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <select
                id="area_id"
                value={masterFormData?.area_id}
                name="area_id"
                onChange={handleFieldOnChange}
              >
                <option value="">Select</option>
                {areaList.map((val) => (
                  <option key={val.id} value={val.id}>
                    {val.name}
                  </option>
                ))}
              </select>
              {errors.area_id && <p className="errorText">{errors.area_id}</p>}
            </div>
            <div className="form-group my-1 mx-3 px-2">
              <label>{t("scheduleProduction.area_grade_name")}</label>
              <br />
              <input
                id="area_grade_name"
                value={masterFormData.area_grade_name}
                name="area_grade_name"
                type="text"
                placeholder={t("scheduleProduction.selectArea")}
                className="placeholder-style"
                disabled={true}
              />
            </div>
            {/* visiblity hidden fake field */}
            <div className="form-group my-1 mx-3 px-2" style={{"visibility":"hidden"}}>
              <label>{t("scheduleProduction.area_grade_name")}</label>
              <br />
              <input
                id="area_grade_name"
                value={masterFormData.area_grade_name}
                name="area_grade_name"
                type="text"
                placeholder={t("scheduleProduction.selectArea")}
                className="filter-input"
                disabled={true}
              />
            </div>
          </div>
          <div className="selectWrap-new">
            <div className="form-group my-1 mx-3 px-2" style={{"width" : "208px"}}>
              <label>
                {t("scheduleProduction.from_date_time")}
                <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <input
                type="date"
                name="from_date_time"
                id="from_date_time"
                value={
                  _h.isNotEmpty(masterFormData.from_date_time)
                    ? moment(
                        masterFormData.from_date_time,
                        "DD-MM-YYYY - HH:mm:ss"
                      ).format("YYYY-MM-DD")
                    : ""
                }
                onChange={handleFieldOnChange}
                className="filter-input"
                min={moment(dateTime).format("YYYY-MM-DD")}
                onKeyDown={(e) => {
                  const allowedKeys = [
                    "Tab",
                    "Enter",
                    "Escape",
                  ];
                  if (!allowedKeys.includes(e.key)) {
                    e.preventDefault();
                  }
                }}
                onPaste={(e) => e.preventDefault()}
              />
              {errors.from_date_time && (
                <p className="errorText">{errors.from_date_time}</p>
              )}
            </div>
            <div className="form-group my-1 mx-3 px-2" style={{"width" : "208px"}}>
              <label>
                {t("scheduleProduction.to_date_time")}
                <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <input
                type="date"
                name="to_date_time"
                id="to_date_time"
                value={
                  _h.isNotEmpty(masterFormData.to_date_time)
                    ? moment(
                        masterFormData.to_date_time,
                        "DD-MM-YYYY - HH:mm:ss"
                      ).format("YYYY-MM-DD")
                    : ""
                }
                min={_h.isNotEmpty(masterFormData.from_date_time)
                  ? moment(
                      masterFormData.from_date_time,
                      "DD-MM-YYYY"
                    ).format("YYYY-MM-DD")
                  : ""}
                onChange={handleFieldOnChange}
                className="filter-input"
                onKeyDown={(e) => {
                  const allowedKeys = [
                    "Tab",
                    "Enter",
                    "Escape",
                  ];
                  if (!allowedKeys.includes(e.key)) {
                    e.preventDefault();
                  }
                }}
                onPaste={(e) => e.preventDefault()}
              />
              {errors.to_date_time && (
                <p className="errorText">{errors.to_date_time}</p>
              )}
            </div>
            {masterFormData.id && (
              <div className="form-group my-1 mx-3 px-2">
                <label htmlFor="" className="ReasonLabel">
                  {t("userManagement.reason")}{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <br />
                <input
                  id="reason"
                  value={masterFormData.reason}
                  name="reason"
                  type="text"
                  placeholder={t("userManagement.reason")}
                  className="reasonClass"
                  onChange={handleFieldOnChange}
                  onKeyDown={(e)=>checkSpecialChar(e)}
                />
                {errors.reason && <p className="errorText">{errors.reason}</p>}
              </div>
            )}
          </div>
          {/* <div className="selectWrap-new">
            {masterFormData.id && (
              <div className="form-group my-1 mx-3 px-2">
                <label htmlFor="" className="ReasonLabel">
                  {t("userManagement.reason")}{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <br />
                <input
                  id="reason"
                  value={masterFormData.reason}
                  name="reason"
                  type="text"
                  placeholder={t("userManagement.reason")}
                  className="reasonClass"
                  onChange={handleFieldOnChange}
                  onKeyDown={(e)=>checkSpecialChar(e)}
                />
                {errors.reason && <p className="errorText">{errors.reason}</p>}
              </div>
            )}
          </div> */}
          <div className="me-3" style={{ marginTop: "1px" }}>
            <div className=" mx-3 pe-2" style={{ height: "25vh" }}>
              <CustomTable
                tableHead={t("scheduleProduction.activityFrequencies")}
                data={frequencyPreviewTableData}
                columns={activityFrequencyColumns}
                isEditable={false}
                totalElements={frequencyPreviewTableData.length}
                isActionVisible={true}
                isViewVisible={false}
                // onViewClick={handleViewClick}
                isSeachable={false}
                // addButton={permission?.includes("add_production_schedule")}
                // addButtonText={t("scheduleProduction.add")}
                // onFilterClick={handleFilter}
                onSortClick={handleAddProTableSort}
                // onAddClick={handleAddClick}
                isToggleStatus={false}
                // onStatusChange={handleStatusChange}
                isQrDownloadable={false}
                // onEditClick={handleEditClick}
                showQrGenerateButton={false}
                onActionClick={addProductionActionClick}
              />
            </div>
          </div>
          <div className="bottomArea">
            <button
              onClick={() => handleFormClose()}
              className="modalCancelBtn"
            >
              {t("buttons.cancel")}
            </button>
            <button
              onClick={() => handleFormValidation()}
              className="squareOrangeBtn3"
            >
              {t("buttons.submit")}
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        show={showViewModal}
        className="modal-view-audit"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
      >
        <Modal.Header closeButton onClick={() => setShowViewModal(false)}>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("scheduleProduction.view")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 style={{ textAlign: "left" }}>
            {t("scheduleProduction.scheduleProductionDetails")}
          </h4>
          {_h.objectHasValue(selectedRow) && (
            <>
              <div
                className="key-value-pairs"
                style={{
                  display: "flex",
                  padding: "10px",
                  width: "100%",
                  textAlign: "left",
                }}
              >
                <div className="column" style={{ flex: "1" }}>
                  <div className="audit-key">
                    {t(
                      "scheduleProduction.tableColumns.production_schedule_name"
                    )}
                  </div>
                  <div className="audit-value">
                    {selectedRow?.production_schedule_name}
                  </div>
                </div>
                <div className="column" style={{ flex: "1" }}>
                  <div className="audit-key">
                    {t("scheduleProduction.tableColumns.product_name")}
                  </div>
                  <div className="audit-value">{selectedRow?.product_name}</div>
                </div>
                <div className="column" style={{ flex: "1" }}>
                  <div className="audit-key">
                    {t("scheduleProduction.tableColumns.production_type")}
                  </div>
                  <div className="audit-value">
                    {selectedRow?.production_type}
                  </div>
                </div>
                <div className="column" style={{ flex: "1" }}>
                  <div className="audit-key">
                    {t("scheduleProduction.tableColumns.from_date_time")}
                  </div>
                  <div className="audit-value">
                    {selectedRow?.from_date_time}
                  </div>
                </div>
              </div>
              <div
                className="key-value-pairs"
                style={{
                  display: "flex",
                  padding: "10px",
                  width: "100%",
                  textAlign: "left",
                }}
              >
                <div className="column" style={{ flex: "1" }}>
                  <div className="audit-key">
                    {t("scheduleProduction.tableColumns.to_date_time")}
                  </div>
                  <div className="audit-value">{selectedRow?.to_date_time}</div>
                </div>
                <div className="column" style={{ flex: "1" }}>
                  <div className="audit-key">
                    {t("scheduleProduction.tableColumns.area_name")}
                  </div>
                  <div className="audit-value">{selectedRow?.area_name}</div>
                </div>
                <div className="column" style={{ flex: "1" }}>
                  <div className="audit-key">
                    {t("scheduleProduction.tableColumns.area_grade_name")}
                  </div>
                  <div className="audit-value">
                    {selectedRow?.area_grade_name}
                  </div>
                </div>
                <div className="column" style={{ flex: "1" }}>
                  <div className="audit-key">
                    {t("scheduleProduction.tableColumns.created_at")}
                  </div>
                  <div className="audit-value">{selectedRow?.created_at}</div>
                </div>
              </div>
              <div
                className="key-value-pairs"
                style={{
                  display: "flex",
                  padding: "10px",
                  width: "100%",
                  textAlign: "left",
                }}
              >
                <div className="column" style={{ flex: "1" }}>
                  <div className="audit-key">
                    {t("scheduleProduction.tableColumns.updated_at")}
                  </div>
                  <div className="audit-value">{selectedRow?.updated_at}</div>
                </div>
                <div className="column" style={{ flex: "1" }}>
                  <div className="audit-key">
                    {t("scheduleProduction.status")}
                  </div>
                  <div className="audit-value">{selectedRow?.status}</div>
                </div>
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer className="justify-content-start">
          <button
            onClick={() => setShowViewModal(false)}
            className="custom-close-button"
          >
            {t("buttons.close")}
          </button>
        </Modal.Footer>
      </Modal>

      {/*modal for generate QR */}
      <Modal
        className="top-right-modal-md"
        centered={false}
        show={showGenerateQrModal}
        // size='lg'
      >
        <div>
          <div className="modalHead">
            <p className="modalHeadText ms-3 pt-3 p-2">
              {t("scheduleProduction.downloadGenerateQr")}
            </p>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => handleQRFormClose()}
              className="p-2 mx-3 my-2 "
            >
              <TfiClose fill="#FFFFFF" />
            </div>
          </div>
          <div className="selectWrap-new">
            <div className="form-group my-1 mx-3 px-2">
              <label>
                {t("scheduleProduction.batch")}
                <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <select
                id="batchId"
                name="batchId"
                onChange={handleQRFieldOnChange}
              >
                <option value="">Select</option>
                {batchList.map((val) => (
                  <option key={val.id} value={val.id}>
                    {val.name}
                  </option>
                ))}
              </select>
              {errors.batchId && <p className="errorText">{errors.batchId}</p>}
            </div>
            <div className="form-group my-1 mx-3 px-2">
              <label>
                {t("scheduleProduction.lot")}
                <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <select id="lotId" name="lotId" onChange={handleQRFieldOnChange}>
                <option value="">Select</option>
                {lotList.map((val) => (
                  <option key={val.id} value={val.id}>
                    {val.name}
                  </option>
                ))}
              </select>
              {errors.lotId && <p className="errorText">{errors.lotId}</p>}
            </div>
            {/* <div className="form-group my-1 mx-3 px-2">
              <label>
                {t("scheduleProduction.nextSerialNo")}
                <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <input
                id="nextSerialNo"
                name="nextSerialNo"
                type="text"
                placeholder={t("scheduleProduction.nextSerialNo")}
                className="filter-input"
                onChange={handleQRFieldOnChange}
              />
              {errors.nextSerialNo && (
                <p className="errorText">{errors.nextSerialNo}</p>
              )}
            </div> */}
            <div className="form-group my-1 mx-3 px-2">
              <label>
                {t("scheduleProduction.totalPlatesNeeded")}
                <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <input
                id="plateCount"
                name="plateCount"
                type="text"
                placeholder={t("scheduleProduction.totalPlatesNeeded")}
                className="filter-input"
                onChange={handleQRFieldOnChange}
                disabled={true}
                value={totalPlateCount}
              />
              {errors.plateCount && (
                <p className="errorText">{errors.plateCount}</p>
              )}
            </div>
          </div>
          {/* <div className="selectWrap-new">
            <div className="form-group my-1 mx-3 px-2">
              <label>
                {t("scheduleProduction.activityType")}
                <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <select
                id="activity_type_id"
                name="activity_type_id"
                onChange={handleQRFieldOnChange}
                disabled={true}
                value={selectedRow?.activity_type_id}
              >
                <option value="">select</option>
                {activityType.map((val) => (
                  <option key={val.id} value={val.id}>
                    {val.name}
                  </option>
                ))}
              </select>
              {errors.activity_type_id && (
                <p className="errorText">{errors.activity_type_id}</p>
              )}
            </div>
          </div> */}

          <div className="me-3" style={{ marginTop: "10px" }}>
            <div className="mx-3 pe-2">
              <CustomTable
                tableHead={t("scheduleProduction.productionOperators")}
                data={opTableData}
                columns={opColumns}
                isEditable={false}
                totalElements={opTotalElements}
                isActionVisible={true}
                isViewVisible={false}
                isSeachable={false}
                addButton={false}
                isToggleStatus={false}
                isQrDownloadable={false}
                showQrGenerateButton={false}
                // isQrDownloadable={true}
                // onEditClick={handleEditClick}
                // onQrDownloadClick={handleQrDownload}
                onActionClick={(val) => {
                  console.log("onActionClick=>val", val);
                  setSelectedOpSlNo(val);
                }}
              />
            </div>
            <div>
              <Pagination
                page={opPage}
                totalPages={opTotalPages}
                handlePageChange={handleOpPageChange}
              />
            </div>
          </div>

          <div className="bottomArea">
            <button
              onClick={() => handleQRFormClose()}
              className="modalCancelBtn"
            >
              {t("buttons.cancel")}
            </button>
            <button
              onClick={() => handleQrDownload()}
              className="squareOrangeBtn3"
            >
              {t("buttons.generatePlateQR")}
            </button>
          </div>
        </div>
      </Modal>

      {/*modal for download QR*/}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={qrModalShow}
      >
        <Modal.Header closeButton onClick={() => setQrModalShow(false)}>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("buttons.download_qr")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Selected {selectedSlnoLen} plate(s) for download QR</p>
          <div className="selectWrap">
            <div className="form-group my-1">
              <label>{t("areaManagement.cell_width")}</label>
              <br />
              <input
                type="number"
                id="cellWidth"
                value={cellWidth}
                min="1"
                onChange={(e) => {
                  const value = parseInt(e.target.value);
                  if (value > 0) {
                    setCellWidth(value); // Update state only if value is greater than zero
                  }
                }}
              />
            </div>
          </div>
          <div
            id="innerDiv"
            hidden
            style={{
              border: "1px solid black",
              padding: "5px",
              margin: "5px",
              overflow: "scroll",
              height: "80vh",
            }}
          >
            {createGrid()}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => handleDownloadBtn()}>
            {t("buttons.submit")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ScheduleProduction;
