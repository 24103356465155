import React, { useEffect, useState } from "react";
import HeaderSection from "../../../common/headerSection";
import PageHeader from "../../../common/pageHeader";
import CustomToast from "../../../../widgets/toaster";
import CustomTable from "../../../../widgets/table";
import Pagination from "../../../../widgets/pagination";
import { columns } from "./partials/columns";
import AlertModal from "../../../../widgets/alertModal";
import { userService } from "../../../../service/userService";
import { Modal } from 'react-bootstrap';
import { TfiClose } from "react-icons/tfi";
import { useTranslation } from "react-i18next";
import { gradeTypes } from "../../../../service/gradeType";
import { DEFAULT_PAGE_SIZE } from "../../../../constants/constants";



const GradeConfiguration = () => {
  const { t } = useTranslation();
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [useEffectRequired, setUseEffectRequired] = useState(false);
  const [rolesList, setrolesList] = useState<Array<any>>([]);
  const [userId, setUserId] = useState("");
  const [authToken, setAuthToken] = useState("");
  const [authUserID, setAuthUserID] = useState("");
  const [useeffect, setUseEffect] = useState(false);
  const [statusAlert, setStatusAlert] = useState(false);
  const [gradeStatus, setGradeStatus] = useState("");
  const [updatedStatus, setUpdatedStatus] = useState("");
  const [modalName, setModalName] = useState("");
  const [permission, setPermission] = useState<any>([]);
  const [addUserData, setAddUserData] = useState({
    gradeType: "",
    activityType: "",
    minimumCFUCount: "",
    maximumCFUCount: "",
    uom: "",
    reason: "",
    id: ""
  });
  const [showAddModal, setShowAddModal] = useState(false)
  const [addStatusAlert, setAddStatusAlert] = useState(false);
  const [activityList, setActivityList] = useState<Array<any>>([]);
  const [uomList, setUomList] = useState<Array<any>>([]);
  const [grade, setGrade] = useState<Array<any>>([]);
  const [gradeDropDown, setGradeDropDown] = useState("");
  const [activityTypeId, setActivityTypeId] = useState("");
  const [keyWord, setkeyWord] = useState("");

  interface Errors {
    gradeType?: string,
    activityType?: string,
    minimumCFUCount?: string,
    maximumCFUCount?: string,
    reason?: string,
  }
  const [errors, setErrors] = useState<Errors>({});
  const [modalEdit, setModalEdit] = useState(false);


  // render all initial apis
  useEffect(() => {
    var storedData = localStorage.getItem("userDetails");

    if (storedData) {
      var storedObject = JSON.parse(storedData);
      setUserId(storedObject[0].id);
      setAuthToken(storedObject[0].x_auth_token);
      setAuthUserID(storedObject[0].x_userid);
      getPermissionByRole(storedObject[0].role_master_id);
    }

    listGradeTableList();
  }, []);

  useEffect(() => {
    listGradeTableList();
    getActivityList();
    getUOMList();
    listGrade();
    setUseEffectRequired(false);
    setUseEffect(false)
  }, [useeffect, useEffectRequired]);

  // list grade types
  const listGradeTableList = async () => {
    try {
      const response = await gradeTypes.listGradeType(page, pageSize, activityTypeId, gradeDropDown);
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            setTotalPages(response?.totalPages);
            setTotalElements(response?.totalElements);
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                return {
                  slno: index + 1,
                  grade: element.grade,
                  activity_type: element.activity_type_name,
                  id: element.id,
                  min_count: element.min_count,
                  max_count: element.max_count,
                  status: element.status,
                  uom_name: element.uom,
                  type: element.activity_type,
                  grade_id: element.grade_id
                };
              }
            );
            setrolesList(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.error("user response", error);
    }
  };

  // handle pagenation
  const handlePageChange = (newPage: any, pageSize?: number) => {
    setPage(newPage);
    if (pageSize) {
      setPageSize(pageSize);
    }
    setUseEffectRequired(true);
  };

  // handle add limit click
  const handleAddClick = () => {
    setShowAddModal(true)
    setModalName(t('applicationConfiguration.addLimits'))
    setModalEdit(false)
  };

  // handle edit click
  const handleEditClick = (id: string) => {
    const filteredUserDetails = rolesList.filter((item) => item.slno === id);
    const selectedIds = filteredUserDetails.map((item) => item.id).join(',');
    setModalName(t('applicationConfiguration.editLimits'))
    setModalEdit(true)
    setShowAddModal(true)
    setAddUserData({
      gradeType: filteredUserDetails[0]?.grade_id,
      activityType: filteredUserDetails[0]?.type,
      minimumCFUCount: filteredUserDetails[0]?.min_count,
      maximumCFUCount: filteredUserDetails[0]?.max_count,
      uom: filteredUserDetails[0]?.uom_name,
      reason: filteredUserDetails[0]?.reason,
      id: filteredUserDetails[0]?.id
    })
  }

  // handle status change button click
  const handleStatusChange = (area_id: any, status: string) => {
    setStatusAlert(true)
    setGradeStatus(area_id)
    if (status === "active") {
      setUpdatedStatus("inactive")
    } else {
      setUpdatedStatus("active")
    }
  }

  // status change api
  const handleStatusChangeSubmit = async () => {
    try {
      const response = await gradeTypes.changeGradeStatus(gradeStatus, updatedStatus, "Limits Configuration Status");

      if (response.status && response.statusCode === 200) {
        setToastType("success")
        setToastMessage(response.message)
      }
      else {
        setToastType("error")
        setToastMessage(response.message)
      }
      setShowToast(true)
      setUseEffectRequired(true)
      setUpdatedStatus("")
      setGradeStatus("")
      setStatusAlert(false)
    } catch (error) {
      console.error(error);
    }
  }

  //get permission by role 
  const getPermissionByRole = async (role: number) => {
    try {
      const response = await userService.getPermissionByRole(role);
      if (response.status && response.statusCode === 200) {
        setPermission(response.data.permissions)
      }
    } catch (error) {
      console.error(error);
    }
  }

  //  handle input change
  const handleAddUserChange = (e: any) => {
    const { name, value } = e.target;

    let regex;
    if (name === "minimumCFUCount") {
      regex = /^[0-9]{0,10}$/;
    } else if (name === "maximumCFUCount") {
      regex = /^[0-9]{0,10}$/;
      // } else if (name === "reason") {
      //   regex = /^[a-zA-Z][a-zA-Z0-9]*$/;
    }

    if (value === "") {
      // console.log("haa122")
    } else if (regex && !regex.test(value)) {
      return
    }

    setAddUserData((prevFormData: any) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // check special charecter validation
  const checkSpecialChar = (e: any) => {
    if (!/[0-9a-zA-Z]/.test(e.key)) {
      e.preventDefault();
    }
  };

  // close add limits modal
  const addGradeClose = () => {
    setShowAddModal(false)
    setErrors({})
    setAddUserData({
      gradeType: "",
      activityType: "",
      minimumCFUCount: "",
      maximumCFUCount: "",
      uom: "",
      reason: "",
      id: ""
    });
  }

  // handle validation and error
  const handleUserAddAlert = () => {
    const errors: Errors = {};
    // if (!addUserData.gradeType) {
    //   errors.gradeType = "Please enter Grade Type";
    // }
    if (!addUserData.activityType) {
      errors.activityType = t('applicationConfiguration.errorsActivityType');
    }
    if (!addUserData.minimumCFUCount) {
      errors.minimumCFUCount = t('applicationConfiguration.errorsMinCFUCount');
    }
    if (!addUserData.maximumCFUCount) {
      errors.maximumCFUCount = t('applicationConfiguration.errorsMaxCFUCount');
    }
    if (addUserData.id && !addUserData.reason) {
      errors.reason = t('applicationConfiguration.errorsReason');
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    setAddStatusAlert(true)
    setErrors({});
  }

  // add limit configuration api
  const handleGradeSubmit = async () => {
    setAddStatusAlert(false)
    const dataToSend = {
      "grade_id": addUserData.gradeType,
      "activity_type": addUserData.activityType,
      "id": addUserData.id,
      "interface_name": "Limits Configuration",
      "min_count": addUserData.minimumCFUCount,
      "max_count": addUserData.maximumCFUCount,
      "uom": addUserData.uom,
      "reason": addUserData.reason
    }
    try {
      const response = await gradeTypes.addGradeType(dataToSend);
      if (response.status && response.statusCode === 200) {
        setToastType("success")
        setToastMessage(response.message)
        setShowToast(true)
        setAddStatusAlert(false)
        addGradeClose();
        setUseEffectRequired(true)
      } else {
        setToastType("error")
        setToastMessage(response.message)
        setShowToast(true)
        setAddStatusAlert(false)

      }

    } catch (error) {
      console.error('Plate response', error);
    }
  }

  // list activity types
  const getActivityList = async () => {
    try {
      const response = await gradeTypes.getActivityList();
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map((element: any, index: number) => {

              return {
                name: element.activity_name,
                id: element.id,
              };
            });
            setActivityList(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  // list UOM options
  const getUOMList = async () => {
    try {
      const response = await gradeTypes.getUOMList();
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map((element: any, index: number) => {

              return {
                label: element.uom,
                value: element.id,
              };
            });
            setUomList(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  // list grade options
  const listGrade = async () => {
    try {
      const response = await gradeTypes.listGradeOptions();
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            // setTotalPages(response?.totalPages);
            // setTotalElements(response?.totalElements);
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                return {
                  slno: index + 1,
                  grade: element.grade,
                  id: element.id,
                };
              }
            );
            setGrade(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.error("user response", error);
    }
  };

  // handle filter
  const handleFilter = (key: string, value: any) => {
    if (key === "search") {
      setkeyWord(value);
    } else if (key === "activityType") {
      setActivityTypeId(value)
    } else if (key === "gradeType") {
      setGradeDropDown(value)
    }
    setUseEffectRequired(true);
  };

  return (
    <div>
      <HeaderSection />
      <PageHeader pageHeader={t('applicationConfiguration.limitsConfiguration')} />
      <CustomToast
        show={showToast}
        onClose={() => setShowToast(false)}
        delay={5000}
        message={toastMessage}
        toastType={toastType}
      />

      <div className="me-3">
        <div className=" mx-3 pe-2" style={{ height: "45vh" }}>
          <CustomTable
            data={rolesList}
            columns={columns}
            isEditable={permission?.includes("edit_grade_type")}
            totalElements={totalElements}
            isActionVisible={false}
            isViewVisible={false}
            isSeachable={false}
            isToggleStatus={false}
            tableHead={t('applicationConfiguration.limitsTable')}
            showBatchFilter={false}
            ActivityType={activityList}
            GradeTypes={grade}
            showSerielNoFilter={false}
            addButton={permission?.includes("add_grade_type")}
            addButtonText={t('applicationConfiguration.addLimits')}
            onAddClick={handleAddClick}
            onEditClick={handleEditClick}
            onStatusChange={handleStatusChange}
            onUseEffectRequired={() => setUseEffectRequired(true)}
            onFilterClick={handleFilter}
          />
        </div>
        <div>
          <Pagination
            page={page}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
          />
        </div>
      </div>

      <AlertModal
        show={statusAlert}
        onHide={() => setStatusAlert(false)}
        title={t('common.alert')}
        message={t('applicationConfiguration.gradeStatusChange')}
        onConfirm={handleStatusChangeSubmit}
      />

      <AlertModal
        show={addStatusAlert}
        onHide={() => setAddStatusAlert(false)}
        title={t('common.alert')}
        message={`${modalName} ${t('applicationConfiguration.type')}`}
        onConfirm={handleGradeSubmit}
      />

      <Modal
        className="top-right-modal"
        centered={false}
        show={showAddModal}
      >
        <div>
          <div className="modalHead">
            <p className="modalHeadText ms-3 pt-3 p-2">{modalName}</p>
            <div onClick={() => addGradeClose()} className="p-2 mx-3 my-2 ">
              <TfiClose fill="#FFFFFF" />
            </div>
          </div>
          <div>
            <div className="selectWrap">
              <div className="form-group my-1">
                <label htmlFor="">{t('areaManagement.grade')}</label>
                <br />
                <select
                  id="gradeType"
                  value={addUserData.gradeType}
                  name="gradeType"
                  onChange={handleAddUserChange}
                  disabled={modalEdit}
                  className={`placeholder-style ${modalEdit ? 'disabled-css' : ''}`}
                >
                  <option value="">{t('common.select')}</option>
                  {grade.map((obj, index) => (
                    <option key={index} value={obj?.id}>
                      {obj?.grade}
                    </option>
                  ))}
                </select>
                {/* {errors.gradeType && <p className="errorText">{errors.gradeType}</p>} */}
              </div>

              <div className="form-group my-1">
                <label htmlFor="">{t('dashboard.activity_type')}<span style={{ color: 'red' }}>*</span></label>
                <br />
                <select
                  id="activityType"
                  value={addUserData.activityType}
                  name="activityType"
                  onChange={handleAddUserChange}
                  disabled={modalEdit}
                  className={`placeholder-style ${modalEdit ? 'disabled-css' : ''}`}
                >
                  <option value="">{t('common.select')}</option>
                  {activityList.map((obj, index) => (
                    <option key={index} value={obj?.id}>
                      {obj?.name}
                    </option>
                  ))}
                </select>
                {errors.activityType && <p className="errorText">{errors.activityType}</p>}
              </div>
            </div>
            <div className="selectWrap">
              <div className="form-group my-1">
                <label htmlFor="">{t('applicationConfiguration.minCFUCount')}<span style={{ color: 'red' }}>*</span></label>
                <br />
                <input
                  id="minimumCFUCount"
                  value={addUserData.minimumCFUCount}
                  name="minimumCFUCount"
                  type='text'
                  placeholder={t('applicationConfiguration.minCFUCount')}
                  className="placeholder-style"
                  onChange={handleAddUserChange}
                />
                {errors.minimumCFUCount && <p className="errorText">{errors.minimumCFUCount}</p>}
              </div>

              <div className="form-group my-1">
                <label htmlFor="">{t('applicationConfiguration.maxCFUCount')}<span style={{ color: 'red' }}>*</span></label>
                <br />
                <input
                  id="maximumCFUCount"
                  value={addUserData.maximumCFUCount}
                  name="maximumCFUCount"
                  type='text'
                  placeholder={t('applicationConfiguration.maxCFUCount')}
                  className="placeholder-style"
                  onChange={handleAddUserChange}
                />
                {errors.maximumCFUCount && <p className="errorText">{errors.maximumCFUCount}</p>}
              </div>




            </div>
            <div className="selectWrap">
              <div className="form-group my-1">
                <label htmlFor="">{t('applicationConfiguration.uom')}</label>
                <br />
                <select
                  id="uom"
                  value={addUserData.uom}
                  name="uom"
                  onChange={handleAddUserChange}
                >
                  <option value="">{t('common.select')}</option>
                  {uomList.map((obj, index) => (
                    <option key={index} value={obj?.value}>
                      {obj?.label}
                    </option>
                  ))}
                </select>
                {/* {errors.role && <p className="errorText">{errors.role}</p>} */}
              </div>

              {addUserData.id ? (
                <div className="form-group my-1 ">
                  <label htmlFor="" className='ReasonLabel'>{t('userManagement.reason')} <span style={{ color: 'red' }}>*</span></label>
                  <br />
                  <input
                    id="reason"
                    value={addUserData.reason}
                    name="reason"
                    type='text'
                    placeholder={t('userManagement.reason')}
                    className="reasonClass"
                    onChange={handleAddUserChange}
                    onKeyDown={(e) => checkSpecialChar(e)}
                  />
                  {errors.reason && <p className="errorText">{errors.reason}</p>}
                </div>
              ) : (
                <div className="form-group my-1" style={{ "visibility": "hidden" }}>
                  <label htmlFor="">{t('userManagement.department')} <span style={{ color: 'red' }}>*</span></label>
                  <br />
                  <select
                    id="department"
                    // value={addUserData.department}
                    name="department"
                  // onChange={handleAddUserChange} 
                  >
                    <option value="">{t('common.select')}</option>
                    {/* {departmentList.map((obj, index) => (
                          <option key={index} value={obj?.id}>
                            {obj?.department_name}
                          </option>
                        ))} */}
                  </select>
                </div>
              )}

            </div>
          </div>
          <div className="bottomArea">
            <button onClick={() => addGradeClose()} className="modalCancelBtn">
              {t('buttons.cancel')}
            </button>
            <button onClick={() => handleUserAddAlert()} className="squareOrangeBtn3">{t('buttons.submit')}</button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default GradeConfiguration;
